import { Outlet, Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Login from './pages/Login';
import Dashboard from "./pages/home/Dashboard";
import Profile from "./pages/profile/User.js";
import ActivityDetail from './pages/activities/details';
import AccountInfo from "./pages/profile/settings/accountInfo";
import Friends from "./pages/home/Friends";
import ViewMode from "./pages/profile/settings/mode";
import Powerlifting from "./pages/profile/settings/powerlifting";
import ThemePage from "./pages/profile/settings/theme";
import Measurements from "./pages/profile/Measurements";
import Activites from "./pages/activities/index";

import Navbar from "./components/Navbar";

import ProtectedRoute from "./ProtectedRoute";


const RoutesWeb = ({ firebase }) => {
  return <Routes>
    <Route path="/" element={<Home />} />
    <Route path="privacy" element={<Privacy />} />
    <Route path="login" element={<Login firebase={firebase} />} />
    <Route path="/" element={<LayoutsWithNavbar />}>
      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/activities" element={<ProtectedRoute><Activites /></ProtectedRoute>} />
      <Route path="/activity" element={<ProtectedRoute><ActivityDetail /></ProtectedRoute>} />
      <Route path="/AccountInfo" element={<ProtectedRoute><AccountInfo /></ProtectedRoute>} />
      <Route path="/Friends" element={<ProtectedRoute><Friends /></ProtectedRoute>} />
      <Route path="/view_mode" element={<ProtectedRoute><ViewMode /></ProtectedRoute>} />
      <Route path="/PowerliftingSettings" element={<ProtectedRoute><Powerlifting /></ProtectedRoute>} />
      <Route path="/theme" element={<ProtectedRoute><ThemePage /></ProtectedRoute>} />
      <Route path="/measurements" element={<ProtectedRoute><Measurements /></ProtectedRoute>} />
    </Route>
  </Routes>
}

const LayoutsWithNavbar = () => {
  return (
    <>
      {/* Your navbar component */}
      <Navbar />

      {/* This Outlet is the place in which react-router will render your components that you need with the navbar */}
      <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
        <Outlet />
      </div>

      {/* You can add a footer to get fancy in here :) */}
    </>
  );
}

export default RoutesWeb;