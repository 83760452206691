import { uniq, orderBy, groupBy } from 'lodash';
import { db } from '../services/firestoredb';
import { collection, getDocs } from "firebase/firestore";
import { convertToDate } from '../utils/formatDate';
import { uuidv4 } from '../utils/uuid';

export const fetchUserLifts = async (uid, allExercisesinDb) => {
  const querySnapshot = await getDocs(collection(db, `Users/${uid}/lift_tracker_groups`));
  let unsortedLifts = [];
  querySnapshot.forEach((documentSnapshot) => {
    unsortedLifts.push({
      ...documentSnapshot.data(),
      key: documentSnapshot.id,
      actual_date: convertToDate(documentSnapshot.data().lifted_at)
    });
  });
  const sortedLifts = orderBy(unsortedLifts, ['actual_date'], ['desc']);
  const prsbyexercise = sortedLifts.reduce((acc, lift) => {
    const lifts_by_exercise_id = groupBy(
      lift.lift_trackers_attributes,
      'exercise_id',
    );
    const uniq_exercise_ids = uniq(Object.keys(lifts_by_exercise_id));
    uniq_exercise_ids.forEach(eid => {
      const exercise_for_lift =
        allExercisesinDb.find(e => e.id === eid) || {};
      const e_lifts_by_lift_id = groupBy(
        lifts_by_exercise_id[eid],
        'lift_id',
      );
      const e_lift_ids = Object.keys(e_lifts_by_lift_id)
        .sort()
        .reverse();
      e_lift_ids.forEach(e_lift_id => {
        const e_lifts = e_lifts_by_lift_id[e_lift_id];
        e_lifts
          .sort((a, b) => (a.set_num > b.set_num ? -1 : 1))
          .forEach(e_lift => {
            const previous_lifts = acc[eid];
            const prObj = {
              ltg_id: lift.id,
              ltg_date: lift.actual_date,
              exercise_id: eid,
              lift_elapsed_seconds: e_lift.lift_elapsed_seconds,
              lift_id: e_lift.lift_id,
              num_of_reps: e_lift.num_of_reps,
              set_num: e_lift.set_num,
              weight: e_lift.weight,
            };
            if (previous_lifts) {
              if (exercise_for_lift.equipment_type === 'Time') {
                if (exercise_for_lift.more_time_is_pr) {
                  if (
                    e_lift.lift_elapsed_seconds >=
                    acc[eid][0].lift_elapsed_seconds
                  ) {
                    acc[eid] = [prObj];
                  }
                } else {
                  if (
                    e_lift.lift_elapsed_seconds <=
                    acc[eid][0].lift_elapsed_seconds
                  ) {
                    acc[eid] = [prObj];
                  }
                }
              } else if (
                exercise_for_lift.equipment_type === 'Body Only'
              ) {
                if (e_lift.num_of_reps >= acc[eid][0].num_of_reps) {
                  acc[eid] = [prObj];
                }
              } else {
                const with_same_weight = acc[eid].filter(l => {
                  return (
                    l.weight.toString() === e_lift.weight.toString()
                  );
                });
                if (
                  with_same_weight === undefined ||
                  with_same_weight.length === 0
                ) {
                  acc[eid].push(prObj);
                } else {
                  acc[eid] = acc[eid].map(pr => {
                    if (
                      pr.weight === e_lift.weight &&
                      e_lift.num_of_reps >= pr.num_of_reps
                    ) {
                      return prObj;
                    } else {
                      return pr;
                    }
                  });
                }
              }
            } else {
              acc[eid] = [prObj];
            }
          });
      });
    });

    return acc;
  }, {});

  const liftsWithPrs = Object.keys(prsbyexercise)
    .flatMap(pr_e_id => {
      const pr_lifts = prsbyexercise[pr_e_id];
      return pr_lifts.map(pr => {
        return [pr.ltg_id, pr.lift_id, pr.set_num];
      });
    })
    .sort((a, b) => (a[0] > b[0] ? -1 : 1));

  const sortedLiftsWithPrs = sortedLifts.map(ltg => {
    const pr_records = liftsWithPrs.filter(pr => pr[0] === ltg.id);
    if (pr_records) {
      ltg.lift_trackers_attributes = ltg.lift_trackers_attributes.map(
        lta => {
          const pr_record = pr_records.find(
            pr => pr[1] === lta.lift_id && pr[2] === lta.set_num,
          );
          return {
            ...lta,
            personal_record_attributes: pr_record
              ? { id: uuidv4() }
              : undefined,
          };
        },
      );
    } else {
      ltg.lift_trackers_attributes = ltg.lift_trackers_attributes.map(
        lta => {
          return { ...lta, personal_record_attributes: undefined };
        },
      );
    }
    return ltg;
  });


  return sortedLiftsWithPrs;
};

export const getPRsByExerciseID = friendServerLifts => {
  return (friendServerLifts || []).reduce((acc, ltg) => {
    (ltg.lift_trackers_attributes || []).forEach(lt => {
      if (lt.personal_record_attributes) {
        acc[lt.exercise.id] = acc[lt.exercise.id] || [];
        acc[lt.exercise.id].push({
          lift_tracker: {
            lift_elapsed_seconds: lt.lift_elapsed_seconds,
            lift_id: lt.lift_id,
            num_of_reps: lt.num_of_reps,
            set_num: lt.set_num,
            weight: lt.weight,
            exercise_id: lt.exercise.id,
          },
        });
      }
    });
    return acc;
  }, {});
};

const get_last_5_lifts = (current_id, friendServerLifts) => {
  return (friendServerLifts || [])
    .filter(ltg => ltg.id !== current_id)
    .reduce((exerciseLifts, ltg) => {
      uniq(ltg.lift_trackers_attributes.map(lt => lt.exercise.id)).forEach(
        ltg_eid => {
          exerciseLifts[ltg_eid] = exerciseLifts[ltg_eid] || [];
          if (exerciseLifts[ltg_eid].length < 5) {
            exerciseLifts[ltg_eid].push(ltg);
          }
        },
      );
      return exerciseLifts;
    }, {});
};

export const get_last_5_lifts_srws = (current_id, friendServerLifts) => {
  const exerciseHash = get_last_5_lifts(current_id, friendServerLifts);
  return Object.keys(exerciseHash).reduce((exerciseLifts, exerciseId) => {
    const lifts = exerciseHash[exerciseId] || [];
    exerciseLifts[exerciseId] = exerciseLifts[exerciseId] || [];
    lifts.forEach(lift => {
      exerciseLifts[exerciseId].push(
        (lift.lift_trackers_attributes || [])
          .filter(lt => lt.exercise && lt.exercise.id === exerciseId)
          .map(lt => {
            return {
              id: lift.id,
              lifted_at: lift.lifted_at,
              name: lift.name,
              personal_record_attributes: lt.personal_record_attributes,
              lift_elapsed_seconds: lt.lift_elapsed_seconds,
              lift_id: lt.lift_id,
              num_of_reps: lt.num_of_reps,
              set_num: lt.set_num,
              weight: lt.weight,
              exercise_id: lt.exercise.id,
            };
          }),
      );
    });
    return exerciseLifts;
  }, {});
};

export const formatLast5ForCharts = currentLast5 => {
  if (currentLast5 === undefined || currentLast5.length === 0) {
    return undefined;
  }
  let last_5_lifts = {};
  const average = arr => {
    if (arr.length === 0) {
      return 0;
    }
    return arr.reduce((sume, el) => sume + el, 0) / arr.length;
  };
  last_5_lifts['srws'] = currentLast5;
  last_5_lifts['sets'] = {};
  last_5_lifts['avgs'] = {};
  last_5_lifts['max_num_of_sets'] = Math.max(
    ...currentLast5
      .map(srws => {
        return srws
          .map(srw => {
            return srw.set_num;
          })
          .flat();
      })
      .flat(),
  );
  if (last_5_lifts['max_num_of_sets'] > 0) {
    [...Array(last_5_lifts['max_num_of_sets'])].forEach((_, i) => {
      const sets = last_5_lifts['srws']
        .map(srw => {
          return srw.find(s => {
            return s.set_num === i + 1;
          });
        })
        .flat()
        .filter(x => x !== undefined && x !== null && x !== 0);
      last_5_lifts['sets'][i + 1] = sets;
      last_5_lifts['avgs'][i + 1] = {};
      last_5_lifts['avgs'][i + 1]['num_of_reps'] = average(
        sets
          .map(s => s.num_of_reps)
          .filter(n => n !== 0 && n !== undefined && n !== null),
      );
      last_5_lifts['avgs'][i + 1]['weight'] = average(
        sets
          .map(s => s.weight)
          .filter(n => n !== 0 && n !== undefined && n !== null),
      );
      last_5_lifts['avgs'][i + 1]['lift_elapsed_seconds'] = average(
        sets
          .map(s => s.lift_elapsed_seconds)
          .filter(n => n !== 0 && n !== undefined && n !== null),
      );
    });
  }
  last_5_lifts['srws'] = currentLast5;

  return last_5_lifts;
};

const serverLiftsFuncs = { get_last_5_lifts_srws, getPRsByExerciseID, formatLast5ForCharts };


export default serverLiftsFuncs;