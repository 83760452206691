import React, { useContext } from 'react';

import { Whatshot } from '@material-ui/icons';

import { toDisplay } from '../../utils/timer';

import { rpeText } from '../../utils/rpe';
import { themeContext } from '../../contexts/theme';
import { isBlank } from '../../localDatabase/serverExercises';

const SRWTable = ({ liftSets, isBodyWeight, isTimeBased, liftPRs }) => {
  const theme = useContext(themeContext);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          width: '100%',
          marginTop: 10
        }}>
        <div
          style={{
            fontSize: theme.mode === 'weightlifter' ? 14 : 20,
            fontWeight: 'bold',
            width: isBodyWeight || isTimeBased
              ? '33%'
              : theme.mode === 'weightlifter'
                ? '25%'
                : '33%',
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
          }}>
          {'Set'}
        </div>

        <div
          style={{
            fontSize: theme.mode === 'weightlifter' ? 14 : 20,
            fontWeight: 'bold',
            width: isBodyWeight || isTimeBased
              ? '33%'
              : theme.mode === 'weightlifter'
                ? '25%'
                : '33%',
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
          }}>
          {isTimeBased ? 'System' : 'Weight'}
        </div>

        <div
          style={{
            fontSize: theme.mode === 'weightlifter' ? 14 : 20,
            fontWeight: 'bold',
            width: isBodyWeight || isTimeBased
              ? '33%'
              : theme.mode === 'weightlifter'
                ? '25%'
                : '33%',
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
          }}>
          {isTimeBased ? 'Time' : 'Reps'}
        </div>

        {theme.mode === 'weightlifter' && (
          <div
            style={{
              fontSize: theme.mode === 'weightlifter' ? 14 : 20,
              fontWeight: 'bold',
              width: isBodyWeight || isTimeBased ? '0%' : '25%',
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            }}>
            {isTimeBased || isBodyWeight ? '' : '∑ Total'}
          </div>
        )}
      </div>

      {liftSets.map((l) => {
        const hasPR =
        liftPRs &&
        liftPRs.filter(pr => pr.set_num.toString() === l.set_num.toString())
          .length > 0;
        return (
          <React.Fragment key={`${l.id}-${l.lift_id}`}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: l.rpe
                  ? 5
                  : liftSets.some((ls) => ls.rpe)
                    ? 25
                    : 5,
              }}>
              <div
                style={{
                  width: isBodyWeight || isTimeBased
                    ? '33%'
                    : theme.mode === 'weightlifter'
                      ? '25%'
                      : '33%',
                  flexDirection: 'row',
                  display: 'flex'
                }}>
                <div
                  style={{
                    backgroundColor:
                      theme.darkMode
                        ? 'rgba(255,255,255,0.1)'
                        : 'rgba(192,192,192,0.2)',
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 20,
                    display: 'inline-block'
                  }}>
                  <div
                    style={{
                      fontSize: theme.mode === 'weightlifter' ? 15 : 20,
                      fontWeight: 'bold',
                      color: theme.darkMode
                        ? 'rgba(255, 255, 255, 0.9)'
                        : 'rgba(0, 0, 0, 0.9)',
                    }}>
                    {l.set_num.toString()}
                  </div>
                </div>
                {theme.mode === 'weightlifter' &&
                  hasPR && (
                    <div style={{ marginLeft: 2, paddingTop: 3 }}>
                      <Whatshot
                        style={{ fill: `rgb(${theme.primary.main})`, fontSize: 15 }}
                      />
                    </div>
                  )}
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: isBodyWeight || isTimeBased
                    ? '33%'
                    : theme.mode === 'weightlifter'
                      ? '25%'
                      : '33%',
                }}>
                <div
                  style={{
                    fontSize: theme.mode === 'weightlifter' ? 15 : 20,
                    fontWeight: '500',
                    paddingRight: 2,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.9)'
                      : 'rgba(0, 0, 0, 0.9)',
                  }}>
                  {isTimeBased
                    ? 'Time Based'
                    : isBodyWeight
                      ? 'Body Weight'
                      : (l.weight || 0).toString()}
                </div>
                <div
                  style={{
                    paddingTop: 3,
                    fontSize: theme.mode === 'weightlifter' ? 12 : 17,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(0, 0, 0, 0.5)',
                  }}>
                  {isBodyWeight || isTimeBased ? '' : ' lbs'}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: isBodyWeight || isTimeBased
                    ? '33%'
                    : theme.mode === 'weightlifter'
                      ? '25%'
                      : '33%',
                }}>
                <div
                  style={{
                    fontSize: theme.mode === 'weightlifter' ? 15 : 20,
                    fontWeight: '500',
                    paddingRight: 2,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.9)'
                      : 'rgba(0, 0, 0, 0.9)',
                  }}>
                  {isTimeBased ? (
                    toDisplay(l.lift_elapsed_seconds)
                  ) : (
                    l.num_of_reps || 0
                  )}
                </div>
                <div
                  style={{
                    paddingTop: 3,
                    fontSize: theme.mode === 'weightlifter' ? 12 : 17,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(0, 0, 0, 0.5)',
                  }}>
                  {!isTimeBased && ' Reps'}
                </div>
              </div>

              {theme.mode === 'weightlifter' && (<div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: isBodyWeight || isTimeBased ? '0%' : '25%',
                }}>
                <div
                  style={{
                    fontSize: theme.mode === 'weightlifter' ? 15 : 20,
                    fontWeight: '500',
                    paddingRight: 2,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.9)'
                      : 'rgba(0, 0, 0, 0.9)',
                  }}>
                  {isTimeBased
                    ? ''
                    : isBodyWeight
                      ? ''
                      : (l.weight * l.num_of_reps).toString()}
                </div>
                <div
                  style={{
                    paddingTop: 3,
                    fontSize: theme.mode === 'weightlifter' ? 12 : 17,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(0, 0, 0, 0.5)',
                  }}>
                  {isBodyWeight || isTimeBased ? '' : ' lbs'}
                </div>
              </div>)}
            </div>

            {
              l.rpe && (
                <div style={{ paddingBottom: 15 }}>
                  <div
                    style={{
                      color: theme.darkMode
                        ? 'rgba(255, 255, 255, 0.7)'
                        : 'rgba(0, 0, 0, 0.7)',
                      fontSize: 13,
                    }}>
                    RPE: {l.rpe} - {rpeText(l.rpe)}
                  </div>
                </div>
              )
            }
          </React.Fragment>
        );
      })}
      {
        !isBlank(liftSets[0].notes) && (
          <div
            style={{
              fontSize: theme.mode === 'weightlifter' ? 14 : 18,
              paddingTop: 20,
              paddingBottom: 5,
              color: theme.darkMode ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)',
            }}>
            {liftSets[0].notes}
          </div>
        )
      }
    </div >
  );
};

export default SRWTable;