import React, { useContext } from 'react';

import { useNavigate, createSearchParams } from 'react-router-dom';


// import ImageView from 'react-native-image-video-viewing';
import { uniq } from 'lodash';
import Avatar from '../Avatar';

import TimerDisplay from '../../components/lifts/TimerDisplay';
import HeartRateChart from './HeartRateChart';
import PhotoThumbnail from '../../components/PhotoThumbnail';
import LiftCardDetails from './CardDetails';
// import pagedEditLifts from '../../redux/pagedEditLifts/actions';

import { get_last_5_lifts_srws } from '../../localDatabase/serverLifts';
import {
  percent_of_max_by_exercise_for_friend_by_PRs,
  percent_of_max_by_exercise_for_friend,
} from '../../localDatabase/serverExercises';

import { formatDateTime, convertToDate, formatDate } from '../../utils/formatDate';

import { rpeWorkoutText } from '../../utils/rpe';

// import { userContext } from '../../contexts/currentUser';
import { themeContext } from '../../contexts/theme';
import { Whatshot } from '@material-ui/icons';

import LiveLiftLikeButton from './likes/liveLiftLikeButton';

const liftsInDay = (
  lifts,
  defaultDetailsOpen,
  last_5_lifts_by_exercise,
  showCharts,
  vsBestMap,
  heartRateData,
  endTime,
  startTime,
  user,
  lift_guid,
) => {
  let liftComps = [];
  const numOfLifts = Object.keys(lifts).length;
  const numOfValidUniqLiftStarts = uniq(
    Object.keys(lifts).flatMap(lift_num => {
      const lift_sets = lifts[lift_num];
      return lift_sets
        .map(lt => lt.lift_started_at && convertToDate(lt.lift_started_at))
        .filter(x => {
          return x !== null && x !== undefined;
        })
        .filter(x => {
          if (startTime === undefined || startTime === null || endTime === undefined || endTime === null) {
            return false;
          } else {
            return (
              new Date(x).getTime() > startTime.getTime() &&
              new Date(x).getTime() < endTime.getTime()
            );
          }
        });
    }),
  ).length;
  const validForSetCharts = numOfLifts > 1 && numOfValidUniqLiftStarts > 0;
  for (const lift_num in lifts) {
    const lift_sets = lifts[lift_num];
    let HRData;
    if (showCharts && heartRateData && validForSetCharts) {
      if (`${lift_num}` === '1') {
        const to =
          numOfLifts > 1
            ? lifts['2']
              .map(lt => lt.lift_started_at)
              .filter(x => {
                return x !== null && x !== undefined;
              })[0] || endTime
            : endTime;

        HRData = heartRateData.filter(hr => {
          return (
            convertToDate(hr.startDate).getTime() >= startTime.getTime() &&
            convertToDate(hr.startDate).getTime() <= convertToDate(to).getTime()
          );
        });
      } else if (`${numOfLifts}` === `${lift_num}`) {
        const start =
          lift_sets
            .map(lt => lt.lift_started_at)
            .filter(x => {
              return x !== null && x !== undefined;
            })[0] || startTime;
        HRData = heartRateData.filter(hr => {
          return (
            convertToDate(hr.startDate).getTime() >=
            convertToDate(start).getTime() &&
            convertToDate(hr.startDate).getTime() <= endTime.getTime()
          );
        });
      } else {
        const start =
          lift_sets
            .map(lt => lt.lift_started_at)
            .filter(x => {
              return x !== null && x !== undefined;
            })[0] || startTime;
        const to =
          lifts[`${Number(lift_num) + 1}`]
            .map(lt => lt.lift_started_at)
            .filter(x => {
              return x !== null && x !== undefined;
            })[0] || endTime;

        HRData = heartRateData.filter(hr => {
          return (
            convertToDate(hr.startDate).getTime() >=
            convertToDate(start).getTime() &&
            convertToDate(hr.startDate).getTime() <= convertToDate(to).getTime()
          );
        });
      }
    }
    liftComps = liftComps.concat(
      <LiftCardDetails
        key={lift_num}
        liftSets={lift_sets}
        defaultDetailsOpen={defaultDetailsOpen}
        last_5_lifts_by_exercise={last_5_lifts_by_exercise}
        showCharts={showCharts}
        HRData={HRData}
        vsBestMap={vsBestMap}
        liftPRs={
          user &&
          user.liftPRs &&
          user.liftPRs[lift_guid] &&
          user.liftPRs[lift_guid].filter(
            pr => pr.lift_id.toString() === lift_num.toString(),
          )
        }
      />,
    );
  }
  return liftComps;
};

const LiftCard = ({
  lift,
  rawLiftData,
  defaultDetailsOpen,
  showCharts,
  user,
  friendsLifts,
  docID,
  photos,
  thumbs,
}) => {
  const navigate = useNavigate();
  // const [pictureViewerVisible, setIsPictureViewerVisible] = useState(false);
  // const [applyPhotoVisible, setApplyPhotoVisible] = useState(false);
  // const [pictureViewerIndex, setIsPictureViewerIndex] = useState(0);
  const numOfPRs = user && user.liftPRs
    ? (user.liftPRs[lift.id] || []).length
    : lift.lift_trackers_attributes &&
    lift.lift_trackers_attributes.filter(tracker => {
      return (
        tracker.personal_record_attributes &&
        tracker.personal_record_attributes.id
      );
    }).length;

  const theme = useContext(themeContext);
  // const currentUser = useContext(userContext);
  // const currentUserLift = currentUser.uid === (user && user.uid);
  const vsBestMap = user.exercisePRs
    ? percent_of_max_by_exercise_for_friend_by_PRs(
      lift.lift_trackers_attributes,
      user.exercisePRs || {},
    )
    : percent_of_max_by_exercise_for_friend(
      lift.lift_trackers_attributes,
      friendsLifts,
    );
  // const [toggleIndex, setToggleIndex] = useState(0);
  // const [photoLiftId, setPhotoLiftId] = useState();
  // const [photoSetId, setPhotoSetId] = useState();

  // const editLift = () => {
  //   dispatch(pagedEditLifts.resetEverything());
  //   setEditDrawerOpen(false);

  //     navigation.push('EditLift', {
  //       screen: 'EditLift',
  //       params: {
  //         id: docID,
  //       },
  //       id: docID,
  //     });

  // };

  // const deleteLift = () => {

  //   let promises = [];
  //   photos.forEach(photo => {
  //     promises.push(
  //       new Promise((resolve, reject) => {
  //         photo.ref
  //           .delete()
  //           .then(() => {
  //             resolve();
  //           })
  //           .catch(err => {
  //             reject();
  //           });
  //       }),
  //     );
  //   });
  //   promises.push(
  //     new Promise((resolve, reject) => {
  //       firestore()
  //         .collection(`lift_comment`)
  //         .doc(docID)
  //         .delete()
  //         .then(() => {
  //           resolve();
  //         })
  //         .catch(err => {
  //           resolve();
  //         });
  //     }),
  //   );

  //   promises.push(
  //     new Promise((resolve, reject) => {
  //       firestore()
  //         .collection(`lift_likes`)
  //         .doc(docID)
  //         .delete()
  //         .then(() => {
  //           resolve();
  //         })
  //         .catch(err => {
  //           resolve();
  //         });
  //     }),
  //   );

  //   Promise.all(promises).then(() => {
  //     firestore()
  //       .collection(`Users/${user.uid}/lift_tracker_groups`)
  //       .doc(docID)
  //       .delete()
  //       .then(() => {
  //         navigation.goBack();
  //       });
  //   });
  // };

  // const thePhoto = photos && photos[pictureViewerIndex];
  // const thePhotosrw = thePhoto && thePhoto.liftId &&
  //   thePhoto.setId &&
  //   rawLiftData.lift_trackers_attributes.find(
  //     lt =>
  //       lt.lift_id === thePhoto.liftId && lt.set_num === thePhoto.setId,
  //   );

  return lift &&
    lift.lift_trackers &&
    Object.keys(lift.lift_trackers).length > 0 &&
    user &&
    Object.keys(user).length > 0 ? (
    <>
      <div
        onClick={() => {
          navigate({
            pathname: '/profile', search: `?${createSearchParams({
              user_id: user && user.uid,
            })}`
          });
        }}
        style={{
          display: 'flex',
          padding: 10,
          marginLeft: 10,
          marginRight: 10,
          backgroundColor: theme.darkMode
            ? 'rgba(255,255,255,0.07)'
            : 'rgba(255,255,255,0.4)',
          borderRadius: 5,
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderWidth: 1,
          borderColor: 'rgba(0,0,0,0.1)',
        }}>
        <Avatar
          type="image"
          color={`rgb(${theme.primary.main})`}
          image={
            <img
              alt="avatar"
              src={user && user.photoURL}
            />
          }
          size={45}
        />
        <div
          style={{
            marginLeft: 15,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}>
          <div
            style={{
              fontSize: 22,
              fontWeight: '300',
              paddingBottom: 2,
              color: theme.darkMode ? `rgb(255,255,255)` : `rgb(0,0,0)`,
            }}>{`${user.displayName || 'Unknown Person'}`}</div>
          <div
            style={{
              color: theme.darkMode
                ? 'rgba(255,255,255,0.9)'
                : 'rgba(0,0,0,0.9)',
              fontSize: 12,
              fontWeight: '200',
            }}>
            {`Joined ${formatDateTime(user.created_at)}`}
          </div>
        </div>
      </div>
      <div>
        <div>
          <div
            style={{
              margin: 10,
              backgroundColor: theme.darkMode
                ? 'rgba(255,255,255,0.07)'
                : 'rgba(255,255,255,0.4)',
              borderRadius: 5,
              padding: 10,
              borderWidth: 1,
              borderColor: 'rgba(0,0,0,0.1)',
            }}>
            <div
              style={{
                color: `rgb(${theme.primary.main})`,
                fontSize: theme.mode === 'weightlifter' ? 20 : 25,
              }}>{`${lift.name}`}</div>
            <div
              style={{
                fontSize: theme.mode === 'weightlifter' ? 15 : 20,
                fontWeight: '500',
                color: theme.darkMode
                  ? 'rgba(255,255,255,.8)'
                  : 'rgba(0,0,0,0.8)',
              }}>
              {formatDateTime(lift.lifted_at)} - {formatDate(lift.lifted_at)}
            </div>
            {theme.mode === 'weightlifter' && lift.rpe && (
              <div
                style={{
                  color: theme.darkMode
                    ? 'rgba(255,255,255,0.7)'
                    : 'rgba(0,0,0,0.7)',
                  fontSize: 15,
                }}>
                RPE: {lift.rpe} - {rpeWorkoutText(lift.rpe)}
              </div>
            )}
          </div>
          {photos.length > 0 && (
            <>
              <div
                style={{
                  margin: 10,
                  backgroundColor: theme.darkMode
                    ? 'rgba(255,255,255,0.07)'
                    : 'rgba(255,255,255,0.4)',
                  borderRadius: 5,
                  padding: 10,
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.1)',
                }}>
                <div
                  style={{
                    width: '100%',
                    borderRadius: 5,
                    flexDirection: 'row',
                    overflow: 'scroll',
                    display: 'flex'
                  }}>
                  {photos.map((photo, index) => {
                    const thumb = thumbs.find(t => {
                      return t.id === photo.id
                    })
                    return <PhotoThumbnail
                      key={`photothumbnail-${index}`}
                      photo={photo}
                      thumb={thumb}
                      index={index}
                      // setIsPictureViewerIndex={setIsPictureViewerIndex}
                      // setIsPictureViewerVisible={
                      //   setIsPictureViewerVisible
                      // }
                      // longPressAction={() => {
                      //   if (currentUserLift) {
                      //     setIsPictureViewerIndex(index);
                      //     setApplyPhotoVisible(true);
                      //   }
                      // }}
                      liftId={photo.liftId}
                      setId={photo.setId}
                      rawLiftData={rawLiftData}
                    />
                  })}
                </div>
                {/* {currentUserLift && (
                          <>
                            <div style={{width: '100%', height: 10}}></div>
                            <div
                              style={{
                                color: theme.darkMode
                                  ? 'rgb(255,255,255)'
                                  : 'rgb(0,0,0)',
                                fontSize: 12,
                                fontWeight: '500',
                              }}

                              >
                              Press and hold to assign file to set.
                            </div>
                          </>
                        )} */}
              </div>
              {/* <PopupModal
                        closeModal={() => {
                          setPhotoLiftId(undefined);
                          setPhotoSetId(undefined);
                          setApplyPhotoVisible(false);
                        }}
                        visible={applyPhotoVisible}
                        title={'Apply To Set'}>
                        <PhotoPreview photo={photos[pictureViewerIndex]} />
                        <div>
                          <div
                            style={{
                              color: theme.darkMode
                                ? 'rgb(255,255,255)'
                                : 'rgb(0,0,0)',
                              fontSize: 16,
                            }}>
                            Select - Lift
                          </div>
                          {Object.keys(lift.lift_trackers).map(lift_id => {
                            const lift_data_for_id =
                              lift.lift_trackers[lift_id];
                            const lift_data_for_id_exercise =
                              lift_data_for_id[0].exercise;
                            return (
                              <ListItem
                                key={lift_id}
                                item={`${lift_id}) ${lift_data_for_id_exercise.name}`}
                                selected={lift_id === photoLiftId}
                                color={
                                  lift_id === photoLiftId
                                    ? `rgba(${theme.primary.main},0.2)`
                                    : 'transparent'
                                }
                                textColor={
                                  theme.darkMode
                                    ? 'rgb(255,255,255)'
                                    : 'rgb(0,0,0)'
                                }
                                onClick={() => {
                                  setPhotoLiftId(lift_id);
                                  setPhotoSetId(undefined);
                                }}
                              />
                            );
                          })}
                          {photoLiftId && (
                            <>
                              <div
                                style={{
                                  color: theme.darkMode
                                    ? 'rgb(255,255,255)'
                                    : 'rgb(0,0,0)',
                                  fontSize: 16,
                                }}>
                                Select - Set
                              </div>
                              {lift.lift_trackers[photoLiftId].map(srw => {
                                return (
                                  <ListItem
                                    key={srw.set_num}
                                    item={`${srw.set_num}`}
                                    selected={srw.set_num === photoSetId}
                                    color={
                                      srw.set_num === photoSetId
                                        ? `rgba(${theme.primary.main},0.2)`
                                        : 'transparent'
                                    }
                                    textColor={
                                      theme.darkMode
                                        ? 'rgb(255,255,255)'
                                        : 'rgb(0,0,0)'
                                    }
                                    onClick={() => {
                                      setPhotoSetId(srw.set_num);
                                    }}
                                  />
                                );
                              })}
                            </>
                          )}
                          {photoLiftId && photoSetId && (
                            <div style={{ height: 50 }}>
                              <Button
                                text={'Save'}
                                type="flat"
                                color={`rgb(${theme.primary.main})`}
                                textColor={
                                  theme.darkMode
                                    ? 'rgb(0,0,0)'
                                    : 'rgb(255,255,255)'
                                }
                                fullWidth
                                onClick={() => {
                                  const new_lift_trackers_attributes =
                                    rawLiftData.lift_trackers_attributes.map(
                                      lt => {
                                        return lt.lift_id === photoLiftId &&
                                          lt.set_num === photoSetId
                                          ? {
                                            ...lt,
                                            photoPath:
                                              photos[pictureViewerIndex].path,
                                          }
                                          : lt;
                                      },
                                    );
                                  firestore()
                                    .collection(
                                      `Users/${currentUser.uid}/lift_tracker_groups`,
                                    )
                                    .doc(docID)
                                    .update({
                                      ...rawLiftData,
                                      lift_trackers_attributes:
                                        new_lift_trackers_attributes,
                                    })
                                    .then(doc => {
                                      setPhotoLiftId(undefined);
                                      setPhotoSetId(undefined);
                                      setApplyPhotoVisible(false);
                                    });
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </PopupModal> */}
            </>
          )}
          {lift.health_data !== undefined && lift.health_data !== null && (
            <div
              style={{
                borderRadius: 5,
                backgroundColor: theme.darkMode
                  ? 'rgba(255,255,255,0.07)'
                  : 'rgba(255,255,255,0.4)',
                padding: 10,
                margin: 10,
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,0.1)',
              }}>
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 10,
                  display: 'flex',
                }}>
                <div
                  style={{
                    flexGrow: 1,
                    width: '25%',
                    paddingEnd: 10,
                  }}>
                  <div
                    style={{
                      color: theme.darkMode
                        ? 'rgb(255,255,255)'
                        : 'rgb(0,0,0)',
                    }}>
                    Total Time
                  </div>
                  <TimerDisplay
                    textStyle={{
                      color: `rgb(${theme.primary.main})`,
                      fontSize: 15,
                    }}
                    elaspedTime={
                      (convertToDate(
                        lift.health_data.endDate,
                      ).getTime() -
                        convertToDate(
                          lift.health_data.startDate,
                        ).getTime()) /
                      1000
                    }
                  />
                </div>
                {parseInt(lift.health_data.activeCalories) > 0 && (
                  <div
                    style={{
                      flexGrow: 1,
                      width: '25%',
                      paddingEnd: 10,
                    }}>
                    <div
                      style={{
                        color: theme.darkMode
                          ? 'rgb(255,255,255)'
                          : 'rgb(0,0,0)',
                      }}>
                      Active Cal
                    </div>
                    <div
                      style={{
                        color: '#ff4d4d',
                        fontSize: 15,
                        textShadowColor: 'rgb(0,0,0)',
                        textShadowRadius: theme.darkMode ? 0 : 0.5,
                        textShadowOffset: { width: 0.5, height: 0.5 },
                      }}>
                      {`${lift.health_data.activeCalories}`}
                    </div>
                  </div>
                )}
                {parseInt(lift.health_data.basalCalories) > 0 &&
                  parseInt(lift.health_data.activeCalories) > 0 && (
                    <div
                      style={{
                        flexGrow: 1,
                        width: '25%',
                        paddingEnd: 10,
                      }}>
                      <div
                        style={{
                          color: theme.darkMode
                            ? 'rgb(255,255,255)'
                            : 'rgb(0,0,0)',
                        }}>
                        Total Cal
                      </div>
                      <div
                        style={{
                          color: '#ff4d4d',
                          fontSize: 15,
                          textShadowColor: 'rgb(0,0,0)',
                          textShadowRadius: theme.darkMode ? 0 : 0.5,
                          textShadowOffset: {
                            width: 0.5,
                            height: 0.5,
                          },
                        }}>
                        {`${lift.health_data.basalCalories +
                          lift.health_data.activeCalories
                          }`}
                      </div>
                    </div>
                  )}
                {lift.health_data.heartRateData &&
                  lift.health_data.heartRateData.length > 0 && (
                    <div
                      style={{
                        flexGrow: 1,
                        width: '25%',
                        paddingEnd: 10,
                      }}>
                      <div
                        style={{
                          color: theme.darkMode
                            ? 'rgb(255,255,255)'
                            : 'rgb(0,0,0)',
                        }}>
                        Avg Heart
                      </div>
                      <div
                        style={{
                          color: '#ff1a1a',
                          fontSize: 15,
                          textShadowColor: 'rgb(0,0,0)',
                          textShadowRadius: theme.darkMode ? 0 : 0.5,
                          textShadowOffset: {
                            width: 0.5,
                            height: 0.5,
                          },
                        }}>
                        {`${Math.ceil(
                          lift.health_data.heartRateData
                            .map(hr => hr['value'])
                            .reduce((a, b) => a + b) /
                          lift.health_data.heartRateData.length,
                        )}`}
                      </div>
                    </div>
                  )}
              </div>
              {lift.health_data.heartRateData &&
                lift.health_data.heartRateData.length > 0 && (
                  <>
                    <div style={{ paddingBottom: 15 }}>
                      <div
                        style={{
                          fontSize: 20,
                          color: theme.darkMode
                            ? 'rgb(255,255,255)'
                            : 'rgb(0,0,0)',
                        }}>
                        Heart Rate
                      </div>
                    </div>
                    <HeartRateChart
                      data={lift.health_data.heartRateData.map(hr => ({
                        date: convertToDate(hr.startDate),
                        value: hr.value,
                      }))}
                      lift_starts={uniq(
                        Object.keys(lift.lift_trackers).flatMap(l => {
                          return uniq(
                            lift.lift_trackers[l].map(lt =>
                              convertToDate(lt.lift_started_at),
                            ),
                          );
                        }),
                      ).filter(x => {
                        return x !== null && x !== undefined;
                      })}
                    />
                  </>
                )}
            </div>
          )}
          {liftsInDay(
            lift.lift_trackers,
            defaultDetailsOpen,
            get_last_5_lifts_srws(lift.id, friendsLifts || []),
            showCharts,
            vsBestMap,
            lift.health_data && lift.health_data.heartRateData,
            lift.health_data && convertToDate(lift.health_data.endDate),
            lift.health_data &&
            convertToDate(lift.health_data.startDate),
            user,
            lift.id,
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              marginLeft: 15,
            }}>
            {theme.mode === 'weightlifter' && numOfPRs > 0 && (
              <>
                <div
                  style={{
                    fontSize: 18,
                    color: theme.darkMode
                      ? 'rgb(255,255,255)'
                      : 'rgb(0,0,0)',
                  }}>{`${numOfPRs} PR${numOfPRs > 1 ? 's' : ''}`}</div>
                <Whatshot
                  style={{ fill: `rgb(${theme.primary.main})`, fontSize: 25 }}
                />
              </>
            )}
          </div>
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginRight: 20,
              flexShrink: 1,
            }}>
            <LiveLiftLikeButton lift_id={docID} lift_user_uid={user?.uid} lift_name={lift?.name} />
          </div>
        </div>
      </div>
    </>) : null;
};

export default LiftCard;