import React, { useEffect, useState, useContext } from 'react';
import SmallCard from './SmallCard';
import Avatar from '../Avatar';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { convertToDate } from '../../utils/formatDate';
import { themeContext } from '../../contexts/theme';
import { getCurrentUser } from '../../localDatabase/serverUsers';

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

const SmallCardWithUser = ({ lift, noIcon, local, hideName }) => {
    const theme = useContext(themeContext);
    const navigation = useNavigate();
    const [user, setUser] = useState({});

    useEffect(() => {
        getCurrentUser(lift.uid, (returnedUserData) => {
            setUser(returnedUserData);
        })
    }, [lift.uid]);

    return (
        <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div
                style={{
                    padding: 15,
                    alignContent: 'center',
                }}>
                {noIcon !== true && (
                    <Avatar
                        type="image"
                        color={`rgb(${theme.primary.main})`}
                        image={
                            <img
                                alt={'Lift user'}
                                src={user && user.photoURL}
                            />
                        }
                        size={40}
                        onPress={() => {
                            navigation({
                                pathname: '/activity', search: `?${createSearchParams({
                                    user_id: user && user.uid,
                                })}`
                            })
                        }}
                    />
                )}
                <div
                    style={{
                        fontSize: 25,
                        fontWeight: '200',
                        textAlign: 'center',
                        color: theme.darkMode ? `rgb(255,255,255)` : `rgb(0,0,0)`,
                    }}>{`${convertToDate(lift.lifted_at).getDate()}`}</div>
                <div
                    style={{
                        fontSize: 20,
                        fontWeight: '300',
                        textAlign: 'center',
                        color: theme.darkMode ? `rgb(255,255,255)` : `rgb(0,0,0)`,
                    }}>{`${monthNames[convertToDate(lift.lifted_at).getMonth()]}`}</div>
            </div>
            <div style={{ flexShrink: 1, flexGrow: 1, paddingTop: 10 }}>
                {!hideName && user && Object.keys(user).length > 0 && (
                    <div
                        style={{
                            fontSize: 25,
                            fontWeight: '200',
                            paddingLeft: 10,
                            color: theme.darkMode ? `rgb(255,255,255)` : `rgb(0,0,0)`,
                        }}>{`${user.displayName || 'Unknown Person'}`}</div>
                )}
                <SmallCard item={lift} local={local} />
            </div>
        </div>
    );
};

export default SmallCardWithUser;