import React, { useContext, useEffect, useState } from 'react';
import { themeContext } from '../../contexts/theme';
import { userContext } from '../../contexts/currentUser';
import { db } from '../../services/firestoredb';
import { collection, query, onSnapshot } from "firebase/firestore";
import humanizeString from '../../utils/humanize';

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryTheme,
  VictoryGroup,
  VictoryScatter,
} from 'victory';

import { convertToDate, monthDayText } from '../../utils/formatDate';
import { filterFloatorZero } from '../../utils/filterInt';

const Measurements = () => {
  const theme = useContext(themeContext);
  const currentUser = useContext(userContext);
  const [loading, setLoading] = useState(true);
  const [measurements, setMeasurements] = useState([]);
  const dataPoints = [
    'weight',
    'neck',
    'forearm',
    'arm',
    'shoulderWidth',
    'chest',
    'waist',
    'leg',
    'calf',
  ];

  const dataByPoint = dataPoints.reduce((obj, point) => {
    obj[point] = measurements
      .map(m => {
        return m[point] === null || m[point] === undefined
          ? undefined
          : {
            date: convertToDate(m.measureDate),
            x: monthDayText(m.measureDate),
            y: filterFloatorZero(m[point]),
          };
      })
      .filter(x => {
        return x !== undefined;
      })
      .sort((a, b) => (a.date > b.date ? 1 : -1));
    return obj;
  }, {});

  useEffect(() => {
    setLoading(true);
    let unsubscribe = () => { };
    const q = query(collection(db, `Users/${currentUser.uid}/measurements`));
    unsubscribe = onSnapshot(q, (querySnapshot) => {
      let measurementSnapshots = [];
      querySnapshot.forEach((documentSnapshot) => {
        measurementSnapshots.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id
        });
      });
      setMeasurements(measurementSnapshots);
      setLoading(false);
    })
    return unsubscribe;
  }, [currentUser.uid]);

  return (
    <div>
      <div
        style={{
          padding: 15,
        }}>
        <div
          style={{
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            fontSize: 15,
            fontWeight: 'bold',
            paddingBottom: 20,
          }}>
          * This data is only viewable by you and only you.
        </div>
        {!loading &&
          measurements.length > 0 &&
          Object.keys(dataByPoint).map((point, index) => {
            const dataFromPoint = dataByPoint[point];
            const minValue = Math.min(...dataFromPoint.map(d => d.y));
            const maxValue = Math.max(...dataFromPoint.map(d => d.y));
            return (
              <div key={`chart-thing-${index}`}>
                <div
                  style={{
                    color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
                    fontSize: 20,
                    fontWeight: 'bold',
                  }}>
                  {humanizeString(point)}
                </div>
                {dataFromPoint && dataFromPoint.length > 0 && (
                  <VictoryChart
                    height={200}
                    minDomain={{ y: minValue - 5 }}
                    maxDomain={{ y: maxValue + 5 }}>
                    <VictoryAxis
                      crossAxis
                      fixLabelOverlap={true}
                      style={{
                        grid: {
                          fill: 'none',
                          stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'
                            }, 0.1)`,
                          strokeDasharray: '10, 5',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                          pointerEvents: 'painted',
                        },
                        axis: {
                          stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'
                            })`,
                          strokeDasharray: '10, 5',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                        },
                        ticks: {
                          stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'
                            })`,
                          size: 0,
                        },
                        tickLabels: {
                          fontSize: 15,
                          padding: 5,
                          fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'
                            })`,
                        },
                      }}
                    />
                    <VictoryAxis
                      dependentAxis
                      theme={VictoryTheme.material}
                      standalone={true}
                      style={{
                        grid: {
                          fill: 'none',
                          stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'
                            }, 0.1)`,
                          strokeDasharray: '10, 5',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                          pointerEvents: 'painted',
                        },
                        axis: {
                          stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'
                            })`,
                          strokeDasharray: '10, 5',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                        },
                        ticks: {
                          stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'
                            })`,
                          size: 0,
                        },
                        tickLabels: {
                          fontSize: 15,
                          padding: 5,
                          fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'
                            })`,
                        },
                      }}
                    />
                    <VictoryGroup data={dataFromPoint}>
                      <VictoryLine
                        style={{
                          data: {
                            stroke: `rgb(${theme.primary.main})`,
                            strokeWidth: 3,
                          },
                          labels: { fill: 'white' },
                        }}
                      />
                      <VictoryScatter
                        style={{ data: { fill: `rgb(${theme.primary.main})` } }}
                        size={4}
                      />
                    </VictoryGroup>
                  </VictoryChart>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Measurements;