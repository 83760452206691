import React, {cloneElement} from 'react';

const Avatar = ({color, image, size, onClick}) => {
  return (
    <div onClick={onClick}>
      <div
        style={{
          borderRadius: size / 2,
          backgroundColor: color,
          width: size,
          height: size,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {cloneElement(image, {
          style: {width: size, height: size, borderRadius: size / 2},
        })}
      </div>
    </div>
  );
};

export default Avatar;