import React, { useState, useContext } from 'react';

import { themeContext } from '../../../contexts/theme';
import { userContext } from '../../../contexts/currentUser';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

const ThemePage = () => {
  const theme = useContext(themeContext);
  const [saveToolTipOpen, setSaveToolTipOpen] = useState(false);
  const [updating, setUpdating] = useState();
  const currentUser = useContext(userContext);
  const [darkMode, setDarkMode] = useState(theme.darkMode);
  const [color, setColor] = useState(
    theme.primary.main ? theme.primary.main : '118, 255, 3',
  );
  const [themecolor, setthemeColor] = useColor("rgb", {r: color.split(', ')[0], g: color.split(', ')[1], b: color.split(', ')[2], a: undefined});

  const [backgroundColor, setBackgroundColor] = useState(
    theme.primary.backgroundColor
      ? theme.primary.backgroundColor
      : theme.darkMode
        ? '0, 0, 0'
        : '237, 237, 237',
  );
  const [backgroundcolor, setbackgroundcolor] = useColor("rgb", {r: backgroundColor.split(', ')[0], g: backgroundColor.split(', ')[1], b: backgroundColor.split(', ')[2], a: undefined});

  const updateBackgroundColor = newColor => {
    const rgb = newColor.rgb;
    const realnewcolor = `${rgb.r}, ${rgb.g}, ${rgb.b}`
    setBackgroundColor(realnewcolor)
    setbackgroundcolor(newColor)
  };

  const updateColor = newColor => {
    const rgb = newColor.rgb;
    const realnewcolor = `${rgb.r}, ${rgb.g}, ${rgb.b}`
    setColor(realnewcolor)
    setthemeColor(newColor)
  };

  return (
    <div
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: `rgb(${theme.primary.backgroundColor
          ? theme.primary.backgroundColor
          : theme.darkMode
            ? '0, 0, 0'
            : '237, 237, 237'})`,
      }}>
      <Snackbar open={saveToolTipOpen} autoHideDuration={6000} onClose={() => setSaveToolTipOpen(false)}>
        <Alert onClose={() => setSaveToolTipOpen(false)} severity="success" sx={{ width: '100%' }}>
          Theme Updated!
        </Alert>
      </Snackbar>
      <div style={{ height: 20, width: '100%' }} />
      <FormGroup>
        <FormControlLabel control={<Checkbox
          inputProps={{ 'aria-label': 'controlled' }}
          checked={darkMode}
          onChange={() => {
            setBackgroundColor(darkMode ? '237, 237, 237' : '0, 0, 0');
            setDarkMode(!darkMode);
          }} />}
          label="Dark Mode" />
      </FormGroup>
      <div style={{ height: 20, width: '100%' }} />

      <>
        <div
          style={{
            fontSize: 23,
            color: darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            marginBottom: 10,
          }}>
          Theme Color
        </div>
        <ColorPicker width={456} height={228} color={themecolor} onChange={updateColor} hideHSV dark />

      </>
      <div style={{ height: 20, width: '100%' }} />

      <>
        <div
          style={{
            fontSize: 23,
            color: darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            marginBottom: 10,
          }}>
          Background Color
        </div>
        <ColorPicker width={456} height={228} color={backgroundcolor} onChange={updateBackgroundColor} hideHSV dark />

      </>
      <div style={{ height: 20, width: '100%' }} />

      <Button
        variant="contained"
        disabled={updating}
        fullWidth
        onClick={() => {
          const doit = async () => {
            setUpdating(true);
            await theme.changeTheme()(currentUser.uid, {
              darkMode: darkMode,
              primary: {
                main: color,
                backgroundColor: backgroundColor,
              },
            });
            setSaveToolTipOpen(true);
            setUpdating(false);
          }
          doit()
        }}
      >
        Save Theme
      </Button>
    </div>
  );
};

export default ThemePage;