import { getPRsByExerciseID } from './serverLifts';
import { db } from '../services/firestoredb';
import { collection, getDocs } from "firebase/firestore";
import defaultExercises from './defaultExercises';


export const fetchUserExercises = async (uid) => {
  const querySnapshot = await getDocs(collection(db, `Users/${uid}/Exercises`));
  let customExercises = [];
  querySnapshot.forEach((doc) => {
    customExercises.push(doc.data());
  });
  return [...customExercises, ...defaultExercises]
}

export const isBlank = (value) => {
  return value === null || value === undefined || value === '';
};

export const best_time_for_exercise = (prs, more_time_is_pr) => {
  prs = prs || [];
  if (more_time_is_pr) {
    return Math.max(...prs.map((pr) => pr.lift_tracker.lift_elapsed_seconds));
  } else {
    return Math.min(...prs.map((pr) => pr.lift_tracker.lift_elapsed_seconds));
  }
};

export const max_reps_for_exercise = (prs) => {
  prs = prs || [];
  return Math.max(...prs.map((pr) => pr.lift_tracker.num_of_reps));
};

export const max_weight_for_exercise = (prs) => {
  prs = prs || [];
  return Math.max(...prs.map((pr) => pr.lift_tracker.weight));
};

export const calc_vs_best_for_lift = (lift, prs) => {
  const exercise_for_lift = lift.exercise;
  if (exercise_for_lift.equipment_type === 'Time') {
    const lift_elapsed_seconds = lift.lift_elapsed_seconds;
    if (isBlank(lift_elapsed_seconds) || lift_elapsed_seconds === 0) {
      return 100;
    } else {
      const bestTimeForExercise = best_time_for_exercise(
        prs,
        exercise_for_lift.more_time_is_pr,
      );
      if (exercise_for_lift.more_time_is_pr) {
        return Math.floor(
          (parseFloat(lift_elapsed_seconds) / parseFloat(bestTimeForExercise)) *
          100,
        );
      } else {
        return Math.floor(
          (parseFloat(bestTimeForExercise) / parseFloat(lift_elapsed_seconds)) *
          100,
        );
      }
    }
  } else if (exercise_for_lift.equipment_type === 'Body Only') {
    const num_of_reps = lift.num_of_reps;
    if (isBlank(num_of_reps)) {
      return 100;
    } else {
      return Math.floor(
        (parseFloat(num_of_reps) / parseFloat(max_reps_for_exercise(prs))) *
        100,
      );
    }
  } else {
    const weight = lift.weight;
    if (isBlank(weight)) {
      return 100;
    } else {
      return Math.floor(
        (parseFloat(weight) / parseFloat(max_weight_for_exercise(prs))) * 100,
      );
    }
  }
};

export const percent_of_max_by_exercise_for_friend = (
  lifts,
  friendServerLifts,
) => {
  const PRsByExerciseID = getPRsByExerciseID(friendServerLifts || []);
  const percentOfMaxByExercise = {};
  (lifts || []).forEach((lift) => {
    const prs = PRsByExerciseID[lift.exercise.id];
    percentOfMaxByExercise[
      `${lift.lift_id}-${lift.set_num}`
    ] = calc_vs_best_for_lift(lift, prs);
  });
  return percentOfMaxByExercise;
};

export const percent_of_max_by_exercise_for_friend_by_PRs = (
  lifts,
  PRsByExerciseID,
) => {
  const percentOfMaxByExercise = {};
  (lifts || []).forEach(lift => {
    const prs = PRsByExerciseID[lift.exercise.id];
    percentOfMaxByExercise[`${lift.lift_id}-${lift.set_num}`] =
      calc_vs_best_for_lift(
        lift,
        prs.map(pr => {
          return {
            ...pr,
            lift_tracker: {
              lift_elapsed_seconds: pr.lift_elapsed_seconds,
              weight: pr.weight,
              num_of_reps: pr.num_of_reps,
            },
          };
        }),
      );
  });
  return percentOfMaxByExercise;
};

const serverExercisesFuncs = { fetchUserExercises, percent_of_max_by_exercise_for_friend, percent_of_max_by_exercise_for_friend_by_PRs, calc_vs_best_for_lift, isBlank, best_time_for_exercise, max_reps_for_exercise, max_weight_for_exercise };

export default serverExercisesFuncs;