import React from "react";

import Background from '../images/loginBackgroundBlurred.jpg';
import LogoImageLight from '../images/brolift-logo-light.png';
import LogoImageDark from '../images/brolift-logo.png';


import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';


const Login = ({firebase}) => {

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      {
        provider: "apple.com"
      }
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };
  const darkMode = true;

  return (
    <div style={{ background: `url(${Background})`, height: '100vh', backgroundSize: 'cover' }}>
      <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        height: '100vh'
      }}>
        <div
          style={{
            backgroundColor: 'rgba(255,255,255,0.1)',
            padding: 20,
            borderRadius: 20,
            height: 300,
            width: 400,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <img
            alt="Brolift Logo"
            style={{ width: 250, height: 60, marginBottom: 30 }}
            src={
              darkMode
                ? LogoImageDark
                : LogoImageLight
            }
          />
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </div>
      </div>
    </div>
  );
}
export default Login;