import React, {useContext} from 'react';

import {themeContext} from '../contexts/theme';

import {
  VictoryChart,
  VictoryGroup,
  VictoryPolarAxis,
  VictoryTheme,
  VictoryArea,
  VictoryLabel,
} from 'victory';

const RadarChart = ({cData, labels}) => {
  const theme = useContext(themeContext);
  const colors = ['transparent', '#2e9598', '#f7db69', '#ec1b4b', '#a8216b'];

  const maxCharValue = cData.reduce((maxNum, obj) => {
    const maxValue = Math.max(...Object.values(obj));
    return maxValue > maxNum ? maxValue : maxNum;
  }, 0);

  const maxCharObj = cData[0]
    ? Object.keys(cData[0]).reduce((memo, key) => {
        memo[key] = maxCharValue;
        return memo;
      }, {})
    : {};

  const characterData = [maxCharObj, ...cData].map(d => {
    return Object.keys(d).reduce((obj, key) => {
      obj[key] =
        d[key] === null || d[key] === undefined || d[key] === 0 ? 1 : d[key];
      return obj;
    }, {});
  });

  const getMaxima = data => {
    if (data[0]) {
      const groupedData = Object.keys(data[0]).reduce((memo, key) => {
        memo[key] = data.map(d => d[key]);
        return memo;
      }, {});
      return Object.keys(groupedData).reduce((memo, key) => {
        memo[key] = Math.max(...groupedData[key]);
        return memo;
      }, {});
    } else {
      return {};
    }
  };

  const processData = data => {
    const maxByGroup = getMaxima(data);
    const makeDataArray = d => {
      return Object.keys(d).map(key => {
        return {x: key, y: d[key] / maxByGroup[key]};
      });
    };
    return data.map(datum => makeDataArray(datum));
  };

  const data = processData(characterData);
  const maxima = getMaxima(characterData);

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <VictoryChart
          polar
          theme={VictoryTheme.material}
          domain={{y: [0, 1]}}>
          <VictoryGroup
            colorScale={colors}
            style={{data: {fillOpacity: 0.2, strokeWidth: 2}}}>
            {data.map((data, i) => {
              return <VictoryArea key={i} data={data} />;
            })}
          </VictoryGroup>
          {Object.keys(maxima).map((key, i) => {
            return (
              <VictoryPolarAxis
                key={i}
                dependentAxis
                style={{
                  axisLabel: {
                    padding: 10,
                    fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                  },
                  tickLabels: {
                    fill: `transparent`,
                  },
                  axis: {stroke: 'none'},
                  grid: {
                    stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                    strokeWidth: 0.25,
                    opacity: 0.8,
                  },
                }}
                tickLabelComponent={<VictoryLabel labelPlacement="vertical" />}
                labelPlacement="perpendicular"
                axisValue={i + 1}
                label={key}
                tickFormat={t => Math.ceil(t * maxima[key])}
                tickValues={[0.25, 0.5, 0.75]}
              />
            );
          })}
          <VictoryPolarAxis
            labelPlacement="parallel"
            tickFormat={() => ''}
            style={{
              axis: {stroke: 'none'},
              tickLabels: {
                fill: `transparent`,
              },
              grid: {
                stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                opacity: 0.81,
              },
            }}
          />
        </VictoryChart>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
        {labels.map((label, index) => {
          return (
            <div
              key={`label-${index}`}
              style={{
                paddingLeft: 10,
                flexDirection: 'row',
                display: 'flex'
              }}>
              <div
                style={{
                  height: 15,
                  width: 15,
                  borderRadius: 2,
                  backgroundColor: colors[index + 1],
                }}></div>
              <div
                style={{
                  textAlign: 'left',
                  paddingLeft: 10,
                  color: colors[index + 1],
                }}>
                {label.toString().trim()}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadarChart;