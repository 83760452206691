import React, { useEffect, useState, useContext } from 'react';
import { mapTrackersToLifts } from '../../utils/lift-trackers';
import LiftCard from '../../components/liftCards/Cards';
import Spinner from '../../components/spinner';
import { db } from '../../services/firestoredb';
import { doc, getDoc, query, getDocs, where, collection } from "firebase/firestore";
import { orderBy, uniq } from 'lodash';


import { liftsContext } from '../../contexts/lifts';
import { userContext } from '../../contexts/currentUser';

import { fetchUser } from '../../localDatabase/serverUsers';
import { convertToDate } from '../../utils/formatDate'

import { useSearchParams } from 'react-router-dom';
import { fetchLiftPhotos } from '../../localDatabase/serverPhotos';

import CommentsList from '../../components/liftCards/comments/CommentsList';

const ActivitesDetail = () => {
  const currentUser = useContext(userContext);
  const [searchParams] = useSearchParams();
  const route = Object.fromEntries([...searchParams]);

  const [activity, setActivity] = useState({});
  const [rawData, setRawData] = useState({});
  const [user, setUser] = useState({});
  const [friendsLifts, setFriendsLifts] = useState([]);
  const [photos, setphotos] = useState([]);
  const [thumbs, setthumbs] = useState([]);
  const [userLoading, setuserLoading] = useState(true);
  const [liftLoading, setliftLoading] = useState(true);
  const [photosLoading, setphotosLoading] = useState(true);
  const currentUserLiftData = useContext(liftsContext);

  const local = currentUser.uid === route.uid;

  const loading =
    userLoading ||
    liftLoading ||
    photosLoading;

  useEffect(() => {

    const doit = async () => {
      setuserLoading(true);
      setliftLoading(true);
      setphotosLoading(true);

      const docRef = doc(db, `Users/${route.uid}/lift_tracker_groups`, route.lift_id);
      const documentSnapshot = await getDoc(docRef);
      if (documentSnapshot.exists()) {
        setActivity(documentSnapshot.data());
        setRawData(documentSnapshot.data());

        fetchLiftPhotos(route.uid, route.lift_id, (serverPhotos, serverThumbs) => {
          setphotos(serverPhotos);
          setthumbs(serverThumbs);
          setphotosLoading(false);
        })

        if (local) {
          setFriendsLifts(currentUserLiftData);
          setliftLoading(false);
        } else {
          // TODO: figure out why this query doesn't work
          // .where(
          //   'exerciseids',
          //   'array-contains-any',
          //   uniq(
          //     querySnapshot.docs[0]
          //       .data()
          //       .lift_trackers_attributes.map((lt) => lt.exercise.id),
          //   ),
          // )

          const usersRef = collection(db, `Users/${route.uid}/lift_tracker_groups`);
          const q = query(usersRef, where("exerciseids", "array-contains-any", uniq(
            documentSnapshot.data().lift_trackers_attributes.map(lt => lt.exercise.id),
          )));
          let tempFriendsLefts = []
          const fquerySnapshot = await getDocs(q);
          fquerySnapshot.docs.forEach((user) => {
            tempFriendsLefts.push({
              ...user.data(),
              actual_date: convertToDate(user.data().lifted_at)
            });
          });
          setFriendsLifts(orderBy(tempFriendsLefts, ['actual_date'], ['desc']));
          setliftLoading(false);
        }
      }

      const theUser = await fetchUser(route?.uid)
      setUser(theUser);
      setuserLoading(false);
    }
    doit()
  }, [currentUserLiftData, local, route.lift_id, route.uid]);

  return (
    <>
      <Spinner loading={loading} />
      <div style={{ display: 'flex' }}>
        <div style={{ width: '60%' }}>
          {mapTrackersToLifts([activity]).map((liftsForDay, i) =>
            <LiftCard
              key={`liftcard-${i}`}
              user={user}
              lift={liftsForDay}
              rawLiftData={rawData}
              defaultDetailsOpen={true}
              showCharts={true}
              friendsLifts={friendsLifts}
              docID={route?.lift_id}
              photos={photos.sort((a, b) => (a.path > b.path ? 1 : -1))}
              thumbs={thumbs}
            />
          )}
        </div>
        <div style={{ width: '40%' }}>
          <CommentsList lift_id={route?.lift_id} lift_user_id={route?.uid} />
        </div>
      </div>
    </>
  );
};

export default ActivitesDetail;