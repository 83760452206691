import React from 'react';
import Like from './like';
import PopupModal from '../../PopupModal';
import { useNavigate, createSearchParams } from "react-router-dom";

const LikeModal = ({ likesModalVisible, setLikesModalVisible, likes, likeUsers }) => {
    const navigation = useNavigate();
    return <PopupModal
        closeModal={() => { setLikesModalVisible(false) }}
        closeText={'Back'}
        visible={likesModalVisible}
        title={'Users that liked the workout'}>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}>
            {likesModalVisible ? (<>
                {(likes || []).map(like => {
                    const luser = likeUsers.find(u => u.uid === like);
                    return {
                        image: luser?.photoURL || null,
                        name: luser?.displayName || 'Unknown Person',
                        user_id: luser?.uid,
                        tap: user_uid => {
                            navigation({
                                pathname: '/profile/', search: `?${createSearchParams({
                                    user_id: user_uid,
                                })}`
                            });
                        },
                    };
                }).map(like => {
                    return <Like key={like?.user_id + ''} like={like} setLikesModalVisible={setLikesModalVisible} />
                })}</>
            ) : null}
        </div>
    </PopupModal>
};

export default LikeModal;