import React, { useState, useContext } from 'react';
import { isVideo } from '../utils/mimechecker';
import { OndemandVideo } from '@material-ui/icons';
import PopupModal from '../components/PopupModal';
import { themeContext } from '../contexts/theme';
import { filterIntOrUndefined } from '../utils/filterInt';
import TimerDisplay from './lifts/TimerDisplay';

const PhotoThumbnail = ({
  photo,
  thumb,
  index,
  setIsPictureViewerIndex,
  setIsPictureViewerVisible,
  removePhoto,
  removePhotoFromServer,
  longPressAction,
  liftId,
  setId,
  rawLiftData,
}) => {
  const theme = useContext(themeContext);
  const [popupVisable, setPopupVisable] = useState(false);

  const clickAction = () => {
    setIsPictureViewerIndex && setIsPictureViewerIndex(index);
    setIsPictureViewerVisible && setIsPictureViewerVisible(true);
    setPopupVisable(true);
  };
  const srw =
    liftId &&
    setId &&
    rawLiftData.lift_trackers_attributes.find(
      lt => lt.lift_id === liftId && lt.set_num === setId,
    );

  return (
    <>
      <div
        onClick={clickAction}
        onDoubleClick={() => {
          if (longPressAction) {
            longPressAction();
          } else if (removePhoto || removePhotoFromServer) {
            if (window.confirm(`Delete Attachment ${photo.ref ? 'From Server' : ''}`)) {
              if (photo.ref) {
                photo.ref
                  .delete()
                  .then(() => {
                    removePhotoFromServer && removePhotoFromServer();
                  });
              } else {
                removePhoto && removePhoto();
              }
            }
          } else {
            clickAction();
          }
        }}
        key={photo.path}>
        <div style={{ display: 'flex', marginRight: 10 }}>
          {!isVideo(photo.mime) || thumb ? (
            <img
              alt="video"
              src={thumb ? thumb.path : photo.path}
              style={{
                width: 65,
                height: 65,
                borderRadius: 5,
              }}
            />
          ) : (
            <OndemandVideo
              style={{ fill: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)', fontSize: 60 }}
            />
          )}
        </div>
        {liftId && (
          <div
            style={{
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              fontSize: 12,
              fontWeight: '500',
            }}>
            {`Lift: ${liftId}`}
          </div>
        )}
        {setId && (
          <div
            style={{
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              fontSize: 12,
              fontWeight: '500',
            }}>
            {`Set: ${setId}`}
          </div>
        )}

      </div>
      <PopupModal
        closeModal={() => setPopupVisable(false)}
        visible={popupVisable}
        title={isVideo(photo.mime) ? 'Video' : 'Photo'}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
            }}>

            {!isVideo(photo.mime) ? (
              <img
                alt="Large View"
                src={photo.path}
                style={{
                  width: '100%',
                  height: 300,
                  borderRadius: 5,
                }}
              />
            ) : (
              <video controls width="500">
                <source src={photo.path.replace(/^http:\/\//i, 'https://')}
                  type={photo.mime} />
                Sorry, your browser doesn't support embedded videos.
              </video>
            )}
          </div>
          {liftId && setId && srw && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: 30,
                paddingTop: 20,
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: theme.darkMode
                  ? 'rgba(255,255,255,0.1)'
                  : 'rgba(0,0,0,0.1)',
              }}>
              <div
                style={{
                  color: theme.darkMode
                    ? 'rgb(255,255,255)'
                    : 'rgb(0,0,0)',
                  fontSize: 16,
                  width: '70%',
                  textAlign: 'left',
                }}>
                {srw && srw.exercise
                  ? `${srw.exercise.name}`
                  : `Lift: ${liftId}`}
              </div>

              <div
                style={{
                  color: theme.darkMode
                    ? 'rgb(255,255,255)'
                    : 'rgb(0,0,0)',
                  fontSize: 16,
                  width: '30%',
                  textAlign: 'center',
                }}
              >
                {srw && srw.exercise
                  ? `Set ${setId}: ${filterIntOrUndefined(srw.weight)
                    ? `${srw.weight} x`
                    : ''
                  } ${filterIntOrUndefined(srw.num_of_reps)
                    ? `${srw.num_of_reps}`
                    : ''
                  }`
                  : `Set ${setId}`}
                {srw &&
                  filterIntOrUndefined(srw.lift_elapsed_seconds) &&
                  filterIntOrUndefined(srw.lift_elapsed_seconds) > 0 ? (
                  <TimerDisplay elaspedTime={srw.lift_elapsed_seconds} />
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
        </div>
      </PopupModal>
    </>
  );
};

export default PhotoThumbnail;