import { db, cloudFunctions } from '../services/firestoredb';
import { doc, getDoc, getDocs, updateDoc, setDoc, where, collection, query, arrayUnion, arrayRemove } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export const chunkArray = (myArray, chunk_size) => {
  myArray = myArray || [];
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const currentUserFriendsUIDs = async (currentUid, cb) => {
    const docRef = doc(db, "Friendships", currentUid);
    const documentSnapshot = await getDoc(docRef);

    if (documentSnapshot.exists()) {
      cb && cb(documentSnapshot.data().friends_uids || []);
    }else{
      cb && cb([]);
    }
};

export const currentUserFriends = async (uid, cb) => {
  let temp_friends = [];
  let friends_uids = [];

  const docRef = doc(db, "Friendships", uid);
  const friendDoc = await getDoc(docRef);

  if (friendDoc.exists()) {
    friends_uids = friendDoc.data().friends_uids || [];
  }

  for (const groupedFriendUids of chunkArray(friends_uids, 9)) {
    const usersRef = collection(db, "Users");

    // Create a query against the collection.
    const q = query(usersRef, where("uid", "in", groupedFriendUids));

    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach((user) => {
      temp_friends.push({
        ...user.data(),
        status: 'accepted',
      });
    });
  };

  cb && cb(temp_friends);
};

export const currentUserPendingFriends = async (uid, cb) => {
  let temp_friends = [];

  const friendsRef = collection(db, "Friendships");

  // Create a query against the collection.
  const friendsQ = query(friendsRef, where("requested", "array-contains", uid));

  const friendsSnapshot = await getDocs(friendsQ);
  const pending_friend_uids = friendsSnapshot.docs.map(f => f.id);

  for (const groupedFriendUids of chunkArray(pending_friend_uids || [], 9)) {
    const usersRef = collection(db, "Users");

    // Create a query against the collection.
    const q = query(usersRef, where("uid", "in", groupedFriendUids));

    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach((user) => {
      temp_friends.push({
        ...user.data(),
        status: 'pending',
      });
    });
  };

  cb && cb(temp_friends);
};

export const sendRequest = async (currentUid, uid, type, cb) => {
  if (type === 'unfriend') {

    await updateDoc(doc(db, "Friendships", currentUid), {
      friends_uids: arrayRemove(uid),
    }, { merge: true });

    await updateDoc(doc(db, "Friendships", uid), {
      friends_uids: arrayRemove(currentUid),
    }, { merge: true });

    cb && cb();

  } else if (type === 'accept') {

    await updateDoc(doc(db, "Friendships", uid), {
      requested: arrayRemove(currentUid),
    }, { merge: true });

    await updateDoc(doc(db, "Friendships", currentUid), {
      friends_uids: arrayUnion(uid),
    }, { merge: true });

    await updateDoc(doc(db, "Friendships", uid), {
      friends_uids: arrayUnion(currentUid),
    }, { merge: true });

    cb && cb();

  } else if (type === 'decline') {

    await updateDoc(doc(db, "Friendships", uid), {
      requested: arrayRemove(currentUid),
    }, { merge: true });

    cb && cb();

  } else if (type === 'send_request') {

    const docRef = doc(db, "Friendships", currentUid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {

      await updateDoc(doc(db, "Friendships", currentUid), {
        requested: arrayUnion(uid),
      }, { merge: true });

      cb && cb();

    } else {

      await setDoc(doc(db, "Friendships", currentUid), {
        requested: arrayUnion(uid),
      }, { merge: true });

      cb && cb();

    }

    const sendFriendsNotification = httpsCallable(cloudFunctions, 'friendRequestNotification');
    sendFriendsNotification({
      friendUid: uid,
      title: 'New Friend Request',
      tab: 'Home',
      screen: 'Friends',
    })
  }
};

export const calcStatus = async (currentUserUid, friendUids, callback) => {
  const friendshipsRelations = {};
  let pending_friends_uids = [];
  let requested_friends_uids = [];
  let friends_uids = [];

    const friendsRef = collection(db, "Friendships");

    // Create a query against the collection.
    const friendsQ = query(friendsRef, where("requested", "array-contains", currentUserUid));

    const friendsSnapshot = await getDocs(friendsQ);
    pending_friends_uids = friendsSnapshot.docs.map(f => f.uid);

    const docRef = doc(db, "Friendships", currentUserUid);
    const documentSnapshot = await getDoc(docRef);

    if (documentSnapshot.exists()) {
      requested_friends_uids = documentSnapshot.data().requested || [];
      friends_uids = documentSnapshot.data().friends_uids || [];
    }

    friendUids.forEach((uid) => {
      if (friends_uids.includes(uid)) {
        friendshipsRelations[uid] = 'accepted';
      } else if (pending_friends_uids.includes(uid)) {
        friendshipsRelations[uid] = 'pending';
      } else if (requested_friends_uids.includes(uid)) {
        friendshipsRelations[uid] = 'requested';
      } else {
        friendshipsRelations[uid] = 'available';
      }
    });
    friendshipsRelations[currentUserUid] = 'self';
    callback && callback(friendshipsRelations);
};

export const searchForFriends = async (searchText, currentUserUID, cb) => {
  const searchRef = collection(db, "Users");

  // Create a query against the collection.
  const searchQ = query(searchRef, where("keywords", "array-contains", searchText.toLowerCase()));

  const searchSnapshot = await getDocs(searchQ);
  const temp = searchSnapshot.docs.map(user => {
    return user.data();
  });
  calcStatus(
    currentUserUID,
    temp.map(f => f.uid),
    statuses => {
      cb(temp.map(f => {
        return { ...f, status: statuses[f.uid] };
      }))
    },
  );
}

const serverFriendsFuncs = { chunkArray, sendRequest, currentUserFriends, currentUserPendingFriends, currentUserFriendsUIDs, searchForFriends };

export default serverFriendsFuncs;