import React, {useContext} from 'react';

import {formatDate} from '../../utils/formatDate';

import {themeContext} from '../../contexts/theme';

import {orderBy} from 'lodash';

import {exercisesContext} from '../../contexts/exercises';

import WorkoutDetailListCard from '../WorkoutDetailListCard';

export const WorkoutListWithDetails = ({
  lift_data,
  loading,
  exerciseId,
}) => {
  const theme = useContext(themeContext);
  const exercises = useContext(exercisesContext);
  const exercise = exercises.find(e => e.id === exerciseId);
  return (
    <>
      {orderBy(
          lift_data.map(ltg => ({
            ...ltg,
            lifted_at_date: formatDate(ltg.lifted_at),
          })),
          ['lifted_at_date'],
          ['desc'],
        ).map((item, index) => {
          const isFirst = index === 0;
          return (
            <WorkoutDetailListCard
              key={`workoutDetaiL-${index}`}
              item={item}
              isFirst={isFirst}
              exerciseId={exerciseId}
              exercise={exercise}
            />
          );
        })}

      {!loading && lift_data.length === 0 && (
        <div
          style={{
            padding: 20,
            margin: 20,
            backgroundColor: theme.darkMode
              ? `rgba(255,255,255,0.1)`
              : 'rgba(255,255,255,0.4)',
            height: 60,
            borderRadius: 5,
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: 30,
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0.1)',
          }}>
          <div
            style={{
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              fontSize: 17,
              fontWeight: 'bold',
              textAlign: 'center',
            }}>
            No Workouts Available
          </div>
        </div>
      )}
    </>
  );
};

export default WorkoutListWithDetails;