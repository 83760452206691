import React, { useContext, useState } from 'react';

import { groupBy, orderBy } from 'lodash';

import { ToggleButtonGroup, ToggleButton } from '@mui/material';

import { useNavigate, createSearchParams } from 'react-router-dom';
import { toDisplay } from '../../utils/timer';

import { formatDate, formatDateTime } from '../../utils/formatDate';

import { ChevronRight } from '@material-ui/icons';
import { themeContext } from '../../contexts/theme';

const Item = ({ item, isFirst }) => {
  const navigation = useNavigate();
  const theme = useContext(themeContext);
  return (
    <div
      key={`${item.lift_tracker.id}`}
      style={{cursor: 'pointer'}}
      onClick={() => {
        navigation({
          pathname: '/activity', search: `?${createSearchParams({
            lift_id: item.lift_tracker_group.id,
            uid: item.uid || item.lift_tracker_group.user_id,
          })}`
        });
      }}>
      <div
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
          paddingBottom: 10,
          borderTopColor: theme.darkMode
            ? 'rgba(255,255,255, 0.1)'
            : 'rgba(0,0,0, 0.1)',
          borderTopWidth: isFirst ? 1 : 0,
          borderBottomColor: theme.darkMode
            ? 'rgba(255,255,255, 0.1)'
            : 'rgba(0,0,0, 0.1)',
          borderBottomWidth: 1,
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: theme.darkMode
            ? `rgba(255,255,255,0.1)`
            : 'rgba(255,255,255,0.4)',
        }}>
        <div style={{ flexGrow: 1, width: '93%' }}>
          <div
            style={{
              backgroundColor: 'transparent',
              color: `rgb(${theme.primary.main})`,
            }}>
            {item.lift_tracker_group.name}
          </div>
          <div
            key={`pr info`}
            style={{
              color: theme.darkMode ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)',
            }}>
            {item.exercise.equipment_type !== 'Time' &&
              item.exercise.equipment_type !== 'Body Only'
              ? item.lift_tracker.weight
                ? `${item.lift_tracker.weight} lbs for `
                : ''
              : ''}
            {item.exercise.equipment_type === 'Time'
              ? toDisplay(item.lift_tracker.lift_elapsed_seconds)
              : item.lift_tracker.num_of_reps
                ? `${item.lift_tracker.num_of_reps} rep${item.lift_tracker.num_of_reps > 1 ? 's' : ''
                }`
                : 'Any'}
          </div>
          <div
            style={{
              backgroundColor: 'transparent',
              color: theme.darkMode
                ? 'rgba(255, 255, 255, 0.7)'
                : 'rgba(0, 0, 0, 0.7)',
            }}>
            {formatDate(item.lift_tracker_group.lifted_at)}
            {' - '}
            {formatDateTime(item.lift_tracker_group.lifted_at)}
          </div>
        </div>
        <div style={{ marginTop: 15, width: '7%' }}>
          <ChevronRight
            style={{ fill: `${theme.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'})`, fontSize: 25 }}
          />
        </div>
      </div>
    </div>
  );
};

export const PRs = ({
  personal_records_attributes,
  exerciseId,
  loading,
}) => {
  const prsByExercise = groupBy(personal_records_attributes, 'exercise.id');
  const theme = useContext(themeContext);
  const [toggleIndex, setToggleIndex] = useState('0');
  const toggleLookup = [{ '0': 'By Weight' }, { '1': 'By Date' }];
  const items = Object.keys(prsByExercise)
    .flatMap(exercise_id => {
      const prs = orderBy(
        prsByExercise[exercise_id],
        toggleIndex === '0'
          ? ['lift_tracker.weight', 'lift_tracker.created_at']
          : ['lift_tracker.created_at'],
        toggleIndex === '0' ? ['desc', 'desc'] : ['desc'],
      );
      return exerciseId === exercise_id ? prs : undefined;
    })
    .filter(x => {
      return x !== null && x !== undefined;
    });

  return (
    <>
      {!loading && items.length > 0 && (
        <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
          <ToggleButtonGroup
            color="primary"
            value={toggleIndex}
            size={'small'}
            exclusive
            onChange={(_, newAlignment) => setToggleIndex(newAlignment)}
          >
              {toggleLookup.map((obj, index) => {
                return <ToggleButton key={`dropdown-${index}`} value={Object.keys(obj)[0]}>{Object.values(obj)[0]}</ToggleButton>
              })}
          </ToggleButtonGroup>
        </div>
      )}
      <div
      >
        {items.map((item, index) => {
          const isFirst = index === 0;
          return <Item key={item => `pr-item-${item.lift_tracker.id}`} item={item} isFirst={isFirst} />
        })}
      </div>

      {!loading && items.length === 0 && (
        <div
          style={{
            padding: 20,
            margin: 20,
            backgroundColor: theme.darkMode
              ? `rgba(255,255,255,0.1)`
              : 'rgba(255,255,255,0.4)',
            height: 60,
            borderRadius: 5,
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: 30,
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0.1)',
          }}>
          <div
            style={{
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              fontSize: 17,
              fontWeight: 'bold',
              textAlign: 'center',
            }}>
            No PRs Available
          </div>
        </div>
      )}
    </>
  );
};

export default PRs;