import React, { useContext } from 'react';
import { themeContext } from '../../../contexts/theme';

const Like = ({ like, setLikesModalVisible }) => {
    const theme = useContext(themeContext);
    return (
        <div
            onClick={() => {
                setLikesModalVisible(false);
            }}
            style={{
                padding: 10,
                borderBottomColor: theme.darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                borderBottomWidth: 1,
                width: '100%'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: 30 }}>
                    <img
                        style={{
                            width: 30,
                            height: 30,
                            borderRadius: 15,
                        }}
                        src={like.image}
                        alt={'Likor'}
                    />
                </div>
                <div style={{ flex: 1, marginLeft: 10 }}>
                    <div
                        style={{
                            marginTop: 5,
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: theme.darkMode
                                ? 'rgba(255,255,255,0.9)'
                                : 'rgba(0,0,0,0.9)',
                        }}>
                        {like.name}
                    </div>
                </div>
                <div style={{ width: 100 }}>
                    <div
                        style={{
                            borderRadius: 5,
                            backgroundColor: `rgb(${theme.primary.main})`,
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 3,
                            paddingBottom: 3,
                            marginTop: 3,
                            display: 'flex',
                            flexDirection: "row",
                            alignContent: "center",
                            justifyContent: "center",
                            maxHeight: 30,
                            color: theme.darkMode ? 'rgb(0,0,0)' : 'rgb(255,255,255)',
                        }}
                        onClick={() => {
                            setLikesModalVisible(false);
                            like.tap(like.user_id);
                        }}
                    >
                        <div
                            style={{
                                fontSize: 13,
                                marginLeft: 10,
                                marginRight: 10,
                                textTransform: "capitalize",
                                color: theme.darkMode ? 'rgb(0,0,0)' : 'rgb(255,255,255)',

                            }}
                        >
                            Profile
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Like;