import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Pagination
} from 'swiper';
import 'swiper/css';
import "swiper/css/pagination"
import { Whatshot } from '@material-ui/icons';


import SRWTable from './SRWTable';
import ExerciseWorkoutLineChart from '../ExerciseWorkoutLineChart';
import ExerciseVolumeLineChart from '../ExerciseVolumeLineChart';
import HeartRateChart from './HeartRateChart';

import { toDisplay } from '../../utils/timer';
import { convertToDate } from '../../utils/formatDate';

import { formatLast5ForCharts } from '../../localDatabase/serverLifts';
import { themeContext } from '../../contexts/theme';
// import { exercisesContext } from '../../contexts/exercises';

import styled from 'styled-components';
const SwiperContainer = styled.div`
  .swiper-pagination-bullet {
    background-color: ${props => props.activeDot};
  }

  .swiper-pagination-bullet-active-next, .swiper-pagination-bullet-active-next-next {
  background-color: ${props => props.dot};
  }

  .swiper-pagination-bullet-active-prev {
  background-color: ${props => props.dot};
  }
`;

const LiftCardDetails = ({
  liftSets,
  last_5_lifts_by_exercise,
  showCharts,
  HRData,
  vsBestMap,
  liftPRs
}) => {
  const theme = useContext(themeContext);
  // todo get theexecise from friends list of exercise not current logged in users
  // const exercises = useContext(exercisesContext);
  // const theExercise = exercises.find(e => e.id === liftSets[0].exercise.id);
  const theExercise = liftSets[0].exercise;

  const isBodyWeight =
    (theExercise &&
      theExercise.equipment_type === 'Body Only') ||
    false;
  const isTimeBased =
    (theExercise && theExercise.equipment_type === 'Time') ||
    false;
    const hasPR = liftPRs
    ? liftPRs.length > 0
    : liftSets
        .map(tracker => {
          return (
            tracker.personal_record_attributes &&
            tracker.personal_record_attributes.id
          );
        })
        .filter(Number).length > 0;

  SwiperCore.use([Pagination]);
  return (
    <div
      style={{
        borderRadius: 5,
        backgroundColor: theme.darkMode
          ? 'rgba(255,255,255,0.07)'
          : 'rgba(255,255,255,0.4)',
        padding: 10,
        margin: 10,
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,0.1)'
      }}
      key={liftSets[0] && liftSets[0].id}>
      <div>
        <div
          style={{
            fontSize: theme.mode === 'weightlifter' ? 22 : 25,
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
          }}>
          {liftSets[0] && theExercise?.name}
          {hasPR && (
            <Whatshot
              key={`pr-icon-${liftSets[0].id}-${theExercise?.id}`}
              style={{ fill: `rgb(${theme.primary.main})`, fontSize: 20 }}
            />
          )}
        </div>
      </div>
      {theme.mode === 'weightlifter' &&
        showCharts &&
        (!isTimeBased ? true : HRData && HRData.length > 0) && (
          <div style={{marginTop: -40}}>
          <SwiperContainer dot={theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'} activeDot={'#787878'}>
            <Swiper
              // install Swiper modules
              pagination={true}
              className="mySwiper">
              {!isTimeBased && (<SwiperSlide>
                <ExerciseWorkoutLineChart
                  lastData={
                    last_5_lifts_by_exercise &&
                    last_5_lifts_by_exercise[`${theExercise?.id}`] &&
                    liftSets[0] &&
                    theExercise &&
                    formatLast5ForCharts(
                      last_5_lifts_by_exercise[`${theExercise?.id}`],
                    )
                  }
                  metric={
                    isBodyWeight
                      ? 'num_of_reps'
                      : isTimeBased
                        ? 'lift_elapsed_seconds'
                        : 'weight'
                  }
                  liftState={{ setsRepsWeight: liftSets }}
                  height={200}
                /></SwiperSlide>
              )}

              {!isBodyWeight && !isTimeBased && (
                <SwiperSlide><ExerciseVolumeLineChart
                  lastData={
                    last_5_lifts_by_exercise &&
                    last_5_lifts_by_exercise[`${theExercise?.id}`] &&
                    liftSets[0] &&
                    theExercise &&
                    formatLast5ForCharts(
                      last_5_lifts_by_exercise[`${theExercise?.id}`],
                    )
                  }
                  liftState={{ setsRepsWeight: liftSets }}
                  height={200}
                /></SwiperSlide>
              )}
              {HRData && HRData.length > 0 && (
                <SwiperSlide><HeartRateChart
                  data={HRData.map(hr => ({
                    date: convertToDate(hr.startDate),
                    value: hr.value,
                  }))}
                  height={300}
                /></SwiperSlide>
              )}
            </Swiper>
          </SwiperContainer>
          </div>
        )}
      {theme.mode === 'weightlifter' && (
        <div style={{ paddingTop: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingBottom: 3,
            }}>
            <div
              style={{
                fontSize: theme.mode === 'weightlifter' ? 14 : 20,
                fontWeight: 'bold',
                width: '25%',
                color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              }}>
              {'# Sets'}
            </div>
            <div
              style={{
                fontSize: theme.mode === 'weightlifter' ? 14 : 20,
                fontWeight: 'bold',
                width: '25%',
                color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              }}>
              {'Top Set'}
            </div>
            <div
              style={{
                fontSize: theme.mode === 'weightlifter' ? 14 : 20,
                fontWeight: 'bold',
                width: '25%',
                color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              }}>
              {'VS Best'}
            </div>
            <div
              style={{
                fontSize: theme.mode === 'weightlifter' ? 14 : 20,
                fontWeight: 'bold',
                width: '25%',
                color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              }}>
              {'∑ Total'}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}>
            <div
              style={{
                fontSize: theme.mode === 'weightlifter' ? 15 : 20,
                fontWeight: 'bold',
                width: '25%',
                color: theme.darkMode
                  ? 'rgba(255, 255, 255, 0.7)'
                  : 'rgba(0, 0, 0, 0.7)',
              }}>
              {liftSets.length.toString()}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '25%'
              }}>
              <div
                style={{
                  fontSize: theme.mode === 'weightlifter' ? 15 : 20,
                  fontWeight: '500',
                  paddingRight: 2,
                  color: theme.darkMode
                    ? 'rgba(255, 255, 255, 0.9)'
                    : 'rgba(0, 0, 0, 0.9)',
                }}>
                {isTimeBased
                  ? theExercise.more_time_is_pr
                    ? toDisplay(
                      Math.max(
                        ...liftSets.map(l => {
                          return l.lift_elapsed_seconds;
                        }),
                      ),
                    )
                    : toDisplay(
                      Math.min(
                        ...liftSets.map(l => {
                          return l.lift_elapsed_seconds;
                        }),
                      ),
                    )
                  : isBodyWeight
                    ? Math.max(
                      ...liftSets.map(l => {
                        return l.num_of_reps;
                      }),
                    )
                      .toString()
                    : Math.max(
                      ...liftSets.map(l => {
                        return l.weight;
                      }),
                    )
                      .toString()}
              </div>
              <div
                style={{
                  paddingTop: 3,
                  fontSize: theme.mode === 'weightlifter' ? 12 : 17,
                  color: theme.darkMode
                    ? 'rgba(255, 255, 255, 0.5)'
                    : 'rgba(0, 0, 0, 0.5)',
                }}>
                {isBodyWeight || isTimeBased ? '' : ' lbs'}
              </div>
            </div>
            <div
              style={{
                fontSize: theme.mode === 'weightlifter' ? 15 : 20,
                fontWeight: '500',
                width: '25%',
                color: theme.darkMode
                  ? 'rgba(255, 255, 255, 0.7)'
                  : 'rgba(0, 0, 0, 0.7)',
              }}>
              {Math.max(
                ...liftSets.map(l => {
                  return vsBestMap[`${l.lift_id}-${l.set_num}`];
                }),
              )
                .toString()
                .concat(' %')}
            </div>
            {theme.mode === 'weightlifter' && (
              <div
                style={{
                  width: '25%',
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <div
                  style={{
                    fontSize: theme.mode === 'weightlifter' ? 15 : 20,
                    fontWeight: '500',
                    paddingRight: 2,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.9)'
                      : 'rgba(0, 0, 0, 0.9)',
                  }}>
                  {isTimeBased
                    ? toDisplay(
                      liftSets
                        .map(l => {
                          return l.lift_elapsed_seconds;
                        })
                        .reduce(function (a, b) {
                          return a + b;
                        }, 0),
                    )
                    : isBodyWeight
                      ? liftSets
                        .map(l => {
                          return l.num_of_reps;
                        })
                        .reduce(function (a, b) {
                          return a + b;
                        }, 0)
                        .toString()
                      : liftSets
                        .map(l => {
                          return l.num_of_reps * l.weight;
                        })
                        .reduce(function (a, b) {
                          return a + b;
                        }, 0)
                        .toString()}
                </div>
                <div
                  style={{
                    paddingTop: 3,
                    fontSize: theme.mode === 'weightlifter' ? 12 : 17,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(0, 0, 0, 0.5)',
                  }}>
                  {isBodyWeight || isTimeBased ? '' : ' lbs'}
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              borderBottomColor: 'rgba(255, 255, 255, 0.2)',
              borderBottomWidth: 1,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          />
        </div>
      )}


      <SRWTable
        liftSets={liftSets}
        isBodyWeight={isBodyWeight}
        isTimeBased={isTimeBased}
        liftPRs={liftPRs}
      />
    </div>
  );
};

export default LiftCardDetails;