import React from 'react';
import {toMinutes, toSeconds} from '../../utils/timer';

const TimerDisplay = ({elaspedTime, textStyle}) => {
  const defaultElaspedTime = isNaN(parseInt(elaspedTime))
    ? 0
    : parseInt(elaspedTime);
  return (
    <div style={textStyle ? textStyle : {}}>
      {toMinutes(defaultElaspedTime)}:{toSeconds(defaultElaspedTime)}
    </div>
  );
};

export default TimerDisplay;