import React, { useState, useEffect, useCallback, useMemo } from "react";

import RoutesWeb from "./RoutesWeb";

import firebase from 'firebase/compat/app';
import { db, firebaseApp } from './services/firestoredb';
import { doc, updateDoc, collection, query, onSnapshot } from "firebase/firestore";
import { firebaseSignAttempt } from "./services/firebase";
import { getCurrentUser } from './localDatabase/serverUsers';

import { themeContext } from './contexts/theme';
import { userContext } from "./contexts/currentUser";
import { liftsContext } from "./contexts/lifts";
import { exercisesContext } from "./contexts/exercises";

import SyncingScreen from "./SyncingScreen";


import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import defaultExercises from './localDatabase/defaultExercises';
import { convertToDate } from './utils/formatDate';
import { uuidv4 } from './utils/uuid';
import {orderBy, groupBy, uniq} from 'lodash';


const defaultTheme = {
  primary: {
    main: '234, 255, 3',
    backgroundColor: '0, 0, 0',
  },
  darkMode: true,
  powerlifting: false,
  appleHealth: false,
  mode: 'basic',
};


function App() {

  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();

  const [loadingLifts, setLoadingLifts] = useState(false);
  const [lifts_data, setLifts] = useState([]);

  const [customExercises, setcustomExercises] = useState([]);
  const [customExercisesLoading, setcustomExercisesLoading] = useState(false);

  const changeTheme = useCallback(() => async (currentUserUID, newTheme) => {
    await getCurrentUser(currentUserUID, async (currentUserFSdata) => {
      const newThemeData = {
        ...defaultTheme,
        ...(currentUserFSdata.theme || {}),
        ...newTheme,
      };
      await updateDoc(doc(db, "Users", currentUserUID), { theme: newThemeData }, { merge: true });
      setTheme({
        ...newThemeData,
        changeTheme: changeTheme,
      });
    });
  }, []);

  const [theme, setTheme] = useState({
    ...defaultTheme,
    changeTheme: changeTheme,
  });

  const muitheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: theme.darkMode ? 'dark' : 'light',
          primary: {
            main: `rgb(${theme.primary.main})`
          },
          background: {
            paper: `rgb(${theme.primary.backgroundColor})`
          }
        },
      }),
    [theme.darkMode, theme.primary.main, theme.primary.backgroundColor],
  );

  useEffect(() => {
    const unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setInitializing(true)
        await firebaseSignAttempt(user, async (currentUserFSdata) => {
          setUser({
            uid: currentUserFSdata.uid,
            displayName: currentUserFSdata.displayName,
            photoURL: currentUserFSdata.photoURL,
            logout: () => {
              setUser(undefined);
            }
          });
          setTheme({
            ...defaultTheme,
            ...currentUserFSdata.theme,
            changeTheme: changeTheme,
          });
        });

        if (window.location.pathname === '/login') {
          window.location = '/dashboard';
        }
        setInitializing(false);
      } else {
        setInitializing(false);
      }
    });

    return unregisterAuthObserver;
  }, [changeTheme]);

  useEffect(() => {
    let unsubscribe = () => { };
    if (user && !customExercisesLoading) {
      setLoadingLifts(true);
      const q = query(collection(db, `Users/${user.uid}/lift_tracker_groups`));
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        let unsortedLifts = [];
        querySnapshot.forEach((documentSnapshot) => {
          unsortedLifts.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
            actual_date: convertToDate(documentSnapshot.data().lifted_at)
          });
        });
        const allExercisesinDb = [...customExercises, ...defaultExercises];
        const sortedLifts = orderBy(unsortedLifts, ['actual_date'], ['desc']);
        const prsbyexercise = sortedLifts.reduce((acc, lift) => {
          const lifts_by_exercise_id = groupBy(
            lift.lift_trackers_attributes,
            'exercise_id',
          );
          const uniq_exercise_ids = uniq(Object.keys(lifts_by_exercise_id));
          uniq_exercise_ids.forEach(eid => {
            const exercise_for_lift =
              allExercisesinDb.find(e => e.id === eid) || {};
            const e_lifts_by_lift_id = groupBy(
              lifts_by_exercise_id[eid],
              'lift_id',
            );
            const e_lift_ids = Object.keys(e_lifts_by_lift_id)
              .sort()
              .reverse();
            e_lift_ids.forEach(e_lift_id => {
              const e_lifts = e_lifts_by_lift_id[e_lift_id];
              e_lifts
                .sort((a, b) => (a.set_num > b.set_num ? -1 : 1))
                .forEach(e_lift => {
                  const previous_lifts = acc[eid];
                  const prObj = {
                    ltg_id: lift.id,
                    ltg_date: lift.actual_date,
                    exercise_id: eid,
                    lift_elapsed_seconds: e_lift.lift_elapsed_seconds,
                    lift_id: e_lift.lift_id,
                    num_of_reps: e_lift.num_of_reps,
                    set_num: e_lift.set_num,
                    weight: e_lift.weight,
                  };
                  if (previous_lifts) {
                    if (exercise_for_lift.equipment_type === 'Time') {
                      if (exercise_for_lift.more_time_is_pr) {
                        if (
                          e_lift.lift_elapsed_seconds >=
                          acc[eid][0].lift_elapsed_seconds
                        ) {
                          acc[eid] = [prObj];
                        }
                      } else {
                        if (
                          e_lift.lift_elapsed_seconds <=
                          acc[eid][0].lift_elapsed_seconds
                        ) {
                          acc[eid] = [prObj];
                        }
                      }
                    } else if (
                      exercise_for_lift.equipment_type === 'Body Only'
                    ) {
                      if (e_lift.num_of_reps >= acc[eid][0].num_of_reps) {
                        acc[eid] = [prObj];
                      }
                    } else {
                      const with_same_weight = acc[eid].filter(l => {
                        return (
                          l.weight.toString() === e_lift.weight.toString()
                        );
                      });
                      if (
                        with_same_weight === undefined ||
                        with_same_weight.length === 0
                      ) {
                        acc[eid].push(prObj);
                      } else {
                        acc[eid] = acc[eid].map(pr => {
                          if (
                            pr.weight === e_lift.weight &&
                            e_lift.num_of_reps >= pr.num_of_reps
                          ) {
                            return prObj;
                          } else {
                            return pr;
                          }
                        });
                      }
                    }
                  } else {
                    acc[eid] = [prObj];
                  }
                });
            });
          });

          return acc;
        }, {});

        const liftsWithPrs = Object.keys(prsbyexercise)
          .flatMap(pr_e_id => {
            const pr_lifts = prsbyexercise[pr_e_id];
            return pr_lifts.map(pr => {
              return [pr.ltg_id, pr.lift_id, pr.set_num];
            });
          })
          .sort((a, b) => (a[0] > b[0] ? -1 : 1));

        updateDoc(doc(db, "Users", user.uid), {
          exercisePRs: prsbyexercise,
          liftPRs: liftsWithPrs.reduce((acc, lift) => {
            if (acc[lift[0]] === undefined) {
              acc[lift[0]] = [];
            }
            acc[lift[0]].push({ lift_id: lift[1], set_num: lift[2] });
            return acc;
          }, {})
        }, { merge: true });

        const sortedLiftsWithPrs = sortedLifts.map(ltg => {
          const pr_records = liftsWithPrs.filter(pr => pr[0] === ltg.id);
          if (pr_records) {
            ltg.lift_trackers_attributes = ltg.lift_trackers_attributes.map(
              lta => {
                const pr_record = pr_records.find(
                  pr => pr[1] === lta.lift_id && pr[2] === lta.set_num,
                );
                return {
                  ...lta,
                  personal_record_attributes: pr_record
                    ? { id: uuidv4() }
                    : undefined,
                };
              },
            );
          } else {
            ltg.lift_trackers_attributes = ltg.lift_trackers_attributes.map(
              lta => {
                return { ...lta, personal_record_attributes: undefined };
              },
            );
          }
          return ltg;
        });


        setLifts(sortedLiftsWithPrs);
        setLoadingLifts(false);
      });
    }

    // Unsubscribe from events when no longer in use
    return unsubscribe;
  }, [user, customExercisesLoading, customExercises]);


  useEffect(() => {
    let unsubscribe = () => { };
    if (user) {
      setcustomExercisesLoading(true);
      const q = query(collection(db, `Users/${user.uid}/Exercises`));
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const customExercisesdata = [];

        querySnapshot.forEach(documentSnapshot => {
          customExercisesdata.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
            isBodyWeight:
              documentSnapshot.data().equipment_type === 'Body Only',
            isTimeBased: documentSnapshot.data().equipment_type === 'Time',
          });
        });
        setcustomExercises(customExercisesdata);
        setcustomExercisesLoading(false);
      });
    }

    // Unsubscribe from events when no longer in use
    return unsubscribe;
  }, [user]);


  if (window.location.pathname !== '/' && window.location.pathname !== '/privacy' && initializing) {
    return <SyncingScreen text={'Initializing...'} theme={theme} />;
  }

  if (
    window.location.pathname !== '/' && window.location.pathname !== '/privacy' && (loadingLifts || customExercisesLoading)
  ) {
    return <SyncingScreen text={'Syncing...'} theme={theme} />;
  }


  return (<div style={{ backgroundColor: `rgb(${theme.primary.backgroundColor})`, minHeight: '100vh' }}>
    <div style={!['/', '/privacy', '/login'].includes(window.location.pathname) ? { minHeight: '100vh', width: '100%', maxWidth: 1200, margin: '0 auto' } : {}}>
      <ThemeProvider theme={muitheme}>
        <CssBaseline />
        <themeContext.Provider value={theme}>
          <userContext.Provider value={user}>
            <liftsContext.Provider value={lifts_data}>
              <exercisesContext.Provider
                value={[...customExercises, ...defaultExercises]}>
                <RoutesWeb firebase={firebase} />
              </exercisesContext.Provider>
            </liftsContext.Provider>
          </userContext.Provider>
        </themeContext.Provider>
      </ThemeProvider>
    </div>
  </div>
  );
}

export default App;