export const filterInt = value => {
  if (!isNaN(value)) {
    return Number(value);
  } else {
    return NaN;
  }
};

export const filterIntorZero = value => {
  if (!isNaN(value)) {
    return Number(value);
  } else {
    return 0;
  }
};

export const filterIntOrUndefined = value => {
  if (!isNaN(value)) {
    return Number(value);
  } else {
    return undefined;
  }
};

export const filterIntOrNull = value => {
  if (!isNaN(value)) {
    return Number(value);
  } else {
    return null;
  }
};

export const filterFloatorZero = value => {
  if (!isNaN(value)) {
    return parseFloat(value);
  } else {
    return 0;
  }
};

const filterIntFuncs = {
  filterInt,
  filterIntorZero,
  filterFloatorZero,
  filterIntOrUndefined,
};

export default filterIntFuncs;