/**
 * Created by tino on 6/6/17.
 */
import React, { useState } from "react";
import PopupModal from '../../components/PopupModal';
import moment from "moment";
import { Close, Send, Check } from '@material-ui/icons';
import styles from "./styles";
import Comment from "./Comment";
import Spinner from '../../components/spinner';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const Comments = (props) => {

    const [replyCommentText, setReplyCommentText] = useState('')
    const [editCommentText, setEditCommentText] = useState('')
    const [editingComment, setEditingComment] = useState(null)
    const [newCommentText, setNewCommentText] = useState('')
    // const [loadingComments, setLoadingComments] = useState(!(props.data && props.data.length))
    const [likesModalVisible, setLikesModalVisible] = useState(false)
    const [likesModalData, setLikesModalData] = useState(null)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [expanded, setExpanded] = useState([])
    // const [pagination, setPagination] = useState([])


    let textInputs = [];

    const getStyle = (name) => {
        return props.styles && props.styles[name]
            ? props.styles[name]
            : {};
    }

    //    const componentWillReceiveProps(nextProps) {
    //      if (nextProps.data) {
    //        setLoadingComments(false)
    //      }
    //    }

    // const isExpanded = (id) => {
    //      return expanded.indexOf(id) !== -1;
    // }

    const toggleExpand = (c, focus) => {
        const id = props.keyExtractor(c);
        let newExpanded = expanded;

        let index = expanded.indexOf(id);

        if (index === -1) {
            newExpanded.push(id);
        } else {
            newExpanded.splice(index, 1);
        }
        setExpanded(newExpanded);
        if (focus && index === -1) {
            focusOnReplyInput(id);
        }
    }

    const handleReport = (c) => {
        props.reportAction(c);
    }

    const focusOnReplyInput = (id) => {
        let input = textInputs["input" + id];

        input &&
            input.measure((x, y, width, height, pageX, pageY) => {
                input.focus();
                props.replyAction(pageY);
            });
    }

    const handleReply = (c) => {
        if (!props.isChild) return;
        if (!props.isChild(c)) {
            toggleExpand(c, true);
        } else {
            focusOnReplyInput(props.parentIdExtractor(c));
        }
    }

    const handleLike = (c) => {
        props.likeAction(c);
    }

    const handleDelete = (c) => {
        props.deleteAction(c);
    }

    const handleEdit = (c) => {
        setEditCommentText(props.bodyExtractor(c))
        setEditingComment(c)
        setEditModalVisible(!editModalVisible);
    }

    const handleUsernameTap = (user_id) => {
        if (props.usernameTapAction) {
            props.usernameTapAction(user_id);
        }
    }

    const handleLikesTap = (c) => {
        setLikesModalData(props.likesExtractor(c));
        setLikesModalVisible(!likesModalVisible);
    }

    const handleEditAction = (c) => {
        props.editAction(editCommentText, c);
    }

    /**
     *
     * Generates a single comment
     * */
    const generateComment = (c, styles, likedColor, unlikedColor) => {
        return (
            <Comment
                styles={styles}
                likedColor={likedColor}
                unlikedColor={unlikedColor}
                data={c}
                id={props.keyExtractor(c)}
                usernameTapAction={handleUsernameTap}
                username={props.usernameExtractor(c)}
                user_id={props.userIdExtractor(c)}
                body={props.bodyExtractor(c)}
                likesNr={
                    props.likesExtractor(c) ? props.likesExtractor(c).length : 0
                }
                canEdit={canUserEdit(c)}
                updatedAt={props.editTimeExtractor(c)}
                replyAction={props.replyAction ? handleReply : null}
                image={props.imageExtractor(c)}
                child={true}
                isOwnComment={props.userIdExtractor(c) === props.viewingUserId}
                reportAction={props.reportAction ? handleReport : null}
                liked={props.likeExtractor ? props.likeExtractor(c) : null}
                reported={
                    props.reportedExtractor ? props.reportedExtractor(c) : null
                }
                likeAction={props.likeAction ? handleLike : null}
                editAction={handleEditAction}
                deleteAction={handleDelete}
                editComment={handleEdit}
                likesTapAction={props.likeAction ? handleLikesTap : null}
            />
        );
    }

    /**
     * Renders comments children
     * */
    const renderChildren = (items, styles, likedColor, unlikedColor) => {
        if (!items || !items.length) return;
        return items.map((c) => {
            return (
                <div key={props.keyExtractor(c) + "" + Math.random()}>
                    {generateComment(c, styles, likedColor, unlikedColor)}
                </div>
            );
        });
    }

    /**
     * Returns last child id
     * */
    const getLastChildCommentId = (item) => {
        if (!item) return;
        const items = item[props.childPropName];
        return props.keyExtractor(items[items.length - 1]);
    }

    /**
     * Returns first child id
     * */
    const getFirstChildCommentId = (item) => {
        if (!item) return;
        const items = item[props.childPropName];

        return props.keyExtractor(items[0]);
    }

    /**
     * Does a pagination action
     * */
    const paginate = (fromCommentId, direction, parentCommentId) => {
        // setLoadingComments(true)
        props.paginateAction(fromCommentId, direction, parentCommentId);
    }

    /**
     * Can user edit a comment
     * */
    const canUserEdit = (item) => {
        if (
            props.viewingUserId === props.userIdExtractor(item) ||
            props.userIsAdmin
        ) {
            if (!props.editMinuteLimit) return true;
            let created =
                moment(props.createdTimeExtractor(item)).valueOf() / 1000;

            return (
                new Date().getTime() / 1000 - created < props.editMinuteLimit * 60
            );
        }
        return false;
    }

    const renderLike = (like) => {
        return (
            <div
                key={like?.user_id + ""}
                onClick={() => {
                    setLikesModalVisible(false);
                }}
                style={{ ...styles.likeContainer, ...getStyle("likeContainer") }}
            >
                <div style={{ flexDirection: "row", display: 'flex' }}>
                    <div style={{ width: 30 }}>
                        <img
                            style={{ ...styles.likeImage, ...getStyle("likeImage") }}
                            src={like?.image}
                            alt={`liker-${like?.user_id}`}
                        />
                    </div>
                    <div style={{ display: 'flex', marginLeft: 10, flexGrow: 1 }}>
                        <div style={{ ...styles.likeName, ...getStyle("likeName") }}>
                            {like?.name}
                        </div>
                    </div>
                    <div style={{ width: 100 }}>
                        <div
                            style={{
                                ...{
                                    borderRadius: 5,
                                    backgroundColor: props.likedColor,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    marginTop: 3,
                                    display: 'flex',
                                    flexDirection: "row",
                                    alignContent: "center",
                                    justifyContent: "center",
                                    maxHeight: 30,
                                },
                                ...getStyle("likeProfileButton"),
                            }}
                            onClick={() => {
                                setLikesModalVisible(false);
                                like.tap(like?.user_id);
                            }}
                        >
                            <div
                                style={{
                                    ...{
                                        color: "rgb(255,255,255)",
                                        fontSize: 13,
                                        marginLeft: 10,
                                        marginRight: 10,
                                        textTransform: "capitalize",
                                    },
                                    ...getStyle("likeProfileButtonText"),
                                }}
                            >
                                Profile
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Renders a comment with pagination
     * */
    const renderComment = (item, styles, likedColor, unlikedColor) => {
        return (
            <div>
                {generateComment(item, styles, likedColor, unlikedColor)}
                <div style={{ marginLeft: 40 }}>
                    {/* {props.childrenCountExtractor(item) &&
                        props.childPropName ? (
                        <div onClick={() => { toggleExpand(item) }}>
                            <div
                                style={{ ...styles.repliedSection, ...getStyle("repliedSection") }}
                            >
                                <div
                                    style={{ ...styles.repliedCount, ...getStyle("repliedCount") }}
                                >
                                    {isExpanded(props.keyExtractor(item))
                                        ? "Hide "
                                        : "View "}
                                    {props.childrenCountExtractor(item) > 1
                                        ? props.childrenCountExtractor(item)
                                        : ""}
                                    {props.childrenCountExtractor(item) > 1
                                        ? " Replies"
                                        : " Reply"}
                                </div>
                            </div>
                        </div>
                    ) : null} */}
                    {props.childrenCountExtractor(item) &&
                        props.paginateAction ? (
                        <div>
                            {props.childPropName &&
                                props.childrenCountExtractor(item) >
                                item[props.childPropName].length ? (
                                <div
                                    onClick={() => {
                                        paginate(
                                            getFirstChildCommentId(item),
                                            "down",
                                            props.keyExtractor(item)
                                        )
                                    }}
                                >
                                    <div
                                        style={{
                                            ...{ textAlign: "center", paddingTop: 15 },
                                            ...getStyle("previousText"),
                                        }}
                                    >
                                        Show previous...
                                    </div>
                                </div>
                            ) : null}

                            {renderChildren(
                                item[props.childPropName],
                                styles,
                                likedColor,
                                unlikedColor
                                // props.keyExtractor(item)
                            )}

                            {props.childrenCountExtractor(item) >
                                item[props.childPropName].length &&
                                props.paginateAction ? (
                                <div
                                    onClick={() => {
                                        paginate(
                                            getLastChildCommentId(item),
                                            "up",
                                            props.keyExtractor(item)
                                        )
                                    }}
                                >
                                    <div
                                        style={{
                                            ...{ textAlign: "center", paddingTop: 15 },
                                            ...getStyle("moreText"),
                                        }}
                                    >
                                        Show more...
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        renderChildren(
                            item[props.childPropName],
                            styles,
                            likedColor,
                            unlikedColor
                            // props.keyExtractor(item)
                        )
                    )}
                    <div style={{ ...styles.inputSection, ...getStyle("inputSection") }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                            <TextField fullWidth id="input-with-wrreply" label="Write reply..." variant="standard" value={replyCommentText}
                                onChange={(event) =>
                                    setReplyCommentText(event.target.value)
                                } />
                            <div
                                onClick={() => {
                                    props.saveAction(
                                        replyCommentText,
                                        props.keyExtractor(item)
                                    );
                                    setReplyCommentText('');
                                }}
                            >
                                <Send style={{ fill: props.submitButtonColor || "gray", fontSize: 25 }} />
                            </div>    
                        </Box>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ ...styles.inputSection, ...getStyle("inputSection") }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                    <TextField fullWidth id="input-with-main-comment" label="Write comment..." variant="standard" value={newCommentText}
                        onChange={(event) => {
                            setNewCommentText(event.target.value)
                        }
                        } />
                    <div
                        onClick={() => {
                            props.saveAction(newCommentText, false);
                            setNewCommentText('');
                        }}
                    >
                        <Send style={{ fill: props.submitButtonColor || "gray", fontSize: 25 }} />
                    </div>    
                </Box>
            </div>

            {!props.loadingComments &&
                props.data &&
                props.data.length &&
                props.paginateAction ? (
                <div
                    onClick={() => {
                        paginate(
                            props.keyExtractor(props.data[0]),
                            "down"
                        );
                    }}
                >
                    <div>
                        <div
                            style={{
                                ...{ textAlign: "center", color: "gray" },
                                ...getStyle("previousText"),
                            }}
                        >
                            Show previous
                        </div>
                    </div>
                </div>
            ) : null}

            {/* Comments */}
            {props.data.length > 0 ? (
                <>
                    {props.data.map((item) => {
                        return (
                            <React.Fragment key={props.keyExtractor(item) + ""}>
                                {renderComment(
                                    item,
                                    props.styles,
                                    props.likedColor,
                                    props.unlikedColor
                                )}
                            </React.Fragment>
                        );
                    })}
                </>
            ) : (
                <div
                    style={{ ...{ textAlign: "center" }, ...getStyle("noCommentsYet") }}
                >
                    No comments yet
                </div>
            )}

            {props.loadingComments ? (
                <div
                    style={{
                        position: "absolute",
                        zIndex: 10,
                        bottom: 0,
                        height: 60,
                        backgroundColor:
                            props.backgroundColor || "rgba(255,255,255, 0.9)",
                    }}
                >
                    <Spinner loading={props.loadingComments} />
                </div>
            ) : null}

            {!props.loadingComments &&
                props.data &&
                props.data.length &&
                props.paginateAction ? (
                <div
                    style={{ height: 70 }}
                    onClick={() => {
                        paginate(
                            props.keyExtractor(
                                props.data[props.data.length - 1]
                            ),
                            "up"
                        );
                    }}
                >
                    <div style={{ textAlign: "center", color: "gray" }}>
                        Show more
                    </div>
                </div>
            ) : null}

            <PopupModal
                closeModal={() => { setLikesModalVisible(false) }}
                closeText={'Back'}
                visible={likesModalVisible}
                title={'Users that liked the Comment'}>
                <>
                    {likesModalVisible ? (
                        <>
                            {likesModalData.map((item) => {
                                return <div key={`${item.like_id + ""}`} style={{width: '100%'}}>{renderLike(item)}</div>
                            })}
                        </>
                    ) : null}
                </>
            </PopupModal>

            <PopupModal
                closeModal={() => {
                    setEditModalVisible(false);
                }}
                closeText={'Back'}
                visible={editModalVisible}
                title={'Edit Comment'}>
                <div
                    style={{
                        ...styles.editModalContainer,
                        ...getStyle("editModalContainer"),
                    }}
                >
                    <div style={{ ...styles.editModal, ...getStyle("editModal") }}>
                        <TextField
                            id="edit-comment-text"
                            label="Edit comment..."
                            fullWidth
                            multiline
                            rows={12}
                            value={editCommentText}
                            onChange={(event) => { setEditCommentText(event.target.value) }}
                            />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: "row",
                                justifyContent: "space-around",
                            }}
                        >
                            <div onClick={() => { setEditModalVisible(false) }}>
                                <div
                                    style={{
                                        ...styles.editButtons,
                                        ...getStyle("editButtons"),
                                        ...getStyle("editButtonClose"),
                                    }}
                                >
                                    <div>
                                        <Close style={{ fill: props.submitButtonColor || "gray", fontSize: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    props.editAction(
                                        editCommentText,
                                        editingComment
                                    );
                                    setEditModalVisible(!editModalVisible);
                                }}
                            >
                                <div
                                    style={{
                                        ...styles.editButtons,
                                        ...getStyle("editButtons"),
                                        ...getStyle("editButtonSave"),
                                    }}
                                >
                                    <div style={{ ...styles.submit, ...getStyle("submit") }}>
                                        <Check style={{ fill: props.submitButtonColor || "gray", fontSize: 20 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PopupModal>
        </div>
    );

}

export default Comments;