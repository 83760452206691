export const removeItemAll = (arr, value) => {
    let arrCopy = [...arr];
    var i = 0;
    while (i < arrCopy.length) {
      if (arrCopy[i] === value) {
        arrCopy.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arrCopy;
  }

const arrayHelperFunctions = {removeItemAll};

export default arrayHelperFunctions;