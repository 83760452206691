export const rpeText = (value) => {
  const rpe = parseFloat(value);
  if (rpe > 0.0 && rpe <= 4.0) {
    return 'Light Weight, usually 20 plus rep sets';
  } else if (rpe >= 4.1 && rpe < 6.0) {
    return 'Warmup Weight';
  } else if (rpe >= 6.0 && rpe < 7.0) {
    return 'Light speed work, moves quickly with moderate force';
  } else if (rpe >= 7.0 && rpe < 8.0) {
    return 'Speed Weight, moves quickly when maximal force is applied';
  } else if (rpe >= 8.0 && rpe < 9.0) {
    return '2-4 rep left, bar starts to slow down, but is not a struggle';
  } else if (rpe >= 9.0 && rpe < 10.0) {
    return 'Probably another rep in the tank, but it was a grind';
  } else if (rpe === 10.0) {
    return 'Maximum Effort, no reps left in the tank';
  }
};

export const rpeWorkoutText = (value) => {
  const rpe = parseFloat(value);
  if (rpe > 0.0 && rpe <= 4.0) {
    return 'Meh, Easy';
  } else if (rpe >= 4.1 && rpe < 6.0) {
    return 'Easy mostly warm up weight';
  } else if (rpe >= 6.0 && rpe < 7.0) {
    return 'Weight felt light and moved quick';
  } else if (rpe >= 7.0 && rpe < 8.0) {
    return 'Decent effort put in and weight moved quickly';
  } else if (rpe >= 8.0 && rpe < 9.0) {
    return 'Few sets with 2-4 rep max but not a huge struggle';
  } else if (rpe >= 9.0 && rpe < 10.0) {
    return 'Close to maximum effort, going to feel this tomorrow';
  } else if (rpe === 10.0) {
    return 'Maximum Effort!';
  }
};

const rpeFuncs = {rpeText, rpeWorkoutText};

export default rpeFuncs;