export const mimeFromURL = (url) => {
  const mimetypes = {
    apng: 'image/apng',
    avif: 'image/avif',
    gif: 'image/gif',
    jpg: 'image/jpeg',
    png: 'image/png',
    svg: 'image/svg+xml',
    webp: 'image/webp',
    avi: 'video/x-msvideo',
    bin: 'application/octet-stream',
    bmp: 'image/bmp',
    ico: 'image/vnd.microsoft.icon',
    mpeg: 'video/mpeg',
    ogv: 'video/ogg',
    tif: 'image/tiff',
    ts: 'video/mp2t',
    webm: 'video/webm',
    '3gp': 'video/3gpp',
    '3g2': 'video/3gpp2',
    mp4: 'video/mp4',
    flv: 'video/x-flv',
    m3u8: 'application/x-mpegURL',
    mov: 'video/quicktime',
    wmv: 'video/x-ms-wmv',
  };
  return mimetypes[get_url_extension(url)];
};

export const get_url_extension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const isVideo = (mime) => {
  return [
    'video/x-flv',
    'video/mp4',
    'application/x-mpegURL',
    'video/MP2T',
    'video/3gpp',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
  ].includes(mime);
};

export const mimeToExtension = (mime) => {
  const extensions = {
    'image/apng': 'apng',
    'image/avif': 'avif',
    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/svg+xml': 'svg',
    'image/webp': 'webp',
    'video/x-msvideo': 'avi',
    'application/octet-stream': 'bin',
    'image/bmp': 'bmp',
    'image/vnd.microsoft.icon': 'ico',
    'video/mpeg': 'mpeg',
    'video/ogg': 'ogv',
    'image/tiff': 'tif',
    'video/mp2t': 'ts',
    'video/webm': 'webm',
    'video/3gpp': '3gp',
    'video/3gpp2': '3g2',
    'video/mp4': 'mp4',
    'video/x-flv': 'flv',
    'application/x-mpegURL': 'm3u8',
    'video/quicktime': 'mov',
    'video/x-ms-wmv': 'wmv',
  };
  return extensions[mime];
};

const mimeheckerFuncs = {isVideo, mimeFromURL, mimeToExtension};

export default mimeheckerFuncs;