import React from 'react';

import { mimeFromURL } from '../../utils/mimechecker';

import LightGallery from 'lightgallery/react';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/city/index.css';


// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgVideo from "lightgallery/plugins/video";

import {convertToDate, formatDate} from '../../utils/formatDate';

window.videojs = videojs;

const VideoPlayer = (item) => {
  const videoURL = item.original.replace(/^http:\/\//i, 'https://');
  return <div
    style={{display: 'inline-block'}}
    key={item.id}
    className="gallery-item vjs-theme-city"
    data-lg-size="1280-720"
    data-video={`{"source": [{"src":"${videoURL}", "type":"${mimeFromURL(item.original)}"}], "attributes": {"preload": false, "playsinline": true, "controls": true}}`}
    data-poster={item.thumbnail}
    data-sub-html={`<h4><a href="/activity?lift_id=${item.lift_id}&uid=${item.user_id}">${item.description} - ${item.lifted_at_date}</a></h4>`}
    data-title={item.description}
  >
    <img
      alt={item.id}
      width="200"
      height="200"
      className="img-responsive"
      src={item.thumbnail}
    />
  </div>
}

const ImageView = (item) => {
  return <a
    key={item.id}
    href={item.original}
    data-sub-html={`<h4><a href="/activity?lift_id=${item.lift_id}&uid=${item.user_id}">${item.description} - ${item.lifted_at_date}</a></h4>`}
    data-src={item.original}
    className="gallery-item"
    data-title={item.description}
  >
    <img
      alt={item.id}
      width="200"
      height="200"
      className="img-responsive"
      src={item.thumbnail} />
  </a>;
}

const PhotoLightGallery = ({ photos, thumbs, lift_data }) => {
  const images = photos.map((photo) => {
    const thumbnail = thumbs.find(thumb => {
      return photo.id === thumb.id;
    });
    const lift = lift_data && lift_data.find((l) => {
      return photo.lift_id === l.id;
    })
    const imgObj = {
      original: photo.path,
      thumbnail: thumbnail ? thumbnail.path : photo.isVideo ? 'https://storage.googleapis.com/brolifthomepage/images/default-video-thumb.png' : photo.path,
    };
    if (photo.isVideo) {
      imgObj.renderItem = VideoPlayer
    } else {
      imgObj.renderItem = ImageView
    }
    if (lift) {
      imgObj.description = lift.name;
      imgObj.lift_id = photo.lift_id;
      imgObj.user_id = photo.user_id;
      imgObj.lifted_at = convertToDate(lift.lifted_at);
      imgObj.lifted_at_date = formatDate(convertToDate(lift.lifted_at));
    }else{
      imgObj.lifted_at = new Date();
      imgObj.lifted_at_date = formatDate(new Date());
    }
    return imgObj;
  });

  return <div className='app' style={{ paddingTop: 40 }}>
    <LightGallery
      speed={500}
      plugins={[lgThumbnail, lgVideo]}
      licenseKey={'9519E65E-CAB64D16-B1576C49-6972BFA1'}
      videojs={true}
      videojsTheme={'vjs-theme-city'}
    >
      {images.sort((a, b) => (a.lifted_at > b.lifted_at) ? 1 : -1).reverse().map((image) => {
        return <React.Fragment key={image.id}>{image.renderItem(image)}</React.Fragment>;
      })}
    </LightGallery>
  </div>;
};

export default PhotoLightGallery;