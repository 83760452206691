import firebase from 'firebase/compat/app';
import { getFirestore } from "firebase/firestore";
import firebaseConfig from './firebaseconfig';
import { getFunctions } from 'firebase/functions';

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const cloudFunctions = getFunctions(firebaseApp);

const firebasedb = {db, firebaseApp, cloudFunctions};

export default firebasedb;