import React, {useContext} from 'react';
import { userContext } from './contexts/currentUser';
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({children}) => {
  const currentUser = useContext(userContext);
  if (!currentUser){
    return <Navigate to={'/login'} replace />;
  }
  return children;
}

export default ProtectedRoute;