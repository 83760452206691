import React, {useState, useEffect, useContext} from 'react';

import Comments from '../../react-native-comments';
import * as commentActions from './actions';
import {orderBy} from 'lodash';
import {convertToDate} from '../../../utils/formatDate';

import {userContext} from '../../../contexts/currentUser';
import { db } from '../../../services/firestoredb';
import { doc, onSnapshot } from "firebase/firestore";


import {getCurrentUser} from '../../../localDatabase/serverUsers';
import {themeContext} from '../../../contexts/theme';
import {uniq} from 'lodash';
import { useNavigate, createSearchParams } from "react-router-dom";

function extractUsername(c, users) {
  try {
    const cuser = users.find(u => u.uid === c.user?.uid);
    return cuser && cuser?.displayName !== undefined
      ? cuser.displayName
      : 'Unknown Person';
  } catch (e) {
    console.log(e);
  }
}

function extractUserId(c) {
  try {
    return c.user?.uid;
  } catch (e) {
    console.log(e);
  }
}

function extractImage(c, users) {
  try {
    const cuser = users.find(u => u.uid === c.user?.uid);
    return cuser && cuser?.photoURL !== '' ? cuser.photoURL : '';
  } catch (e) {
    console.log(e);
  }
}

function extractBody(c) {
  try {
    return c.comment && c.comment !== '' ? c.comment : null;
  } catch (e) {
    console.log(e);
  }
}

function extractEditTime(item) {
  try {
    return convertToDate(item.updated_at);
  } catch (e) {
    console.log(e);
  }
}

function extractCreatedTime(item) {
  try {
    return item.created_at
      ? convertToDate(item.created_at)
      : convertToDate(item.updated_at);
  } catch (e) {
    console.log(e);
  }
}

function reportedExtractor(item, currentUserUID) {
  return (item.reported || []).includes(currentUserUID);
}

function likeExtractor(item, currentUserUID) {
  return (item.likes || []).includes(currentUserUID);
}

function likesExtractor(item, users, goToProfile) {
  return (item.likes || []).map(like => {
    const luser = users.find(u => u.uid === like);
    return {
      image: luser?.photoURL || null,
      name: luser?.displayName || 'Unknown Person',
      user_id: luser?.uid,
      tap: user_uid => {
        goToProfile(user_uid);
      },
    };
  });
}

function extractChildrenCount(c) {
  try {
    return c.children ? c.children.length : 0;
  } catch (e) {
    console.log(e);
  }
}

function isCommentChild(item) {
  return (
    item.parentId !== null &&
    item.parentId !== undefined &&
    item.parentId !== false
  );
}

const CommentsList = ({lift_id, lift_user_id}) => {
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(true);
  const currentUser = useContext(userContext);
  const theme = useContext(themeContext);
  const [users, setUsers] = useState([]);
  const navigation = useNavigate();

  const goToProfile = user_uid => {
    navigation({
        pathname: '/profile/', search: `?${createSearchParams({
            user_id: user_uid,
          })}`
      });
  };

  useEffect(() => {
    let subscriber = () => {};
    subscriber = onSnapshot(doc(db, "lift_comment", lift_id), (documentSnapshot) => {
        if (documentSnapshot.exists()) {
            const sortedComments = orderBy(
              documentSnapshot.data().comments || [],
              ['updated_at'],
              ['desc'],
            );
            const usersInComment = uniq(
              sortedComments
                .flatMap(c => {
                  return [
                    c.user?.uid,
                    ...(c.children || []).map(cc => cc.user?.uid),
                    ...(c.likes || []),
                    ...(c.children || []).flatMap(cc => {
                      return cc.likes || [];
                    }),
                  ];
                })
                .filter(x => x !== undefined && x !== null),
            );
            const userFetchPromises = usersInComment.map(u => {
              return new Promise((resolve, reject) => {
                getCurrentUser(u, userData => {
                  resolve(userData);
                });
              });
            });
            Promise.all(userFetchPromises).then(returnedUsers => {
              const peopleWhoExist = returnedUsers.filter(
                x => x !== undefined && x !== null,
              );
              setUsers(peopleWhoExist);
              setComments(sortedComments);
            });
          }
          setLoadingComments(false);
    });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, [lift_user_id, lift_id]);

  return (
    /*
     * They should add scroll to end on save action
     *They should not update comments if there are modals opened
     *
     * */
    <div style={{flexDirection: 'row', padding: 20, paddingBottom: 300}}>
      <Comments
        backgroundColor={`rgb(${theme.primary.backgroundColor})`}
        likedColor={`rgb(${theme.primary.main})`}
        unlikedColor={
          theme.darkMode ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)'
        }
        submitButtonColor={
          theme.darkMode ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)'
        }
        inputPlaceholderTextColor={
          theme.darkMode ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)'
        }
        data={comments}
        loadingComments={loadingComments}
        //To compare is user the owner
        viewingUserName={currentUser.displayName}
        viewingUserId={currentUser.uid}
        // is the current user an admin
        userIsAdmin={false}
        // Styles to pass. Search for getStyles to find out what can be overwritten
        styles={{
          rightContent: {
            paddingTop: 10,
            paddingLeft: 10,
            borderRadius: 5,
            backgroundColor: theme.darkMode
              ? 'rgba(255,255,255,0.05)'
              : 'rgba(255,255,255,0.4)',
          },
          rightActionBar: {
            marginTop: 6,
          },
          body: {
            color: theme.darkMode ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)',
          },
          username: {
            color: `rgb(${theme.primary.main})`,
          },
          actionText: {
            color: theme.darkMode ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)',
          },
          input: {
            backgroundColor: `rgb(${theme.primary.backgroundColor})`,
            color: theme.darkMode ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)',
            flexGrow: 1,
            padding: 3,
          },
          inputSection: {
            backgroundColor: `rgb(${theme.primary.backgroundColor})`,
            paddingBottom: 30
          },
          submit: {
            backgroundColor: `rgb(${theme.primary.backgroundColor})`,
          },
          editModalContainer: {
            justifyContent: 'flex-start',
          },
          editModal: {
            backgroundColor: `rgb(${theme.primary.backgroundColor})`,
            borderColor: theme.darkMode
              ? 'rgba(255,255,255,0.2)'
              : 'rgba(0,0,0,0.2)',
            borderRadius: 5,
            borderWidth: 1,
            padding: 20,
            paddingBottom: 0,
            width: '100%',
            margin: 0,
            height: '40%',
          },
          editButtons: {
            borderColor: theme.darkMode
              ? 'rgba(255,255,255,0.2)'
              : 'rgba(0,0,0,0.2)',
          },
          editButtonSave: {
            backgroundColor: `rgb(${theme.primary.main})`,
          },
          menu: {
            backgroundColor: `rgb(${theme.primary.backgroundColor})`,
            borderColor: theme.darkMode
              ? 'rgba(255,255,255,0.2)'
              : 'rgba(0,0,0,0.2)',
            borderRadius: 5,
            borderWidth: 1,
            width: 150,
          },
          menuText: {
            color: theme.darkMode ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)',
          },
          likeModalContainer: {
            backgroundColor: `rgb(${theme.primary.backgroundColor})`,
            display: 'flex'
          },
          likeContainer: {
            borderBottomColor: theme.darkMode
              ? 'rgba(255,255,255,0.1)'
              : 'rgba(0,0,0,0.1)',
            borderBottomWidth: 1,
          },
          likeModalContainerHeader: {
            borderBottomColor: theme.darkMode
              ? 'rgba(255,255,255,0.1)'
              : 'rgba(0,0,0,0.1)',
            borderBottomWidth: 1,
          },
          likeName: {
            marginTop: 5,
            fontWeight: 'bold',
            fontSize: 16,
            color: theme.darkMode ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)',
          },
          likeProfileButtonText: {
            color: theme.darkMode ? 'rgb(0,0,0)' : 'rgb(255,255,255)',
          },
          likeHeader: {
            color: theme.darkMode ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)',
          },
          likeNr: {
            color: `rgb(${theme.primary.main})`,
          },
          repliedCount: {
            color: theme.darkMode ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)',
          },
          repliedSection: {
            marginBottom: 20,
          },
          noCommentsYet: {
            color: theme.darkMode ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)',
          },
        }}
        //how many comments to display on init
        // initialDisplayCount={9999}
        //How many minutes to pass before locking for editing
        editMinuteLimit={0}
        //What happens when user taps on username or photo
        usernameTapAction={user_uid => {
          goToProfile(user_uid);
        }}
        //Where can we find the children within item.
        //Children must be prepared before for pagination sake
        childPropName={'children'}
        isChild={item => isCommentChild(item)}
        //We use this for key prop on flat list (i.e. its comment_id)
        keyExtractor={item => item.id}
        //Extract the key indicating comments parent
        parentIdExtractor={item => item.parentId}
        //what prop holds the comment owners username
        usernameExtractor={item => extractUsername(item, users)}
        //what prop holds the comment owners id
        userIdExtractor={item => extractUserId(item)}
        //when was the comment last time edited
        editTimeExtractor={item => extractEditTime(item)}
        //When was the comment created
        createdTimeExtractor={item => extractCreatedTime(item)}
        //where is the body
        bodyExtractor={item => extractBody(item)}
        //where is the user image
        imageExtractor={item => extractImage(item, users)}
        //Where to look to see if user liked comment
        likeExtractor={item => likeExtractor(item, currentUser.uid)}
        //Where to look to see if user reported comment
        reportedExtractor={item => reportedExtractor(item, currentUser.uid)}
        //Where to find array with user likes
        likesExtractor={item => likesExtractor(item, users, goToProfile)}
        //Where to get nr of replies
        childrenCountExtractor={item => extractChildrenCount(item)}
        //what to do when user clicks reply. Usually its header height + position (b/c scroll list heights are relative)
        replyAction={offset => {
          // scrollViewRef.current?.scrollTo({
          //   x: null,
          //   y: scrollIndex + offset - 300,
          //   animated: true
          // });
        }}
        //what to do when user clicks submits edited comment
        saveAction={(text, parentCommentId) => {
          commentActions.save(
            comments,
            text,
            parentCommentId,
            currentUser,
            lift_id,
            lift_user_id,
          );
        }}
        //what to do when user clicks submits edited comment
        editAction={(text, comment) => {
          commentActions.edit(comments, comment, text, lift_id, currentUser);
        }}
        //what to do when user clicks report submit
        reportAction={comment => {
          commentActions.report(comments, comment, lift_id, currentUser);
        }}
        //what to do when user clicks like
        likeAction={comment => {
          commentActions.like(
            comments,
            comment,
            lift_id,
            currentUser,
            lift_user_id,
          );
        }}
        //what to do when user clicks like
        deleteAction={comment => {
          commentActions.deleteComment(comments, comment, lift_id, currentUser);
        }}
        //Must return promise
        // paginateAction={(from_comment_id, direction, parent_comment_id) => {
        //   //Must return array of new comments after pagination

        //   let newComments = commentActions.paginateComments(
        //     comments,
        //     from_comment_id,
        //     direction,
        //     parent_comment_id
        //   );

        //   setTimeout(function () {
        //     if (direction == "up") {
        //       scrollViewRef.current?.scrollTo({
        //         x: 0,
        //         y: 500,
        //         animated: true
        //       });
        //     } else {
        //       scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
        //     }
        //   }, 3000);
        // }}
      />
    </div>
  );
};

export default CommentsList;