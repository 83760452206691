import React, {useContext} from 'react';
import {themeContext} from '../contexts/theme';
import Dialog from '@mui/material/Dialog';

const PopupModal = ({
  closeText,
  closeModal,
  saveText,
  saveModal,
  visible,
  title,
  children,
}) => {
  const theme = useContext(themeContext);
  return <Dialog onClose={closeModal} open={visible}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            padding: 15
          }}>
          <div style={{width: 65}}>
            {closeModal && (
              <div
                style={{
                  margin: 0,
                  padding: 5,
                  cursor: 'pointer',
                }}
                onClick={closeModal}>
                <div
                  style={{
                    textAlign: 'left',
                    color: `rgb(${theme.primary.main})`,
                    fontSize: 15,
                    fontWeight: '400',
                  }}>
                  {closeText || 'Close'}
                </div>
              </div>
            )}
          </div>
          <div style={{flexGrow: 1}}>
            <div
              style={{
                color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
                textAlign: 'center',
                fontSize: 20,
              }}>
              {title}
            </div>
          </div>
          <div style={{width: 65}}>
            {saveModal && (
              <div
                style={{
                  margin: 0,
                  padding: 5,
                }}
                onClick={saveModal}>
                <div
                  style={{
                    color: `rgb(${theme.primary.main})`,
                    textAlign: 'right',
                    fontSize: 15,
                    fontWeight: '400',
                  }}>
                  {saveText || 'Close'}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: 20,
            display: 'flex',
          }}>
          {children}
        </div>
    </Dialog>;
};

export default PopupModal;