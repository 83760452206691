import { db } from '../services/firestoredb';
import { doc, getDoc } from "firebase/firestore";

export const getCurrentUser = async (currentUserUid, callback) => {
  const docRef = doc(db, "Users", currentUserUid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    callback && callback(docSnap.data());
  }
};

export const fetchUser = async (uid) => {
  const docRef = doc(db, "Users", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
  return undefined;
};

const serverUsers = { getCurrentUser, fetchUser }

export default serverUsers;