import React, { useContext } from 'react';

import { themeContext } from '../contexts/theme';

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryGroup,
  VictoryTheme,
  VictoryBar,
  VictoryLabel,
  VictoryVoronoiContainer
} from 'victory';

const BarLineComboChart = ({
  minBarDisplayValue,
  maxBarValue,
  barData,
  lineData,
  barDomainPadding,
  lineDomainPadding,
  formatBarLabel,
  formatLineLabel,
  height,
  width,
  barLabelPadding
}) => {
  const theme = useContext(themeContext);
  return (
    <div>
      <VictoryChart
        height={height || 300}
        width={width || 450}
        domainPadding={{ x: barDomainPadding }}
        minDomain={{
          y: 0,
        }}
        maxDomain={{
          y:
            maxBarValue < minBarDisplayValue + 1
              ? minBarDisplayValue
              : maxBarValue,
        }}
      >
        <VictoryAxis
          crossAxis
          style={{
            grid: {
              fill: 'none',
              stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'}, 0.1)`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              pointerEvents: 'painted',
            },
            axis: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            ticks: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              size: 0,
            },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              paddingLeft: 20
            },
          }}
          tickLabelComponent={<VictoryLabel dx={barLabelPadding || 25} />}
        />
        <VictoryAxis
          standalone={false}
          orientation="left"
          dependentAxis
          theme={VictoryTheme.material}
          tickFormat={t => t.toString()}
          style={{
            grid: {
              fill: 'none',
              stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'}, 0.1)`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              pointerEvents: 'painted',
            },
            axis: {
              stroke: 'transparent',
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            ticks: {
              stroke: 'transparent',
              size: 0,
            },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fill: `transparent`,
            },
          }}
        />

        <VictoryBar
          alignment="start"
          barRatio={0.8}
          data={barData}
          labels={formatBarLabel}
          labelComponent={
            <VictoryLabel dx={barLabelPadding || 25} textAnchor="middle" />
          }
          style={{
            data: {
              strokeWidth: 1,
              fill: `rgba(${theme.primary.main}, 0.8)`,
              stroke: `rgb(${theme.primary.main})`,
            },
            labels: {
              fill: `rgb(${theme.primary.main})`,
              fontWeight: 'bold'
            },
          }}
        />
      </VictoryChart>


      <VictoryChart
        style={{parent: {
          marginTop: -(height || 300)
        }}}
        height={height || 300}
        width={width || 450}
        domainPadding={{ x: lineDomainPadding }}
        minDomain={{
          y: 0,
        }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `${datum.y}`}
            labelComponent={
              <VictoryLabel backgroundPadding={10}
                backgroundStyle={{ fill: `rgba(${theme.darkMode ? '0,0,0,0.7' : '0,0,0,0.1'})` }}
              />
            }
          />
        }>
        <VictoryAxis
          standalone={false}
          orientation="right"
          dependentAxis
          theme={VictoryTheme.material}
          tickFormat={t => (parseFloat(t) >= 1 ? t.toString() : '0')}
          style={{
            grid: {
              fill: 'none',
              stroke: `transparent`,
            },
            axis: {
              stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'},0.8)`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            ticks: {
              stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'},0.8)`,
              size: 0,
            },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fill: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'},0.8)`,
            },
          }}
        />
        <VictoryGroup data={lineData}>
          <VictoryLine
            labels={formatLineLabel}
            style={{
              data: {
                stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'
                  },0.8)`,
                strokeWidth: 2,
              },
              labels: {
                fill: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'},0.8)`,
                fontWeight: 'bold',
              },
            }}
          />
        </VictoryGroup>
      </VictoryChart>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
        <div
          style={{
            paddingLeft: 10,
            flexDirection: 'row',
            display: 'flex'
          }}>
          <div
            style={{
              height: 15,
              width: 15,
              borderRadius: 2,
              backgroundColor: `rgb(${theme.primary.main})`,
            }}></div>
          <div
            style={{
              fontSize: 15,
              paddingLeft: 10,
              color: `rgb(${theme.primary.main})`,
            }}>
            Workouts
          </div>
        </div>
        <div
          style={{
            paddingLeft: 10,
            flexDirection: 'row',
            display: 'flex'
          }}>
          <div
            style={{
              height: 15,
              width: 15,
              borderRadius: 2,
              backgroundColor: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'
                },0.8)`,
            }}></div>
          <div
            style={{
              fontSize: 15,
              paddingLeft: 10,
              color: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'},0.8)`,
            }}>
            PRs
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarLineComboChart;