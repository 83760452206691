import React from 'react';
import PopupModal from '../PopupModal';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

const EditOptions = ({ closeMenu, canEdit, reportAction, isOwnComment, reported, handleEdit, handleDelete }) => {

    const options = () => {
        let optionsArr = [];
        optionsArr.push('Cancel')
        if (canEdit) {
            optionsArr.push('Edit');
            optionsArr.push('Delete');
        }
        if (reportAction && !isOwnComment) {
            optionsArr.push(reported ? 'Reported' : 'Report');
        }
        return optionsArr;
    }

    const optionsActions = () => {
        let optionsArrActions = [];
        optionsArrActions.push(() => {
            closeMenu();
        })
        if (canEdit) {
            optionsArrActions.push(handleEdit)
            optionsArrActions.push(handleDelete)
        }
        if (reportAction && !isOwnComment) {
            optionsArrActions.push(reportAction)
        }
        return optionsArrActions;
    }

    return <PopupModal
        closeModal={() => { closeMenu() }}
        closeText={'Back'}
        visible={true}
        title={'Actions'}>
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                '& > *': {
                    m: 1,
                },
            }}
        >
            <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
                fullWidth={true}
            >
                {options().map((option, buttonIndex) => {
                    return <Button fullWidth={true} key={`button-${buttonIndex}`} onClick={() => {
                        optionsActions()[buttonIndex]()
                    }}>{option}</Button>
                })}
            </ButtonGroup>
        </Box>
    </PopupModal>
}

export default EditOptions;