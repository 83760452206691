import {groupBy} from 'lodash';

import {formatDateTime, formatDate} from './formatDate';

export const mapTrackersToLifts = lifts => {
  return lifts.map(lift => {
    lift.lift_trackers = groupBy(lift.lift_trackers_attributes, 'lift_id');
    return lift;
  });
};

export const mapServerLiftsToSmallLifts = (lifts, uid) => {
  return (lifts || []).map(lift => ({
    id: lift.id,
    lifted_at_date: formatDate(lift.lifted_at),
    lifted_at_text: formatDateTime(lift.lifted_at),
    uid: uid || lift.user_id,
    lifted_at: lift.lifted_at,
    rpe: lift.rpe,
    name: lift.name,
    user: lift.user,
    exercises: Object.entries(
      groupBy(lift.lift_trackers_attributes, 'lift_id'),
    ).map(lts => lts[1][0].exercise.name),
    numOfPRs:
      lift.lift_trackers_attributes &&
      lift.lift_trackers_attributes.filter(tracker => {
        return (
          tracker.personal_record_attributes &&
          tracker.personal_record_attributes.id
        );
      }).length,
  }));
};

const liftTrackersFuncs = { mapServerLiftsToSmallLifts, mapTrackersToLifts };

export default liftTrackersFuncs;