import React, { useState, useContext } from 'react';

import Avatar from '../../../components/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { isBlank } from '../../../localDatabase/serverExercises';

import { db, firebaseApp } from '../../../services/firestoredb';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";

import { userContext } from '../../../contexts/currentUser';
import { themeContext } from '../../../contexts/theme';

const AccountInfo = () => {
  const currentUser = useContext(userContext);
  const storageApp = getStorage(firebaseApp);

  const [name, setName] = useState(currentUser.displayName);
  const currentPhoto = currentUser.photoURL;
  const [tempPhoto, setTempPhoto] = useState();
  const [updating, setUpdating] = useState();
  const theme = useContext(themeContext);
  const [saveToolTipOpen, setSaveToolTipOpen] = useState(false);

  const updateAccountInfo = async () => {
    setUpdating(true);

    if (tempPhoto) {
      const storageRef = ref(storageApp, `/Users/${currentUser.uid}/profile/image.png`);
      uploadBytes(storageRef, tempPhoto).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          await updateDoc(doc(db, "Users", currentUser.uid), {
            displayName: name,
            updated_at: new Date(),
            photoURL: url,
          }, { merge: true });
          setUpdating(false);
          setSaveToolTipOpen(true);
        });
      });
    } else {
      await updateDoc(doc(db, "Users", currentUser.uid), {
        displayName: name,
        updated_at: new Date(),
      }, { merge: true });
      setUpdating(false);
      setSaveToolTipOpen(true);
    }
  };

  return (
    <>
      <Snackbar open={saveToolTipOpen} autoHideDuration={6000} onClose={() => setSaveToolTipOpen(false)}>
        <Alert onClose={() => setSaveToolTipOpen(false)} severity="success" sx={{ width: '100%' }}>
          Account Updated!
        </Alert>
      </Snackbar>
      <div
        style={{
        }}>
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <div
            style={{
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              fontSize: 18,
            }}>
            Name:
          </div>
          <div style={{ height: 5, width: '100%' }} />
          <div style={{ paddingRight: 10, flexGrow: 1 }}>
            <TextField value={name} label="name" variant="filled" id={'account-name-input'} onChange={(e) => setName(e.target.value)} />
          </div>
        </div>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>

          {tempPhoto && (
            <Avatar
              color={`rgb(${theme.primary.main})`}
              type="image"
              image={<img alt="new profile" src={URL.createObjectURL(tempPhoto)} />}
              size={64}
            />
          )}
          {!tempPhoto && !isBlank(currentPhoto) && (
            <Avatar
              type="image"
              color={`rgb(${theme.primary.main})`}
              image={<img alt="current profile" src={currentPhoto} />}
              size={64}
            />
          )}
          <div style={{ flex: 1, paddingLeft: 15, paddingBottom: 25 }}>
            <div
              style={{
                color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
                fontSize: 18,
              }}>
              Avatar:
            </div>
            <div style={{ height: 5, width: '100%' }} />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setTempPhoto(e.target.files[0])}
            />
          </div>
        </div>

        <Button
          variant="contained"
          disabled={updating}
          fullWidth
          onClick={updateAccountInfo}
        >
          Update Account
        </Button>
      </div>
    </>
  );
};

export default AccountInfo;