import React, { useContext, useState } from "react";

import { firebaseApp } from '../services/firestoredb';
import { userContext } from '../contexts/currentUser';
import { themeContext } from "../contexts/theme";

import LogoImageLight from '../images/brolift-logo-light.png';
import LogoImageDark from '../images/brolift-logo.png';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { NavLink as Link } from "react-router-dom";
import NavbarLink from './NavbarLink';

import { useNavigate } from "react-router-dom";


const Navbar = () => {

    const navigation = useNavigate();
    const currentUser = useContext(userContext);
    const theme = useContext(themeContext);

    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const pages = [{ url: '/activities', text: 'feed' }];

    return (
        <div style={{marginBottom: 10}}>
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <div style={{paddingRight: 20}}>
                        <div onClick={() => {
                            navigation({
                                pathname: '/dashboard'
                                });
                        }}>
                            <img
                                alt="Brolift logo"
                                style={{ width: 175, height: 40 }}
                                src={
                                    theme.darkMode
                                        ? LogoImageDark
                                        : LogoImageLight
                                }
                            />
                        </div>
                    </div>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page, index) => (
                            <Button
                                key={`page-${index}`}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <Link to={page.url}>
                                    {page.text}
                                </Link>
                            </Button>
                        ))}
                    </Box>


                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src={currentUser?.photoURL} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >

                            <NavbarLink
                                key={'AccountInfo'}
                                url={'/AccountInfo'}
                                text={'Account Info'}
                                handleCloseUserMenu={handleCloseUserMenu} />
                            <NavbarLink
                                key={'Measurements'}
                                url={'/measurements'}
                                text={'Measurements'}
                                handleCloseUserMenu={handleCloseUserMenu} />
                            <NavbarLink
                                key={'Friends'}
                                url={'/Friends'}
                                text={'Friends'}
                                handleCloseUserMenu={handleCloseUserMenu} />

                            <NavbarLink
                                key={'View Layout Mode'}
                                url={'/view_mode'}
                                text={'View Layout Mode'}
                                handleCloseUserMenu={handleCloseUserMenu} />
                            <NavbarLink
                                key={'Powerlifting'}
                                url={'/PowerliftingSettings'}
                                text={'Powerlifting'}
                                handleCloseUserMenu={handleCloseUserMenu} />
                            <NavbarLink
                                key={'Theme'}
                                url={'/theme'}
                                text={'Theme'}
                                handleCloseUserMenu={handleCloseUserMenu} />

                            <MenuItem key={`logoutSetting`} onClick={() => {
                                firebaseApp.auth().signOut();
                                currentUser.logout();
                                window.location = '/';
                            }}>
                                <Typography color={theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'} textAlign="center">Logout</Typography>
                            </MenuItem>

                        </Menu>
                    </Box>


                </Toolbar>
            </Container>
        </AppBar>
        </div>
    );
};
export default Navbar;