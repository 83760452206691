const defaultExercises = [
  {
      "name": "Decline Smith Press",
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "id": "394",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "00hxJdcI5jzOjSadxcb7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "name": "Standing Toe Touches",
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "687",
      "muscle_type": "Hamstrings",
      "key": "0747h3hN0Y6W9gLToTds",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Reverse Crunch",
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "id": "187",
      "equipment_type": "Body Only",
      "key": "08cPZA3cYuxUAQTKDktM",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Tuck Crunch",
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "id": "364",
      "key": "0GMKzg3W54a7MbSUEq0c",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Side Neck Stretch",
      "more_time_is_pr": null,
      "muscle_type": "Neck",
      "id": "777",
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "0KQdOrEAwMhHXqqGnoLO",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Standing Pelvic Tilt",
      "muscle_type": "Lower Back",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "id": "823",
      "equipment_type": "Body Only",
      "key": "0LI6EHxjVllBrYkthAZ7",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Bands",
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "id": "1017",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "name": "Bench Press With Short Bands",
      "key": "0LpkNgAGg6wVcPR8CPBf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "id": "1070",
      "more_time_is_pr": null,
      "name": "Russian Bar Dip",
      "key": "0bxrsRpLn4qPUnLPKEfQ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "id": "738",
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "muscle_type": "Neck",
      "name": "Neck Bridge Prone",
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "key": "0llUNHlYpZ3PF5x9s898",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "id": "1057",
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "name": "Single-Leg Skater Squat",
      "key": "0ox9GLjMTiuZnCLawYuJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "name": "Kneeling Forearm Stretch",
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "id": "761",
      "muscle_type": "Forearms",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "0tBBtByzZ2PXVvCY7lHs",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "name": "Diamond Push-Up",
      "equipment_type": "Body Only",
      "id": "938",
      "key": "0uEXiW2jghYfieOPOBrX",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "One Arm Pronated Dumbbell Triceps Extension",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "id": "386",
      "muscle_type": "Triceps",
      "equipment_type": "Dumbbell",
      "key": "0ukFIyacilFufteGopHY",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "name": "Front Barbell Squat To A Bench",
      "muscle_type": "Quadriceps",
      "id": "750",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "key": "0ynGNs5ceqG7BKgjyhh2",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "name": "Reverse Plate Curls",
      "muscle_type": "Biceps",
      "id": "653",
      "more_time_is_pr": null,
      "key": "0zAIHgt2G091Bv6vzA6g",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "name": "Snatch from Blocks",
      "id": "866",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "muscle_type": "Quadriceps",
      "key": "164A5gWgyAGrgV6IJb9W",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "name": "Medicine Ball Ninja",
      "equipment_type": "Medicine Ball",
      "updated_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "id": "1087",
      "key": "19BvpuIOyTefx0lOeSpN",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "646",
      "muscle_type": "Chest",
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "name": "Smith Machine Decline Press",
      "key": "1ANiLvXKX75ObGsCIqyI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "468",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Wide-Stance Barbell Squat",
      "key": "1ApYN1QGjVJ2tH43eGDX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Smith Single-Leg Split Squat",
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "id": "574",
      "equipment_type": "Machine",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "1D2sFa4UAGP37jD6Z4W7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "name": "Suspended Crunch",
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "id": "928",
      "more_time_is_pr": null,
      "key": "1DkjQ1RTynQdLFYLKVzi",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "id": "696",
      "name": "Piriformis-SMR",
      "equipment_type": "Foam Roll",
      "more_time_is_pr": null,
      "muscle_type": "Glutes",
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "key": "1O7ED35wR7IGNmATykcj",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "619",
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "name": "One-Arm Kettlebell Swings",
      "key": "1OuhXzZPzTf4FyOVUbqw",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "name": "Leverage Iso Row",
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "id": "392",
      "more_time_is_pr": null,
      "muscle_type": "Lats",
      "key": "1OznS2q0Q606NTIT3onW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "id": "994",
      "name": "Single-Leg Lateral Hop",
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "key": "1VLNx24TVsUOPkRXfVXI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "id": "151",
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "name": "Cable Crunch",
      "key": "1dCYC3xAHEgeig3XdgdA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "693",
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "equipment_type": "Bands",
      "muscle_type": "Abductors",
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "name": "Monster Walk",
      "more_time_is_pr": null,
      "key": "1eVU5mjd2EwYF3WKCdCt",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Straight Bar Bench Mid Rows",
      "id": "701",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "muscle_type": "Middle Back",
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "key": "1g4ZMUupziGwmLGtw5U6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "221",
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "name": "Tricep Dumbbell Kickback",
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "key": "1hUTwdV21f6drQjpilR8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "669",
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "name": "Pin Presses",
      "key": "1mD4okF0NIjtBN5FlQAu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "id": "778",
      "name": "Elbows Back",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "1mpe6CO3jLW9hUSvpjrG",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "id": "915",
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "name": "Partner Side-To-Side Russian Twist &amp; Pass",
      "key": "1s5W6zUfIHoJ8QCauGYI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Glutes",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "name": "Pull Through",
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "id": "628",
      "more_time_is_pr": null,
      "key": "1tSMd2BAzGjVRiJjeCoQ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "name": "Medicine Ball Scoop Throw",
      "id": "814",
      "more_time_is_pr": null,
      "key": "1u0lQr7s5mVvK44SLzIK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "name": "Squat with Bands",
      "id": "860",
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "key": "1v0ZQFdqOFApQnonT2Xq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "44",
      "name": "Bottoms Up",
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Abdominals",
      "key": "1vRqfHKB7zKUMLClBMRP",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Fast Kick With Arm Circles",
      "equipment_type": "Body Only",
      "id": "1007",
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "key": "24SngFRkZKl8Ztn3Ofj7",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "id": "168",
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Barbell Wide Grip Bench Press",
      "updated_at": {
          "seconds": 1581295444,
          "nanoseconds": 0
      },
      "key": "24tGGmEQqfcPEBiLGf1x",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Dumbbell Lying Rear Lateral Raise",
      "id": "434",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "24vMxsX3wCWvK6Y3jKq9",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "id": "188",
      "name": "Air Bike",
      "equipment_type": "Body Only",
      "key": "24xzgQ8MCwbFiZZsjTD4",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Lower Back",
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "name": "Seated Good Mornings",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "id": "494",
      "key": "25ulooR79EvJ8KQXNBXv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Kettlebells",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "name": "One-Arm Overhead Kettlebell Squats",
      "muscle_type": "Quadriceps",
      "id": "673",
      "key": "2AlO5kjb5SNMXSB1skqK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Single Dumbbell Raise",
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "id": "136",
      "equipment_type": "Dumbbell",
      "muscle_type": "Shoulders",
      "key": "2FDntO9f7eDkhKca7vUy",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Goblet Squat",
      "equipment_type": "Kettlebells",
      "id": "248",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "2FN7TR6yrFeEm6ETAEZZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "32",
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "name": "Hang Clean",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "key": "2FxVB3Kaeb8zwx7fNOlf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Double Kettlebell Alternating Hang Clean",
      "more_time_is_pr": null,
      "id": "850",
      "muscle_type": "Hamstrings",
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "key": "2IwxvRHxajp8qfNRDTOL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "name": "Heavy Bag Thrust",
      "id": "989",
      "equipment_type": "Other",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "key": "2Qwu6eys6iYSwke0yE6Y",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Single Leg Butt Kick",
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "584",
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "key": "2SLE6iEfdIs6kTbplPWy",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "632",
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "name": "Side Jackknife",
      "key": "2VuwonvDEzoN2NfPbz1z",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Kettlebell Figure 8",
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "579",
      "equipment_type": "Kettlebells",
      "key": "2ZLPjMPm90MgwBvDwtDh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Standing Biceps Cable Curl",
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "id": "327",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "key": "2aBq6s7DzBE9ibfuPuMp",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "206",
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "equipment_type": "Cable",
      "name": "Close-Grip Front Lat Pulldown",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "key": "2eLI1UeG1GsfbLeBB3Ji",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "id": "1042",
      "name": "Negative Pullup",
      "muscle_type": "Lats",
      "updated_at": {
          "seconds": 1581296046,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "key": "2fyVMpBdCCv8F8YyHBPC",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "muscle_type": "Biceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "id": "216",
      "name": "Standing One-Arm Cable Curl",
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "key": "2hdknJIDjlyVfHmNVLCR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "id": "479",
      "more_time_is_pr": null,
      "equipment_type": "Foam Roll",
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "name": "Iliotibial Tract-SMR",
      "muscle_type": "Abductors",
      "key": "2jC2zHUL7dlyKJqNbI2Z",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "449",
      "equipment_type": "Dumbbell",
      "name": "Bent-Arm Dumbbell Pullover",
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "key": "2nvZ6ZEaKH4ULx5F8mAA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "654",
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "name": "Overhead Press",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1586265196,
          "nanoseconds": 0
      },
      "key": "2pE5nfSzFP8ISdgyC7VI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "976",
      "muscle_type": "Hamstrings",
      "name": "Hanging Bar Good Morning",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "key": "2pmPsZW33sCfPglYc3sc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "name": "Push-Ups With Feet On An Exercise Ball",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "326",
      "equipment_type": "Exercise Ball",
      "key": "2t6nwY7WP7yFiACGq9v6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "name": "Decline Oblique Crunch",
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Abdominals",
      "id": "329",
      "key": "2vqxo2QHjsmWfQdCZEmy",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "id": "822",
      "more_time_is_pr": null,
      "name": "Chair Lower Back Stretch",
      "key": "31vTE96sqw7jFNZvulYP",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "name": "Standing Leg Curl",
      "muscle_type": "Hamstrings",
      "id": "615",
      "key": "365jbbAylyNYQWPV9KKT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "867",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "muscle_type": "Quadriceps",
      "name": "Split Clean",
      "key": "36ZIKe6AnI3YMEGiU5Y0",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1586267454,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "muscle_type": "Triceps",
      "id": "1097",
      "more_time_is_pr": null,
      "name": "Close Grip Pin Bench Press",
      "created_at": {
          "seconds": 1584644827,
          "nanoseconds": 0
      },
      "key": "37hTArS03kW8COfhGjeI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "equipment_type": "Bands",
      "id": "1028",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "name": "Forward Band Walk",
      "key": "3IZ2vk1pIC1YTiOcEy93",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "527",
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Abductors",
      "more_time_is_pr": null,
      "name": "Fire Hydrant",
      "key": "3JgJmv808tAfWPtyXbWN",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "id": "195",
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "name": "Stairmaster",
      "key": "3MWh39h4t25TUNtrHACE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "id": "117",
      "muscle_type": "Forearms",
      "name": "Seated Palms-Down Barbell Wrist Curl",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "key": "3MazNBzTGHnTuTtqofZ7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Biceps",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "id": "547",
      "name": "High Cable Curls",
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "key": "3MzRnYflQHQh8r43BH6f",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Dips - Chest Version",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1581296294,
          "nanoseconds": 0
      },
      "id": "100",
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "key": "3Pjch2R6rnHisPtDXD2B",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "937",
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "name": "Stability Ball Pike With Knee Tuck",
      "more_time_is_pr": null,
      "equipment_type": "Exercise Ball",
      "muscle_type": "Abdominals",
      "key": "3VGtoamuiUTyrelSO42n",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Chest Push from 3 point stance",
      "id": "987",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "key": "3WkEyQRcNr025QtwkKim",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Weighted Jump Squat ",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "133",
      "key": "3WvtOhxtq4PzbWR3JL5n",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "685",
      "equipment_type": "Barbell",
      "muscle_type": "Traps",
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "name": "Clean Shrug",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "key": "3XkwiOWg1EAveDSahDxP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "358",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Jumping Jacks",
      "key": "3Y70Kej1fPEmEms0bweH",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Axle Deadlift",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "id": "77",
      "key": "3Zhk3zFtcSbCiLPJr5ew",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "name": "Body Tricep Press",
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "462",
      "key": "3iIy4KhKss85Ve10vDWm",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "id": "639",
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "name": "Wide-Grip Decline Barbell Pullover",
      "key": "3s9TyQWilCBy3Jc7R8GS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "name": "Flutter Kicks",
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Glutes",
      "id": "431",
      "more_time_is_pr": null,
      "key": "3sIa7AlVV8PBhzpuqts3",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "name": "Lunge Heel Kick",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "id": "962",
      "equipment_type": "Body Only",
      "key": "3yNlBAAlICUtFadSHUNH",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Standing Cable Chest Press",
      "id": "460",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "key": "3yirNBkUuImpUaO8rUUg",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Snatch Deadlift",
      "muscle_type": "Hamstrings",
      "id": "178",
      "more_time_is_pr": null,
      "key": "41Q5YrS40eNiS3fdD2TP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "322",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "name": "Pushups (Close and Wide Hand Positions)",
      "key": "42Ijv19fPx65aZ6EOiBu",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "name": "Cable Rope Rear-Delt Rows",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "id": "366",
      "key": "43fMJw5uOm45F0trW2Wz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "id": "337",
      "muscle_type": "Quadriceps",
      "name": "Scissors Jump",
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "471kVAnHoEublEpJ0ITm",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Hamstrings",
      "name": "90/90 Hamstring",
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "id": "766",
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "482lZU9QWg8XWra4lUhs",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Glutes",
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "id": "20",
      "equipment_type": "Barbell",
      "name": "Barbell Glute Bridge",
      "key": "4BFo0VVhqQrURTwnUAU9",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "name": "Barbell Lunge",
      "id": "210",
      "muscle_type": "Quadriceps",
      "equipment_type": "Barbell",
      "key": "4CCeCgjFNMI1jbxAQdwn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "id": "307",
      "name": "Triceps Pushdown",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "4GYY33Rnf7ahUfAoDePn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Single-Arm Push-Up",
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "id": "294",
      "equipment_type": "Body Only",
      "key": "4I7DodeaTJmFjlWGbJRs",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "id": "273",
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "name": "Seated Palm-Up Barbell Wrist Curl",
      "more_time_is_pr": null,
      "key": "4LweqaYYnn73ChD9Zn2s",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "name": "Sandbag Load",
      "muscle_type": "Quadriceps",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "id": "861",
      "more_time_is_pr": null,
      "key": "4R6KsE5aLcTfrieWmgpB",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "name": "Lateral Speed Step",
      "id": "902",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "4T7SUVx4vO32wGwPiA5O",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Squats - With Bands",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "equipment_type": "Bands",
      "id": "733",
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "key": "4UFTSBE4TBfyxdh5NpsP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "162",
      "muscle_type": "Lats",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "name": "Muscle Up",
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "key": "4cMeJ7hPOCo3ZNvsAm5z",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "882",
      "name": "Carioca Quick Step",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "muscle_type": "Adductors",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "key": "4jxWUEwZSFDIyWceeKdU",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "equipment_type": "E-Z Curl Bar",
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "id": "52",
      "muscle_type": "Biceps",
      "name": "Spider Curl",
      "more_time_is_pr": null,
      "key": "4yWSR7KM4fuuBlNGe2rD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Biceps",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "id": "1078",
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "name": "Hammer Plate Curl",
      "more_time_is_pr": null,
      "key": "539gsvXTmxdc5qsWpATO",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1036",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "muscle_type": "Shoulders",
      "name": "Half-kneeling Dumbbell Shoulder Press",
      "key": "569PtwZcSZQMM3t6Tjqf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Bent-Knee Hip Raise",
      "id": "500",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "key": "570Joze8QnvCVWHpIuRf",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "name": "Standing Overhead Barbell Triceps Extension",
      "equipment_type": "Barbell",
      "id": "287",
      "key": "59iaHkFJ2xHotM8gTGEn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "id": "121",
      "muscle_type": "Chest",
      "name": "Bodyweight Flyes",
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "E-Z Curl Bar",
      "key": "59xts3PadRnYRMohhpfF",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Kettlebells",
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "name": "Kettlebell Seated Press",
      "id": "674",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "key": "5BNdsteVcnrjy7BsCNjv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Foam Roll",
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "743",
      "muscle_type": "Hamstrings",
      "name": "Hamstring-SMR",
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "key": "5Gy1CuopJbcdu7XflLsf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "name": "Press Sit-Up",
      "equipment_type": "Barbell",
      "muscle_type": "Abdominals",
      "id": "347",
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "5I4MwO4DZRNskU7I82LP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "280",
      "updated_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "name": "Standing Bradford Press",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "muscle_type": "Shoulders",
      "key": "5LgXKM6sBOJpTG2xpjLD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "name": "Side Wrist Pull",
      "more_time_is_pr": null,
      "id": "389",
      "key": "5MFOePxKjilDwvRBKaE1",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "1073",
      "name": "Burpee To Medicine Ball Press",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "key": "5V2PzVTmhEmvxNyucdsG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "672",
      "name": "Barbell Side Bend",
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "muscle_type": "Abdominals",
      "key": "5V40yPcR9pryArw6Qt8h",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "equipment_type": "Body Only",
      "id": "1052",
      "more_time_is_pr": null,
      "name": "Wide-Hands Push-Up",
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "key": "5fGEOrR1fR6Rq56q5KEr",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Conans Wheel",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "id": "975",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "5k5nWl54EXFoQa33Wwc6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "id": "613",
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "name": "Bench Jump",
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "5oF9o4zumYNCWdmqdo4K",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Glutes",
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "equipment_type": "Exercise Ball",
      "id": "800",
      "name": "Downward Facing Balance",
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "5qNwFf8vSiQztSSMHwfE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Hang Snatch - Below Knees",
      "muscle_type": "Hamstrings",
      "id": "855",
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "key": "5qstzEfGJ77ZnvbYBMSp",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "name": "Linear Depth Jump",
      "id": "886",
      "muscle_type": "Quadriceps",
      "key": "5xTnWkp99Q2lEHYYau2t",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Calves",
      "id": "712",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "name": "Knee Circles",
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "60glXpuvQdVItBZqmoNf",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "One Knee To Chest",
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "muscle_type": "Glutes",
      "more_time_is_pr": null,
      "id": "832",
      "key": "62WUMixa7pLkILkIBCUC",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "992",
      "muscle_type": "Abdominals",
      "equipment_type": "Medicine Ball",
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Medicine Ball Full Twist",
      "key": "653sX6EwgwZ5pVddQqVn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "776",
      "muscle_type": "Calves",
      "more_time_is_pr": null,
      "equipment_type": "Bands",
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "name": "Calf Raises - With Bands",
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "key": "6A5FgVgis8Mb38Gg6iwf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "equipment_type": "E-Z Curl Bar",
      "id": "205",
      "name": "Reverse Barbell Preacher Curls",
      "more_time_is_pr": null,
      "key": "6FhfgAqT2ICB1xWLonHW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "id": "477",
      "equipment_type": "Dumbbell",
      "name": "Seated Bent-Over Two-Arm Dumbbell Triceps Extension",
      "key": "6HdyJL3dxeVECGPaxz5r",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Side To Side Chins",
      "muscle_type": "Lats",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "id": "385",
      "equipment_type": "Other",
      "key": "6Iz8YZymCy9PLdSoyk3B",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "name": "Wall Mountain Climber",
      "more_time_is_pr": null,
      "id": "1085",
      "updated_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "6MUP2RN1MUv5xRNnFhGv",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "135",
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "equipment_type": "E-Z Curl Bar",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1586265366,
          "nanoseconds": 0
      },
      "name": "EZ Bar Skull Crusher",
      "key": "6QFYP4AcMbluyQ7S0B8q",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Cable Russian Twists",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "id": "507",
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "6SzFLYbIebGT41riLoFh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "id": "1069",
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "name": "Body Triceps Press Using Flat Bench",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "key": "6TSqgYCgrRctj94XXd3x",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "1014",
      "name": "Axle Clean And Press",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "key": "6WTUCZQFHFDaOhXVAj4i",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "One-Arm Kettlebell Push Press",
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "id": "38",
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "key": "6X5gPZ2LFMRxU7mO7zTE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1586266806,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "1102",
      "name": "Bent-Over Lateral Raise",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1586266806,
          "nanoseconds": 0
      },
      "key": "6cE5xBdSAz6SGiS4JcAT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Traps",
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "more_time_is_pr": null,
      "id": "570",
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "name": "Smith Machine Behind the Back Shrug",
      "key": "6dHmvlIT6xfI6YC5k247",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "name": "Pendlay Rown",
      "muscle_type": "Middle Back",
      "equipment_type": "Barbell",
      "id": "488",
      "more_time_is_pr": null,
      "key": "6dTqAStAPQIaTPGX2jyc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "name": "Linear 3-Part Start Technique",
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "id": "885",
      "key": "6dYxERdT83F1l5SYZSxT",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "811",
      "equipment_type": "Other",
      "muscle_type": "Lats",
      "name": "One Handed Hang",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "key": "6ee8MZdDleXJKVAqma5l",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "id": "509",
      "muscle_type": "Lats",
      "name": "Bent-Arm Barbell Pullover",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "6fiT72oYv8IfiSJXCxpz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "muscle_type": "Triceps",
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "id": "531",
      "name": "Smith Machine Close-Grip Bench Press",
      "key": "6g1vYD8JRNFtzbFMhgJv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Seated Floor Hamstring Stretch",
      "muscle_type": "Hamstrings",
      "id": "764",
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "key": "6p9AHKUnRlQyJ52MF3Gu",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "id": "664",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "name": "Seated Leg Tucks",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "6vae6u1ExSRAUyoqk5XQ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "name": "Seated Band Hamstring Curl",
      "id": "888",
      "equipment_type": "Other",
      "key": "6weESbkME1qAaRQ5bKXv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Kettlebell One-Legged Deadlift",
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "id": "311",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "key": "7044FyvP3MeOHIEXrotA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "219",
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "name": "Seated Close-Grip Concentration Barbell Curl",
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "key": "70XZjzVaThxdYTAQFQb0",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "name": "Plie Dumbbell Squat",
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "id": "396",
      "muscle_type": "Quadriceps",
      "key": "75J7AmUoISCxyoV2c1Go",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1000",
      "name": "Sled Reverse Flye",
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "key": "78lnbtMS7E8nLpmiy837",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "name": "Dumbbell Lunge and Curl",
      "muscle_type": "Quadriceps",
      "id": "1063",
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "key": "7BnHsIQtFB9lR6iaEjwq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "588",
      "name": "Incline Bench Pull",
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Middle Back",
      "equipment_type": "Barbell",
      "key": "7CuZ3loSg9CNNtzNZzrg",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Kettlebells",
      "muscle_type": "Middle Back",
      "name": "Two-Arm Kettlebell Row",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "id": "641",
      "key": "7Ek43pJ1uZsQMVTsK02l",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "id": "838",
      "more_time_is_pr": null,
      "name": "Overhead Lat",
      "key": "7F15LUO2nZQW0nOq4h3R",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "One-Arm Flat Bench Dumbbell Flyes",
      "id": "703",
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569379090,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "7I1HNb11fkR6U0TB7BnE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "id": "164",
      "name": "Incline Dumbbell Press Reverse-Grip",
      "muscle_type": "Chest",
      "key": "7JLmP2TNCGCdoJwUNf8n",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "id": "549",
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Standing Barbell Press Behind Neck",
      "equipment_type": "Barbell",
      "muscle_type": "Shoulders",
      "key": "7PaFYAXy21mvRVBNDZ7T",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Shoulders",
      "id": "728",
      "name": "Shoulder Stretch",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "key": "7RNsoPto4udhRkfzh0MP",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Reverse Barbell Curl",
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "id": "470",
      "muscle_type": "Biceps",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "key": "7VdxKRtNfueKGweJ581y",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "name": "Trap Bar Jump",
      "id": "1060",
      "key": "7Y1zreAZi6Pe2TDo9BYU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "564",
      "muscle_type": "Quadriceps",
      "name": "Walking High Knees",
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "key": "7ap68uc7aONQrOcOSl7p",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "306",
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "name": "Knee/Hip Raise On Parallel Bars",
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "key": "7bFsNaZfJjHK6fU9NsTC",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "id": "904",
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "muscle_type": "Chest",
      "name": "Suspended Chest Fly",
      "more_time_is_pr": null,
      "key": "7fSWxIlqMsn3J4A2T2nm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Smith Machine Hip Raise",
      "id": "525",
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "key": "7jnTgVpiuf2OIsRnXvZC",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "name": "EZ Bar Curl",
      "muscle_type": "Biceps",
      "id": "73",
      "equipment_type": "E-Z Curl Bar",
      "updated_at": {
          "seconds": 1586265324,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "7m4SDK92NgJIJTQ8DuFY",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "name": "Chain Handle Extension",
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "410",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "key": "7n2dJVL08HDm4PH2KKGh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "id": "108",
      "name": "Hanging Pike",
      "more_time_is_pr": null,
      "key": "7oTV8tA5UQsMLR5Oe0j9",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "id": "826",
      "name": "Double Kettlebell Windmill",
      "more_time_is_pr": null,
      "key": "7svrAfxMobFNMRlKjkgR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "name": "Incline Dumbbell Flyes",
      "equipment_type": "Dumbbell",
      "id": "209",
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "7t0ZszWRlECdCfoP58kb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Lats",
      "id": "785",
      "more_time_is_pr": null,
      "equipment_type": "Foam Roll",
      "name": "Latissimus Dorsi-SMR",
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "key": "7wzmmtdno0KYl6DiQAba",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "name": "Brachialis-SMR",
      "id": "820",
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "equipment_type": "Foam Roll",
      "key": "7y7Iy7o6EasKlHmVew9k",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Machine",
      "name": "Ab Crunch Machine",
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "id": "515",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "key": "7yaE7ptHEFFxZhAmdI2Z",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "name": "Catch and Overhead Throw",
      "more_time_is_pr": null,
      "muscle_type": "Lats",
      "equipment_type": "Medicine Ball",
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "id": "735",
      "key": "7yh1DXI0vE2hR0HUQ1tZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "name": "Barbell Hip Thrust",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "muscle_type": "Glutes",
      "updated_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "id": "75",
      "key": "81qZ9v3Hpo9hbrPncYo7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "name": "Box Squat with Bands",
      "id": "131",
      "key": "84UTRbg0U9P99WtygRyr",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "id": "775",
      "name": "Alternating Hang Clean",
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "key": "853fAuMXExzh5U88XXiE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "One-Arm Push-Up",
      "id": "942",
      "key": "85K7ooHURdCcutemzk61",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Sumo Deadlift with Bands",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "id": "864",
      "key": "8ABcXyRlZt2TmG2nJ0o0",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "id": "45",
      "name": "Spell Caster",
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "key": "8DqlB4ps10xVymWd2Y5e",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "muscle_type": "Neck",
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "name": "Seated Head Harness Neck Resistance",
      "id": "595",
      "key": "8HPfUrTEcdDfzNCyd3ti",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "id": "174",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "name": "Cross Body Hammer Curl",
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "key": "8I4a72nDmiKM9UHpurHy",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "muscle_type": "Abdominals",
      "id": "1074",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "name": "V-Sit Lying Down Ball Throw And Catch",
      "more_time_is_pr": null,
      "key": "8MgevXwjQqROgnuRvKKU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "muscle_type": "Hamstrings",
      "name": "Standing Hamstring and Calf Stretch",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "id": "692",
      "key": "8OBxAm55eCiwTZMEyvEO",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "One-Arm Kettlebell Clean",
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "id": "288",
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "key": "8Q3xfFZav1K9laBJGmiQ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Ice Skater",
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "id": "1037",
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "8XAfnsQQCMqTYH4fMqoo",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1586265401,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1586265401,
          "nanoseconds": 0
      },
      "id": "1100",
      "name": "Dumbbell Skull Crusher",
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "key": "8aCorJlQRJ7CyRcje8MH",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Floor Glute-Ham Raise",
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "id": "80",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "key": "8awxpw70mXXIWEZUrozh",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "name": "Suspended Curl",
      "muscle_type": "Biceps",
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "id": "1031",
      "equipment_type": "Other",
      "key": "8b6CP40D3JEh8Ma3vHG6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "id": "211",
      "more_time_is_pr": null,
      "name": "Seated Cable Rows",
      "muscle_type": "Middle Back",
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "key": "8gugd5ixZezhDKqWxMP1",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "263",
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Smith Machine Squat",
      "equipment_type": "Machine",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "key": "8l59MJGuTYUNtxERiLMK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Glutes",
      "updated_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "id": "260",
      "name": "Step-up with Knee Raise",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "8lpt7reYsl8OCOPjs2Mh",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "id": "551",
      "equipment_type": "Machine",
      "muscle_type": "Middle Back",
      "name": "Leverage High Row",
      "more_time_is_pr": null,
      "key": "8pbfFVLtIa5gYo4F18Np",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "380",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "name": "Incline Dumbbell Bench With Palms Facing In",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "key": "8w17AmOZU9kGnLKmDM9A",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "name": "Dumbbell Seated Box Jump",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "414",
      "key": "8xPRxN07MTxxyLKac2af",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "616",
      "name": "Leg Pull-In",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "key": "8xhxPmxU9Yvo7thZ5bsl",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Barbell Thruster",
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Barbell",
      "id": "417",
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "key": "8xqIRNNAJgjIfDHcD80G",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "name": "Suspended Triceps Press",
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "id": "1033",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "8ypf770qOJP4A6N768Uv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "201",
      "muscle_type": "Middle Back",
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Dumbbell Incline Row",
      "key": "91oNKgY2781AGIgLtJXi",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "id": "109",
      "more_time_is_pr": null,
      "name": "Narrow Stance Squats",
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "key": "93no2aarzqP5njulA9dx",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "name": "Clapping Push-Up",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "940",
      "key": "95L2ngjKkVaHDlqmG5Tv",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "name": "Tire Flip",
      "id": "23",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "key": "968WhHrO4N9I4kRwUpIg",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "id": "568",
      "name": "Dumbbell One-Arm Upright Row",
      "more_time_is_pr": null,
      "key": "96qqAiqnhl4dFpPfK94N",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "626",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "name": "Seated Dumbbell Inner Biceps Curl",
      "key": "9BaxQ8mCfAq7B2lt7VmW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "id": "995",
      "name": "Single-Leg Stride Jump",
      "key": "9DTZSht5mBuP4ggVZXcK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Sit Squats",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "id": "722",
      "key": "9DpRL9o6bgqhLo3YxJ8v",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "name": "Inchworm",
      "muscle_type": "Hamstrings",
      "id": "606",
      "key": "9EA8h5i2I7gLoytKHeL3",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "name": "Barbell Close Grip Bench Press",
      "id": "67",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1581295435,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "key": "9MVrwLzCcMBg7mbSbYEl",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "equipment_type": "Dumbbell",
      "id": "943",
      "name": "Close-Stance Dumbbell Front Squat",
      "key": "9Uu0kKElIOgdE2eNG6ji",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Wide-Stance Leg Press",
      "equipment_type": "Machine",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "678",
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "key": "9inPmnLBqdg9XSLga0vO",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "231",
      "name": "Chair Squat",
      "equipment_type": "Machine",
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "9ixOsFB5hhbnmD4ZZ4TF",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "id": "894",
      "more_time_is_pr": null,
      "name": "Suitcase Crunch",
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "9mVv5aXs4kx0vTDFKQX4",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "name": "Face Pull",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "id": "372",
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "key": "9p2ZLKzpbLnIujTTOYzc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "65",
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Barbell Walking Lunge",
      "more_time_is_pr": null,
      "key": "9rbZ4qIm94Xh9eWvqG7E",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "muscle_type": "Lats",
      "updated_at": {
          "seconds": 1581295526,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Lat Pulldown",
      "id": "490",
      "key": "9uegDGwHpPDYi7xqLzNU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Hammer Curls",
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "id": "83",
      "equipment_type": "Dumbbell",
      "key": "9x6U6gWgQts8W5sx4PTx",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "514",
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "name": "Cuban Press",
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "equipment_type": "Dumbbell",
      "key": "A4wdLWm78F9Cpdy20AvW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "name": "Smith Incline Shoulder Raise",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "id": "841",
      "key": "A9pTsjSersgc34IF0QLt",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "id": "276",
      "name": "Cable Chest Press",
      "more_time_is_pr": null,
      "key": "AAR6nRxUjaAb5R30RhLo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Calf-Machine Shoulder Shrug",
      "id": "267",
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "muscle_type": "Traps",
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "key": "AGU9jkawwH3rNfxxrCln",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "398",
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "muscle_type": "Shoulders",
      "name": "Standing Low-Pulley Deltoid Raise",
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "key": "ANWPfPdKG2voXtSsUx9g",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "792",
      "muscle_type": "Quadriceps",
      "name": "Looking At Ceiling",
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "key": "AdAOabHDJvqo3QKuk0zb",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "name": "Partner Suitcase Carry Competition",
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Forearms",
      "id": "1025",
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "key": "AdjEcJ56WLRweqPlShuL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Seated Calf Raise",
      "equipment_type": "Machine",
      "id": "266",
      "key": "AfMCP7UQ9UTbOwMi0rgX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "id": "448",
      "name": "Yates Row Reverse Grip",
      "muscle_type": "Middle Back",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "key": "Anv4CENpcVREZjngSOuw",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "id": "930",
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "name": "Triceps Plank Extension",
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "key": "AtZL5lHhFzJ6URAzHapt",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "id": "30",
      "more_time_is_pr": null,
      "name": "Snatch",
      "updated_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "key": "AwCUjPicRWFG5GBcRrjy",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "id": "926",
      "equipment_type": "Medicine Ball",
      "name": "Medicine Ball Rotational Throw",
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "Ax3ExGqs6ki012rVoFK6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Standing Concentration Curl",
      "created_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "279",
      "muscle_type": "Biceps",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "key": "B0uZzuwFRZGskANGtBTU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Barbell Squat To A Bench",
      "equipment_type": "Barbell",
      "id": "623",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "key": "B11ScebsQaG6uaukyvpD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "227",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "name": "Standing Hip Circles",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "muscle_type": "Abductors",
      "key": "B1CNYzIDfea5ya2ossre",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "375",
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "name": "Isometric Wipers",
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "key": "B1ZOS1ARHuwYyyzrip8p",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "muscle_type": "Biceps",
      "id": "502",
      "name": "Standing Dumbbell Reverse Curl",
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "key": "B2jjGtbJdO2qZ5fa1oub",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "589",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "name": "Barbell Incline Bench Press",
      "updated_at": {
          "seconds": 1569378909,
          "nanoseconds": 0
      },
      "key": "B30RXRbJ8JMXjH3JGSZU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1581295618,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1581295618,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "muscle_type": "Hamstrings",
      "id": "1096",
      "name": "Stiff Legged Dumbbell Deadlift",
      "key": "BBRZtNQGkJC3WNXSx4BT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "External Rotation",
      "id": "330",
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "key": "BCZkk8gZIizBHo8pEO6R",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1081",
      "name": "Side Lunge Touching Heel",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "BDPLOrIAINO8pQHWfy26",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "id": "143",
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "name": "Wide-Grip Decline Barbell Bench Press",
      "key": "BEaHebh8H0ScXUGhY6Oa",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "id": "499",
      "muscle_type": "Traps",
      "more_time_is_pr": null,
      "name": "Upright Cable Row",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "key": "BLfrXjq7W5PjFXSG8zuM",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Body-Up",
      "muscle_type": "Triceps",
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "528",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "key": "BMno2VbsZn1CdUp8qEHH",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "id": "581",
      "equipment_type": "Barbell",
      "name": "Board Press",
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "BQaRAquhixRE4dL8MErl",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "827",
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Lying Glute",
      "muscle_type": "Glutes",
      "equipment_type": "Body Only",
      "key": "BRwBOT1erBCGjf6F8wNJ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "794",
      "name": "Upper Back Stretch",
      "muscle_type": "Middle Back",
      "key": "BSqL2bumlHaSjgGTsOqT",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "id": "345",
      "name": "One-Arm Incline Lateral Raise",
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "key": "BVLhgS5BVbJmvBJIUH84",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Sled Push",
      "id": "682",
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "key": "Bb9EUXSYhfagrwS3wKAi",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "103",
      "name": "Barbell Curl",
      "updated_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "key": "BchBlEM4vfAqFLzXyuNv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Split Jerk",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "id": "409",
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "key": "Bd3PlLaOKmfUfuN7HPar",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "id": "513",
      "more_time_is_pr": null,
      "name": "Smith Machine Incline Bench Press",
      "equipment_type": "Machine",
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "key": "BfT85qnNLdDgvyFXvpVa",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "980",
      "equipment_type": "Barbell",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "name": "Snatch Balance ",
      "key": "BjZSpn3uaLTqB5skFDk7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "id": "889",
      "name": "Speed Band Overhead Triceps",
      "equipment_type": "Bands",
      "muscle_type": "Triceps",
      "key": "BkjDmzjcZVKNWnkJUZb9",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Bench Dips",
      "more_time_is_pr": null,
      "id": "246",
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Triceps",
      "key": "Blg5tyr37twCAwIefclc",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Middle Back",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "name": "T-Bar Row with Handle",
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "id": "8",
      "equipment_type": "Barbell",
      "key": "BmILLrKBuOBd1v71ODVu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "316",
      "equipment_type": "Bands",
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "muscle_type": "Adductors",
      "name": "Band Hip Adductions",
      "key": "BtZhGUjNBCVNT7xblC7F",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "id": "302",
      "name": "Alternate Hammer Curl",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "key": "BukyMkBUhKHEvxFpKWJk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "913",
      "equipment_type": "Bands",
      "muscle_type": "Middle Back",
      "name": "Partner Facing Feet-Elevated Side Plank With Band Row",
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "C7PyxgbInc5syfYWYrra",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Chest",
      "equipment_type": "Medicine Ball",
      "name": "Medicine Ball Chest Pass",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "id": "730",
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "key": "CB2cDq6EVsLVzXEy3sw3",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "name": "Reverse Band Power Squat",
      "muscle_type": "Quadriceps",
      "id": "869",
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "key": "CFMdeMvEKVfQSZy8TzEe",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "816",
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "name": "Peroneals Stretch",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "CHVQzKL1zfM3BQ7EeEte",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "id": "1004",
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Speed Box Squat",
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "key": "CJPV0xnXT0p3hzzZmdW5",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "name": "Overhead Triceps",
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "819",
      "muscle_type": "Triceps",
      "key": "CKCnd6H75Cop5N2TNC3v",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Decline Close-Grip Bench To Skull Crusher",
      "equipment_type": "Barbell",
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "id": "156",
      "key": "CNCpncpQTqvoPClC73QJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "id": "546",
      "name": "Lateral Raise - With Bands",
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Bands",
      "muscle_type": "Shoulders",
      "key": "CNpI4FyAApbXH6Q70jiF",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "931",
      "name": "Single-Leg Box Jump",
      "key": "CQqlGpSwL5yVMnPoJnVr",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "One Arm Against Wall",
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "id": "732",
      "muscle_type": "Lats",
      "equipment_type": "Body Only",
      "key": "CZVV9bv9vIJKwbZKBqdy",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Glutes",
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "name": "Leg Lift",
      "id": "702",
      "key": "CbwNWllpcXRsEtxUHj1U",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "1082",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Jumping Knee Up Down",
      "key": "CcU1trz1yy4TdTmZx2wx",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "659",
      "name": "Cable Incline Triceps Extension",
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "key": "CeKeV0ZrZ7lIYY46zjWd",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "285",
      "name": "Cable Lying Triceps Extension",
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "key": "CimUK3Byy0YRopjcC1q1",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "id": "708",
      "equipment_type": "Exercise Ball",
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "name": "Weighted Ball Side Bend",
      "key": "CnYg6iyBQVrdefDc82Iu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "name": "Lying Face Up Plate Neck Resistance",
      "equipment_type": "Other",
      "muscle_type": "Neck",
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "359",
      "key": "CqT5ShtRz8JcI2oMxPJW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "name": "Hug Knees To Chest",
      "id": "789",
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "key": "CrQuMlDOO61DLcWn1quJ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Cable",
      "id": "553",
      "name": "Pallof Press With Rotation",
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "key": "Cue3afed4Kg1QJ14wug4",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Adductors",
      "id": "193",
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Groiners ",
      "key": "CvDLNzsdvWp6zgp8Vrzd",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Ring Dips",
      "id": "229",
      "muscle_type": "Triceps",
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "key": "DA7X4HqWi86PIXmYpFQ7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Lying Leg Curls",
      "muscle_type": "Hamstrings",
      "id": "145",
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "key": "DBEpS5C9q6D2nN5s4MlP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "name": "Kneeling Hip Flexor",
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "id": "748",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "key": "DBUmsL3dQ2g7mbk1yzFy",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "402",
      "name": "Reverse Triceps Bench Press",
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "key": "DIkGWxe0iVcn8sQHUZD3",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "name": "High Kick",
      "id": "1048",
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "key": "DSRchqnRnjGY11OPfouO",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "519",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "name": "Jerk Dip Squat",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "key": "DSmyant3EslRgkBn3H6K",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Barbell Incline Shoulder Raise",
      "more_time_is_pr": null,
      "id": "704",
      "muscle_type": "Shoulders",
      "key": "DUq86vo1fhvkSGoTaeQq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "id": "204",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "name": "Burpee",
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "key": "DYKujQcWZ9mkMkZBM0S2",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "id": "268",
      "muscle_type": "Calves",
      "name": "Rocking Standing Calf Raise",
      "more_time_is_pr": null,
      "key": "Ddp3tEZ0LGG91wVshx4N",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "446",
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "equipment_type": "Body Only",
      "name": "Cobra Triceps Extension",
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "key": "Dfxh5Zy04tjeX6ZLCung",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "name": "Standing Palms-Up Barbell Behind The Back Wrist Curl",
      "updated_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "id": "26",
      "key": "DiF7wzU1moDSV926Q1oI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "name": "One-Arm Dumbbell Row",
      "more_time_is_pr": null,
      "id": "125",
      "equipment_type": "Dumbbell",
      "key": "DklqqDDE0SPtFmGiVU9p",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Middle Back",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "name": "Bodyweight Mid Row",
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "id": "203",
      "equipment_type": "Other",
      "key": "DmM76w1VbhtfvX6GEHV2",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "id": "1046",
      "name": "Walking Lunge With Overhead Weight",
      "key": "DneDOdNzfCeUM4lEDrjF",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "name": "Push Press - Behind the Neck",
      "id": "705",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "key": "DqK8jlBqU8uMCZANRQLb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "name": "Dumbbell One-Arm Shoulder Press",
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "id": "220",
      "equipment_type": "Dumbbell",
      "key": "DqOlMGGiEma8pvVQIItW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abductors",
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "id": "234",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "name": "Clam",
      "equipment_type": "Body Only",
      "key": "DwS7Qoa5KPiwzLtjmgus",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "656",
      "muscle_type": "Glutes",
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Bands",
      "name": "Hip Extension with Bands",
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "key": "Dwv2T4SDyeRiC2bvIZl4",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "name": "Low Cable Crossover",
      "id": "94",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "key": "E4tzPvf5JXWh92SxU4U7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1581295966,
          "nanoseconds": 0
      },
      "id": "12",
      "muscle_type": "Lats",
      "name": "Weighted Pullups",
      "key": "E68KWiva28vhVpk7NUqz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Supine Chest Throw",
      "muscle_type": "Triceps",
      "equipment_type": "Medicine Ball",
      "id": "481",
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "EBCnpvRrWNfPcKY9xR9r",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Kettlebell Thruster Progression",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Kettlebells",
      "id": "956",
      "key": "EHbazTaWu1JP9M9V921g",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "name": "Incline Push-Up Reverse Grip",
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "id": "891",
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "key": "EJNvz1MobfBzJtgaWTrD",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "name": "Standing Bent-Over One-Arm Dumbbell Triceps Extension",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "id": "548",
      "key": "EJplmeI3T7zMOvE6DZS9",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Exercise Ball",
      "name": "Bosu Ball Push-Up",
      "id": "1034",
      "key": "ELdPFpPrc7ZNWGAoKvTu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Star Jump",
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "id": "996",
      "key": "EPx7MPo53nTOjDpkFeso",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "muscle_type": "Lats",
      "id": "182",
      "name": "Wide Grip Pullup",
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1581296108,
          "nanoseconds": 0
      },
      "key": "EbzPu5vEdnwyglYAqnD1",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Dumbbell Overhead Squat ",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "id": "923",
      "key": "EfTAClzJlI6TsMtepcUc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Chest Stretch on Stability Ball",
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "equipment_type": "Exercise Ball",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "id": "809",
      "key": "Enk0Xk7aipS1vur7Na2d",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Suspended Hip Thrust",
      "muscle_type": "Glutes",
      "id": "1032",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "key": "EoPzfmznVKX8B4LRtMdg",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "id": "1075",
      "name": "Superman Push-Up",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "key": "EqAbq1E6EZwO54e6TLQF",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "914",
      "muscle_type": "Abdominals",
      "name": "Partner 3-Touch Motion Russian Twist",
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "key": "EyY8iDA0N5xLYNxakPYE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "id": "856",
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "name": "Jerk Balance",
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "key": "F175oB0tkoVrTchuR9wQ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "id": "1019",
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "equipment_type": "Exercise Ball",
      "name": "Bosu Ball Squat",
      "muscle_type": "Quadriceps",
      "key": "F3qvshuLvDOpfIJyFxAT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "name": "Sumo Deadlift with Chains",
      "equipment_type": "Barbell",
      "muscle_type": "Hamstrings",
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "559",
      "key": "F3z1twLp5xxKCdfsMfVf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "849",
      "equipment_type": "Kettlebells",
      "name": "Kettlebell Hang Clean",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "key": "FDfUnxqyh4CqNSokK7iz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "name": "Chest And Front Of Shoulder Stretch",
      "equipment_type": "Other",
      "id": "825",
      "more_time_is_pr": null,
      "key": "FHynFHejLjWfvcggnkf3",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "id": "163",
      "muscle_type": "Lats",
      "name": "Shotgun Row",
      "key": "FJ7Kg7BBSpzj5PBgjoK7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Prone Manual Hamstring",
      "muscle_type": "Hamstrings",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "id": "1003",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "key": "FPHkth9C6ANgI12o2Keb",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "152",
      "muscle_type": "Lats",
      "name": "V-Bar Pulldown",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "key": "FQ7yU13mbn8aAK1RGLnL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "name": "Seated Cable Shoulder Press",
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "id": "339",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "key": "FWRzsL37Ct0HLW8xlluc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "name": "Clean from Blocks",
      "muscle_type": "Quadriceps",
      "id": "11",
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "key": "Fb5fzsIhZQsTtTz7Jt5P",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "id": "41",
      "muscle_type": "Quadriceps",
      "name": "Reverse Band Box Squat",
      "key": "FcIh2MACSK1LkDDvD9cR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Lats",
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "id": "25",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "name": "Barbell Deadlift",
      "updated_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "key": "FdmcvLeckt75dvXoukhT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "id": "1002",
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "name": "Platform Hamstring Slides",
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "key": "FmiXu4bmoekmgn2d1Hyp",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": true,
      "name": "Running Lunge",
      "equipment_type": "Time",
      "id": "958",
      "updated_at": {
          "seconds": 1614355007,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "key": "Fnqt6EviqpiDVrMKgXuc",
      "isBodyWeight": false,
      "isTimeBased": true
  },
  {
      "id": "907",
      "muscle_type": "Triceps",
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "JM Press With Bands",
      "key": "Fq7LENCeOv2FFWqQB9VP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "36",
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "name": "Standing Palms-In Dumbbell Press",
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "key": "FuL3vaKHz2ZeHM3jL6IY",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "name": "Power Snatch from Blocks",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "id": "984",
      "muscle_type": "Quadriceps",
      "key": "Fva8KJTZrhsLFd0tEWxf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Kettlebells",
      "id": "406",
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "name": "Advanced Kettlebell Windmill",
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "key": "FzU05Z4iKSpxzbscFGwc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "id": "426",
      "name": "Lying Supine Dumbbell Curl",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "key": "G2eHiLiCpYfQxVYYEV0E",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1581295240,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "muscle_type": "Hamstrings",
      "name": "Stiff Legged Barbell Deadlift",
      "id": "420",
      "more_time_is_pr": null,
      "key": "G2ld7D8zOq2OXwoOmRx2",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Medicine Ball",
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "name": "Chest Push (single response)",
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "id": "871",
      "key": "G4cc31kLsOxw7kMHjQrp",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "id": "360",
      "name": "Straight-Arm Dumbbell Pullover",
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "key": "G5ABAw7az5GKiudmJn8B",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "id": "498",
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "name": "Close-Grip Dumbbell Press",
      "key": "G5H0HXDsxdKhvFOTblhW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "id": "999",
      "name": "Sled Overhead Backward Walk",
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "key": "G7hL2ZhKy22RD8nKZNfP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "id": "328",
      "name": "Low Pulley Row To Neck",
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "GEhz5nRZm9EEKMnCdLGc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "id": "660",
      "equipment_type": "Cable",
      "name": "Lying Close-Grip Bar Curl On High Pulley",
      "muscle_type": "Biceps",
      "key": "GFmsx1ne8Svg1NgMIrnS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "id": "158",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "name": "Bicycling",
      "key": "GKgm32Tal8dtBhNr5hZI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "name": "Side Laterals to Front Raise ",
      "muscle_type": "Shoulders",
      "id": "7",
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "key": "GKwBV2YyiRbLF1LsBJzt",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "name": "Plate Twist",
      "more_time_is_pr": null,
      "id": "98",
      "key": "GLzqtNvZOEquKHZRZbZd",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "503",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "name": "Zercher Squats",
      "equipment_type": "Barbell",
      "key": "GP1oyVbNqHce1rgn7Epo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Triceps Pushdown - Rope Attachment",
      "muscle_type": "Triceps",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "id": "146",
      "more_time_is_pr": null,
      "key": "GSF352iaachpORtPrHrN",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "name": "Reverse Grip Bent-Over Rows",
      "more_time_is_pr": null,
      "id": "50",
      "key": "GZ4j1fLyhNQGYGtMWU4B",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "muscle_type": "Forearms",
      "created_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Rickshaw Carry",
      "id": "1",
      "key": "GiW4YmHJpkGyMoaqxVKL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "id": "1039",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "muscle_type": "Middle Back",
      "name": "Yates Row",
      "key": "GmrBuHq2U3orFiOh9vUK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "name": "Front Dumbbell Raise",
      "id": "115",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "key": "GqPqp4IS1kg8XdzEvo2g",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "165",
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "name": "T-Bar Row",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "more_time_is_pr": null,
      "key": "GvA5X6G7PQUxqOmsK0Tc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "723",
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "name": "Crucifix",
      "muscle_type": "Shoulders",
      "key": "GwvxBTkTYJ8fibBr6qUa",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Glutes",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "id": "1005",
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "name": "Neck Bridge Supine",
      "key": "GzMQj6Tm1jgwEuK6Wxn7",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Torso Rotation",
      "id": "769",
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Exercise Ball",
      "key": "GzTU1TvtGih6eLb4ePHl",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "name": "Quad Stretch",
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "id": "774",
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "key": "H7I2YmKNiFLoeELr0Kbk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1586266869,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "id": "1103",
      "created_at": {
          "seconds": 1586266869,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "name": "Dumbbell Front Raise",
      "key": "HEHFGirJfck7nyrgCs7s",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "name": "Front Raise And Pullover",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "id": "432",
      "key": "HEQXeQddfuIhA2rLWcM3",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "609",
      "name": "Balance Board",
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "equipment_type": "Other",
      "key": "HJwFnmWMUg6HLLCVC5ka",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "name": "Sumo Deadlift",
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "id": "253",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "muscle_type": "Lower Back",
      "key": "HKoRV7PaR39Nfd9lkiKo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "id": "679",
      "more_time_is_pr": null,
      "name": "Toe Touchers",
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "key": "HL07LZExJyCik60WXvUK",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "id": "971",
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "name": "Open Palm Kettlebell Clean ",
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "key": "HNEHm68D1g9SYwtXyTtP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Hanging Oblique Knee Raise",
      "equipment_type": "Body Only",
      "id": "123",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "key": "HPxmZl8fJZsnyWL707r1",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "id": "457",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "name": "Recumbent Bike",
      "key": "HSvRHoYdwV4wq2mWSxFM",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Muscle Snatch",
      "id": "859",
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "key": "HVbp3xhhOI7nWdWkjUXX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "447",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "name": "Bodyweight Reverse Lunge",
      "key": "HWDbi0GnJL2fQKTI9Kyk",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "name": "One Leg Barbell Squat",
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "id": "325",
      "key": "HYvm1rAaMjCXLthyFNDo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Squat Jerk",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "id": "863",
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "key": "HdEfZJ6abtg8JnDeyAyO",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Side Standing Long Jump",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "id": "883",
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "key": "HeVL9VbGNN6Yy518Y2Ty",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "id": "786",
      "name": "Seated Overhead Stretch",
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "key": "HgbZvGMnFtvS3snsRuPR",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "1079",
      "more_time_is_pr": null,
      "name": "Dumbbell Pistol Squat",
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "key": "HjBpz4TaDfXd2Bc38qL5",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Front Squat Push Press",
      "more_time_is_pr": null,
      "id": "1054",
      "key": "Hjd8dVpU9O6Y8o4oCnD6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "muscle_type": "Lower Back",
      "name": "Partner Flat-Bench Back Extension With Hold",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "id": "918",
      "key": "HmJ95b1af2BjnTtmyDEA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "name": "Dumbbell Clean",
      "more_time_is_pr": null,
      "id": "464",
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "key": "HqVjmFBdLWt5aLwsRsi7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Medicine Ball",
      "name": "Backward Medicine Ball Throw",
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "id": "683",
      "key": "HwOfgfg8va3TAPSgEhHV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "name": "Reverse Burpee",
      "updated_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "id": "1089",
      "key": "HzOG2zt0lLbzUmCB9m4I",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Box Squat with Chains",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "id": "852",
      "key": "HzxGuuhht96iD0mLI9Ol",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "id": "169",
      "name": "Decline Barbell Bench Press",
      "equipment_type": "Barbell",
      "key": "I0b1idMh5Ytwh7vmBAju",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "name": "Football Up-Down",
      "id": "1008",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "key": "I1eYFJp3B4zf9qOsEpiS",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "name": "Skin The Cat To Push-Up",
      "id": "1061",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "key": "I49ZvuXwltDvdOdF3qIb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "muscle_type": "Traps",
      "more_time_is_pr": null,
      "id": "630",
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "name": "Smith Machine Upright Row",
      "equipment_type": "Machine",
      "key": "I8Z6jzp44Y02BctCsqFK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "281",
      "updated_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "name": "Dumbbell Rear Delt Row",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "key": "IAaZrz6nGm8epJqiODSZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Kneeling Jump Squat",
      "id": "598",
      "muscle_type": "Glutes",
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "key": "ICVRYRkuhNeigIHzfE47",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "638",
      "muscle_type": "Triceps",
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "name": "One Arm Floor Press",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "key": "IEdIXmkpbNQs233iH4fa",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "equipment_type": "Body Only",
      "id": "788",
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "name": "Seated Front Deltoid",
      "key": "IR0P8wHdfGKRJspipFQz",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Reverse Band Bench Press",
      "equipment_type": "Barbell",
      "muscle_type": "Triceps",
      "id": "985",
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "key": "IXA6flIn0DmKWdyvwPPF",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "id": "517",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "name": "Jefferson Squats",
      "key": "IdzSMyBFyEHLMWGc3ETq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "694",
      "equipment_type": "Barbell",
      "name": "Squat with Plate Movers",
      "key": "IgfsGFZH97E99qi4fGwm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "810",
      "name": "Runners Stretch",
      "muscle_type": "Hamstrings",
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "key": "ImDPqUMZfvMd8VcZZJIO",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "966",
      "equipment_type": "Body Only",
      "name": "Wall Shoulder Tap",
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "key": "IrV6oyxx4OSPCBycJaTg",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "742",
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "name": "Tricep Side Stretch",
      "key": "J0zNd6rz8ZDvLnL1Cbdx",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Forearms",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "id": "567",
      "name": "Cable Wrist Curl",
      "key": "J5I2onNLkfrfDcg48gA6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "id": "718",
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "name": "Extended Range One-Arm Kettlebell Floor Press",
      "key": "J7BTVQzJoSHduiUZBBrB",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "803",
      "muscle_type": "Quadriceps",
      "equipment_type": "Body Only",
      "name": "Frog Hops",
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "key": "J7lqNLJj078mrAJjzGjP",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Tricep Overhand Pressdown",
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1586267421,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1586267421,
          "nanoseconds": 0
      },
      "id": "1105",
      "equipment_type": "Cable",
      "key": "JCQeha4fxvVbi5eyY7jo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Middle Back",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "id": "806",
      "name": "Middle Back Stretch",
      "key": "JEUhrfbLK9sUh3MPJV2f",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "249",
      "equipment_type": "Kettlebells",
      "muscle_type": "Traps",
      "name": "Kettlebell Sumo High Pull",
      "key": "JKjaXlITSJDtUZVucPTR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "name": "Lying Close-Grip Barbell Triceps Press To Chin",
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "equipment_type": "E-Z Curl Bar",
      "id": "505",
      "key": "JNCxFBOtR0i27V6n1a23",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Lower Back",
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "183",
      "name": "Hyperextensions With No Hyperextension Bench",
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "key": "JTdONOatjzsY1dUoGDzi",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "167",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "name": "Bent Over Two-Arm Long Bar Row",
      "muscle_type": "Middle Back",
      "equipment_type": "Barbell",
      "key": "JUcB0DIsAcQqawplVuf8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "245",
      "name": "One Arm Dumbbell Preacher Curl",
      "equipment_type": "Dumbbell",
      "muscle_type": "Biceps",
      "updated_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "key": "JcKqMHsofsslTeodkKjS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "name": "Seated Bent-Over One-Arm Dumbbell Triceps Extension",
      "id": "603",
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "key": "JqojYsDjt1fGiyP4Jehz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Bosu Ball Cable Crunch With Side Bends",
      "equipment_type": "Cable",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "id": "356",
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "key": "Jr70tNyvWCdLYMm0XA2y",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "muscle_type": "Traps",
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "name": "Snatch Shrug",
      "id": "721",
      "key": "JxMfOxIXJuxfrEMKOqo6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Crunches",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "id": "238",
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "JxV38xWPs1IFYKi8ezBg",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "id": "461",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "name": "Cable Reverse Crunch",
      "updated_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "key": "KDe6SOJhOm8VKiX2cXZ5",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "997",
      "equipment_type": "Medicine Ball",
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "name": "Supine One-Arm Overhead Throw",
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "KGr8fub4JXZ5scH9Svwn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "id": "1030",
      "name": "Barbell Squat To A Box",
      "more_time_is_pr": null,
      "key": "KHvTelYfyBVQyvlQBNz5",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Kettlebell Curtsy Lunge",
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "id": "576",
      "equipment_type": "Kettlebells",
      "muscle_type": "Quadriceps",
      "key": "KK8bFqV1cHpJ3e6jV3um",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "691",
      "equipment_type": "Other",
      "name": "Intermediate Groin Stretch",
      "key": "KODDJF1RSLvxsIQNmDtV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "id": "1024",
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "name": "Partner Lying Leg Raise With Lateral Throw Down",
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "key": "KT1Qn8A6PpFKPi2ON9EH",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "id": "384",
      "name": "Russian Twist",
      "more_time_is_pr": null,
      "key": "KYTM1P9btQPtkssC2Nl9",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "id": "85",
      "muscle_type": "Triceps",
      "name": "Weighted Bench Dip",
      "key": "KaUZE7fZmDCUYlk9zO03",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "765",
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "name": "Arm Circles",
      "key": "KhMcTMsPMz0FroVpYt0n",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Middle Back",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "id": "709",
      "name": "Inverted Row with Straps",
      "key": "KmZBnds1BFXYVFY1t5EI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Machine",
      "id": "194",
      "more_time_is_pr": null,
      "name": "Elliptical Trainer",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "key": "KmwxKCf5T1pXl2cDNC6K",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "name": "Dips - Triceps Version",
      "id": "14",
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "L8keEdf4VGoI8dUs3zVA",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "936",
      "muscle_type": "Lats",
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "equipment_type": "Bands",
      "name": "Assisted Chin-Up",
      "more_time_is_pr": null,
      "key": "LA4bBSWDT95uLVStO7Zc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Lower Back",
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "id": "22",
      "name": "Deficit Barbell Deadlift",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1581295183,
          "nanoseconds": 0
      },
      "key": "LA8EwFBZ9elvDoAeQzB3",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "id": "872",
      "name": "Hurdle Hops",
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "key": "LAZZcJFX7m9SHV21Y0lw",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "muscle_type": "Shoulders",
      "name": "One-Arm Kettlebell Jerk",
      "id": "842",
      "key": "LAtYMMsjLSQHAYK6nwwn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "251",
      "name": "Deadlift with Bands",
      "equipment_type": "Barbell",
      "muscle_type": "Lower Back",
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "key": "LHbUvklJwhEpQHLhFKyi",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Crab Toe Touch",
      "equipment_type": "Body Only",
      "muscle_type": "Abdominals",
      "id": "1091",
      "updated_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "key": "LIWrBfHoccdfPqhN213J",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "949",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "equipment_type": "Other",
      "name": "Decline Explosive Push-Up",
      "key": "LJom9eAWu2po1H5gqSgj",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Arnold Dumbbell Press",
      "equipment_type": "Dumbbell",
      "id": "149",
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "key": "LLW7lzh8PmJxFAwvR0Uk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "equipment_type": "Machine",
      "name": "Machine Shoulder (Military) Press",
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "id": "511",
      "key": "LMS0ga0hOkgrJ0it1vwR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "name": "Mixed Grip Chin",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "id": "453",
      "equipment_type": "Other",
      "muscle_type": "Middle Back",
      "key": "LTcIywvsT8QyptrFgZ10",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "name": "Split Squats",
      "muscle_type": "Hamstrings",
      "id": "795",
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "key": "LU8Wn56J1S68vGdiufM1",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "id": "968",
      "name": "Thigh Killa",
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "LV0yHKM06EYbfVG1Ujli",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "id": "602",
      "name": "Sit-Up",
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "LV4DUTrMG3Wo7mgaZxXA",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "equipment_type": "Bands",
      "muscle_type": "Shoulders",
      "name": "Back Flyes - With Bands",
      "id": "493",
      "key": "LeEbbFW0UtWbpkZwz5VW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "159",
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Rowing, Stationary",
      "key": "LiQGRoDmRGFZSdORC0VE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Burpee Box Jump",
      "created_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "muscle_type": "Quadriceps",
      "id": "1093",
      "key": "LjPaab35g18oBzieroSm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "equipment_type": "Bands",
      "muscle_type": "Abductors",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "id": "921",
      "name": "Lateral Band Walk",
      "key": "Ln21ob6tQGF7GRJv6koE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "name": "Leg Press",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "id": "185",
      "key": "LrGjxnXQeA2LyeU4JW5B",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Middle Back",
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "id": "592",
      "more_time_is_pr": null,
      "name": "Suspended Row",
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "key": "LrKIHeDHWVsEcqiaHhyD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "id": "320",
      "name": "Hammer Grip Incline DB Bench Press",
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "key": "LvZIAfYiofKWWhas410n",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "877",
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "name": "Stride Jump Crossover",
      "key": "Lx11L4VR89xGiBRA5zfw",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Smith Machine Calf Raise",
      "id": "59",
      "muscle_type": "Calves",
      "key": "LxDOTs8tRKLFq3A2x93s",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "name": "Seated One-arm Cable Pulley Rows",
      "equipment_type": "Cable",
      "id": "351",
      "more_time_is_pr": null,
      "key": "LzyXcdc76dKsrT8WF7c6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Push Up to Side Plank",
      "id": "662",
      "muscle_type": "Chest",
      "equipment_type": "Body Only",
      "key": "M13bvPSv8uW8sUQMRqOT",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Lower Back",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "id": "837",
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "equipment_type": "Exercise Ball",
      "name": "Pyramid",
      "key": "M3SHDXT3CLexPUAj6vjl",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Triceps",
      "id": "130",
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "name": "Push-Ups - Close Triceps Position",
      "more_time_is_pr": null,
      "key": "M6dh3lXj9mPJLHPskSZq",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "id": "3",
      "name": "Atlas Stones",
      "updated_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "key": "MCxQzSIR47vkAtmAPjtH",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "name": "Standing Alternating Dumbbell Press ",
      "more_time_is_pr": null,
      "id": "138",
      "muscle_type": "Shoulders",
      "key": "MFjEAcFj6Ce4Dh5ByDJN",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "name": "Kettlebell Thruster",
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "312",
      "key": "MOm75iY4Kbj3aKPjbkSQ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "id": "535",
      "name": "Depth Jump Leap",
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "key": "MSB5WP4k2buDzyyQjY40",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "name": "Suspended Back Fly",
      "muscle_type": "Shoulders",
      "id": "1015",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "key": "MYvC297OHXbXzvLX1yaW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "name": "Seated Triceps Press",
      "muscle_type": "Triceps",
      "id": "176",
      "key": "MaX6vVUxCSQ0ehHzTdZo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Weighted Push-Up",
      "more_time_is_pr": null,
      "id": "1062",
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "key": "MdD0jehNpWwn4iiaT9o1",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Barbell Rollout from Bench",
      "id": "416",
      "muscle_type": "Abdominals",
      "key": "Mfccn2FfXEw4JgU4VVSi",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "name": "Wall Sprawl",
      "id": "1084",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "key": "MhNFI5YxLmfSckhfNNgV",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "name": "Cable Internal Rotation",
      "equipment_type": "Cable",
      "id": "304",
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "key": "MihOAIYPmJWIqsxKOR5b",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Neutral-Grip Pullups",
      "id": "466",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1581295958,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "key": "MkUSE3ISdFbwOoCAzOW2",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "name": "Split Snatch",
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "id": "982",
      "key": "MlktQx8sCwu1Oljy8koh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "id": "874",
      "name": "Side Hop-Sprint",
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "key": "MmJCOGZymTlxkV4oQAMG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Plank with Twist",
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "id": "881",
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "key": "Mmd5vv1swNSUpXq8qO4c",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Biceps",
      "name": "Dumbbell Curl",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1586267650,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "id": "155",
      "key": "MnHxEysUmsENF8UoGJcX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "id": "64",
      "muscle_type": "Abdominals",
      "name": "Spider Crawl",
      "more_time_is_pr": null,
      "key": "MoyS0R1Wq62empoCuOkL",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "1012",
      "equipment_type": "Body Only",
      "name": "Slide Jump Shot",
      "muscle_type": "Quadriceps",
      "key": "MtWepMjtj4Tp2M9SJCKX",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "id": "456",
      "name": "One-Arm Kettlebell Row",
      "muscle_type": "Middle Back",
      "key": "MxfLA7RtsrUBC7CgnmZS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Front Squats With Two Kettlebells",
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "id": "54",
      "more_time_is_pr": null,
      "key": "N0g6ofMdHeosBtKxpWpf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Partner Facing Plank With Band Row",
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "id": "1022",
      "equipment_type": "Bands",
      "key": "NBZBxKp3hkCV54BbOtqJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "id": "349",
      "name": "One Arm Dumbbell Bench Press",
      "equipment_type": "Dumbbell",
      "key": "NGdST3FmJkmN0O8wCJKc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Side Lying Groin Stretch",
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "id": "807",
      "muscle_type": "Adductors",
      "key": "NM9ObQKOYduoR5MqtefL",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Machine Preacher Curls",
      "id": "400",
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "key": "NNEBh4FcWi5369NpiwIr",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "name": "Double Under",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "id": "952",
      "updated_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "key": "NRC7vAjYtoh5Peu3lpFn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "542",
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Single-Leg Leg Extension",
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "key": "NSbfWKriq7SJqobpTY7E",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Glutes",
      "id": "724",
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "equipment_type": "Bands",
      "name": "Hip Lift with Band",
      "key": "NTqALOsTYBw0xlhF5rv6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Foam Roll",
      "name": "Rhomboids-SMR",
      "muscle_type": "Middle Back",
      "id": "737",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "key": "NWiIXJTWQ6qDXfzNbXGk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "name": "Overhead Stretch",
      "id": "804",
      "key": "NZVX8jmXhaulZyachabm",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "799",
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "name": "Chair Upper Body Stretch",
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "key": "NZwrqACyz5tVepylFzOe",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "id": "197",
      "name": "Standing Long Jump",
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "NamWScn0fmwNsoApm1lx",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "629",
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "name": "Incline Push-Up Close-Grip",
      "equipment_type": "Body Only",
      "muscle_type": "Triceps",
      "key": "NgzL5iq0YYkygCtojxHn",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Lying Rear Delt Raise",
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "id": "455",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "key": "NlsFLSWtVjEqoX2NtJnJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "name": "Standing Hip Flexors",
      "id": "790",
      "key": "NmqKveUhCqcDAwE58yuR",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "name": "Crunch - Hands Overhead",
      "id": "300",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "NrZfvXnOxMwF28B7XEOh",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "muscle_type": "Shoulders",
      "id": "970",
      "name": "One-Arm Kettlebell Split Snatch ",
      "more_time_is_pr": null,
      "key": "Nsov1A1KwOSg3aTNGTOj",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "698",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "name": "Cable Seated Lateral Raise",
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "NxNIr1mrdRHsOPocBBa8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "422",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Butt-Ups",
      "key": "Nzr4mxkjVtIxgnkyIlCJ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "id": "86",
      "more_time_is_pr": null,
      "name": "Seated Dumbbell Press",
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "key": "O6mOWBm6zx95pyMlMEeV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Farmers Walk",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "18",
      "muscle_type": "Forearms",
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "key": "OBa02OKLLEoWfW0N6uli",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "612",
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Plyo Push-up",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "key": "OCLMZtXgO14oxCUNsYLR",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "One-Arm Side Deadlift",
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "id": "600",
      "key": "OEVI3XJk5wANzgXOKWkF",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Single-Arm Cable Crossover",
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "id": "296",
      "equipment_type": "Cable",
      "key": "OOtZVggg1vQvARt68fG2",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Vertical Swing",
      "id": "605",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "muscle_type": "Hamstrings",
      "key": "OUPdpYDkpHjqtRtzViPn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Standing Elevated Quad Stretch",
      "equipment_type": "Other",
      "id": "830",
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "key": "OUg4E6FZDVsYeF6rnqvE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "id": "1044",
      "equipment_type": "Body Only",
      "name": "Wall Walk",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "OVIq6r6x85eChxtvQo6p",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "139",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Medicine Ball",
      "name": "Weighted Suitcase Crunch",
      "key": "OakVDvebbomMCiAmSuAC",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "60",
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "name": "Seated Two-Arm Palms-Up Low-Pulley Wrist Curl",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "key": "OihKrpCKiE0urSGhdnvb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "name": "Landmine Linear Jammer",
      "id": "686",
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "key": "OngX7iuP6wHdwyhLCXj8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "id": "716",
      "muscle_type": "Abdominals",
      "name": "Bent Press",
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "key": "OvRAIQYgMX6dUyKEMXra",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Olympic Squat ",
      "updated_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "id": "76",
      "equipment_type": "Barbell",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "key": "OwoMObZASqed4V51BhOQ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "818",
      "name": "On-Your-Back Quad Stretch",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "key": "OxdDTArpm4erIJK76g5x",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "id": "74",
      "name": "Power Partials",
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "key": "Oy4FCZxssQ5PxWD5kfuv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "name": "The Straddle",
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "id": "793",
      "key": "P1DWO5lO2DzMOZs4aZ29",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Supine Two-Arm Overhead Throw",
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "753",
      "muscle_type": "Abdominals",
      "key": "P3faDVzpnDrLdlxjFrG6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "954",
      "muscle_type": "Quadriceps",
      "name": "Sprawl Frog Kick",
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "PAZRS89ZVESuymjlbN7Z",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "id": "317",
      "name": "Sledgehammer Swings",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "key": "PKOr9iAwwkWD1NIor80f",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "name": "Power Jerk ",
      "id": "480",
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "muscle_type": "Quadriceps",
      "key": "POqCKMutsBXs2r60n4O7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Triple Clap Push-Up",
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "equipment_type": "Body Only",
      "id": "941",
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "key": "PPsruV7jpXf4fB4lVk5e",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Dumbbell Squat To A Bench",
      "id": "666",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "PTYwyNMLvYBeAtx6XacO",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "id": "831",
      "name": "Foot-SMR",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Calves",
      "key": "PUeJCaP36rpKyOzmLN63",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "name": "Jump Lunge To Feet Jack",
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "id": "1009",
      "key": "PWUQ3nWO1WLdd56Z5wvT",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Glutes",
      "equipment_type": "Barbell",
      "id": "313",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "name": "Kneeling Squat",
      "key": "PXDQRHyIxTQysBqRS9Vk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "name": "Standing Bent-Over Two-Arm Dumbbell Triceps Extension",
      "more_time_is_pr": null,
      "id": "222",
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "equipment_type": "Dumbbell",
      "key": "PZHjvgvMfLoSLPfOQkij",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1035",
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "name": "Single-Leg Balance ",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "key": "PaXqYE6n5sFnNnlp93zJ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Plyo Kettlebell Pushups",
      "equipment_type": "Kettlebells",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "id": "534",
      "key": "Pf5AQnq7V7bFF2rNnGcq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "865",
      "muscle_type": "Triceps",
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "name": "Floor Press with Chains",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "key": "PfRhcDHe1dKNJpvyLl4Y",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Tiger-Bend Push-Up",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "594",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "key": "PkINMLO79J3GouhSO84t",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "id": "637",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "name": "Flat Bench Cable Flyes",
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "key": "Psw0ImIskXYKXuF7Adwh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "name": "Cable Preacher Curl",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "id": "607",
      "muscle_type": "Biceps",
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "key": "PzkhqkS8wqoiXI9fl0dX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "muscle_type": "Biceps",
      "id": "301",
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "name": "Preacher Hammer Dumbbell Curl",
      "key": "QA7I0l2j5A5cUr4LTISb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Dumbbell Front Squat",
      "more_time_is_pr": null,
      "id": "1066",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "key": "QBXiZw5Ksd5cNWl255KR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "name": "Standing Low-Pulley One-Arm Triceps Extension",
      "muscle_type": "Triceps",
      "equipment_type": "Cable",
      "id": "512",
      "key": "QFJKMYSsOBwktcYMmYXF",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Quadriceps-SMR",
      "more_time_is_pr": null,
      "id": "749",
      "equipment_type": "Foam Roll",
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "key": "QFq2yteyiTpdmj2xE35R",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Lower Back Curl",
      "id": "700",
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "QGZ08mSRyYndpSQwalSz",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "name": "Incline Hammer Curls",
      "updated_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "muscle_type": "Biceps",
      "id": "4",
      "key": "QJtiF9gCnBsvHh4o75mv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "name": "Kettlebell Pistol Squat",
      "id": "90",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Kettlebells",
      "key": "QKNNtMcoO4Z41rRUQhm4",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "88",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "name": "Reverse Flyes",
      "key": "QMQslNvY7yR2inEaQTDZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Seated Flat Bench Leg Pull-In",
      "more_time_is_pr": null,
      "id": "382",
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "key": "QX8aXosjtOKH1bpv9Eyb",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Single-Arm Linear Jammer",
      "id": "6",
      "muscle_type": "Shoulders",
      "key": "QdYgALrc2OXqTfdjNEGb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "equipment_type": "Bands",
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "id": "429",
      "name": "Cross Over - With Bands",
      "key": "QjGSXFsqbC9NP7qpgmjK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "858",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "name": "Log Lift",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "key": "Qk6ztUS4tr59x87NZjyq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "id": "1029",
      "name": "Tall Muscle Snatch",
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "QopaHF5eSm4On39Lyd0e",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "751",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "name": "Calf Stretch Hands Against Wall",
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "key": "QxBWgsu1clJu7zHfPMHi",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "id": "134",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "muscle_type": "Quadriceps",
      "name": "Single-Leg High Box Squat",
      "key": "R17XnlVYiuyejkYiL0Y0",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Weighted Sissy Squat",
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "id": "368",
      "muscle_type": "Quadriceps",
      "equipment_type": "Barbell",
      "key": "REeL9CBFnUAQ1UDuMl44",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "more_time_is_pr": null,
      "name": "Cable Rear Delt Fly",
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "id": "482",
      "key": "RHcqvoHIjO8XqPzb5z4u",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Bands",
      "name": "Hip Flexion with Band",
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "id": "880",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "key": "RI74UK3VTOi1eRdLTU5R",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Lower Back-SMR",
      "id": "787",
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Foam Roll",
      "muscle_type": "Lower Back",
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "key": "ROgCnq6c2XtnWHnJa9HE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "906",
      "name": "JM Press",
      "equipment_type": "Barbell",
      "muscle_type": "Triceps",
      "key": "RV3OaA5FDV23ET16M4tY",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "id": "48",
      "muscle_type": "Lats",
      "name": "Pullups",
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "Rlj6DXYeDiXRRDY4tDY9",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Bear Crawl Sled Drags ",
      "equipment_type": "Other",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "id": "118",
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "key": "RmRMFrUbCUl0ejeZ4tSM",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1051",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "name": "Svend Press",
      "key": "Rq9izbwtZx08J6bzNj5I",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Keg Load",
      "muscle_type": "Lower Back",
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "id": "857",
      "equipment_type": "Other",
      "key": "RyLYXoOoIRZpg3c0gAvU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "equipment_type": "Cable",
      "name": "Triceps Pushdown - V-Bar Attachment",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "id": "71",
      "updated_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "key": "RydfiOYbjYiOGsdGDluL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Machine Triceps Extension",
      "id": "620",
      "key": "S1WsJIYKo0tZh5I2c8m6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "name": "Barbell Hack Squat",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "id": "342",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "key": "S7heZXaRsprrKYikMdo4",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "172",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "name": "Barbell Ab Rollout - On Knees",
      "key": "S8i1QeUxnq69VhGZHvXU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "id": "689",
      "more_time_is_pr": null,
      "name": "Upward Stretch",
      "key": "S9LEiXSTQpBFl6jnLg67",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Neck-SMR",
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "muscle_type": "Neck",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "id": "829",
      "more_time_is_pr": null,
      "key": "SAOwb5OzswiJ5KmU8ApB",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "981",
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "name": "Snatch Pull",
      "key": "SB9aL3t5SjcPpTD9Hiac",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "id": "89",
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "name": "Incline Dumbbell Press",
      "equipment_type": "Dumbbell",
      "key": "SKEtIqS5LYjLWBHke0jo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Reverse Hyperextension",
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "id": "536",
      "muscle_type": "Hamstrings",
      "equipment_type": "Machine",
      "key": "SMv7rgnZ3sOE3CGMuTxi",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "name": "Single Leg Push-off",
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "id": "57",
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "key": "SN9pDCLmi2GGh2YpyTds",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "One-Arm Open Palm Kettlebell Clean ",
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "id": "972",
      "key": "SQ4rbnrhRg1Eyse7YD3u",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "539",
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "name": "Cable Shrugs",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "muscle_type": "Traps",
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "key": "SQH74myKlO5vjwsD9YtA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "id": "407",
      "name": "Bottoms-Up Clean From The Hang Position",
      "key": "SR5KnJ0C5LxhyWq8M03c",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "id": "768",
      "muscle_type": "Biceps",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "name": "Seated Biceps",
      "key": "SRJDXozmNQimu6Hre3GC",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "707",
      "name": "Isometric Neck Exercise - Front And Back",
      "more_time_is_pr": null,
      "muscle_type": "Neck",
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "SYeXDeh0orxOX3lyzIyf",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "747",
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "name": "Upper Back-Leg Grab",
      "key": "SaPZ9s8Ij1cOcCE7KaNi",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "id": "978",
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "name": "Power Stairs",
      "equipment_type": "Other",
      "key": "SirB8CAoRtOEmrfKPEHN",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "name": "Crossover Reverse Lunge",
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "more_time_is_pr": null,
      "id": "805",
      "equipment_type": "Body Only",
      "key": "SyV9vp9xWs6itSZx0NJq",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "name": "Standing Dumbbell Calf Raise",
      "id": "217",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "muscle_type": "Calves",
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "key": "T9u1WYeM9lUEvW23Rdy3",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "id": "951",
      "name": "Plate Shoulder Circle Big To Small Rotation",
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "key": "TCbHEQh9G3mXog58YC3x",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "equipment_type": "Kettlebells",
      "id": "1088",
      "name": "Kettlebell Fire Feet",
      "created_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "TH7ZInxLldKd4URzldQJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "540",
      "name": "Overhead Squat",
      "more_time_is_pr": null,
      "key": "THaNeBBYMOxjaOh3IbyG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Traps",
      "id": "247",
      "name": "Standing Dumbbell Upright Row",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "TK2hNfpqqrlZsIQx2Exs",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "more_time_is_pr": null,
      "id": "338",
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "name": "Calf Press",
      "key": "TLXIbq76xy4UJFIa6X5L",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Lying Machine Squat",
      "id": "510",
      "key": "TNK6mJecPb53YZlL1fQP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "name": "Jackknife Sit-Up",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "323",
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "key": "TNnsL2uBJAHQGT1vRJBJ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "974",
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "name": "Lying Prone Quadriceps",
      "key": "TPN6en78wI31nRRg9ugq",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Rockers (Pullover To Press) Straight Bar",
      "muscle_type": "Lats",
      "more_time_is_pr": null,
      "id": "908",
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "key": "TQvzITAxNhzChsDM4rvx",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Single-Leg Squat To Box",
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "1056",
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Other",
      "key": "Tc9uiwSeOkaTlChe7Oa0",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Cocoons",
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "id": "95",
      "more_time_is_pr": null,
      "key": "ThxZ7QhlJQRu79EwQW7v",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "name": "External Rotation with Cable",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "id": "255",
      "key": "TyRakbgWzCEMEt1ysXAs",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "name": "Heaving Snatch Balance",
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "id": "977",
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "key": "U4D9gDwyW9wfsdMpA6yT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "name": "Ankle Circles",
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "id": "655",
      "key": "U4Kc1509emft7XgYNv39",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "name": "Cable Shoulder Press",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "id": "506",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "key": "UCzy9zNO2xlTFeoO7QnS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "853",
      "equipment_type": "Other",
      "name": "Car Deadlift",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "key": "UG3roICAMMafAW75UKCr",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "muscle_type": "Abdominals",
      "name": "Oblique Crunches",
      "id": "399",
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "key": "UIkD7VgYjUpRIk4vVQ8B",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "name": "Suspended Split Squat",
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "706",
      "key": "UNnQbcETdWCe3ZrI8rDS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "id": "376",
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "name": "Pallof Press",
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "key": "UV2spVCtXxDGe7ROnhAJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "1058",
      "more_time_is_pr": null,
      "name": "Wall Squat",
      "key": "UW6S72uTCG2sEymXAJp2",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "id": "128",
      "name": "Incline Cable Flye",
      "key": "UWOcRwKDjCfNf5haGuI2",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "muscle_type": "Lats",
      "name": "Side-Lying Floor Stretch",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "id": "840",
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "key": "UZCsbi5NcTjvs9gT2P5z",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "more_time_is_pr": null,
      "name": "Kneeling Single-Arm High Pulley Row",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "id": "445",
      "key": "UZVZhtWjQjtF0pQJPIfZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Triceps Stretch",
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "id": "782",
      "muscle_type": "Triceps",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "UdGjpXkPG8cWTXnmAPKR",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Single Leg Glute Bridge ",
      "equipment_type": "Body Only",
      "muscle_type": "Glutes",
      "id": "200",
      "key": "UkskcoJUgAdPmscQcPYx",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "id": "959",
      "more_time_is_pr": null,
      "name": "Southpaw Sprawl",
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "key": "UlfPHEL7Ao8uDxAN9BRb",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "id": "93",
      "name": "Standing Dumbbell Press ",
      "key": "UnAoQvh24LLZPCNJlFwu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Handstand Push-Ups",
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "243",
      "equipment_type": "Body Only",
      "key": "Uu7HdWo0Jikb5v6MaCnb",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "983",
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "name": "Rickshaw Deadlift ",
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "key": "V6iulutPFsU5jYXznDPh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Barbell Ab Rollout",
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "id": "153",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "key": "VI3sPuUvb1VvE5rUSfoH",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Clean and Press",
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "24",
      "equipment_type": "Barbell",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "key": "VJgOrFpwUYciOfazW2Zb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "957",
      "name": "Kettlebell 3-Point Extension",
      "equipment_type": "Body Only",
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "key": "VLWhDb82Ht1bxsAvMdjY",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "name": "Incline Cable Chest Press",
      "updated_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "257",
      "equipment_type": "Cable",
      "muscle_type": "Chest",
      "key": "VSEB41P2BeRFtrrNoXzO",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "582",
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "name": "Circus Bell",
      "equipment_type": "Other",
      "key": "VYhBoi0yi3w6i2yo1sbU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "id": "979",
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "name": "Rack Delivery",
      "equipment_type": "Barbell",
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "key": "VaA2sHJNqjcYKZvReh3e",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Incline Inner Biceps Curl",
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "id": "533",
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "key": "VbjuEJea8PdDNTZ6vmUe",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "name": "Stomach Vacuum",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "id": "331",
      "key": "VbspeDJH0Cb7LtXzwIM3",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "309",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Kettlebells",
      "name": "Alternating Kettlebell Press",
      "key": "VdUu3IuTE1Qr3TDrasi5",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "muscle_type": "Lats",
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "id": "213",
      "name": "V-Bar Pullup",
      "key": "VeDmS373Gdj33IiQWPzw",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Glute Kickback",
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "muscle_type": "Glutes",
      "more_time_is_pr": null,
      "id": "421",
      "equipment_type": "Body Only",
      "key": "VeLifw5dHQ3pvrVgkCzk",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Staggered Push-Up",
      "id": "905",
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "key": "VfxefUqUHTFPDoL9McRA",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "name": "Two-Arm Kettlebell Jerk",
      "id": "846",
      "equipment_type": "Kettlebells",
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "key": "ViZPPtFvZQw1yRH2jxtn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "name": "One Arm Supinated Dumbbell Triceps Extension",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "id": "387",
      "equipment_type": "Dumbbell",
      "key": "VkdHXxIpsLxbO1z3i2k3",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "name": "Front Plate Raise",
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "id": "173",
      "muscle_type": "Shoulders",
      "key": "VqSISgkfKwYSqigIFRNs",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "name": "Rope Crunch",
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "id": "291",
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "key": "VrINiSCakSi7sqFjDzlD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "670",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Lats",
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "name": "London Bridges",
      "equipment_type": "Other",
      "key": "VtQKZxwbMXqDjwwfpwWK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "name": "Decline EZ Bar Triceps Extension",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "28",
      "muscle_type": "Triceps",
      "key": "Vw8fyE0SF86CLzcVRV7T",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "161",
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "name": "Kneeling Cable Crunch With Alternating Oblique Twists",
      "key": "W1VgeLWP2T1fkEDc0E9t",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Foam Roll",
      "more_time_is_pr": null,
      "id": "726",
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "name": "Calves-SMR",
      "key": "W2SWc3adIvhp2NpBuMjW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "id": "495",
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "name": "Barbell Back Squat",
      "updated_at": {
          "seconds": 1587224823,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "key": "W4V2TtBHJlYRqa7lwUsX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Bent Over Low-Pulley Side Lateral",
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "id": "321",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "key": "W6ClInqo9Vk5izeBCwHE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Hamstrings",
      "name": "Lunge Pass Through",
      "more_time_is_pr": null,
      "equipment_type": "Kettlebells",
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "id": "258",
      "key": "W8AKHFF4GdwS9SVKreZi",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "731",
      "equipment_type": "Barbell",
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "name": "Lying High Bench Barbell Curl",
      "key": "WNO0CelShAsf93dxJDQm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "475",
      "name": "Machine Bench Press",
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "key": "WPTaosFWuM9LYOd7UiuC",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "name": "Bent Over One-Arm Long Bar Row",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "id": "142",
      "key": "WPxg3yNbtlwkBRfv0yHc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "id": "671",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "name": "90-Degree Jump Squat Twist",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "key": "WRHW8IWuZp7rBkUEbpAR",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "id": "489",
      "name": "Stiff Leg Barbell Good Morning",
      "more_time_is_pr": null,
      "muscle_type": "Lower Back",
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "key": "WTpVWr1XgOg7BTkIPC2x",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Calves",
      "name": "Donkey Calf Raises",
      "id": "627",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "WTrG4dtQbWWjJiyuvkNA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "66",
      "updated_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "name": "Dumbbell Flyes",
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "key": "WU6a5COBzFsTU0x7poAI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Leverage Deadlift",
      "id": "998",
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "key": "WWhI2IhSh91MSFvqsYz4",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "id": "408",
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "name": "Rack Pulls",
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "more_time_is_pr": null,
      "key": "Wi9ZdzxhBvySXxgwGZJc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Traps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "name": "Barbell Shrug Behind The Back",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "id": "427",
      "key": "X3AKZ1ghy7F0xM3VWUvZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "id": "256",
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "name": "Incline Push-Up Medium",
      "key": "X9cejKHV6sMyazItO8Jm",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Seated Hamstring and Calf Stretch",
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "id": "851",
      "key": "XAixcOS2ZcKTZfffOYmX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Glutes",
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Physioball Hip Bridge",
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "equipment_type": "Exercise Ball",
      "id": "560",
      "key": "XCpvQWaDrby7q8sprXaZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "name": "Smith Machine One-Arm Upright Row",
      "more_time_is_pr": null,
      "id": "650",
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "equipment_type": "Machine",
      "key": "XFcIeXHVD5lb93kLIR0H",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "name": "Double Kettlebell Jerk",
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "id": "675",
      "more_time_is_pr": null,
      "key": "XGSehNMBRBS3qmvw4GH1",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "id": "878",
      "name": "External Rotation with Band",
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "equipment_type": "Bands",
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "key": "XHEpkWXfGQOKgXYB59qS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "name": "Incline Push-Up Wide",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "357",
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "key": "XJbDvt3qNq2jc4LPq2vM",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "name": "Side Lunge",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "id": "563",
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "key": "XPaBe2LHRjXUYKzKdnof",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "365",
      "equipment_type": "Dumbbell",
      "name": "Lying Dumbbell Tricep Extension",
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "key": "XPdE7XhG0k0LMjqvHjgM",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "id": "955",
      "equipment_type": "Kettlebells",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "name": "Kettlebell Side Squat",
      "updated_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "key": "XQa2Jd8YEnPIX1QfVXV8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "name": "Kneeling Cable Triceps Extension",
      "equipment_type": "Cable",
      "id": "101",
      "updated_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "key": "XWhhTqpcsjbqFaQoKA8W",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "175",
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "name": "Close-Grip EZ Bar Curl",
      "muscle_type": "Biceps",
      "key": "XXP4D72bhvhjGqNWDXmp",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Otis-Up",
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "id": "233",
      "key": "XYwHUKRTak3YYQRsvPfK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "name": "Calf Press On The Leg Press Machine",
      "equipment_type": "Machine",
      "id": "269",
      "more_time_is_pr": null,
      "muscle_type": "Calves",
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "key": "XZinQn3IFojoRGNmvfVU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Upright Row - With Bands",
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "id": "556",
      "equipment_type": "Bands",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "muscle_type": "Traps",
      "key": "Xa6IU3efo4mvU79ggKjM",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "261",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1586264892,
          "nanoseconds": 0
      },
      "name": "Barbell Bent Over Row",
      "muscle_type": "Middle Back",
      "key": "XdGIs5MC9Nty8gHrADsX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "name": "Decline Dumbbell Flyes",
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "id": "97",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "key": "XfKB1VJsk3Zofd99u4fF",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "name": "Bench Press - With Bands",
      "id": "741",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "equipment_type": "Bands",
      "key": "Xmi3A9eZBpO1sE4r0W4N",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Lower Back",
      "id": "290",
      "name": "Deadlift with Chains",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "key": "XprSbOFuGACZW2iCztaP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "name": "Hang Clean - Below the Knees",
      "id": "353",
      "more_time_is_pr": null,
      "key": "XvRtTCBPEiBv7sT7zkfU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Push-Up Wide",
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "id": "415",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "key": "Xx7nMgUOeG1DEzwjY6hE",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "643",
      "equipment_type": "Body Only",
      "name": "Lateral Bound ",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "muscle_type": "Adductors",
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "key": "Y1ismhiEFOd5d5fMpjql",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "name": "Wide Stance Stiff Legs",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "id": "336",
      "muscle_type": "Hamstrings",
      "key": "Y37H2lN41x2942kKHVzH",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "name": "Mountain Climbers",
      "id": "224",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Body Only",
      "key": "Y3fqtEFZgVgTfozK4wRZ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "muscle_type": "Calves",
      "name": "Standing Gastrocnemius Calf Stretch",
      "id": "739",
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "key": "Y5AOg6rfLcZvRt5l91XP",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "id": "87",
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "name": "Standing Dumbbell Straight-Arm Front Delt Raise Above Head",
      "key": "Y65bjPhnDUJwUb6JXbW4",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "name": "Palms-Down Dumbbell Wrist Curl Over A Bench",
      "id": "379",
      "key": "Y7Ujar35G5ROlyDTEr2h",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "id": "228",
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Trap Bar Deadlift",
      "key": "YCYhHEOIf6JaileH7gYZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "id": "82",
      "muscle_type": "Lower Back",
      "more_time_is_pr": null,
      "name": "Hyperextensions (Back Extensions)",
      "equipment_type": "Other",
      "key": "YCb7xkUNe8dYf9b9zag8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "404",
      "name": "Seated Side Lateral Raise",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "key": "YG9POqyvIuotXPmWSBLd",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "muscle_type": "Shoulders",
      "name": "Kneeling Arm Drill ",
      "id": "802",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "key": "YSAr8XLxEAgoy3SQYVoS",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "2",
      "equipment_type": "Machine",
      "updated_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "name": "Single-Leg Press",
      "key": "Yg5mBTcVMlLJVfen2Lf2",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "name": "Dumbbell Clean And Jerk",
      "id": "1045",
      "updated_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "YidNd17wJQrI2LQQizkf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "684",
      "equipment_type": "Kettlebells",
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "name": "Kettlebell Arnold Press ",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "key": "YkZanTkhERoOOjsIAtRG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "id": "344",
      "equipment_type": "Dumbbell",
      "name": "Decline Dumbbell Triceps Extension",
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "key": "YpCHAaCP3cWIfMQ7mKma",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "543",
      "name": "Pistol Squat",
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "key": "YpJGLDknxBW171lqQcvz",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Cable Rope Overhead Triceps Extension",
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "id": "491",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "key": "YpKojTtJj2WQM1HLUbnV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "899",
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Body Only",
      "name": "Defensive Slide",
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "key": "Yt1b3WeAtrNMjAKyQTgT",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "10",
      "more_time_is_pr": null,
      "name": "Palms-Down Wrist Curl Over A Bench",
      "equipment_type": "Barbell",
      "muscle_type": "Forearms",
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "key": "Yubqrj2EnCdwJpYdsDY2",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "id": "537",
      "name": "Alternating Cable Shoulder Press",
      "key": "Yug2CKejjJPRRw79U7u6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "name": "Weighted Squat",
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "id": "648",
      "key": "YwSklbE1tL6jW78Ytqc6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "equipment_type": "Barbell",
      "id": "476",
      "name": "Bradford/Rocky Presses",
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "YwoDUZGalQD1zTwwzfm7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Chest",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "id": "218",
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "name": "Neck Press",
      "more_time_is_pr": null,
      "key": "YxohrxL4OouKiv9uoJtH",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "name": "Vertical Mountain Climber",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "id": "1010",
      "key": "Z05n36QHXC0FfaebjZU2",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Wind Sprints",
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "id": "523",
      "key": "Z0NFPIf9Tc8wDOvDVta3",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "935",
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "name": "Barbell Reverse Lunge",
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "key": "Z0q4r5GdmH5EZUPFmXLD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Feet-Elevated TRX Push-Up",
      "equipment_type": "Bands",
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "id": "1090",
      "created_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "key": "Z296dE1HALROXxOtrCyp",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "name": "Hip Circles (prone)",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "id": "58",
      "muscle_type": "Abductors",
      "key": "Z4VqyRPJt6YJIFitBtl8",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "518",
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Abductors",
      "more_time_is_pr": null,
      "name": "Windmills",
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "key": "Z89cWaBetXCu44rq2GfU",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Lower Back",
      "id": "487",
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "name": "Seated Back Extension",
      "equipment_type": "Machine",
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "key": "Z8B6FMUhu0fv6dCB4EBh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "equipment_type": "Dumbbell",
      "id": "569",
      "name": "Dumbbell Scaption",
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "key": "Z9ZNgTJdKphpswCP0t6u",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "id": "875",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "name": "Single-Leg Hop Progression",
      "key": "ZCChyWgDHCrxVunwujAv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Kettlebell Squat Clean",
      "id": "1092",
      "equipment_type": "Kettlebells",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "key": "ZHiXTfMTxQV1iRFTtHXm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "name": "Round The World Shoulder Stretch",
      "id": "756",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "key": "ZHpEG57NXgolNvgsaGFX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "948",
      "updated_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Bar Throw And Press",
      "muscle_type": "Chest",
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "key": "ZOLwuhldWUXtnlzp9WvH",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "name": "Cross Crunch",
      "more_time_is_pr": null,
      "id": "900",
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "key": "ZWhSzzCJDL3FtadzY3MQ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "name": "Chair Leg Extended Stretch",
      "muscle_type": "Hamstrings",
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "id": "834",
      "key": "ZkVV70ZURkHfPqYTLeCk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "id": "383",
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "name": "Exercise Ball Crunch",
      "more_time_is_pr": null,
      "equipment_type": "Exercise Ball",
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "key": "Zu1xYsqNzqxNbycdy8rB",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Flat Bench Lying Leg Raise",
      "muscle_type": "Abdominals",
      "id": "381",
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "a6PMgpxb67sZxkdII5mb",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "618",
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "name": "Dumbbell Incline Shoulder Raise",
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "key": "a7SV9qMs0S674LJclHfu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "name": "One-Arm Side Laterals",
      "id": "72",
      "equipment_type": "Dumbbell",
      "key": "a8gtQK26pSzmHTmWTYam",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "808",
      "muscle_type": "Neck",
      "name": "Chin To Chest Stretch",
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "aDyyAD8xqboDH5XVOBwv",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "name": "Dumbbell Shoulder Press",
      "more_time_is_pr": null,
      "id": "129",
      "equipment_type": "Dumbbell",
      "muscle_type": "Shoulders",
      "key": "aGzsBceFyraQRUoCcg9M",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1040",
      "more_time_is_pr": null,
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "name": "Split Squat With Kettlebells",
      "muscle_type": "Quadriceps",
      "key": "aMsDSdLY24Y4yK65gV91",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "474",
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "name": "Standing Barbell Calf Raise",
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "muscle_type": "Calves",
      "more_time_is_pr": null,
      "key": "aOLpYXLdrPwrF4fwJYLT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "id": "717",
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "name": "One Arm Chin-Up",
      "key": "aSPDb9upQJSUggot2OWq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Hip Stretch With Twist",
      "id": "1050",
      "equipment_type": "Body Only",
      "muscle_type": "Hamstrings",
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "aSdmyeQq85yB9roxXAO3",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Middle Back",
      "equipment_type": "Machine",
      "name": "Lying T-Bar Row",
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "id": "544",
      "more_time_is_pr": null,
      "key": "aTMVaXafZ5j7SobePkBR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "148",
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "name": "Cable One Arm Tricep Extension",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "key": "aWzW4gNC1qbcw52h2SyM",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Bands",
      "updated_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "name": "Band Pull Apart",
      "id": "463",
      "key": "aYsXbTt5nsqh2yjdKkMU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "name": "Partner Target Sit-Up",
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "1020",
      "muscle_type": "Abdominals",
      "key": "aa9aZ9vYw2Xw0NgtKVRi",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Pop Squat",
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "id": "1059",
      "equipment_type": "Body Only",
      "key": "adcEkZYP69to8rhNIqKN",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Dumbbell Lying One-Arm Rear Lateral Raise",
      "id": "112",
      "key": "afBwXhYHqBOS8eydSNOs",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "muscle_type": "Adductors",
      "name": "Adductor/Groin ",
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "id": "812",
      "key": "ai7SeooabIAeRKiunQRt",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Superman",
      "id": "428",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "key": "bGubHpKCYFZeASWfp56d",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "id": "583",
      "name": "Frankenstein Squat",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "key": "bH2zV9mgqqSzlh3ZCH3U",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "muscle_type": "Lower Back",
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Cat Stretch",
      "id": "771",
      "key": "bP8TignZyDWlVIxMhUgq",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "695",
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "name": "Side To Side Push-Up",
      "more_time_is_pr": null,
      "key": "bdEoSFGT0RPVwCuYho5l",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "id": "450",
      "muscle_type": "Shoulders",
      "name": "Bent Over Dumbbell Rear Delt Raise With Head On Bench",
      "more_time_is_pr": null,
      "key": "bhLt7PIRckNso7J2ZlHb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "name": "Front Box Jump",
      "id": "314",
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "key": "br61lW6fPlgXm5CFa2pZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "name": "Standing Two-Arm Overhead Throw",
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "876",
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "key": "bs1JvFX0tH5AwgRcmLct",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "id": "46",
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "name": "Dumbbell V-Sit Cross Jab",
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "key": "bxbpHpRgH6Dc75dUaaIA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "896",
      "muscle_type": "Abdominals",
      "name": "Seated Scissor Kick",
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "key": "c2hRkXakbUSYHsqWZXMY",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "name": "Forward Drag with Press",
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "id": "727",
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "key": "c4bCkQltf1iF93COmmJb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "id": "69",
      "updated_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "name": "Chin-Up",
      "muscle_type": "Lats",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "c51GNysQNL3P8dyQ5QjE",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "name": "Linear Acceleration Wall Drill",
      "id": "413",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "key": "c8GnsrvgzyWl291X4gpH",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "equipment_type": "Kettlebells",
      "id": "934",
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "name": "Kettlebell Sumo Squat",
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "cGj4P52yuS83UBTx4IhC",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Cable Judo Flip",
      "equipment_type": "Cable",
      "id": "770",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "key": "cImD9DPRMIBzRA9epSaD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Lats",
      "more_time_is_pr": null,
      "id": "644",
      "name": "One Arm Lat Pulldown",
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "key": "cLNo6KGoCDE2iLcwLW03",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "name": "Bar Push-Up Smith Machine",
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "id": "1072",
      "key": "cQJGbZLcJNJnMiMDYk1a",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "id": "126",
      "more_time_is_pr": null,
      "name": "Standing Calf Raises",
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "key": "cXFRWO41y7sL1ThO0HLA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "muscle_type": "Shoulders",
      "id": "403",
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "name": "Upright Barbell Row",
      "key": "cZKHATYVXBSTA7E2lGRE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "id": "144",
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Front Barbell Squat",
      "equipment_type": "Barbell",
      "key": "ce7UKxsFu2CGmk1JoGGU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "muscle_type": "Chest",
      "name": "One-Arm Kettlebell Floor Press",
      "more_time_is_pr": null,
      "id": "843",
      "key": "cfgjicEXDBK6PRftfVGR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Alternating Leg Swing",
      "id": "1026",
      "muscle_type": "Hamstrings",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "key": "chKnWVXsbykSzWD6OKRA",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Machine",
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "id": "483",
      "name": "Reverse Machine Flyes",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "cixycfHEmVFYKbuvhHwZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "name": "Rope Straight-Arm Pulldown",
      "muscle_type": "Lats",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "id": "259",
      "key": "ck2U0dylFx3Ilwpwzvqw",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "muscle_type": "Hamstrings",
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "id": "538",
      "name": "Seated Leg Curl",
      "more_time_is_pr": null,
      "key": "cna4IqWHDimiWbvRbggA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Biceps",
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "name": "One-Arm Plank Dumbbell Biceps Curl",
      "id": "586",
      "key": "coIzwovYth1vQU66deUS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "name": "Quick Leap",
      "id": "993",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "cwHxaIdEJpIZKYo6dqTb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1581296120,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "equipment_type": "Body Only",
      "name": "Wide Grip Rear Pullup",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "id": "242",
      "key": "d0BHaTZVATXWI1V87dIl",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "963",
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "name": "Gorilla Squat",
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "key": "d4JjoGLZrwvNnQ7LJiFL",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "318",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "name": "Decline Push-Up",
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "key": "d6HA23qVIuyxusrr59aN",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "642",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "name": "Front Cone Hops (or hurdle hops)",
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "key": "d6Y4w5iYDe0mzLU1cSlA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Machine",
      "muscle_type": "Lats",
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "name": "Machine-Assisted Pullup",
      "updated_at": {
          "seconds": 1581296034,
          "nanoseconds": 0
      },
      "id": "467",
      "more_time_is_pr": null,
      "key": "d6f2s1GbNFkIHP9y60zb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "745",
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "equipment_type": "Exercise Ball",
      "name": "Hug A Ball",
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "dER8adZfEwrFjjHj73uN",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Weighted Crunches",
      "id": "180",
      "equipment_type": "Medicine Ball",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "key": "dGoWHWMImVzoJZnznhsU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "name": "Fast Skipping",
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "id": "744",
      "more_time_is_pr": null,
      "key": "dKi9D2ksSmyqi89dcUle",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "346",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "name": "Drag Curl",
      "key": "dMQWFWsSfzk6pgi3kvY1",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "name": "Dumbbell Bench Press with Neutral Grip",
      "updated_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "id": "277",
      "created_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "key": "dS0INa8ufoaK0aOW300b",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "532",
      "muscle_type": "Shoulders",
      "name": "Reverse Flyes With External Rotation",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "key": "dVLUDZ5zHgsbuABNBud9",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Lats",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "name": "Gironda Sternum Chins",
      "id": "350",
      "key": "ddKw2Gf4BmmKk8xxHtq4",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "757",
      "name": "Spinal Stretch",
      "muscle_type": "Middle Back",
      "equipment_type": "Body Only",
      "key": "dgu22eKzMcxzP9rqoq7g",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "484",
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "name": "Standing Cable Wood Chop",
      "key": "dieqxvsv02pzAUXhpBG0",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "id": "575",
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "name": "Seated Leg Press",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "key": "dk1L5NntK9J7igt690ga",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "557",
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Barbell Side Split Squat",
      "more_time_is_pr": null,
      "key": "dkC0X0KMmyJaizySil19",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "15",
      "name": "Barbell Full Squat",
      "muscle_type": "Quadriceps",
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "dkqFsOYWMgwsvdWla8ig",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Low Cable Triceps Extension",
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "id": "473",
      "key": "dpcdOt3NWiW00Hapchnm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "id": "120",
      "more_time_is_pr": null,
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "name": "Machine Bicep Curl",
      "equipment_type": "Machine",
      "key": "duaBilTYUpcWVPR6hCSG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "name": "Side to Side Box Shuffle",
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "179",
      "key": "dxwtPomX0YHsAR6YfBBG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "equipment_type": "Dumbbell",
      "name": "Standing One-Arm Dumbbell Triceps Extension",
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "436",
      "key": "e2SBRYges1Eh5dF3KgiX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Machine",
      "name": "Abductor",
      "updated_at": {
          "seconds": 1581296668,
          "nanoseconds": 0
      },
      "muscle_type": "Abductors",
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "472",
      "key": "e8TsosEqgCVGslnjvMQ6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "name": "Crunch - Legs On Exercise Ball",
      "id": "631",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "eOPQh6xQj34H17JpLXb9",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "377",
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "name": "Jog In Place",
      "key": "ePU7HfBz56l2Q9JSPTA1",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "muscle_type": "Quadriceps",
      "id": "677",
      "name": "Bench Sprint",
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "eYFTERT8FRaSD738g5as",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "id": "270",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "name": "Front Incline Dumbbell Raise",
      "key": "eYbeoBQuGAsyqstZ8Enl",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Close-Grip Standing Barbell Curl",
      "equipment_type": "Barbell",
      "muscle_type": "Biceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "id": "286",
      "key": "ecaWMQmgXuLIzmqX9oJL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "892",
      "equipment_type": "Machine",
      "name": "Lunge Sprint",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "key": "ekpLFfEVLrOrXlPEkICP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "id": "51",
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "muscle_type": "Traps",
      "name": "Smith Machine Shrug",
      "equipment_type": "Machine",
      "key": "elL9iN6NOj166hffzLrw",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "name": "Decline Dumbbell Bench Press",
      "id": "340",
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "key": "eltJ48C08XsOf33Jjk7U",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "name": "Barbell Rear Delt Row",
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "id": "332",
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "key": "euOFeV8LQmXP8lugtjW8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "name": "On Your Side Quad Stretch",
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "id": "817",
      "more_time_is_pr": null,
      "key": "ev5PJZ51bcvKsvRtemXN",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "name": "Bear Crawl Fire Feet",
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "id": "960",
      "key": "f8Zbe25RKnxxiP0arJc9",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Bicycling, Stationary",
      "equipment_type": "Machine",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "id": "437",
      "key": "f96hlP5xN0OSwe1iLaD9",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "id": "625",
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "name": "Janda Sit-Up",
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "key": "fAz4g4dOXQxsIsykKXuy",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Hamstrings",
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "name": "Smith Machine Hang Power Clean",
      "more_time_is_pr": null,
      "id": "736",
      "key": "fEdAcWMjNal4B0QljK9l",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "id": "986",
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "name": "Box Skip",
      "key": "fFTpT5CXnLIrXUI63StQ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "muscle_type": "Lats",
      "id": "663",
      "more_time_is_pr": null,
      "name": "Wide-Grip Pulldown Behind The Neck",
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "key": "fG66VPAGNJqIVxOkteiZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Step Mill",
      "id": "91",
      "key": "fG9CUF5BlldmrDHne9d8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "189",
      "name": "Narrow Stance Hack Squats",
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Machine",
      "key": "fHIxj8tNa4OnFO2DFjvj",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "id": "760",
      "muscle_type": "Calves",
      "name": "Anterior Tibialis-SMR",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "key": "fNc4tEnPEOTHaI46ZcUt",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Standing Soleus And Achilles Stretch",
      "id": "796",
      "equipment_type": "Body Only",
      "muscle_type": "Calves",
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "key": "fURQtcYdw8KoeQOxZT4G",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "name": "Stiff-Legged Dumbbell Deadlift",
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "id": "208",
      "muscle_type": "Hamstrings",
      "key": "fWnuUoIz2hEU9TP21WUn",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "name": "Shoulder Tap",
      "id": "945",
      "key": "fXv5vBabibGYinkv4k16",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "name": "Wrist Circles",
      "id": "833",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "key": "fZL4R0JFukrOrhwp78j6",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "451",
      "equipment_type": "Cable",
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Lying Cable Curl",
      "key": "fbn3uvGUh5HAHnA5Iq8q",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "39",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "name": "Box Squat",
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "key": "fc7pe2mp3wKJJvbT2VLb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "924",
      "equipment_type": "Dumbbell",
      "name": "Single-Arm Dumbbell Overhead Squat",
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "key": "fg91XT4MzZXzJ2w2KZK3",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "muscle_type": "Abductors",
      "id": "836",
      "more_time_is_pr": null,
      "name": "Lying Crossover",
      "key": "flcebF5fPT7ONet8LxMj",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Hamstrings",
      "id": "634",
      "equipment_type": "Body Only",
      "name": "Moving Claw Series ",
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "fuQYKdlYIkkW5IHeiKjj",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Typewriter Push-Up",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "id": "939",
      "muscle_type": "Chest",
      "key": "fzQHYGArLIyDdmqzFbmv",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "id": "232",
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "name": "3/4 Sit-Up",
      "key": "g0UTfoGCLWblgDwKpqi9",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Isometric Chest Squeezes",
      "id": "658",
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "key": "g2ncgawQecNjQhhNDHcv",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "1065",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "name": "Lateral Hop Holding Dumbbells",
      "key": "g57YCbcuUyOur1MxxbIq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "id": "1006",
      "name": "Feet Jack",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "g9GJ8GJ1Hs1P2knVmEnJ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Wall Ball Squat",
      "id": "529",
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "key": "gFWPd7AGIJinxRtzEqz6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "id": "657",
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "name": "Kipping Muscle Up",
      "muscle_type": "Lats",
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "gHXhODNtwgEFbnzNuLDm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "One-Arm High-Pulley Cable Side Bends ",
      "muscle_type": "Abdominals",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "79",
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "key": "gKFySnjR3VseGqtMXyPR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "953",
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "name": "Jumping Jack",
      "updated_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Body Only",
      "key": "gV2RTnbvWT3h6NIaODJg",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Lower Back",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "name": "Rack Pull with Bands",
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "id": "354",
      "key": "gaCaAOsjOsXGZjGuNavc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "name": "Leverage Chest Press",
      "id": "355",
      "key": "gbHtws7ZzzInb13eFWdk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "id": "1076",
      "name": "Barbell Front-To-Back Squat",
      "key": "ghD4fr05q8AX5UXtKG5f",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "equipment_type": "Kettlebells",
      "name": "One-Arm Kettlebell Clean and Jerk ",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "id": "390",
      "key": "gmLSM4fnUQlLXxhkhkTW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Hamstrings",
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "92",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "name": "Natural Glute Ham Raise",
      "key": "gmUq3mzPIZ1AeAb41k7a",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "id": "520",
      "muscle_type": "Quadriceps",
      "name": "Yoke Walk",
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "key": "gnbvMkTenHd7f6kPmnWL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Standing Towel Triceps Extension",
      "id": "699",
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "key": "gogkV3GyFO2MvxunBWVN",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "id": "137",
      "more_time_is_pr": null,
      "name": "Car Drivers",
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "key": "gvZna1m6lyUZM87WNq3t",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "id": "887",
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "name": "Elevated Back Lunge",
      "muscle_type": "Quadriceps",
      "equipment_type": "Barbell",
      "key": "gz2ViGZ0C50eNwfl40MB",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "muscle_type": "Quadriceps",
      "name": "Sled Drag - Harness",
      "id": "862",
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "key": "h099zhVYP91DQV0cl3H2",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "name": "One-Arm Kettlebell Para Press  ",
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "id": "845",
      "equipment_type": "Kettlebells",
      "key": "h4HBGO0EfUhA7o2zuXq0",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Kettlebell Windmill",
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Kettlebells",
      "muscle_type": "Abdominals",
      "id": "578",
      "key": "h8N5TQjg5M4q2gR9kPzT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Lateral Box Jump",
      "muscle_type": "Adductors",
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "id": "990",
      "key": "hByQumoLn6yvJmM7uGYd",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Intermediate Hip Flexor and Quad Stretch",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "id": "713",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "hCVxN0ulFpeKvPoZWKkr",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "equipment_type": "Dumbbell",
      "id": "40",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "name": "Dumbbell Floor Press",
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "key": "hEHKg4UUzl2YTzyB1VvE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "name": "Front Cable Raise",
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "237",
      "muscle_type": "Shoulders",
      "equipment_type": "Cable",
      "key": "hEuWzErJUxpIrJslBHXa",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "name": "Oblique Cable Crunch",
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "id": "140",
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "equipment_type": "Cable",
      "key": "hGE7cnfjkjUBKuGLuOCD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "405",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "name": "Seated One-Arm Dumbbell Palms-Down Wrist Curl",
      "key": "hJkSanal2n7nZq5Rzpg0",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "muscle_type": "Shoulders",
      "name": "Kettlebell Pirate Ships",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "id": "562",
      "key": "hRxaMs2DYVMrzdTlTa8r",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "id": "839",
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "name": "Posterior Tibialis Stretch",
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "more_time_is_pr": null,
      "key": "hYmMBVewDYLIbbKiH6lP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "name": "Hip Crossover",
      "id": "879",
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "key": "hcDnj1s51hlYSPrxLM3N",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Barbell",
      "id": "649",
      "name": "Clean Pull ",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "key": "hfP7jF96hat6k8mN8gke",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "601",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "name": "Zottman Preacher Curl",
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "key": "hjOjAzjRhlnddMN9HvJu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "714",
      "name": "Lying Hamstring",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "key": "htDBI0ZKK7u7ZAQXmQ2p",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "name": "Partner Hanging Knee Raise With Throw Down",
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "id": "919",
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "huYbL2RMyDW5jm6voxC3",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "id": "124",
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "name": "Bodyweight Lunge",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "key": "hy0zec0btc12RHMXiRBD",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Incline Push-Up",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "id": "199",
      "key": "i1BYFEf40QwLUAbyzNpi",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "muscle_type": "Forearms",
      "name": "Suitcase Dumbbell Carry",
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "id": "1038",
      "more_time_is_pr": null,
      "key": "iBQbI18ItH1hrg7u3WT0",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "68",
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "name": "Cross-Body Crunch",
      "equipment_type": "Body Only",
      "key": "iOvqkWBnZhhpQmoR3M6h",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "name": "Lying Bent Leg Groin",
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "id": "828",
      "muscle_type": "Adductors",
      "equipment_type": "Other",
      "key": "iPSNZP3rOQVjgyKv5U1J",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "name": "Seated Glute Stretch",
      "muscle_type": "Glutes",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "id": "1011",
      "more_time_is_pr": null,
      "key": "iaVBkMU8y7qw0TYp7Hk3",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Side Leg Raises",
      "id": "580",
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Adductors",
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "icyTwc4zwA8O2dmA7rqa",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "id": "652",
      "more_time_is_pr": null,
      "muscle_type": "Lats",
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "name": "Cable Incline Pushdown",
      "key": "ieedhf9xcXOqX6Dtc63k",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "id": "911",
      "name": "Partner Facing Planks With Alternating High-Five",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "key": "ifF9h7yVj1iGCWCYzHpW",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Hamstrings",
      "equipment_type": "Body Only",
      "name": "Hamstring Stretch",
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "729",
      "key": "ilG9ZW2QTFt1nX9JfJX8",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "name": "Partner Lying Leg Raise With Throw Down",
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "id": "1021",
      "key": "ixoZSamSolKbZw2uyQEg",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Middle Back",
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "name": "Smith Machine Bent Over Row",
      "more_time_is_pr": null,
      "id": "501",
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "key": "jBcpPwil8R3wz4fponDW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "name": "Cable Seated Crunch",
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "id": "573",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "key": "jBijICweXE3ET8SdyLgc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1013",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "name": "High Knee Jog",
      "created_at": {
          "seconds": 1569355519,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "key": "jGJpsdwfm58VviPgUk4v",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "equipment_type": "Cable",
      "id": "1104",
      "name": "Tricep Underhand Pressdown",
      "created_at": {
          "seconds": 1586267406,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1586267406,
          "nanoseconds": 0
      },
      "key": "jNOHllHADeaPFZJawZbk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "id": "566",
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "See-Saw Press (Alternating Side Press)",
      "key": "jP6cEpYOvXgu8dUNIdmE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Biceps",
      "name": "Concentration Curls",
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "99",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "key": "jZYQHPQG2bZ7Wokg5MpL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "id": "688",
      "muscle_type": "Chest",
      "name": "Barbell Guillotine Bench Press",
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "key": "jcqsaeXDvtglZg3jtZmR",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "665",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "more_time_is_pr": null,
      "name": "Standing Biceps Stretch",
      "key": "jfkNExW1j1cgE3IoY90d",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "367",
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "name": "Dumbbell Raise",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "key": "jilyEwV04VUk3DyRl7TP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "920",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "name": "Partner Hanging Knee Raise With Manual Resistance",
      "key": "jmKtMiKnEimrFIOLpO2S",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Squat with Chains",
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "id": "132",
      "equipment_type": "Barbell",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "jqInZuKeJP0EYQt5LcpY",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "name": "Lateral Cone Hops",
      "id": "991",
      "muscle_type": "Adductors",
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "key": "jqp4nlik5GUEjbHeiUGo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "id": "969",
      "equipment_type": "Kettlebells",
      "name": "Double Kettlebell Snatch",
      "muscle_type": "Shoulders",
      "key": "js1wTXYYLyjN9Xew2n8B",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "name": "Knee To Chest",
      "muscle_type": "Hamstrings",
      "id": "1049",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "key": "jsfb356rVnhahiqg2giV",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1586264767,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Ab Roller",
      "id": "171",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "k1S2nsawuL96Ull1yAUW",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "438",
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Machine",
      "name": "Jogging-Treadmill",
      "more_time_is_pr": null,
      "key": "k4GuCbGy5YOV4LI39Zdw",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "id": "293",
      "equipment_type": "Cable",
      "more_time_is_pr": null,
      "name": "Triceps Overhead Extension with Rope",
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "key": "kCvaNyNqfVHTfo6h2C3D",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "214",
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Triceps",
      "equipment_type": "Machine",
      "name": "Dip Machine",
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "key": "kFbgNRb5SzEfurgOTEL5",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "name": "Finger Curls",
      "more_time_is_pr": null,
      "id": "33",
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "key": "kGCZDNRyhBrL3FNufiq1",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "name": "Clock Push-Up",
      "muscle_type": "Chest",
      "id": "393",
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "key": "kJgP6ncAiarTkqKmUlSt",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "id": "230",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "name": "Parallel Bar Dip",
      "equipment_type": "Other",
      "key": "kK6OgkPSyu3FQ1Taj01b",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "id": "303",
      "equipment_type": "Dumbbell",
      "muscle_type": "Biceps",
      "name": "Seated Dumbbell Curl",
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "key": "kPPbcapy3RRXQvvyBbsB",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Seated Dumbbell Palms-Down Wrist Curl",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "id": "369",
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "equipment_type": "Dumbbell",
      "key": "kRdvFL5xot8TAVzr7nOm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "name": "Single-Arm Landmine Row",
      "equipment_type": "Barbell",
      "muscle_type": "Middle Back",
      "id": "925",
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "key": "kTTeQ0k3uP80HdnAQwjB",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "name": "Seated Glute",
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "id": "824",
      "muscle_type": "Glutes",
      "key": "kTiasmEKe9HPoQeYkPzW",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "170",
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "name": "Dumbbell Squat",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "key": "kYtmp6SzaEjmUYG5BSG5",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "id": "119",
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "name": "Alternating Deltoid Raise",
      "muscle_type": "Shoulders",
      "key": "kbDjBQ07ibDLugmWL1Si",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Box Jump (Multiple Response)",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "id": "496",
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "key": "kcOjQvkXNcQPK0o1YZxa",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "name": "Reverse Band Deadlift",
      "id": "868",
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "key": "kg8MAR5W8ngOWO32UlVm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Pelvic Tilt Into Bridge",
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "763",
      "key": "klpi2pAQgdxBKJvMq9kr",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "id": "898",
      "more_time_is_pr": null,
      "name": "Slow Jog",
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "key": "ksJTkCEokTtg4YiO0WBD",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "id": "84",
      "name": "Zottman Curl",
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Biceps",
      "key": "l2hurvkO3JbpCAMdZ4t4",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1068",
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "name": "L-Sit Chin-Up",
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "muscle_type": "Lats",
      "more_time_is_pr": null,
      "key": "l4oxbRZdS1EjMtAQ9Z5q",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "362",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "muscle_type": "Traps",
      "equipment_type": "Barbell",
      "name": "Barbell Shrug",
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "key": "l93yn5w4GW6A7icDbAEA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "id": "55",
      "name": "Power Snatch",
      "equipment_type": "Barbell",
      "key": "lGRcSPz2gCoNGm95c5vh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "661",
      "updated_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Adductors",
      "name": "Groin and Back Stretch",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355489,
          "nanoseconds": 0
      },
      "key": "lJ6vXBYjzqCe8TKgaaUq",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "id": "250",
      "equipment_type": "Time",
      "more_time_is_pr": false,
      "updated_at": {
          "seconds": 1614355019,
          "nanoseconds": 0
      },
      "name": "Trail Running/Walking",
      "muscle_type": "Quadriceps",
      "key": "lNLXWjwKLxqizwEcgfum",
      "isBodyWeight": false,
      "isTimeBased": true
  },
  {
      "muscle_type": "Forearms",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "id": "192",
      "equipment_type": "Dumbbell",
      "name": "Dumbbell Lying Pronation",
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "key": "lOg6pyfbBGwtbr6nDkYD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569380234,
          "nanoseconds": 0
      },
      "name": "Weighted Dips",
      "created_at": {
          "seconds": 1569380234,
          "nanoseconds": 0
      },
      "id": "1094",
      "equipment_type": "Other",
      "muscle_type": "Triceps",
      "key": "lWztF3A9P3XcXwzyberz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "muscle_type": "Adductors",
      "updated_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "name": "Thigh Adductor",
      "id": "102",
      "equipment_type": "Machine",
      "key": "lhEFvpiB2MrbarGIxqU8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "name": "Partner Sit-Up With High-Five",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "id": "1023",
      "key": "liNx8tICvLWcaJNUVJH7",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "name": "Biceps Curl To Shoulder Press",
      "muscle_type": "Biceps",
      "id": "96",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355440,
          "nanoseconds": 0
      },
      "key": "lj9lTMelmRPjRb1NmjuT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1581295382,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "id": "440",
      "name": "Barbell Flat Bench Press with Chains",
      "muscle_type": "Triceps",
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "key": "lntH4ckJuuxcxRxgSWel",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "muscle_type": "Neck",
      "name": "Isometric Neck Exercise - Sides",
      "id": "614",
      "key": "lo36Z3bgKOAHBoDYXDd3",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Dumbbell Prone Incline Curl",
      "more_time_is_pr": null,
      "id": "590",
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "equipment_type": "Dumbbell",
      "key": "lpfu1ojTtzeBrbs33BoW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "964",
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Burppe Box Jump",
      "key": "lvKhPFox17uRzfNDA5dc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "265",
      "muscle_type": "Biceps",
      "equipment_type": "Cable",
      "name": "Reverse Cable Curl",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "key": "lxgJRWj5ok1p70sK8nSz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1043",
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "name": "Hand Release Push-Up",
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "m0OAuyxRt2qbDSBAysx2",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "847",
      "name": "One-Arm Kettlebell Split Jerk",
      "muscle_type": "Shoulders",
      "more_time_is_pr": null,
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "key": "m5KgCdpOJXbABHPZowAu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1586267366,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "name": "Dumbbell Tricep Extension",
      "equipment_type": "Dumbbell",
      "id": "715",
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "key": "m6WOYYZPpcQXAjdCi35G",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "id": "150",
      "equipment_type": "Exercise Ball",
      "name": "Exercise Ball Pull-In",
      "key": "mBPcFWGT8xRbRN4PpLCZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "name": "Barbell Seated Calf Raise",
      "id": "596",
      "more_time_is_pr": null,
      "key": "mEgr1QafoDGHSQlKJFeq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "name": "Push Press",
      "updated_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "id": "29",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "key": "mIRP3dMN8d5reWFfoGzW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Plate Row",
      "more_time_is_pr": null,
      "muscle_type": "Middle Back",
      "equipment_type": "Barbell",
      "id": "1018",
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "key": "mOPsrC6NisHbrQL6IXfY",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355517,
          "nanoseconds": 0
      },
      "name": "Chest Push with Run Release",
      "equipment_type": "Medicine Ball",
      "id": "988",
      "key": "mQB8TgOLnHartcZVFwTD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "name": "Alien Squat",
      "id": "469",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "key": "mUlSbEwAaLJpw80jFy6Y",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1572985148,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1572985148,
          "nanoseconds": 0
      },
      "id": "1095",
      "muscle_type": "Quadriceps",
      "name": "Barbell 3-0-3 Tempo Back Squat",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "key": "mUmHjGX7MMBvIIfnTVih",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "id": "635",
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "name": "Suspended Push-Up",
      "key": "mYEzjdzKCPS0mkSbHeeX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Machine",
      "name": "Leverage Shoulder Press",
      "created_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "160",
      "updated_at": {
          "seconds": 1569355446,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "mZHUos84kpuYOjUophFG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355443,
          "nanoseconds": 0
      },
      "muscle_type": "Glutes",
      "name": "One-Legged Cable Kickback",
      "id": "127",
      "equipment_type": "Cable",
      "key": "mmWYCUpm6FyApWEobbnu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "105",
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "name": "Gorilla Chin/Crunch",
      "equipment_type": "Body Only",
      "key": "mu8qkBgzoSydVR2AGp63",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "id": "443",
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Bands",
      "name": "Internal Rotation with Band",
      "key": "mvYVV8a7K7vg5nZLJRnw",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Cable Hammer Curls - Rope Attachment",
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "id": "452",
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "more_time_is_pr": null,
      "key": "mzucVx8rqQ7EFbPE9IEa",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "922",
      "muscle_type": "Shoulders",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "name": "Dumbbell Squat To Shoulder Press",
      "key": "n9oTwmDNjeRPhn8XiNnX",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Palms-Up Dumbbell Wrist Curl Over A Bench",
      "created_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Forearms",
      "id": "282",
      "equipment_type": "Dumbbell",
      "key": "nAZKVNq1hd1s0j8rTM6S",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "id": "492",
      "muscle_type": "Triceps",
      "name": "Tate Press",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "key": "nG6cQYXUbWPBsk1fMqJt",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "id": "412",
      "equipment_type": "Machine",
      "name": "Leverage Decline Chest Press",
      "key": "nJ5NPHugJom1HSdCrSrL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "id": "225",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "name": "Drop Push",
      "equipment_type": "Other",
      "key": "nJYZl6Fob4EAp0MgGl5b",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "name": "Front Dumbbell Raise Using Towel",
      "id": "944",
      "created_at": {
          "seconds": 1569355513,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "key": "nL8sZYwgjOXJY5oG7lNP",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "id": "1083",
      "more_time_is_pr": null,
      "name": "Burpee Tuck Jump",
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "key": "nL9YQW2lXSoKevORGc0t",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Weighted Sit-Ups - With Bands",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "id": "516",
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "muscle_type": "Abdominals",
      "key": "nLlfm8RE7GppPSAlLDhJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "63",
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "name": "Power Clean from Blocks",
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "key": "nQAJZAK36QHIkkhPl1fz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "id": "813",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "muscle_type": "Glutes",
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "name": "Knee Across The Body",
      "key": "nSprKm5hfFPaxOOVzybN",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569378871,
          "nanoseconds": 0
      },
      "name": "Barbell Flat Bench Press",
      "equipment_type": "Barbell",
      "id": "114",
      "muscle_type": "Chest",
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "nUfqFU9PkwEtuu16IKPW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "241",
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "name": "Power Clean",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "key": "nZiAcpHI1b85xhh9Ve61",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "equipment_type": "Machine",
      "name": "Machine Squat",
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "id": "235",
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "key": "nZiRktLJeAd3ZpkqMRSa",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "id": "315",
      "name": "Split Squat with Dumbbells",
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "key": "nbxzX5jYhHmlOyHmKTOs",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "690",
      "name": "Shoulder Circles",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355492,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "ngwp34rNG61U7i30T720",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "id": "746",
      "name": "Dancers Stretch",
      "more_time_is_pr": null,
      "key": "nllfp6x5FDb99b9gJtWW",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "374",
      "name": "Band Skull Crusher",
      "equipment_type": "Bands",
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "key": "nlsyzOMWO8QXwfsRdIN2",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Standing Rope Crunch",
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "more_time_is_pr": null,
      "id": "295",
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "key": "nn0dcvEudfOakBQ1D3eV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Single-Cone Sprint Drill",
      "id": "884",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355508,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "key": "np0Z8IWuOFM75sfYWgKe",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Barbell Curls Lying Against An Incline",
      "id": "191",
      "key": "np4NWlUmZi8u9TeQRWzM",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Dynamic Back Stretch",
      "id": "779",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "more_time_is_pr": null,
      "key": "nvxoWKeHn32Mo6RkH1Fx",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "equipment_type": "Medicine Ball",
      "id": "916",
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "name": "Medicine-Ball Push-Up",
      "key": "o2aUhBnNmOqSzDf1r8iC",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "name": "Running, Treadmill",
      "equipment_type": "Time",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1614355014,
          "nanoseconds": 0
      },
      "more_time_is_pr": false,
      "id": "391",
      "key": "oArTf7lJCX9hjjSFma8L",
      "isBodyWeight": false,
      "isTimeBased": true
  },
  {
      "name": "Glute Bridge Hamstring Walkout",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "id": "895",
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "muscle_type": "Glutes",
      "key": "oFNp2BBPIHrusEAZBK2K",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "561",
      "equipment_type": "Other",
      "name": "Suspended Reverse Crunch",
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "key": "oMyrCeIrzJYU4RAOTKzJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "9",
      "equipment_type": "Medicine Ball",
      "name": "One-Arm Medicine Ball Slam",
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "key": "oOQK63pkFIK8YOZsEIGv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Kettlebells",
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "id": "633",
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "name": "Kettlebell Pass Between The Legs",
      "key": "oROiWQZsglaNLcHtG4rI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "id": "890",
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "name": "Sled Overhead Triceps Extension",
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "oUbf6AtsAON04856CBzE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "name": "Oblique Crunches - On The Floor",
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "id": "624",
      "key": "oV9ODJAuFCxuvnhTzBjX",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "id": "262",
      "name": "Barbell Step Ups",
      "key": "ocIDo1OmtFHHIW48QxLG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "name": "Dumbbell Alternating Lunge",
      "more_time_is_pr": null,
      "id": "1080",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "key": "ofs00rL5Ns9o9GbC2iN9",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355471,
          "nanoseconds": 0
      },
      "id": "454",
      "muscle_type": "Quadriceps",
      "name": "Cable Hip Adduction",
      "more_time_is_pr": null,
      "key": "ohFxNqSpkFCKeIGh5Emi",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "equipment_type": "Bands",
      "id": "550",
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "name": "Band Good Morning",
      "key": "ojdg95uYYtD0WYIgdCgK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Pushups",
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "id": "49",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "olFPHpMWJuGPzcTVNYQk",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Middle Back Shrug",
      "id": "424",
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "key": "oo0sWFOYsOO7fDyO3VGx",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "621",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "name": "Prowler Sprint",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "key": "orZIdKn0AIb7PH3GKzIc",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "name": "Jump Squat",
      "id": "593",
      "key": "oyGH5uxxAEExQVnv8lf2",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "equipment_type": "Dumbbell",
      "name": "Calf Raise On A Dumbbell",
      "id": "780",
      "key": "p03TdDvYU5NHpWJE4fkI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "name": "Dumbbell Step Ups",
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "id": "471",
      "muscle_type": "Quadriceps",
      "key": "p2qcWsIuvOyfjYciZYX1",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Close-Grip EZ Bar Press",
      "updated_at": {
          "seconds": 1586265320,
          "nanoseconds": 0
      },
      "equipment_type": "E-Z Curl Bar",
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "id": "202",
      "more_time_is_pr": null,
      "key": "p5I35VnoD8OAc5nFy8zG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "id": "767",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "name": "All Fours Quad Stretch",
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "key": "p6NdH6U6Fysmx8nQ9cE1",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355473,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "478",
      "muscle_type": "Shoulders",
      "equipment_type": "Kettlebells",
      "name": "Kettlebell Turkish Get-Up (Lunge style)",
      "key": "p6ngMnorV0zDx9vpRUgB",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Close-Hands Push-Up",
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "id": "565",
      "muscle_type": "Chest",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "key": "p9boMLnObr2EtwT0Ew0z",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "id": "854",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355506,
          "nanoseconds": 0
      },
      "name": "Good Morning off Pins",
      "muscle_type": "Hamstrings",
      "equipment_type": "Barbell",
      "key": "p9vew5tD5VwsRsxe0BNw",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Butt Kicks",
      "muscle_type": "Quadriceps",
      "equipment_type": "Body Only",
      "id": "465",
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "key": "pAv2E7wLULHZTFw8EjI4",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "id": "299",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "name": "Wide-Grip Lat Pulldown",
      "key": "pCRQ9WKKczoiZ8HEDlaf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Middle Back",
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "id": "333",
      "equipment_type": "Kettlebells",
      "name": "Alternating Renegade Row",
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "pEFOnUQsgMbxCPYPsoG9",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "name": "Backward Drag",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "id": "680",
      "key": "pH0WC0gdomF0P543MdNO",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Triceps",
      "id": "645",
      "name": "Close-Grip Push-Up off of a Dumbbell",
      "more_time_is_pr": null,
      "key": "pJvfhyfMZ6mAHThlemkG",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "id": "521",
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "name": "Rocket Jump",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "key": "pPmFcJslxk1NP7ivBvFc",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355454,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "name": "Hang Snatch",
      "id": "254",
      "key": "pT49r48BldviDnvZZxHm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Seated Hamstring",
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "id": "759",
      "key": "pYGTXYcll4zQCuLgESht",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "id": "373",
      "muscle_type": "Hamstrings",
      "name": "Glute Ham Raise",
      "more_time_is_pr": null,
      "key": "pZDVyJzBe9RorK3JXLmD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "423",
      "equipment_type": "Machine",
      "muscle_type": "Chest",
      "name": "Smith Machine Bench Press",
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "paWmRj3EAfBqS7JcWvJg",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "31",
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Clean and Jerk",
      "key": "pbaFhRbMs4JCOagSedLq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Machine",
      "id": "341",
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "name": "Leg Extensions",
      "muscle_type": "Quadriceps",
      "key": "pf9xcf6CP8fGUAkmMiRC",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Lying Face Down Plate Neck Resistance",
      "muscle_type": "Neck",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355434,
          "nanoseconds": 0
      },
      "id": "27",
      "key": "phTlgQbKI3lOafHEoH59",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "70",
      "updated_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Wrist Roller",
      "muscle_type": "Forearms",
      "created_at": {
          "seconds": 1569355438,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "key": "phm2Me8STcDotmysfuDh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Exercise Ball",
      "id": "198",
      "name": "Ball Leg Curl",
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "key": "pnp6Nz00vqMUlBSTzI1K",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "equipment_type": "Body Only",
      "name": "Bodyweight Squat",
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "id": "226",
      "key": "puwwuDJzimYl0KXpoCRH",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Clean",
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "id": "334",
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "key": "pxA9LmSapMkjJFltr1aV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "equipment_type": "Dumbbell",
      "id": "113",
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "name": "Standing Dumbbell Triceps Extension",
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "pycS0euWfqmjxmCYzay6",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "name": "Single Arm Overhead Kettlebell Squat",
      "equipment_type": "Kettlebells",
      "more_time_is_pr": null,
      "id": "893",
      "key": "qE2SX1bqclDQHIt4XKWJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "name": "Lying One-Arm Lateral Raise",
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "id": "545",
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "key": "qGzxE0XtLwi2Pp96GY5d",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "310",
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "name": "One-Arm Kettlebell Snatch",
      "muscle_type": "Shoulders",
      "key": "qIWmb73YYa8MyZOMXkZh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "muscle_type": "Quadriceps",
      "id": "184",
      "name": "Dumbbell Lunges",
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "qWN17B0RQ4NBgF4Qacra",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Around The World Pullup",
      "updated_at": {
          "seconds": 1581296008,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "id": "950",
      "key": "qYn1NWTK7Lsg28XRIuHM",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Smith Machine Leg Press",
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "id": "522",
      "muscle_type": "Quadriceps",
      "key": "qctCfegQTSyWEnfsdqnu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Chest Push (multiple response)",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "equipment_type": "Medicine Ball",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "id": "441",
      "key": "qcz0WTzqp6RVFoQ36L6r",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355491,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "681",
      "more_time_is_pr": null,
      "name": "Alternate Leg Diagonal Bound",
      "key": "qdFEEiBdSxQcG2YfU6eV",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Bands",
      "muscle_type": "Shoulders",
      "id": "597",
      "name": "Shoulder Press - With Bands",
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "key": "qeWaN6u2ozjWCxJNQDtE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "id": "388",
      "name": "Seated Bent-Over Rear Delt Raise",
      "key": "qeZpEPIKbBU1M7Ew2ZLr",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "897",
      "created_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "name": "Punches",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355509,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "key": "qjQj6pvarQPiqmuy0TOw",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "name": "Dead Bug",
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "297",
      "key": "qlPsbEKVCZpbBJP15LyD",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "212",
      "name": "Cable Crossover",
      "key": "qmMa4yoA3qG3I1oPwW2D",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "name": "Standing Cable Lift",
      "id": "43",
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "key": "qmPAsmXSWP3pIfOVyfiE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "name": "Alternating Kettlebell Row",
      "equipment_type": "Kettlebells",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355479,
          "nanoseconds": 0
      },
      "id": "541",
      "key": "qnyMQLZ1goKFEc1Wl3Ep",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "name": "Dumbbell Jump Squat",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "id": "1064",
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "key": "qp8KR4fWD1ioqqtyNMUo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "676",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Smith Machine Pistol Squat",
      "equipment_type": "Machine",
      "key": "qrGWO5fURar30tUKs7eI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "611",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "name": "Split Jump",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "key": "qrS61RFuZ8LDSVeoTbev",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "id": "298",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "name": "Standing Olympic Plate Hand Squeeze",
      "more_time_is_pr": null,
      "muscle_type": "Forearms",
      "key": "r1OPwvAXObKAi3mG6Jgi",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "id": "397",
      "equipment_type": "Dumbbell",
      "name": "Incline Dumbbell Flyes - With A Twist",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "key": "r4MbnykPfBb3f4QqBEQE",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Cable Iron Cross",
      "created_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "395",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355466,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "key": "r7ighGy6i12NYIizfzxl",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Snatch-Grip Behind-The-Neck Overhead Press",
      "id": "141",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355444,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "key": "rBV9Xaf84YREimwfVkEz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "id": "622",
      "equipment_type": "Barbell",
      "name": "Barbell Front Raise",
      "updated_at": {
          "seconds": 1569355486,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "rDJPLPcLiI7lzh9dOZz1",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "34",
      "name": "Suspended Fallout",
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "equipment_type": "Other",
      "key": "rFBcXYteQ6ZgUZKu29Hg",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "215",
      "created_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355450,
          "nanoseconds": 0
      },
      "name": "Preacher Curl",
      "muscle_type": "Biceps",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "key": "rFxkBlGiLl22CBkzJJcI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "id": "973",
      "name": "Kettlebell Seesaw Press",
      "updated_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355516,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "key": "rIA07zhmo5Fx9dzX0OVJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "equipment_type": "Dumbbell",
      "name": "Lateral Hop 4 Times To Sprint",
      "id": "1067",
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "key": "rLU4bmuqBg5xfYJ3ocVs",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Iron Cross",
      "equipment_type": "Dumbbell",
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "id": "425",
      "muscle_type": "Shoulders",
      "key": "rSiBXAqA0dPdq5FKFci3",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "name": "Decline Reverse Crunch",
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "id": "53",
      "more_time_is_pr": null,
      "key": "raI3y9mvSGoIQ51Y94QU",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Childs Pose",
      "equipment_type": "Body Only",
      "id": "755",
      "muscle_type": "Lower Back",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "key": "rehZZNnox5VGMc1gUzeT",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "id": "348",
      "name": "Butterfly",
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Chest",
      "key": "rpN4vPyDAo4DCUoo10QA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "190",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "name": "Narrow Stance Leg Press",
      "key": "rpYUfEUfYHSW50BZS0qU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355478,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Alternate Heel Touchers",
      "id": "530",
      "muscle_type": "Abdominals",
      "key": "rxzJNEndSufZB2ptFVz1",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "id": "37",
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "name": "Standing Military Press",
      "muscle_type": "Shoulders",
      "key": "ryaGrBNWKTEHnfQQ3dHT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "muscle_type": "Shoulders",
      "id": "444",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "name": "Straight Raises on Incline Bench",
      "key": "s2IQA8ooDlq07gJPp2CD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "name": "Plank",
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "equipment_type": "Time",
      "updated_at": {
          "seconds": 1589246570,
          "nanoseconds": 0
      },
      "more_time_is_pr": true,
      "id": "42",
      "key": "s7NPWerLjT3pIbZQQWbS",
      "isBodyWeight": false,
      "isTimeBased": true
  },
  {
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "name": "Dumbbell Walking Lunge",
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "id": "636",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "key": "sAiXQdLsLsw88hgD1mNm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "name": "Dumbbell Squat Snatch",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "965",
      "key": "sLYui6hmmD1MYZHA3iMN",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Man Maker",
      "equipment_type": "Dumbbell",
      "id": "378",
      "updated_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355465,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "more_time_is_pr": null,
      "key": "sN0HNTdKsLWgl7zwSD4J",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "id": "401",
      "name": "Plate Pinch",
      "created_at": {
          "seconds": 1569355467,
          "nanoseconds": 0
      },
      "key": "sPaplDJXRPRqsr0PkRKu",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "name": "Around The Worlds",
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "id": "587",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "key": "sPpI2fK5CNSCnqpejwei",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "name": "Dumbbell Goblet Squat",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "id": "181",
      "equipment_type": "Dumbbell",
      "muscle_type": "Quadriceps",
      "key": "sTR9k6qfZUYMOoF2Rn4n",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "id": "1047",
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Waiters Carry",
      "key": "sVyxXdydGlHnviGUdlJZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "name": "Elbow Circles",
      "id": "758",
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "key": "sg5CE4H3RE4fVWJRHsww",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "One Half Locust",
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "784",
      "equipment_type": "Body Only",
      "key": "sm5JIovGUM4CGVK9fjWz",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "1077",
      "muscle_type": "Biceps",
      "name": "Seated Straight-Bar Curl Superset",
      "updated_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355525,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "key": "stLfak3GnilzSVV9KcvS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "id": "61",
      "muscle_type": "Shoulders",
      "name": "Seated Barbell Military Press",
      "equipment_type": "Barbell",
      "key": "t784SUdmL974CWEtdA9E",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Triceps",
      "updated_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355476,
          "nanoseconds": 0
      },
      "id": "504",
      "equipment_type": "E-Z Curl Bar",
      "name": "Lying Triceps Press",
      "more_time_is_pr": null,
      "key": "tDxXrEyDkBFo2Zpic4CI",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Chest",
      "equipment_type": "Machine",
      "name": "Leverage Incline Chest Press",
      "id": "292",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "key": "tFdlOr7bUZfPtxhjKg0m",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "640",
      "name": "Smith Machine Reverse Calf Raises",
      "updated_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "muscle_type": "Calves",
      "created_at": {
          "seconds": 1569355487,
          "nanoseconds": 0
      },
      "key": "tHDTI19KHcQJh8uvFA5T",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "id": "324",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "name": "Push-Ups With Feet Elevated",
      "key": "tJJsWuYopqVGV6nG0tBf",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "One-Arm Kettlebell Military Press To The Side",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "id": "844",
      "muscle_type": "Shoulders",
      "equipment_type": "Kettlebells",
      "key": "tMJrf8va0vhnS5cHUpjT",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "967",
      "name": "Bear Crawl Shoulder Tap",
      "muscle_type": "Shoulders",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "key": "tNDFApRt3lZtZ7J2nDna",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Dynamic Chest Stretch",
      "equipment_type": "Body Only",
      "id": "734",
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "key": "tQnvSMjtuQGn4bZZuOGp",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "muscle_type": "Traps",
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "name": "Leverage Shrug",
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "id": "78",
      "key": "tQzLW7lUf2KYLhJTX4tL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "name": "Bosu Ball Crunch",
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "equipment_type": "Exercise Ball",
      "muscle_type": "Abdominals",
      "id": "929",
      "more_time_is_pr": null,
      "key": "tTJy1sBk0VKhLPGrNwjm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "name": "Side Bridge",
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "608",
      "key": "tapWcW3vQPxA7I7a9ZQB",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "equipment_type": "Cable",
      "more_time_is_pr": null,
      "name": "Overhead Cable Curl",
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "id": "107",
      "muscle_type": "Biceps",
      "key": "tdyRzETXlg87qawHWNQq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "name": "Dumbbell Shrug",
      "more_time_is_pr": null,
      "muscle_type": "Traps",
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "id": "264",
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "key": "tg842FNoLCZBi1I2lArJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "772",
      "muscle_type": "Calves",
      "name": "Calf Stretch Elbows Against Wall",
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "tgHEe6xlirqR0QpytLxY",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "604",
      "name": "Two-Arm Kettlebell Clean",
      "updated_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "equipment_type": "Kettlebells",
      "created_at": {
          "seconds": 1569355484,
          "nanoseconds": 0
      },
      "key": "tjIuqh8u0Sjb7K1PtleS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Rope Climb",
      "muscle_type": "Lats",
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "id": "278",
      "key": "tkpIrUAk4p8LNSQIobUY",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "id": "147",
      "name": "Smith Machine Overhead Shoulder Press",
      "equipment_type": "Machine",
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "key": "to0BIWFgT8SXWWWju8Ib",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "id": "873",
      "name": "Return Push from Stance",
      "more_time_is_pr": null,
      "equipment_type": "Medicine Ball",
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "key": "toR23cRq9ZoZmUoHAlmo",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Palms-Up Barbell Wrist Curl Over A Bench",
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Forearms",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "id": "17",
      "key": "tv8n9p8oybJP4jPNTDxA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Butt Lift (Bridge)",
      "updated_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "muscle_type": "Glutes",
      "equipment_type": "Body Only",
      "id": "186",
      "created_at": {
          "seconds": 1569355448,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "tvygCSTsTdrrynxz1fAr",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355442,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "name": "Seated One-Arm Dumbbell Palms-Up Wrist Curl",
      "id": "116",
      "equipment_type": "Dumbbell",
      "key": "u0NTLOIu1yWJKIDb2sAA",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "equipment_type": "Body Only",
      "id": "910",
      "name": "Partner Supermans With Alternating High-Five",
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "key": "u1wFSeTmlGMNdxhjFhLW",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "870",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "name": "Reverse Band Sumo Deadlift",
      "muscle_type": "Hamstrings",
      "created_at": {
          "seconds": 1569355507,
          "nanoseconds": 0
      },
      "key": "u5wBcRPjGDnN2txOPjFJ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Lats",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "id": "343",
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Underhand Cable Pulldowns",
      "key": "u6CF0t9ryQZSTEme55US",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "E-Z Curl Bar",
      "updated_at": {
          "seconds": 1586265316,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "554",
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "name": "Close-Grip EZ Bar Curl with Band",
      "key": "u6vrbwzYqifIHFwM3608",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "id": "459",
      "name": "Inverted Row",
      "updated_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "uHLoUG9cQUVw5nJondy7",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "equipment_type": "Dumbbell",
      "id": "617",
      "name": "Standing Inner-Biceps Curl",
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "key": "uQCt14UTFpxRnYGqEY6T",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "id": "1001",
      "name": "Sled Row",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355518,
          "nanoseconds": 0
      },
      "muscle_type": "Middle Back",
      "key": "uSiGp8QZRcSkFPPJOd9n",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "id": "909",
      "name": "Straight-Legged Hip Raise",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "key": "uUYn3EU9b59ScTtz2Cmc",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "id": "411",
      "name": "Band Good Morning (Pull Through)",
      "more_time_is_pr": null,
      "equipment_type": "Bands",
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "key": "uXV9aUgP5XA0Mid2brUD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Two-Arm Kettlebell Military Press",
      "more_time_is_pr": null,
      "id": "274",
      "updated_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "created_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "key": "udCG0YgVtwlwIlAfcVEU",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "798",
      "equipment_type": "Body Only",
      "muscle_type": "Shoulders",
      "name": "Shoulder Raise",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "key": "ugplcv9zndeHwhOw02mv",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Front Leg Raises",
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "id": "370",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "key": "uh5oq95FnjzWLFO9ddZJ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "719",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "name": "Seated Dumbbell Palms-Up Wrist Curl",
      "updated_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355494,
          "nanoseconds": 0
      },
      "muscle_type": "Forearms",
      "key": "ullvU7tC59f1BcVcndQH",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abductors",
      "updated_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "id": "1027",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "name": "Hip Circle",
      "created_at": {
          "seconds": 1569355521,
          "nanoseconds": 0
      },
      "key": "umUnhqodkeol3BzlMYbB",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355526,
          "nanoseconds": 0
      },
      "name": "Full Moon",
      "more_time_is_pr": null,
      "muscle_type": "Abdominals",
      "id": "1086",
      "key": "uoGkDZ93wAH6zYdDbnBs",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Alternating Floor Press",
      "muscle_type": "Chest",
      "id": "725",
      "updated_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355495,
          "nanoseconds": 0
      },
      "key": "uqNY3TLd3NZ1AbnfFYYD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Elevated Cable Rows",
      "muscle_type": "Lats",
      "id": "433",
      "more_time_is_pr": null,
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "key": "uuMamLrRkHdsBWFLnc1F",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Glutes",
      "name": "Ankle On The Knee",
      "updated_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "id": "781",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355499,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "ux0n43CGsFPpuwSGb3Z9",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355498,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "name": "Iron Crosses (stretch)",
      "id": "762",
      "equipment_type": "Body Only",
      "key": "v0HJb088i82fY1Zz7Zux",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "13",
      "muscle_type": "Abdominals",
      "name": "Landmine 180s",
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "key": "v0s6yS55a468imcrpOqG",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "name": "Square Hop",
      "more_time_is_pr": null,
      "id": "901",
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355510,
          "nanoseconds": 0
      },
      "key": "v3T8CJQNwuaBh4YgTGC5",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "783",
      "name": "Seated Barbell Twist",
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "key": "v4HQc7CavKwJoVpRzCk5",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "651",
      "name": "Machine Lateral Raise",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "muscle_type": "Shoulders",
      "key": "v5jzl7tHRl4yX9XCN9n7",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "id": "1016",
      "name": "Front-To-Back Squat With Belt",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355520,
          "nanoseconds": 0
      },
      "key": "v6xo9fyeEZXK1JLEPF4G",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "id": "577",
      "name": "Frog Sit-Ups",
      "updated_at": {
          "seconds": 1569355482,
          "nanoseconds": 0
      },
      "key": "vAiLHti1zCVHk5F3K8bD",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "more_time_is_pr": null,
      "name": "Battling Ropes",
      "muscle_type": "Shoulders",
      "updated_at": {
          "seconds": 1569355460,
          "nanoseconds": 0
      },
      "id": "319",
      "key": "vNH0OIJI7fujFP5lcmfN",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "id": "571",
      "more_time_is_pr": null,
      "name": "Flat Bench Leg Pull-In",
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "key": "vO9siLsgUXvPb5Vl96z1",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "name": "Rear Leg Raises",
      "id": "815",
      "updated_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355502,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "key": "vVHwltOe0ega1SU5tfwU",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Hamstrings",
      "updated_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355461,
          "nanoseconds": 0
      },
      "id": "335",
      "equipment_type": "Barbell",
      "name": "Good Morning",
      "more_time_is_pr": null,
      "key": "vjyYHfmB5tHA7f21PlFl",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "id": "106",
      "more_time_is_pr": null,
      "name": "Decline Crunch",
      "updated_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "muscle_type": "Abdominals",
      "key": "vpwgZXtP0OSCD8XUesuO",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Side Lateral Raise",
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "muscle_type": "Shoulders",
      "id": "308",
      "created_at": {
          "seconds": 1569355459,
          "nanoseconds": 0
      },
      "key": "vuGIp3Y70pf1oZPT1Pjk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "id": "524",
      "name": "Bodyweight Walking Lunge",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355477,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "key": "w2RfA9hOTa3WHdJexY19",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "35",
      "created_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355435,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "equipment_type": "Barbell",
      "name": "Wide-Grip Standing Barbell Curl",
      "more_time_is_pr": null,
      "key": "w5ioi6PpIzPuAZ5FOYDh",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Two-Arm Dumbbell Preacher Curl",
      "updated_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "muscle_type": "Biceps",
      "created_at": {
          "seconds": 1569355469,
          "nanoseconds": 0
      },
      "id": "430",
      "key": "w9GtrVCOurSM426Irlhd",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "352",
      "equipment_type": "Dumbbell",
      "muscle_type": "Triceps",
      "name": "Dumbbell One-Arm Triceps Extension",
      "updated_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355462,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "wGQ6quvgOLbHbba2O3yv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Shoulders",
      "equipment_type": "Dumbbell",
      "name": "Standing Palm-In One-Arm Dumbbell Press",
      "id": "16",
      "created_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355433,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "wJw8CuTiHEcvNmorzWRz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "591",
      "name": "Band Assisted Pullup",
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "muscle_type": "Lats",
      "updated_at": {
          "seconds": 1581296014,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "wUBs8xMiPt3ybRDZSp3D",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "created_at": {
          "seconds": 1569355496,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "id": "740",
      "name": "Chain Press",
      "key": "wXf7QYC74YDvdeigaRnL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "933",
      "muscle_type": "Shoulders",
      "name": "Hand Stand Push-Up",
      "key": "waWvaTtmDjXx4MtjqqXh",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "id": "361",
      "name": "Hack Squat",
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "key": "wgZeFfAUBWgKvngla1Mq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Foam Roll",
      "name": "Adductor",
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "id": "801",
      "muscle_type": "Adductors",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "key": "wgg5wwoAXuZiQH026HaQ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "id": "835",
      "muscle_type": "Abductors",
      "updated_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355504,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "IT Band and Glute Stretch",
      "key": "wjjYnl7ixhbIjrzz0lJk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "283",
      "name": "Dumbbell Side Bend",
      "updated_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355456,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "wmrd8ZzpRnlEoJEVcmJN",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "1106",
      "updated_at": {
          "seconds": 1586267499,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1586267499,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "name": "Reverse Grip Bench Press",
      "equipment_type": "Barbell",
      "key": "wy1s2xHKmF46hitDxaZS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "558",
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "equipment_type": "Body Only",
      "name": "Leg-Up Hamstring Stretch",
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "key": "wyy7s89vQ5rpL05JLLRD",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "917",
      "more_time_is_pr": null,
      "name": "Partner Flat-Bench Back Extension",
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "muscle_type": "Lower Back",
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "key": "wzl169Yy0LylihCjx8Hi",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355453,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1581296232,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "id": "240",
      "name": "Incline Dumbbell Curl",
      "key": "x0bRFbLYtTTPL3BYaSRV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "912",
      "updated_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355511,
          "nanoseconds": 0
      },
      "name": "Partner Facing Side Plank With Band Row",
      "equipment_type": "Bands",
      "more_time_is_pr": null,
      "muscle_type": "Middle Back",
      "key": "x4R66Sfw7Fb2lCy1swVD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355436,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "name": "Dumbbell Bench Press",
      "id": "47",
      "muscle_type": "Chest",
      "more_time_is_pr": null,
      "key": "xDNpnvSOdKmRAcu6nV0c",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Other",
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "name": "Atlas Stone Trainer",
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "id": "711",
      "muscle_type": "Lower Back",
      "more_time_is_pr": null,
      "key": "xGH2xi79msSc0NtPQfBD",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Body Only",
      "muscle_type": "Calves",
      "id": "797",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "name": "Seated Calf Stretch",
      "updated_at": {
          "seconds": 1569355501,
          "nanoseconds": 0
      },
      "key": "xGWFdImF5vyqqppIiCg0",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "muscle_type": "Quadriceps",
      "name": "Cable Deadlifts",
      "updated_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355483,
          "nanoseconds": 0
      },
      "id": "585",
      "equipment_type": "Cable",
      "key": "xHm39p6Eq4xEqWkoRCgr",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "932",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "name": "Burpee Over Barbell",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "key": "xIa37IrfcZyiCCsXs6BK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Wrist Rotations with Straight Bar",
      "id": "5",
      "created_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355432,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "muscle_type": "Forearms",
      "more_time_is_pr": null,
      "key": "xU69q6Ey3yxxdNTyL4ys",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "updated_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "name": "Standing One-Arm Dumbbell Curl Over Incline Bench",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355458,
          "nanoseconds": 0
      },
      "muscle_type": "Biceps",
      "id": "305",
      "key": "xUFEdw3soSIfBVnUDWO4",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "id": "289",
      "muscle_type": "Shoulders",
      "name": "Kettlebell Turkish Get-Up (Squat style)",
      "equipment_type": "Kettlebells",
      "created_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355457,
          "nanoseconds": 0
      },
      "key": "xUs3GuYdVMZjoLvutiPQ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "name": "Double Leg Butt Kick",
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355449,
          "nanoseconds": 0
      },
      "id": "196",
      "key": "xgzx2tq47fzMClsDLduk",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "equipment_type": "Body Only",
      "id": "961",
      "updated_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "name": "Breakdancer",
      "created_at": {
          "seconds": 1569355515,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "xnhQjUk2L3ImrMdMQoCZ",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "name": "Incline Barbell Triceps Extension",
      "updated_at": {
          "seconds": 1569355447,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "id": "177",
      "muscle_type": "Triceps",
      "key": "xqbnUM7jFcrx1D1tdVRS",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "486",
      "equipment_type": "Kettlebells",
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "name": "Single Leg Deadlift",
      "key": "xtBZclQ9qiGAanmh1ywW",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Double Kettlebell Push Press",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "equipment_type": "Kettlebells",
      "created_at": {
          "seconds": 1569355485,
          "nanoseconds": 0
      },
      "id": "610",
      "muscle_type": "Shoulders",
      "key": "xuab978Vqul4g9j3qnQg",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Wide-Stance Jump Squat To Close-Stance",
      "updated_at": {
          "seconds": 1569355524,
          "nanoseconds": 0
      },
      "id": "1071",
      "equipment_type": "Body Only",
      "muscle_type": "Quadriceps",
      "key": "y4ISYnSFG0sZb5YBLcVN",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355522,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "name": "Burpee Pullup",
      "muscle_type": "Lats",
      "id": "1041",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1581296026,
          "nanoseconds": 0
      },
      "key": "y4xbONNF4C2a3zxIUJQk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Skating",
      "id": "371",
      "updated_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "equipment_type": "Other",
      "muscle_type": "Quadriceps",
      "created_at": {
          "seconds": 1569355464,
          "nanoseconds": 0
      },
      "key": "y5VnBPfSE1KDQzsuNZ5Z",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355472,
          "nanoseconds": 0
      },
      "equipment_type": "Machine",
      "name": "Walking, Treadmill",
      "more_time_is_pr": null,
      "id": "458",
      "key": "y6gp2eubV06aEgDFwUpr",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "1098",
      "name": "Romanian Deadlift",
      "equipment_type": "Barbell",
      "more_time_is_pr": null,
      "muscle_type": "Hamstrings",
      "created_at": {
          "seconds": 1586097553,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1586097553,
          "nanoseconds": 0
      },
      "key": "yBpNdyjQ0Uif671WMJ4u",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Reverse Grip Triceps Pushdown",
      "id": "104",
      "equipment_type": "Cable",
      "updated_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355441,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "key": "yMVU4i2k7rpXw0pBXMoZ",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "435",
      "equipment_type": "Exercise Ball",
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "name": "Weighted Ball Hyperextension",
      "muscle_type": "Lower Back",
      "more_time_is_pr": null,
      "key": "ySgays4l31O5b4dmneXL",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "id": "485",
      "muscle_type": "Shoulders",
      "name": "Anti-Gravity Press",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355474,
          "nanoseconds": 0
      },
      "key": "yVHP7RW5EkaY2i7r5KMm",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Kettlebells",
      "name": "Kettlebell Dead Clean",
      "updated_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355497,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "id": "752",
      "key": "yXoKohicUUf24oYUbVfz",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "more_time_is_pr": null,
      "equipment_type": "Barbell",
      "name": "Floor Press",
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "id": "439",
      "key": "yaDw8rVJ70uE34AhcBNV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Straight-Arm Pulldown",
      "id": "418",
      "muscle_type": "Lats",
      "equipment_type": "Cable",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355468,
          "nanoseconds": 0
      },
      "key": "yhMa5dluA8x8cUYZLjtv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "555",
      "name": "Kneeling High Pulley Row",
      "equipment_type": "Cable",
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Lats",
      "key": "yne3Kjesi778jZWgePZf",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "updated_at": {
          "seconds": 1569355503,
          "nanoseconds": 0
      },
      "name": "Standing Lateral Stretch",
      "id": "821",
      "muscle_type": "Abdominals",
      "more_time_is_pr": null,
      "key": "yutG7f8CATCwu4eVjTF3",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Hanging Leg Raise",
      "equipment_type": "Body Only",
      "id": "154",
      "key": "yvg8eT4K89p262A3kEKt",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "muscle_type": "Triceps",
      "name": "V-Sit Dumbbell Triceps Extension",
      "id": "946",
      "created_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355514,
          "nanoseconds": 0
      },
      "key": "ywTyxMfkMxdcHnAXqy3Q",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "updated_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "name": "Scissor Kick",
      "equipment_type": "Body Only",
      "muscle_type": "Abdominals",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "id": "668",
      "more_time_is_pr": null,
      "key": "z0c6uStesCdV8pfdS0Aw",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "id": "442",
      "more_time_is_pr": null,
      "name": "Incline Push-Up Depth Jump",
      "muscle_type": "Chest",
      "updated_at": {
          "seconds": 1569355470,
          "nanoseconds": 0
      },
      "key": "z1xNO3h1n4u5iorJkly8",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Medicine Ball",
      "created_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "name": "Overhead Slam",
      "more_time_is_pr": null,
      "muscle_type": "Lats",
      "id": "552",
      "updated_at": {
          "seconds": 1569355480,
          "nanoseconds": 0
      },
      "key": "z8HwBaNLwbZHnv1k52dV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "id": "223",
      "updated_at": {
          "seconds": 1569355451,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "muscle_type": "Shoulders",
      "name": "Front Two-Dumbbell Raise",
      "key": "z8djxOIhwdlfxaEJtOnq",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "name": "Freehand Jump Squat",
      "updated_at": {
          "seconds": 1569355463,
          "nanoseconds": 0
      },
      "id": "363",
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "key": "zEUoR4L1q2uUUmimGG9G",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "equipment_type": "Dumbbell",
      "muscle_type": "Quadriceps",
      "id": "239",
      "name": "Dumbbell Rear Lunge",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355452,
          "nanoseconds": 0
      },
      "key": "zJlFccjMjHqrLfg2c5jv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "id": "81",
      "name": "Elbow to Knee",
      "muscle_type": "Abdominals",
      "updated_at": {
          "seconds": 1569355439,
          "nanoseconds": 0
      },
      "key": "zLNngvPqSErpLKUg666z",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "updated_at": {
          "seconds": 1569355481,
          "nanoseconds": 0
      },
      "id": "572",
      "muscle_type": "Quadriceps",
      "more_time_is_pr": null,
      "name": "Speed Squats",
      "key": "zLvXX1V2oHaz0fm5PcZV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Forearms",
      "id": "157",
      "more_time_is_pr": null,
      "equipment_type": "Dumbbell",
      "name": "Dumbbell Lying Supination",
      "updated_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355445,
          "nanoseconds": 0
      },
      "key": "zRfMwu9nSeUH5TeDNOlK",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "name": "Knee Tuck Jump",
      "updated_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "id": "497",
      "equipment_type": "Body Only",
      "created_at": {
          "seconds": 1569355475,
          "nanoseconds": 0
      },
      "key": "zWdUpO86kvvoig4jy4cw",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "id": "647",
      "name": "Dumbbell Seated One-Leg Calf Raise",
      "created_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "equipment_type": "Dumbbell",
      "more_time_is_pr": null,
      "muscle_type": "Calves",
      "updated_at": {
          "seconds": 1569355488,
          "nanoseconds": 0
      },
      "key": "zeYb55H3Z6KCo6DnwmXs",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "muscle_type": "Hamstrings",
      "name": "Worlds Greatest Stretch",
      "equipment_type": "Body Only",
      "id": "791",
      "created_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355500,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "key": "zecoRvmlbVFi7GckKmOC",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "muscle_type": "Hamstrings",
      "name": "Suspended Leg Curl",
      "id": "927",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355512,
          "nanoseconds": 0
      },
      "equipment_type": "Other",
      "key": "zfmKM84Z7J4vQ2vOTphb",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "equipment_type": "Barbell",
      "muscle_type": "Quadriceps",
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "more_time_is_pr": null,
      "name": "Barbell Front Squat",
      "id": "1055",
      "key": "zfmSqjaLx5IfJYV45Biv",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "710",
      "name": "Peroneals-SMR",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "muscle_type": "Calves",
      "updated_at": {
          "seconds": 1569355493,
          "nanoseconds": 0
      },
      "equipment_type": "Foam Roll",
      "key": "zp0D7hPtIakooTPdeuNt",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "id": "62",
      "more_time_is_pr": null,
      "name": "Rope Jumping",
      "updated_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "created_at": {
          "seconds": 1569355437,
          "nanoseconds": 0
      },
      "muscle_type": "Quadriceps",
      "equipment_type": "Other",
      "key": "zpwp50QNdUPDMnVnyYeV",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Kettlebells",
      "muscle_type": "Chest",
      "id": "848",
      "created_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "updated_at": {
          "seconds": 1569355505,
          "nanoseconds": 0
      },
      "name": "Leg-Over Floor Press",
      "key": "zr2pVN2YELN9zQeQZ6ti",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "id": "1053",
      "name": "Dive Bomber Push-Up",
      "more_time_is_pr": null,
      "updated_at": {
          "seconds": 1569355523,
          "nanoseconds": 0
      },
      "muscle_type": "Chest",
      "equipment_type": "Body Only",
      "key": "zrvVjkVglTSNTuYhwsCC",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "created_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "equipment_type": "Barbell",
      "id": "272",
      "more_time_is_pr": null,
      "name": "Lying Close-Grip Barbell Triceps Extension Behind The Head",
      "updated_at": {
          "seconds": 1569355455,
          "nanoseconds": 0
      },
      "muscle_type": "Triceps",
      "key": "ztb1j10naPQWq4PnJfxk",
      "isBodyWeight": false,
      "isTimeBased": false
  },
  {
      "name": "Rack Pullups",
      "id": "1099",
      "updated_at": {
          "seconds": 1586264756,
          "nanoseconds": 0
      },
      "equipment_type": "Body Only",
      "more_time_is_pr": null,
      "created_at": {
          "seconds": 1586097677,
          "nanoseconds": 0
      },
      "muscle_type": "Lats",
      "key": "ztt4PlsMWIz1IMGjsEvX",
      "isBodyWeight": true,
      "isTimeBased": false
  },
  {
      "more_time_is_pr": null,
      "equipment_type": "Machine",
      "created_at": {
          "seconds": 1569355490,
          "nanoseconds": 0
      },
      "id": "667",
      "updated_at": {
          "seconds": 1586265426,
          "nanoseconds": 0
      },
      "name": "Smith Machine Stiff Legged Deadlift",
      "muscle_type": "Hamstrings",
      "key": "zyVWyP3wTLNQJnKcBFvl",
      "isBodyWeight": false,
      "isTimeBased": false
  }
]
export default defaultExercises;