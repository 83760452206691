import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { bodyParts, muscleTypes } from '../../utils/exerciseFilters';

const MuscleTypeFilter = ({
  muscleType,
  onMuscleTypeClick,
  theme,
  onBodyPartClick,
  bodyPart,
}) => {
  const options =
    theme.mode === 'weightlifter' ? muscleTypes : Object.keys(bodyParts);
  const onClickAction =
    theme.mode === 'weightlifter' ? onMuscleTypeClick : onBodyPartClick;
  const selectedOption = theme.mode === 'weightlifter' ? muscleType : bodyPart;
  return (
    <div style={{ marginTop: 10, paddingLeft: 5 }}>
      <div
        style={{
          fontSize: 20,
          padding: 5,
          fontWeight: 'bold',
          color: theme.darkMode ? `rgb(255,255,255)` : `rgb(0,0,0)`,
        }}>
        Muscle
      </div>
      <Stack direction="row" sx={{flexWrap: 'wrap'}}>
        {options.map((option, index) => {
          const selected = option === selectedOption;
          return (
            <Chip
              sx={{marginRight: 1, marginTop: 1}}
              key={`option-${index}`}
              label={option}
              size={'small'}
              onClick={() => {
                if (selectedOption === option) {
                  onClickAction(undefined);
                } else {
                  onClickAction(option);
                }
              }}
              color={selected ? "primary" : "default"}
              variant={selected ? "filled" : "outlined"} />
          );
        })}
      </Stack>
    </div>
  );
};

export default MuscleTypeFilter;