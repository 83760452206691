import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fetchUser } from '../../localDatabase/serverUsers';
import { fetchUserExercises } from '../../localDatabase/serverExercises'
import { fetchUserLifts } from '../../localDatabase/serverLifts';

import { userContext } from '../../contexts/currentUser';
import { exercisesContext } from '../../contexts/exercises';
import { liftsContext } from '../../contexts/lifts';

import Spinner from '../../components/spinner';

import Profile from './Profile';
import Friend from '../../components/friends/Friend';

import { formatDateTime } from '../../utils/formatDate';

import { fetchAllPhotos } from '../../localDatabase/serverPhotos';

const User = () => {
    const [searchParams] = useSearchParams();
    const route = Object.fromEntries([...searchParams]);

    const loggedInUser = useContext(userContext);
    const loggedInUserExercises = useContext(exercisesContext);
    const loggedInUserLiftData = useContext(liftsContext);

    const [userLoading, setUserLoading] = useState(true);
    const [exerciseLoading, setExerciseLoading] = useState(true);
    const [liftsLoading, setLiftsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState({});
    const [exercises, setExercises] = useState([]);
    const [lift_data, setLiftData] = useState([]);
    const [photos, setphotos] = useState([]);
    const [thumbs, setthumbs] = useState([]);

    const loading =
        userLoading ||
        exerciseLoading ||
        liftsLoading;

    useEffect(() => {
        const doit = async () => {
            if (loggedInUser.uid === route.user_id || route.user_id === undefined || route.user_id === '') {
                setCurrentUser(loggedInUser);
                setUserLoading(false);
                setExercises(loggedInUserExercises);
                setExerciseLoading(false);
                setLiftData(loggedInUserLiftData);
                setLiftsLoading(false);
            } else {
                const friend = await fetchUser(route?.user_id || loggedInUser.uid);
                setCurrentUser(friend);
                setUserLoading(false);
                const friendExercises = await fetchUserExercises(route?.user_id || loggedInUser.uid);
                setExercises(friendExercises);
                setExerciseLoading(false);
                const friendLifts = await fetchUserLifts(route?.user_id || loggedInUser.uid, friendExercises);
                setLiftData(friendLifts);
                setLiftsLoading(false);
            }
        }
        doit();

        fetchAllPhotos(route.user_id, (serverPhotos, serverThumbs) => {
            setphotos(serverPhotos);
            setthumbs(serverThumbs);
        })
    }, [route.user_id, loggedInUser, loggedInUserExercises, loggedInUserLiftData])

    return loading ? <Spinner loading={loading} /> : <>
        <Friend key={'swiggity'} id={currentUser.uid} name={currentUser.displayName} imageUrl={currentUser.photoURL} status={'TODO'} joined_at={formatDateTime(currentUser.created_at)} cb={() => { }} />
        <Profile currentUser={currentUser} exercises={exercises} lift_data={lift_data} photos={photos} thumbs={thumbs} />
    </>
};

export default User;