import React, { useState, useContext } from 'react';
import { rpeText } from '../../../utils/rpe';
import { themeContext } from '../../../contexts/theme';
import { userContext } from '../../../contexts/currentUser';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const PowerliftingSettingsPage = () => {
  const theme = useContext(themeContext);
  const currentUser = useContext(userContext);
  const [rpe, setRpe] = useState(1);
  const [saveToolTipOpen, setSaveToolTipOpen] = useState(false);
  return (
    <div>
      <Snackbar open={saveToolTipOpen} autoHideDuration={6000} onClose={() => setSaveToolTipOpen(false)}>
        <Alert onClose={() => setSaveToolTipOpen(false)} severity="success" sx={{ width: '100%' }}>
          Powerlifting Mode Updated!
        </Alert>
      </Snackbar>
      <div style={{ flexDirection: 'row' }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox
            inputProps={{ 'aria-label': 'controlled' }}
            checked={theme.powerlifting}
            onChange={() => {
              const doit = async () => {
                await theme.changeTheme()(currentUser.uid, {
                  powerlifting: !theme.powerlifting,
                });
                setSaveToolTipOpen(true);
              }
              doit()
            }} />}
            label="Powerlifting Mode" />
        </FormGroup>
      </div>
      <div style={{ height: 20, width: '100%' }} />
      <div
        style={{
          color: `rgb(${theme.primary.main})`,
          fontSize: 18,
        }}>
        RPE: Rating of Perceived Exertion Scale
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
          alignItems: 'stretch',
          justifyContent: 'center',
          height: 75,
        }}>
        <Box width={300}>
          <Slider
            defaultValue={rpe}
            min={1}
            max={10}
            step={0.5}
            valueLabelDisplay="auto"
            onChange={(e) => {
              setRpe(Math.round(e.target.value * 10) / 10)
            }}
          />
        </Box>
        <div
          style={{
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            fontSize: 13,
            fontWeight: 'bold',
          }}>
          RPE: {rpe} - {rpeText(rpe)}
        </div>
      </div>
      <div style={{ height: 20, width: '100%' }} />
      <div
        style={{
          color: `rgb(${theme.primary.main})`,
          fontSize: 18,
        }}>
        Rep Max Calculator and Powerlift total stats will be added to profile
        page.
      </div>
    </div>
  );
};

export default PowerliftingSettingsPage;