import React, { useState, useContext } from 'react';
import PRs from '../../components/profile/PRs';
import WorkoutListWithDetails from '../../components/profile/WorkoutListWithDetails';
import ExerciseSelector from '../../components/lifts/ExerciseSelector';
import { themeContext } from '../../contexts/theme';
import ExerciseLineChart from '../../components/ExerciseLineChart';
import ActivityCalendar from 'react-activity-calendar';

import { convertToDate, formatDate, getDateArray } from '../../utils/formatDate';
import { uniq } from 'lodash';
import { toDisplay } from '../../utils/timer';
import moment from 'moment';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const weekdays = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
];

const Exercises = ({ uid, exercises, lift_data }) => {

  const [exerciseId, setExerciseId] = useState();
  const theme = useContext(themeContext);
  const selectedExercise = exercises.find(e => e.id === exerciseId);

  const [toggleIndex, setToggleIndex] = useState('0');
  const toggleLookup = [{ '0': 'Charts' }, { '1': 'PRs' }, { '2': 'History' }];

  const [daysIndex, setDaysIndex] = useState('0');
  const daysIndexLookup = { '0': 90, '1': 180, '2': 365, '3': 99999 };
  const dateRangeLookup = [{ '0': '3 Months' }, { '1': '6 Months' }, { '2': '1 Year' }, { '3': 'All Time' }];

  let last30byExercise = {};
  lift_data.forEach(ltg => {
    if (
      ltg.lift_trackers_attributes.filter(lt => {
        if (exerciseId) {
          return lt.exercise.id === exerciseId;
        } else {
          return false;
        }
      }).length > 0
    ) {
      uniq(
        ltg.lift_trackers_attributes.map(lt => {
          return lt.exercise.id;
        }),
      ).forEach(eid => {
        last30byExercise[eid] = last30byExercise[eid] || [];
        if (
          moment().diff(convertToDate(ltg.lifted_at), 'days') <
          daysIndexLookup[daysIndex]
        ) {
          last30byExercise[eid].push(ltg);
        }
      });
    }
  });

  let rm_calculations = {};
  Object.keys(last30byExercise).forEach(eid => {
    rm_calculations[eid] = last30byExercise[eid].reverse().map(ltg => {
      const estimated_1rm = Math.max(
        ...ltg.lift_trackers_attributes
          .filter(lt => {
            return lt.exercise.id === eid;
          })
          .map(lt => {
            return Math.ceil(
              (lt.weight || 0) * (1 + (lt.num_of_reps || 0) / 30),
            );
          }),
      );
      const maxWeight = Math.max(
        ...ltg.lift_trackers_attributes
          .filter(lt => {
            return lt.exercise.id === eid;
          })
          .map(lt => {
            return lt.weight || 0;
          }),
      );
      const maxReps = Math.max(
        ...ltg.lift_trackers_attributes
          .filter(lt => {
            return lt.exercise.id === eid;
          })
          .map(lt => {
            return lt.num_of_reps || 0;
          }),
      );
      const maxTime = Math.max(
        ...ltg.lift_trackers_attributes
          .filter(lt => {
            return lt.exercise.id === eid;
          })
          .map(lt => {
            return lt.lift_elapsed_seconds || 0;
          }),
      );
      const minTime = Math.min(
        ...ltg.lift_trackers_attributes
          .filter(lt => {
            return lt.exercise.id === eid;
          })
          .map(lt => {
            return lt.lift_elapsed_seconds || 0;
          }),
      );
      return {
        date: convertToDate(ltg.lifted_at),
        estimated_1rm: estimated_1rm,
        maxWeight: maxWeight,
        maxReps: maxReps,
        maxTime: maxTime,
        minTime: minTime,
        formattedDate: formatDate(ltg.lifted_at)
      };
    });
  });
  last30byExercise = undefined;

  const personal_records_attributes = lift_data
    .map(ltg => {
      return ltg.lift_trackers_attributes.map(lt => {
        return {
          ...lt.personal_record_attributes,
          exercise_id: lt.exercise.id,
          exercise: {
            id: lt.exercise.id,
            equipment_type: lt.exercise.equipment_type,
          },
          lift_tracker: lt,
          lift_tracker_group: ltg,
          uid: ltg.user_id,
        };
      });
    })
    .flat()
    .filter(x => {
      return x && x.id !== undefined;
    });

  const [muscleType, setMuscleType] = useState(undefined);
  const [equipmentType, setEquipmentType] = useState(undefined);
  const [bodyPart, setBodyPart] = useState(undefined);
  const [searchValue, setSearchValue] = useState('');

  const lift_data_for_exercise = lift_data.filter(ltg => {
    return ltg.lift_trackers_attributes.some(lt => {
      return (lt.exercise && lt.exercise.id) === exerciseId;
    });
  });

  const daysLiftedWithExercise = lift_data_for_exercise.map(ltg => convertToDate(ltg.lifted_at));
  const minDayLiftedWithExercise = moment().subtract(12, 'months');
  const maxDayLiftedWithExercise = new Date();

  const hasCartData = selectedExercise
    ? (selectedExercise.isBodyWeight
      ? (rm_calculations[exerciseId] || []).map(d => d.maxReps)
      : selectedExercise.isTimeBased
        ? (rm_calculations[exerciseId] || []).map(d =>
          selectedExercise.more_time_is_pr ? d.maxTime : d.minTime,
        )
        : (rm_calculations[exerciseId] || []).map(d => d.maxWeight)
    ).length > 0
    : false;

  return (
    <div
      style={{
        display: 'block'
      }}>
      <ExerciseSelector
        exerciseId={exerciseId}
        muscleType={muscleType}
        bodyPart={bodyPart}
        equipmentType={equipmentType}
        onExerciseClick={value => {
          setExerciseId(value);
        }}
        onMuscleTypeClick={setMuscleType}
        onBodyPartClick={setBodyPart}
        onEquipmentTypeClick={setEquipmentType}
        exerciseError={false}
        lift_id={'doesnt matter'}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        uid={uid}
        lift_data={lift_data}
      />
      {exerciseId && (
        <>
          <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
            <ToggleButtonGroup
              color="primary"
              value={toggleIndex}
              size={'small'}
              exclusive
              onChange={(_, newAlignment) => {
                setToggleIndex(newAlignment)
              }}
            >
              {toggleLookup.map((obj, index) => {
                return <ToggleButton key={`dropdown-${index}`} value={Object.keys(obj)[0]}>{Object.values(obj)[0]}</ToggleButton>
              })}
            </ToggleButtonGroup>
          </div>
          {toggleIndex === '0' && (
            <div>
              <div style={{ padding: 10, paddingBottom: 0, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                  color="primary"
                  value={daysIndex}
                  size={'small'}
                  exclusive
                  onChange={(_, newAlignment) => setDaysIndex(newAlignment)}
                >
                  {dateRangeLookup.map((obj, index) => {
                    return <ToggleButton key={`dropdown-${index}`} value={Object.keys(obj)[0]}>{Object.values(obj)[0]}</ToggleButton>
                  })}
                </ToggleButtonGroup>
              </div>
              {hasCartData ? (
                <div>
                  {!selectedExercise.isBodyWeight &&
                    !selectedExercise.isTimeBased && (
                      <>
                        <ExerciseLineChart
                          key={'Estimated 1 RM'}
                          title={'Estimated 1 RM'}
                          data={rm_calculations[exerciseId].map(
                            d => { return { y: d.estimated_1rm, x: d.formattedDate } }
                          )}
                        />
                        <ExerciseLineChart
                          key={'Max Weight'}
                          title={'Max Weight'}
                          data={rm_calculations[exerciseId].map(
                            d => { return { y: d.maxWeight, x: d.formattedDate } }
                          )}
                        />
                      </>
                    )}
                  {selectedExercise.isBodyWeight && (
                    <ExerciseLineChart
                      key={'Max Reps'}
                      title={'Max Reps'}
                      data={rm_calculations[exerciseId].map(d => { return { y: d.maxWeight, x: d.maxReps } })}
                    />
                  )}
                  {selectedExercise.isTimeBased && (
                    <ExerciseLineChart
                      key={'Time'}
                      title={'Time'}
                      data={rm_calculations[exerciseId].map(d =>
                        d => {
                          return {
                            y: selectedExercise.more_time_is_pr
                              ? d.maxTime
                              : d.minTime, x: d.maxReps
                          }
                        }

                      )}
                      formatYLabel={value => {
                        return toDisplay(value);
                      }}
                    />
                  )}
                  <div
                    style={{
                      borderRadius: 5,
                      padding: 10,
                      paddingTop: 20,
                      paddingBottom: 0,
                      margin: 10,
                    }}>
                    <div style={{ flexDirection: 'row' }}>
                      <div style={{ flexGrow: 1 }}>
                        <div
                          style={{
                            color: theme.darkMode
                              ? 'rgb(255,255,255)'
                              : 'rgb(0,0,0)',
                            fontSize: 18,
                            fontWeight: 'bold',
                            paddingBottom: 15,
                          }}>
                          Activity
                        </div>
                      </div>
                    </div>
                    <div>
                      {lift_data_for_exercise.length > 0 && <ActivityCalendar
                        color={`rgb(${theme.primary.main})`}
                        blockRadius={20}
                        blockSize={20}
                        data={getDateArray(minDayLiftedWithExercise, maxDayLiftedWithExercise).map(lt => {
                          const hasLift = daysLiftedWithExercise.map(d => formatDate(d)).includes(lt);
                          return {
                            count: hasLift ? 1 : 0,
                            date: lt,
                            level: hasLift ? 1 : 0
                          }
                        })}
                        hideColorLegend={true}
                        hideTotalCount={true}
                        labels={{
                          legend: {
                            less: '',
                            more: ''
                          },
                          months: monthNames,
                          weekdays: weekdays
                        }}
                      >
                      </ActivityCalendar>}

                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    padding: 20,
                    margin: 20,
                    backgroundColor: theme.darkMode
                      ? `rgba(255,255,255,0.1)`
                      : 'rgba(255,255,255,0.4)',
                    height: 60,
                    borderRadius: 5,
                    justifyContent: 'center',
                    alignContent: 'center',
                    marginTop: 30,
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.1)',
                  }}>
                  <div
                    style={{
                      color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
                      fontSize: 17,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}>
                    No Chart Data Available
                  </div>
                </div>
              )}
            </div>
          )}

          {toggleIndex === '1' && (
            <div>
              <PRs
                personal_records_attributes={personal_records_attributes}
                exerciseId={exerciseId}
                loading={false}
              />
            </div>
          )}

          {toggleIndex === '2' && (
            <div>
              <WorkoutListWithDetails
                lift_data={lift_data_for_exercise}
                exerciseId={exerciseId}
                loading={false}
                local={true}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Exercises;