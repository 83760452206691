import moment from 'moment';

export const getDateArray = (start, end) => {
  let arr = [];
  let dt = new Date(start);
  while (dt <= end) {
    arr.push(formatDate(new Date(dt)));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
}

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
};

export const formatAMPM = (date) => {
  const formattedMegaDate = convertToDate(date);
  let hours = formattedMegaDate.getHours();
  let minutes = formattedMegaDate.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, '0');
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const formatDateTime = (value) => {
  if (value === undefined || value === null || value === '') {
    return '';
  }
  if (typeof value === 'string') {
    value = moment(value).toDate();
  }
  if (typeof value === 'object' && value.seconds) {
    value = value.toDate();
  }
  return moment(value).fromNow();
};

export const formatDate = (value, defaultValue = '') => {
  if (value === undefined || value === null || value === '') {
    return defaultValue;
  }
  if (typeof value === 'string' || typeof value === 'number') {
    value = moment(value).toDate();
  }
  if (typeof value === 'object' && value.seconds) {
    try {
      value = value.toDate();
    } catch (err) {
      return defaultValue;
    }
  }

  const offset = value.getTimezoneOffset();
  value = new Date(value.getTime() - offset * 60 * 1000);
  return value.toISOString().split('T')[0];
};

export const convertToDate = (value) => {
  if (value === undefined || value === null || value === '') {
    return new Date();
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return moment(value).toDate();
  }
  if (typeof value === 'object' && typeof value.seconds === 'number') {
    try {
      return value.toDate();
    } catch (err) {
      return new Date();
    }
  }
  if (typeof value === 'object') {
    return value;
  }
  return new Date();
};

export const monthDayText = value => {
  return moment(convertToDate(value)).format('MM/DD');
};

const formatDateObj = {formatDateTime, formatDate, convertToDate, getDateArray, dateInPast, formatAMPM, monthDayText};

export default formatDateObj;