import React, { useContext } from 'react';

import { orderBy, uniq } from 'lodash';

import { toMinutes, toSeconds } from '../../utils/timer';

import { themeContext } from '../../contexts/theme';

import MuscleTypeFilter from './MuscleTypeFilter';
import EquipmentTypeFilter from './EquipmentTypeFilter';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const ExerciseSearch = ({
  filteredExercises,
  onExerciseClick,
  exerciseId,
  muscleType,
  equipmentType,
  onMuscleTypeClick,
  onEquipmentTypeClick,
  PRsByExerciseID,
  exercisesLoading,
  onBodyPartClick,
  bodyPart,
}) => {
  const theme = useContext(themeContext);

  const sortedSearchedData = orderBy(
      uniq(filteredExercises, 'id'),
      ['has_pr_sort', 'name'],
      ['desc', 'asc'],
    ).map(item => {
      return { item: item };
    });

  return (
    <>
      {!exerciseId && (
        <div>
          <MuscleTypeFilter
            muscleType={muscleType}
            onMuscleTypeClick={onMuscleTypeClick}
            theme={theme}
            onBodyPartClick={onBodyPartClick}
            bodyPart={bodyPart}
          />
          <EquipmentTypeFilter
            equipmentType={equipmentType}
            onEquipmentTypeClick={onEquipmentTypeClick}
            theme={theme}
          />
        </div>
      )}
      <Autocomplete
        disablePortal
        id="exercise-autocomplete"
        options={sortedSearchedData}
        loading={exercisesLoading}
        getOptionLabel={(option) => option.item.name}
        groupBy={(option) => option.item.has_pr_sort ? 'Have Worked Out' : "Haven't Worked Out"}
        onChange={(_, value) => {
          onExerciseClick(value?.item?.id)
        }}
        isOptionEqualToValue={(option, value) => option?.item?.id === value?.item?.id}
        sx={{ width: '100%', marginTop: 3 }}
        renderInput={(params) => <TextField {...params} label="Select Exercise" />}
        renderOption={(props, item, { inputValue }) => {
          const matches = match(item.item.name, inputValue);
          const parts = parse(item.item.name, matches);

          const PRS = PRsByExerciseID[item.item.id] || [];
          const has_pr = PRS.length > 0;
          let max_text = '';
          if (has_pr) {
            const isBodyWeight =
              item && item.item.equipment_type === 'Body Only';
            const isTimeBased = item && item.item.equipment_type === 'Time';
            const max_weight = Math.max(
              ...PRS.map(epr => epr.lift_tracker.weight),
            );
            const max_reps = Math.max(
              ...PRS.map(epr => epr.lift_tracker.num_of_reps),
            );
            const seconds = PRS.map(
              epr => epr.lift_tracker.lift_elapsed_seconds,
            );
            const lift_elapsed_seconds = item.item.more_time_is_pr
              ? Math.max(...seconds)
              : Math.min(...seconds);
            max_text = ` ( ${isBodyWeight ? max_reps : max_weight} )`;
            max_text = isTimeBased
              ? ` ( ${toMinutes(lift_elapsed_seconds)}:${toSeconds(
                lift_elapsed_seconds,
              )} )`
              : max_text;
          }

          return (
            <li {...props}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ paddingRight: 5 }}>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                  {item.item.has_pr_sort && (
                    <div
                      key={`${item.item.id}-pr`}
                      style={{
                        fontSize: theme.mode === 'weightlifter' ? 16 : 20,
                        color: theme.darkMode
                          ? 'rgb(255,255,255)'
                          : 'rgb(0,0,0)',
                        fontWeight: 'normal',
                      }}>
                      {max_text}
                    </div>
                  )}
                </div>
                {theme.mode === 'weightlifter' && (
                  <div style={{ flexDirection: 'row' }}>
                    <div style={{ marginRight: 15 }}>
                      <div
                        style={{
                          fontSize: 12,
                          color: theme.darkMode
                            ? 'rgba(255,255,255,0.7)'
                            : 'rgba(0,0,0,0.7)',
                        }}>
                        Muscle: {item.item.muscle_type}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: 12,
                          color: theme.darkMode
                            ? 'rgba(255,255,255,0.7)'
                            : 'rgba(0,0,0,0.7)',
                        }}>
                        Equipment: {item.item.equipment_type}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </li>
          );
        }}
      />
    </>
  );
};

export default ExerciseSearch;