export const bodyParts = {
  Chest: ['Chest'],
  Back: ['Lats', 'Lower Back', 'Middle Back', 'Neck'],
  Shoulders: ['Shoulders', 'Traps'],
  Arms: ['Biceps', 'Forearms', 'Triceps'],
  Legs: [
    'Abductors',
    'Adductors',
    'Calves',
    'Glutes',
    'Hamstrings',
    'Quadriceps',
  ],
  Abs: ['Abdominals'],
};

export const muscleTypes = [
  'Abdominals',
  'Abductors',
  'Adductors',
  'Biceps',
  'Calves',
  'Chest',
  'Forearms',
  'Glutes',
  'Hamstrings',
  'Lats',
  'Lower Back',
  'Middle Back',
  'Neck',
  'Quadriceps',
  'Shoulders',
  'Traps',
  'Triceps',
];

const exerciseFilters = { bodyParts, muscleTypes }

export default exerciseFilters;