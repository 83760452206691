import React, {useContext} from 'react';
import {themeContext} from '../contexts/theme';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryScatter,
  VictoryGroup,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryLabel
} from 'victory';

const ExerciseLineChart = ({title, data, formatYLabel}) => {
  const theme = useContext(themeContext);
  return (
    <div
      style={{
        borderRadius: 5,
        padding: 10,
        paddingTop: 20,
        paddingBottom: 0,
        margin: 10,
      }}>
      <div style={{flexDirection: 'row'}}>
        <div style={{flexGrow: 1}}>
          <div
            style={{
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              fontSize: 18,
              fontWeight: 'bold',
            }}>
            {title}
          </div>
        </div>
      </div>
      <div>
      <VictoryChart
          width={800}
          height={250}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              labelComponent={
                <VictoryLabel backgroundPadding={10}
                  backgroundStyle={{ fill: `rgba(${theme.darkMode ? '0,0,0,0.7' : '0,0,0,0.1'})` }}
                />
              }
            />
          }>
          <VictoryAxis
            crossAxis
            style={{
              grid: {
                fill: 'none',
                stroke: `transparent`,
                strokeDasharray: '10, 5',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                pointerEvents: 'painted',
              },
              axis: {
                stroke: `transparent`,
                strokeDasharray: '10, 5',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              },
              ticks: {
                stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                size: 0,
              },
              tickLabels: {
                fontSize: 0,
                fill: `transparent`,
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            theme={VictoryTheme.material}
            standalone={true}
            style={{
              grid: {
                fill: 'none',
                stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'
                  }, 0.1)`,
                strokeDasharray: '10, 5',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                pointerEvents: 'painted',
              },
              axis: {
                stroke: `transparent`,
                strokeDasharray: '10, 5',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              },
              ticks: {
                stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                size: 0,
              },
              tickLabels: {
                fontSize: 15,
                padding: 5,
                fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              },
            }}
            tickFormat={(t) => `${formatYLabel ? formatYLabel(t) : `${Math.round(t)}`}`}
          />

            <VictoryGroup data={data}>
              <VictoryLine
                style={{
                  data: { stroke: `rgba(${theme.primary.main},1)`, strokeWidth: 3 },
                  labels: { fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})` },
                }}
              />
              <VictoryScatter
                style={{ data: { fill: `rgba(${theme.primary.main},1)` }, labels: { fill: 'transparent' }, }}
                size={3}
              />
            </VictoryGroup>
        </VictoryChart>
      </div>
    </div>
  );
};

export default ExerciseLineChart;