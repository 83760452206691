import React from 'react';
import Background from './images/loginBackgroundBlurred.jpg';
import LogoImageLight from './images/brolift-logo-light.png';
import LogoImageDark from './images/brolift-logo.png';

const SyncingScreen = ({text, theme}) => {
  return (<div style={{ background: `url(${Background})`, height: '100vh', backgroundSize: 'cover' }}>
      <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        height: '100vh'
      }}>
        <div
          style={{
            backgroundColor: 'rgba(255,255,255,0.1)',
            padding: 20,
            borderRadius: 20,
            height: 300,
            width: 400,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <img
            alt="Brolift logo"
            style={{ width: 250, height: 60, marginBottom: 30 }}
            src={
              theme.darkMode
                ? LogoImageDark
                : LogoImageLight
            }
          />
            <div
              style={{
                fontSize: 20,
                marginTop: 10,
                color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
              }}>
              {text}
            </div>
          </div>
      </div></div>
  );
};

export default SyncingScreen;