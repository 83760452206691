import React, { useContext } from 'react';

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryLabel
} from 'victory';

import { filterIntorZero } from '../../utils/filterInt';
import { themeContext } from '../../contexts/theme';
import { formatAMPM } from '../../utils/formatDate'


const HeartRateChart = ({ data, lift_starts, height, marginLeft }) => {
  const theme = useContext(themeContext);

  const heartData = data.map(d => ({ ...d, value: filterIntorZero(d.value) }));
  const avg = Math.ceil(
    heartData.map(hr => hr.value).reduce((a, b) => a + b) / heartData.length,
  );
  const max = Math.max(...heartData.map(hr => hr.value));
  const min = Math.min(...heartData.map(hr => hr.value));

  const lineData = heartData.map(d => {
    return {
      x: d.date,
      y: d.value
    }
  });

  return (
    <>
    <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignContent: 'center',
          marginTop: 10,
        }}>
        <div>
          <div
            style={{
              color: 'rgba(255,26,26,1)',
              fontSize: 12,
              fontWeight: 'bold',
            }}>{`Min: ${min}`}</div>
        </div>
        <div>
          <div
            style={{
              color: 'rgba(255,26,26,1)',
              fontSize: 12,
              fontWeight: 'bold',
            }}>{`Max: ${max}`}</div>
        </div>
        <div>
          <div
            style={{
              color: 'rgba(255,26,26,1)',
              fontSize: 12,
              fontWeight: 'bold',
            }}>{`Avg: ${avg}`}</div>
        </div>
      </div>
      <div style={{marginTop: -30}}>
      <VictoryChart
        height={height || 200}
        width={800}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `${formatAMPM(datum.x)}: ${datum.y}`}
            labelComponent={
              <VictoryLabel backgroundPadding={10}
                backgroundStyle={{ fill: `rgba(${theme.darkMode ? '0,0,0,0.7' : '0,0,0,0.1'})` }}
              />
            }
          />
        }
        domainPadding={{ y: 10 }}>
        <VictoryAxis
          crossAxis
          style={{
            grid: {
              fill: 'none',
              stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'
                }, 0.1)`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              pointerEvents: 'painted',
            },
            axis: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            ticks: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              size: 0,
            },
            tickLabels: {
              fontSize: 0,
              fill: `transparent`,
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          theme={VictoryTheme.material}
          standalone={true}
          tickFormat={t => `${Math.round(t)}`}
          style={{
            grid: {
              fill: 'none',
              stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'
                }, 0.1)`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              pointerEvents: 'painted',
            },
            axis: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            ticks: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              size: 0,
            },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
            },
          }}
        />
        <VictoryLine
          data={lineData}
          interpolation={'step'}
          style={{
            data: { stroke: 'red', strokeWidth: 3 },
            labels: { fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})` },
          }}
        />
      </VictoryChart>
      </div>
    </>
  );
};

export default HeartRateChart;