import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { rpeWorkoutText } from '../../utils/rpe';
import { removeItemAll } from '../../utils/arrayHelper';
import { Whatshot, ThumbUp, ThumbUpOutlined } from '@material-ui/icons';
import { db, cloudFunctions } from '../../services/firestoredb';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { themeContext } from '../../contexts/theme';
import { userContext } from '../../contexts/currentUser';
import PhotoThumbnail from '../../components/PhotoThumbnail';
import { fetchLiftPhotos } from '../../localDatabase/serverPhotos';

const SmallCard = ({ item }) => {
  const theme = useContext(themeContext);
  const currentUser = useContext(userContext);
  const navigation = useNavigate();

  const [likes, setLikes] = useState([]);
  const [photos, setphotos] = useState([]);
  const [thumbs, setthumbs] = useState([]);

  const lift_user_id = item.uid || (item.user && item.user.uid);

  useEffect(() => {
    const doit = async () => {
      //fetch likes
      const docRef = doc(db, 'lift_likes', item.id);
      const documentSnapshot = await getDoc(docRef);
      if (documentSnapshot.exists()) {
        setLikes(
          documentSnapshot.data().likes ? documentSnapshot.data().likes : [],
        );
      }
      fetchLiftPhotos(lift_user_id, item.id, (serverPhotos, serverThumbs) => {
        setphotos(serverPhotos);
        setthumbs(serverThumbs);
      })
    }
    doit()
  }, [item.id, lift_user_id, setLikes, setphotos]);


  return (
    <div
      style={{
        padding: 10,
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: theme.darkMode
          ? 'rgba(255,255,255,0.13)'
          : 'rgba(255,255,255,0.4)',
        borderRadius: 5,
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 15,
        flexGrow: 1,
      }}>
      <div
        onClick={() =>
          navigation({
            pathname: '/activity', search: `?${createSearchParams({
              lift_id: item.id,
              uid: item.uid || (item.user && item.user.uid)
            })}`
          })
        }
        style={{
          color: `rgb(${theme.primary.main})`,
          fontSize: 18,
          fontWeight: '500',
          cursor: 'pointer'
        }}>
        {`${item.name}`}
      </div>
      {item.rpe && (
        <div
          style={{
            color: theme.darkMode
              ? 'rgba(255,255,255,0.7)'
              : 'rgba(0,0,0,0.7)',
            fontSize: 13,
          }}>
          RPE: {rpeWorkoutText(item.rpe)}
        </div>
      )}
      {photos.length > 0 && (
        <>
          <div
            style={{
              margin: 10,
              backgroundColor: theme.darkMode
                ? 'rgba(255,255,255,0.07)'
                : 'rgba(255,255,255,0.4)',
              borderRadius: 5,
              padding: 10,
              borderWidth: 1,
              borderColor: 'rgba(0,0,0,0.1)',
            }}>
            <div
              style={{
                width: '100%',
                borderRadius: 5,
                flexDirection: 'row',
                overflow: 'scroll',
                display: 'flex'
              }}>
              {photos.map((photo, index) => {
                const thumb = thumbs.find(t => {
                  return t.id === photo.id
                })
                return <PhotoThumbnail
                  key={`photothumbnail-${index}`}
                  photo={photo}
                  thumb={thumb}
                  index={index}
                />
              })}
            </div>
          </div>
        </>
      )}
      <div onClick={() =>
        navigation({
          pathname: '/activity', search: `?${createSearchParams({
            lift_id: item.id,
            uid: item.uid || (item.user && item.user.uid)
          })}`
        })
      } style={{ cursor: 'pointer' }}>
        {item.exercises &&
          item.exercises.map((exercise, index) => (
            <div
              key={`exercise-${index}`}
              style={{
                color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
                fontSize: 15,
                fontWeight: '500',
                marginTop: 3,
              }}>
              {`${exercise}`}
            </div>
          ))}
      </div>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingTop: 5,
        }}>

        {theme.mode === 'weightlifter' && item.numOfPRs > 0 && (
          <>
            <div style={{ marginRight: 3 }}>
              <div
                style={{
                  marginTop: 4,
                  fontSize: 15,
                  color: theme.darkMode
                    ? 'rgb(255,255,255)'
                    : 'rgb(0,0,0)',
                }}>
                {`${item.numOfPRs}`}
              </div>
            </div>
            <Whatshot
              style={{ fill: `rgb(${theme.primary.main})`, fontSize: 25 }}
            />
          </>
        )}

        {likes && likes.length > 0 && (
          <div style={{ marginLeft: 5 }}>
            <div
              style={{
                marginTop: 4,
                fontSize: 15,
                color: theme.darkMode
                  ? 'rgb(255,255,255)'
                  : 'rgb(0,0,0)',
              }}>
              {`${likes.length}`}
            </div>
          </div>
        )}

        <div style={{ marginLeft: 5, cursor: 'pointer' }}
          onClick={async () => {
            await updateDoc(doc(db, "lift_likes", item.id), {
              likes:
                likes && likes.includes(currentUser.uid)
                  ? arrayRemove(
                    currentUser.uid,
                  )
                  : arrayUnion(
                    currentUser.uid,
                  ),
            }, { merge: true });
            if (likes && !likes.includes(currentUser.uid) && currentUser.uid !== lift_user_id) {
              setLikes([...likes, currentUser.uid])
              const sendFriendsNotification = httpsCallable(cloudFunctions, 'friendRequestNotification');
              sendFriendsNotification({
                friendUid: lift_user_id,
                title: `${currentUser.displayName} liked your workout`,
                body: item.name,
                tab: 'Feed',
                screen: '/activity/',
                initialParams: {
                  lift_id: item.id,
                  uid: lift_user_id,
                },
              })
            }
            if (likes && !likes.includes(currentUser.uid)) {
              setLikes([...likes, currentUser.uid])
            } else {
              setLikes(removeItemAll(likes, currentUser.uid))
            }
          }}>
          {likes && likes.length > 0 && likes.includes(currentUser.uid) ?
            <ThumbUp style={{ fill: `rgb(${theme.primary.main})`, fontSize: 25 }} /> :
            <ThumbUpOutlined style={{ fill: `rgb(${theme.primary.main})`, fontSize: 25 }} />
          }
        </div>
      </div>
    </div>
  );
};

export default SmallCard;