import React, { useState, useContext } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Avatar from '../Avatar';
import { userContext } from '../../contexts/currentUser';
import { themeContext } from '../../contexts/theme';

import { sendRequest } from '../../localDatabase/serverFriends';

const Friend = ({ name, imageUrl, status, id, joined_at, cb }) => {
  const navigate = useNavigate();
  const buttons = [];
  const currentUser = useContext(userContext);
  const theme = useContext(themeContext);

  const [loading, setLoading] = useState(false);

  const dispatchCallFriendsAction = type => {
    setLoading(true);
    sendRequest(currentUser.uid, id, type, () => {
      cb && cb();
      setLoading(false);
    });
  };

  if (status === 'accepted') {
    buttons.push(
      <Button
        key="Unfriend"
        variant="contained"
        onClick={() => {
          if (window.confirm(`Are you sure you don't want to be friends?`)) {
            dispatchCallFriendsAction(`unfriend`);
          }
        }}
        disabled={loading}
      >Unfriend</Button>,
    );
  }

  // if (status === 'blocked') {
  //   buttons.push(
  //     <Button
  //       text="Unblock"
  //       key="Unblock"
  //       variant="contained"
  //       onClick={() => dispatchCallFriendsAction(`unblock`)}
  //       disabled={loading}
  //     />,
  //   );
  // }

  if (status === 'pending') {
    buttons.push(
      <div style={{ width: '45%', marginRight: '10%' }}>
        <Button
          key="Approve"
          variant="contained"
          onClick={() => dispatchCallFriendsAction(`accept`)}
          disabled={loading}
        >Approve</Button>
      </div>,
    );
    buttons.push(
      <div style={{ width: '45%' }}>
        <Button
          key="Decline"
          variant="contained"
          onClick={() => dispatchCallFriendsAction(`decline`)}
          disabled={loading}
        >Decline</Button>
      </div>,
    );
  }

  if (status === 'requested') {
    buttons.push(
      <Button
        key="Requested Friend"
        variant="contained"
        disabled={true}
      >Requested Friend</Button>,
    );
  }

  if (status === 'available' && id !== currentUser.id) {
    buttons.push(
      <Button
        key="Add Friend"
        variant="contained"
        onClick={() => dispatchCallFriendsAction(`send_request`)}
        disabled={loading}
      >Add Friend</Button>,
    );
  }

  return (
    <div
      key={id}
      style={{
        padding: 15,
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.darkMode
          ? `rgba(255,255,255,0.1)`
          : 'rgba(255,255,255,0.4)',
        marginTop: 15,
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,0.1)',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Avatar
          type="image"
          image={<img alt="friend" src={imageUrl} />}
          size={40}
          onClick={() => {
            navigate({
              pathname: '/profile', search: `?${createSearchParams({
                user_id: id,
              })}`
            });
          }}
        />
        <div
          style={{
            marginLeft: 16,
            marginTop: -5,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div
            style={{
              color: `rgb(${theme.primary.main})`,
              fontSize: 20,
              fontWeight: '500',
            }}>
            {name}
          </div>

          <div
            style={{
              fontSize: 12,
              fontWeight: '500',
              color: theme.darkMode
                ? 'rgba(255,255,255,0.8)'
                : 'rgba(0,0,0,0.8)',
            }}>
            {joined_at}
          </div>
        </div>
      </div>

      {buttons.length > 0 && (
        <div
          style={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'row',
          }}>
          {buttons}
        </div>
      )}
    </div>
  );
};

export default Friend;