export const toMinutes = totalSeconds => {
  return Math.floor(totalSeconds / 60)
    .toString()
    .padStart(2, '0');
};

export const toSeconds = totalSeconds => {
  return (totalSeconds - Math.floor(totalSeconds / 60) * 60)
    .toString()
    .padStart(2, '0');
};

export const toDisplay = totalSeconds => {
  const displaySeconds = totalSeconds || 0;
  return `${toMinutes(displaySeconds)}:${toSeconds(displaySeconds)}`;
};

const timerfuncs = {
  toMinutes,
  toSeconds,
  toDisplay,
};

export default timerfuncs;