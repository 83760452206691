import React, { useEffect, useState, useContext } from 'react';

import SmallCardWithUser from '../../components/liftCards/SmallCardWithUser';
import Spinner from '../../components/spinner';

import { mapServerLiftsToSmallLifts } from '../../utils/lift-trackers';
import { formatDate } from '../../utils/formatDate';

import { chunkArray } from '../../localDatabase/serverFriends';
import { themeContext } from '../../contexts/theme';
import { userContext } from '../../contexts/currentUser';
import { currentUserFriendsUIDs } from '../../localDatabase/serverFriends';

import { collectionGroup, query, where, getDocs, orderBy, limit } from "firebase/firestore";
import { db } from '../../services/firestoredb';

const Activites = () => {
    const theme = useContext(themeContext);
    const currentUser = useContext(userContext);

    const [friendsLiftsloading, setfriendsLiftsloading] = useState(true);
    const [friendsLifts, setfriendsLifts] = useState([]);

    useEffect(() => {
        setfriendsLiftsloading(true);
        currentUserFriendsUIDs(currentUser.uid, (friends_uids) => {
            if (friends_uids.length > 0) {
                const friendPromises = chunkArray(friends_uids, 9).map(
                    groupedFriendUids => {
                        return new Promise((resolve) => {
                            const fetchGroupOfFriendsLift = async () => {
                                const friendGroupedLifts = query(collectionGroup(db, 'lift_tracker_groups'), where('user_id', 'in', groupedFriendUids), orderBy('lifted_at', 'desc'), limit(20));
                                const querySnapshot = await getDocs(friendGroupedLifts);
                                let returnedLifts = []
                                querySnapshot.forEach((doc) => {
                                    returnedLifts.push(doc.data())
                                });
                                resolve(returnedLifts)
                            }
                            fetchGroupOfFriendsLift();
                        });
                    },
                );
                Promise.all(friendPromises).then((lifts) => {
                    setfriendsLifts(mapServerLiftsToSmallLifts(lifts.flat()));
                    setfriendsLiftsloading(false);
                });
            } else {
                setfriendsLiftsloading(false);
            }
        });
    }, [ currentUser.uid]);

    return (
        <div
            style={{
                marginTop: friendsLiftsloading ? 100 : 0,
            }}>
            <Spinner loading={friendsLiftsloading} />
            {friendsLifts.length > 0 &&
                friendsLifts.sort((a, b) => (formatDate(a.lifted_at_date) > formatDate(b.lifted_at_date)) ? 1 : -1).reverse().map(lift => {
                    return (
                        <SmallCardWithUser key={lift.id} lift={lift} local={false} />
                    );
                })}
            {!friendsLiftsloading && friendsLifts.length === 0 && (
                <div
                    style={{
                        padding: 20,
                        margin: 20,
                        backgroundColor: theme.darkMode
                            ? `rgba(255,255,255,0.1)`
                            : 'rgba(255,255,255,0.4)',
                        height: 60,
                        borderRadius: 5,
                        justifyContent: 'center',
                        alignContent: 'center',
                        marginTop: 60,
                        borderWidth: 1,
                        borderColor: 'rgba(0,0,0,0.1)',
                    }}>
                    <div
                        style={{
                            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
                            fontSize: 17,
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}>
                        Your Friends don't have any lifts yet.
                    </div>
                </div>
            )}
        </div>
    );
};

export default Activites;