import React, { useState, useContext } from 'react';
import { themeContext } from '../../../contexts/theme';
import { userContext } from '../../../contexts/currentUser';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const PowerliftingSettingsPage = () => {
  const theme = useContext(themeContext);
  const currentUser = useContext(userContext);
  const [mode, setMode] = useState(theme.mode || 'weightlifter');
  const [updating, setUpdating] = useState();
  const [saveToolTipOpen, setSaveToolTipOpen] = useState(false);

  return (
    <div>
      <Snackbar open={saveToolTipOpen} autoHideDuration={6000} onClose={() => setSaveToolTipOpen(false)}>
        <Alert onClose={() => setSaveToolTipOpen(false)} severity="success" sx={{ width: '100%' }}>
          Mode Updated!
        </Alert>
      </Snackbar>
      <div style={{ flexDirection: 'row', marginBottom: 25 }}>
        <div
          style={{
            marginLeft: 10,
            fontSize: 18,
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
          }}>
          Basic Mode - You only care about the exercise, reps, and sets.
          Everything is easier to use and bigger with less stuff on the screen.
        </div>
      </div>
      <div style={{ flexDirection: 'row', marginBottom: 25 }}>
        <div
          style={{
            marginLeft: 10,
            fontSize: 18,
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
          }}>
          Detailed Mode - You care about specific muscles and how much volumne
          per set and your PRs(Personal Records) on top of the basic mode. You
          get detailed charts on progress over time, etc.
        </div>
      </div>
      <div style={{ flexDirection: 'row' }}>
        <RadioGroup
          aria-labelledby="mode-radio-buttons-group-label"
          defaultValue={mode}
          name="radio-buttons-group"
          onChange={(e) => setMode(e.target.value)}
        >
          <FormControlLabel value="weightlifter" control={<Radio />} label="Detailed Mode" />
          <FormControlLabel value="basic" control={<Radio />} label="Basic Mode" />
        </RadioGroup>
      </div>
      <Button
        variant="contained"
        disabled={updating}
        fullWidth
        onClick={() => {
          const doit = async () => {
            setUpdating(true);
            await theme.changeTheme()(currentUser.uid, {
              mode: mode,
            });
            setSaveToolTipOpen(true);
            setUpdating(false);
          }
          doit()
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default PowerliftingSettingsPage;