import React, { useState, useEffect, useContext } from 'react';
import { getCurrentUser } from '../../../localDatabase/serverUsers';
import { Cloud, ThumbUp, ThumbUpAltOutlined } from '@material-ui/icons';
import { themeContext } from '../../../contexts/theme';
import { userContext } from '../../../contexts/currentUser';
import { httpsCallable } from "firebase/functions";
import LikeModal from './likeModal';
import { uniq } from 'lodash';

import { db, cloudFunctions } from '../../../services/firestoredb';
import { doc, onSnapshot, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";

const LiveLiftLikeButton = ({ lift_id, lift_user_uid, lift_name }) => {
    const [likeUsers, setLikeUsers] = useState([])
    const [likes, setLikes] = useState([])
    const [likesloading, setlikesloading] = useState(true);
    const [addingLike, setAddingLike] = useState(false);
    const [likesModalVisible, setLikesModalVisible] = useState(false);
    const theme = useContext(themeContext);
    const currentUser = useContext(userContext);


    useEffect(() => {
        let subscriber = () => { };
        subscriber = onSnapshot(doc(db, "lift_likes", lift_id), (documentSnapshot) => {
            if (documentSnapshot.exists()) {
                setLikes(documentSnapshot.data().likes || []);

                const likeuserpromise = uniq(documentSnapshot.data().likes || []).map(luid => {
                    return new Promise((resolve) => {
                        getCurrentUser(luid, (userData) => {
                            resolve(userData)
                        })
                    })
                })

                Promise.all(likeuserpromise).then(likeusers => {
                    setLikeUsers(likeusers.filter(x => x !== undefined && x !== null));
                    setlikesloading(false);
                })
            } else {
                setlikesloading(false);
            }

        });

        // Unsubscribe from events when no longer in use
        return () => subscriber();
    }, [lift_user_uid, lift_id]);


    return <>
        {addingLike || likesloading ? (
            <div style={{ marginLeft: 10 }}>
                <Cloud style={{ fill: `rgb(${theme.primary.main})`, fontSize: theme.mode === 'weightlifter' ? 25 : 30 }} />
            </div>
        ) : (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {likes && likes.length > 0 &&
                    <div
                        style={{
                            padding: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 20,
                            backgroundColor: theme.darkMode
                                ? 'rgba(255,255,255,0.1)'
                                : 'rgba(0,0,0,0.1)',
                        }}
                        onClick={() => { setLikesModalVisible(true) }}>
                        <div
                            style={{
                                fontSize: 15,
                                color: theme.darkMode
                                    ? 'rgb(255,255,255)'
                                    : 'rgb(0,0,0)',
                            }}>{`${likes ? likes.length : ''}`}</div>
                    </div>}
                <div onClick={async () => {
                    setAddingLike(true);
                    await updateDoc(doc(db, "lift_likes", lift_id), {
                        likes:
                            likes && likes.includes(currentUser.uid)
                                ? arrayRemove(
                                    currentUser.uid,
                                )
                                : arrayUnion(
                                    currentUser.uid,
                                ),
                    }, { merge: true });
                    if (likes && !likes.includes(currentUser.uid) && currentUser.uid !== lift_user_uid) {
                        const sendFriendsNotification = httpsCallable(cloudFunctions, 'friendRequestNotification');
                        sendFriendsNotification({
                            friendUid: lift_user_uid,
                            title: `${currentUser.displayName} liked your workout`,
                            body: lift_name,
                            tab: 'Feed',
                            screen: '/activity/',
                            initialParams: {
                                lift_id: lift_id,
                                uid: lift_user_uid,
                            },
                        })
                        setAddingLike(false);
                    }else{
                        setAddingLike(false);
                    }
                }}>
                    {likes && likes.length > 0 && likes.includes(currentUser.uid) ?
                        <ThumbUp style={{ fill: `rgb(${theme.primary.main})`, fontSize: theme.mode === 'weightlifter' ? 25 : 30 }} /> :
                        <ThumbUpAltOutlined style={{
                            fill: theme.darkMode
                                ? 'rgb(255,255,255)'
                                : 'rgb(0,0,0)', fontSize: theme.mode === 'weightlifter' ? 25 : 30
                        }} />}
                </div>
            </div>
        )}
        <LikeModal likes={likes} likeUsers={likeUsers} likesModalVisible={likesModalVisible} setLikesModalVisible={setLikesModalVisible} />
    </>
};

export default LiveLiftLikeButton;