import React, { useState, useContext, useEffect, useCallback } from 'react';
import Friend from '../../components/friends/Friend';
import Spinner from '../../components/spinner';
import { formatDateTime } from '../../utils/formatDate';

import {
  currentUserFriends,
  currentUserPendingFriends,
  searchForFriends
} from '../../localDatabase/serverFriends';
import { userContext } from '../../contexts/currentUser';
import { themeContext } from '../../contexts/theme';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { Search } from '@material-ui/icons';

const Friends = () => {
  const currentUser = useContext(userContext);
  const theme = useContext(themeContext);
  const [pending_friends, set_pending_friends] = useState([]);
  const [pendingLoading, setPendingLoading] = useState(false);
  const [friends, set_friends] = useState([]);
  const [friendsLoading, setFriendsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchFriends, setSearchFriends] = useState([]);
  const loading = friendsLoading || pendingLoading || searchLoading;


  const fetchUserData = useCallback(() => {
    setPendingLoading(true);
    setFriendsLoading(true);

    currentUserPendingFriends(currentUser.uid, (pendingFriends) => {
      set_pending_friends(pendingFriends);
      setPendingLoading(false);
    });

    currentUserFriends(currentUser.uid, (friends) => {
      set_friends(friends);
      setFriendsLoading(false);
    });
  }, [currentUser.uid]);

  const searchForUsers = async () => {
    setSearchLoading(true);
    setSearchFriends([]);
    searchForFriends(searchText, currentUser.uid, (friends) => {
      setSearchFriends(friends);
      setSearchLoading(false);
    })
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData])


  return (
    <div>
      <Spinner loading={loading} />
      <Paper
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search for Friends"
          inputProps={{ 'aria-label': 'search for friends' }}
          onChange={(event) => setSearchText(event.target.value)}
        />
        <IconButton sx={{ p: '10px' }} aria-label="search" disabled={loading} onClick={() => {
          searchForUsers()
        }}>
          <Search />
        </IconButton>
      </Paper>
      {searchFriends.map(f => {
              return (
                <Friend
                  key={`friend-${f.id}`}
                  id={f.uid}
                  name={f.displayName}
                  imageUrl={f.photoURL}
                  status={f.status}
                  joined_at={formatDateTime(f.created_at)}
                  cb={searchForUsers}
                />
              );
            })}
      {searchFriends.length === 0 && (
        <div
          style={{
            padding: 20,
            marginTop: 20,
            backgroundColor: theme.darkMode
              ? `rgba(255,255,255,0.1)`
              : 'rgba(255,255,255,0.4)',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0.1)',
          }}>
          <div
            style={{
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            }}>
            Nobody matches your search.
          </div>
        </div>
      )}
      <div style={{padding: 10}} />
      <div
        style={{
          fontSize: 23,
          color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
        }}>
        Pending Friends
      </div>
      {pending_friends.map(f => {
        return (
          <Friend
            key={f.uid}
            id={f.uid}
            name={f.displayName}
            imageUrl={f.photoURL}
            status={f.status}
            joined_at={formatDateTime(f.created_at)}
            cb={fetchUserData}
          />
        );
      })}
      {pending_friends.length === 0 && (
        <div
          style={{
            padding: 20,
            marginTop: 20,
            backgroundColor: theme.darkMode
              ? `rgba(255,255,255,0.1)`
              : 'rgba(255,255,255,0.4)',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0.1)',
          }}>
          <div
            style={{
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            }}>
            You have no pending friends requests.
          </div>
        </div>
      )}
      <div style={{ height: 15, width: '100%' }} />
      <div
        style={{
          fontSize: 23,
          color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
        }}>
        Friends
      </div>
      {friends.map(f => {
        return (
          <Friend
            key={`pending-${f.uid}`}
            id={f.uid}
            name={f.displayName}
            imageUrl={f.photoURL}
            status={f.status}
            joined_at={formatDateTime(f.created_at)}
            cb={fetchUserData}
          />
        );
      })}
      {friends.length === 0 && (
        <div
          style={{
            padding: 20,
            marginTop: 20,
            backgroundColor: theme.darkMode
              ? `rgba(255,255,255,0.1)`
              : 'rgba(255,255,255,0.4)',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: 'rgba(0,0,0,0.1)',
          }}>
          <div
            style={{
              color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            }}>
            No friends yet.
          </div>
        </div>
      )}
    </div>
  );
};

export default Friends;