import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const EquipmentTypeFilter = ({ equipmentType, onEquipmentTypeClick, theme }) => {
  return (
    <div style={{ marginTop: 5, paddingLeft: 5 }}>
      <div
        style={{
          fontSize: 20,
          padding: 5,
          fontWeight: 'bold',
          color: theme.darkMode ? `rgb(255,255,255)` : `rgb(0,0,0)`,
        }}>
        Equipment
      </div>
      <Stack direction="row" sx={{flexWrap: 'wrap'}}>
        {[
          'Barbell',
          'Dumbbell',
          'Body Only',
          'Time',
          'Bands',
          'Cable',
          'E-Z Curl Bar',
          'Exercise Ball',
          'Foam Roll',
          'Kettlebells',
          'Machine',
          'Medicine Ball',
          'Other',
        ].map((equipment_type, index) => {
          const selected = equipment_type === equipmentType;
          return (
            <Chip
              sx={{marginRight: 1, marginTop: 1}}
              key={`option-${index}`}
              label={equipment_type}
              size={'small'}
              onClick={() => {
                if (equipmentType === equipment_type) {
                  onEquipmentTypeClick(undefined);
                } else {
                  onEquipmentTypeClick(equipment_type);
                }
              }}
              color={selected ? "primary" : "default"}
              variant={selected ? "filled" : "outlined"} />
          );
        })}
      </Stack>
    </div>
  );
};

export default EquipmentTypeFilter;