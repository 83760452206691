import React, { useContext, useState, useEffect } from 'react';
import Profile from '../profile/Profile';
import { userContext } from '../../contexts/currentUser';
import { exercisesContext } from '../../contexts/exercises';
import { liftsContext } from '../../contexts/lifts';
import { fetchAllPhotos } from '../../localDatabase/serverPhotos';


const Dashboard = () => {
    const currentUser = useContext(userContext);
    const exercises = useContext(exercisesContext);
    const lift_data = useContext(liftsContext);
    // fetch photos
    const [photos, setphotos] = useState([]);
    const [thumbs, setthumbs] = useState([]);

    useEffect(() => {
        fetchAllPhotos(currentUser.uid, (serverPhotos, serverThumbs) => {
            setphotos(serverPhotos);
            setthumbs(serverThumbs);
        })
    }, [currentUser.uid]);
    return <Profile currentUser={currentUser} exercises={exercises} lift_data={lift_data} photos={photos} thumbs={thumbs} />
};

export default Dashboard;