import React, { useContext } from 'react';

import { filterIntorZero } from '../utils/filterInt';
import { themeContext } from '../contexts/theme';

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryScatter,
  VictoryGroup,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryLabel
} from 'victory';

const ExerciseVolumeLineChart = ({ lastData, liftState, height }) => {
  const theme = useContext(themeContext);
  let labels = [];
  let datasetsWithLabels = [];
  const setsRepsWeight = liftState.setsRepsWeight || [];
  if (lastData !== undefined || liftState !== undefined) {
    const maxNumOfSets =
      ((lastData && lastData.max_num_of_sets) || 0) >
        ((liftState && setsRepsWeight.length) || 0)
        ? lastData.max_num_of_sets
        : setsRepsWeight.length;

    for (let step = 0; step < maxNumOfSets; step++) {
      labels = labels.concat(step + 1);
    }

    if (lastData !== undefined) {
      const avgWeights = {
        data: labels.map(setNum => {
          const obj = (lastData && lastData.avgs[setNum]) || {};
          return filterIntorZero(obj['weight']) > 0 &&
            filterIntorZero(obj['num_of_reps']) > 0
            ? filterIntorZero(obj['weight']) *
            filterIntorZero(obj['num_of_reps'])
            : 0;
        }),
        color: (opacity = 1) => `rgb(${theme.primary.main},0.5)`,
      };
      datasetsWithLabels = datasetsWithLabels.concat({ label: 'Avg: ', data: avgWeights, color: `rgb(${theme.primary.main},0.5)` });

      if (lastData && lastData.srws && Object.keys(lastData.srws).length > 0) {
        for (let lift = 0; lift < Object.keys(lastData.srws).length; lift++) {
          let lifts = {
            data: [],
            color: (opacity = 1) =>
              `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'}, 0.25)`,
          };
          for (let set = 0; set < maxNumOfSets; set++) {
            const obj = lastData.srws[lift][set] || {};
            lifts.data = lifts.data.concat(
              filterIntorZero(obj['weight']) > 0 &&
                filterIntorZero(obj['num_of_reps']) > 0
                ? filterIntorZero(obj['weight']) *
                filterIntorZero(obj['num_of_reps'])
                : undefined,
            );
          }
          datasetsWithLabels = datasetsWithLabels.concat({ label: 'Prev: ', data: lifts, color: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'}, 0.25)` });
        }
      }
    }

    if (liftState !== undefined) {
      const currentLifts = {
        data: labels.map(setNum => {
          const obj = setsRepsWeight[setNum - 1] || {};
          return filterIntorZero(obj['weight']) > 0 &&
            filterIntorZero(obj['num_of_reps']) > 0
            ? filterIntorZero(obj['weight']) *
            filterIntorZero(obj['num_of_reps'])
            : undefined;
        }),
        color: (opacity = 1) => `rgb(${theme.primary.main})`,
      };
      datasetsWithLabels = datasetsWithLabels.concat({ label: 'This: ', data: currentLifts, color: `rgb(${theme.primary.main})` });
    }
  }

  const lineChartData = datasetsWithLabels.map(dsl => {
    return {
      color: dsl.color,
      data: dsl.data.data.map((d, i) => {
        return d !== undefined
          ? {
            x: i,
            y: d,
            prefix: dsl.label
          }
          : null;
      })
    }
  });


  const minYDomain = Math.min(
    ...lineChartData
      .flatMap(seriersData => seriersData.data.flatMap(d => d && d.y))
      .filter(x => x !== undefined && x !== null),
  );
  const maxYDomain = Math.max(
    ...lineChartData
      .flatMap(seriersData => seriersData.data.flatMap(d => d && d.y))
      .filter(x => x !== undefined && x !== null),
  );

  return labels.length < 1 ? null : (
    <div
      style={{
        display: 'flex'
      }}>
      <VictoryChart
        height={height || 200}
        domainPadding={{ y: 55 }}
        minDomain={{
          y: minYDomain === maxYDomain || minYDomain < 50 ? 0 : minYDomain,
        }}
        maxDomain={{
          y: minYDomain < 50 ? maxYDomain + 5 : maxYDomain + 20,
        }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `${datum.prefix || ''} ${datum.y || ''}`}
            labelComponent={
              <VictoryLabel backgroundPadding={10}
                backgroundStyle={{ fill: `rgba(${theme.darkMode ? '0,0,0,0.7' : '0,0,0,0.1'})` }}
              />
            }
          />
        }>
        <VictoryAxis
          crossAxis
          style={{
            grid: {
              fill: 'none',
              stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'
                }, 0.1)`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              pointerEvents: 'painted',
            },
            axis: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            ticks: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              size: 0,
            },
            tickLabels: {
              fontSize: 0,
              fill: `transparent`,
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          theme={VictoryTheme.material}
          standalone={true}
          style={{
            grid: {
              fill: 'none',
              stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'
                }, 0.1)`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              pointerEvents: 'painted',
            },
            axis: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              strokeDasharray: '10, 5',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            ticks: {
              stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
              size: 0,
            },
            tickLabels: {
              fontSize: 15,
              padding: 5,
              fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
            },
          }}
        />
        {lineChartData.map((seriersData, i) => (
          <VictoryGroup data={seriersData.data} key={`vg-${i}`}>
            <VictoryLine
              style={{
                data: { stroke: seriersData.color, strokeWidth: 3 },
                labels: { fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})` },
              }}
            />
            <VictoryScatter
              style={{ data: { fill: seriersData.color }, labels: { fill: 'transparent' }, }}
              size={4}
            />
          </VictoryGroup>
        ))}
      </VictoryChart>
    </div>
  );
};

export default ExerciseVolumeLineChart;