import useImportScript from '../hooks/importScript';

const Home = props => {

  useImportScript("https://storage.googleapis.com/brolifthomepage/js/scripts.js");

  return (<>
    <div className="spinner-wrapper">
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>

    <nav className="navbar navbar-expand-md navbar-dark navbar-custom fixed-top">
      <a className="navbar-brand logo-image" href="/">
        <img
          src="https://storage.googleapis.com/brolifthomepage/images/logo.png"
          alt="alternative"
        />
      </a>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExampleDefault"
        aria-controls="navbarsExampleDefault"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-awesome fas fa-bars"></span>
        <span className="navbar-toggler-awesome fas fa-times"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#header">
              HOME <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#features"> FEATURES </a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#preview"> PREVIEW </a>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle page-scroll"
              href="#details"
              id="navbarDropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              DETAILS
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <div className="dropdown-items-divide-hr"></div>
              <a className="dropdown-item" href="/privacy">
                <span className="item-text">PRIVACY POLICY</span>
              </a>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link page-scroll" href="#contact"> CONTACT </a>
          </li>

          <li className="nav-item">
            <a className="nav-link page-scroll" href="/login"> LOGIN </a>
          </li>
        </ul>
        <span className="nav-item social-icons">
          <span className="fa-stack">
            <a href="#your-link">
              <i className="fas fa-circle fa-stack-2x"></i>
              <i className="fab fa-facebook-f fa-stack-1x"></i>
            </a>
          </span>
          <span className="fa-stack">
            <a href="#your-link">
              <i className="fas fa-circle fa-stack-2x"></i>
              <i className="fab fa-twitter fa-stack-1x"></i>
            </a>
          </span>
        </span>
      </div>
    </nav>

    <header id="header" className="header">
      <div className="header-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container">
                <h1>
                  TRACK YOUR <br />
                  <span id="js-rotating">WORKOUTS, PRs</span>
                </h1>
                <p className="p-large">
                  BROLIFT is your best friend for managing your exercises,
                  creating routines, planning your workouts and recording your
                  progress all the way!
                </p>
                <a
                  className="btn-solid-lg page-scroll"
                  href="https://apps.apple.com/us/app/brolift/id1495919572"
                >
                  <i className="fab fa-apple"></i>APP STORE
                </a>
                <a
                  className="btn-solid-lg page-scroll"
                  href="https://play.google.com/store/apps/details?id=com.brolift"
                >
                  <i className="fab fa-google-play"></i>PLAY STORE
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-container">
                <img
                  className="img-fluid"
                  src="https://storage.googleapis.com/brolifthomepage/images/header-iphone.png"
                  alt="alternative"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div id="features" className="tabs">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>FEATURES</h2>
            <div className="p-heading p-large">
              BROLIFT is a workout tracker with a focus on simplicity and clean
              design.
            </div>
          </div>
        </div>
        <div className="row">
          <ul className="nav nav-tabs" id="lenoTabs" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="nav-tab-1"
                data-toggle="tab"
                href="#tab-1"
                role="tab"
                aria-controls="tab-1"
                aria-selected="true"
              >
                <i className="fas fa-star"></i>HIGHLIGHTS
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="nav-tab-2"
                data-toggle="tab"
                href="#tab-2"
                role="tab"
                aria-controls="tab-2"
                aria-selected="false"
              >
                <i className="fas fa-user"></i>PROFILE
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="nav-tab-3"
                data-toggle="tab"
                href="#tab-3"
                role="tab"
                aria-controls="tab-3"
                aria-selected="false"
              >
                <i className="fas fa-binoculars"></i>EXTRAS
              </a>
            </li>
          </ul>

          <div className="tab-content" id="lenoTabsContent">
            <div
              className="tab-pane fade show active"
              id="tab-1"
              role="tabpanel"
              aria-labelledby="tab-1"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="card left-pane first">
                      <div className="card-body">
                        <div className="text-wrapper">
                          <h4 className="card-title">Goal Setting</h4>
                          <p>
                            Set your Goals and track your progress with the help
                            of in-app charts &amp; graphs features
                          </p>
                        </div>
                        <div className="card-icon">
                          <i className="far fa-compass"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card left-pane">
                      <div className="card-body">
                        <div className="text-wrapper">
                          <h4 className="card-title">Join your Friends</h4>
                          <p>
                            BROLIFT also comes with an option to join your
                            friends and track their activites
                          </p>
                        </div>
                        <div className="card-icon">
                          <i className="far fa fa-user-friends"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card left-pane">
                      <div className="card-body">
                        <div className="text-wrapper">
                          <h4 className="card-title">Detailed Reports</h4>
                          <p>
                            Workouts / routines / exercises history - view all
                            your workouts history and see how your progress goes
                          </p>
                        </div>
                        <div className="card-icon">
                          <i className="far fa-gem"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <img
                      className="img-fluid"
                      src="https://storage.googleapis.com/brolifthomepage/images/features-iphone-1.png"
                      alt="alternative"
                    />
                  </div>

                  <div className="col-lg-4">
                    <div className="card right-pane first">
                      <div className="card-body">
                        <div className="card-icon">
                          <i className="far fa-calendar-check"></i>
                        </div>
                        <div className="text-wrapper">
                          <h4 className="card-title">Planned Workouts</h4>
                          <p>
                            Schedule your workouts, make highlights and
                            periodical evaluations using the provided calendar
                            option
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card right-pane">
                      <div className="card-body">
                        <div className="card-icon">
                          <i className="far fa-bookmark"></i>
                        </div>
                        <div className="text-wrapper">
                          <h4 className="card-title">Workout Log</h4>
                          <p>
                            View and navigate daily workout logs quickly by
                            swiping between them within app
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card right-pane">
                      <div className="card-body">
                        <div className="card-icon">
                          <i className="fas fa fa-cloud"></i>
                        </div>
                        <div className="text-wrapper">
                          <h4 className="card-title">Cloud Saving</h4>
                          <p>
                            Connect your account with Gmail/iCloud and sync your
                            data online
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="tab-2"
              role="tabpanel"
              aria-labelledby="tab-2"
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <img
                      className="img-fluid"
                      src="https://storage.googleapis.com/brolifthomepage/images/features-iphone-2.png"
                      alt="alternative"
                    />
                  </div>

                  <div className="col-md-8">
                    <div className="text-area">
                      <h3>Easily manage all of your acitvites</h3>
                      <p>
                        In profile section, you can keep an eye on your
                        everday's activites &amp; track your progress on daily
                        basis with a more in depth view with the help of charts.
                      </p>
                    </div>

                    <div className="icon-cards-area">
                      <div className="card">
                        <div className="card-icon">
                          <i className="fas fa-cube"></i>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Activity Logs</h4>
                          <p>
                            Prodived by the charts &amp; graphs, you can keep a
                            tab on all of your activites
                          </p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-icon">
                          <i className="far fa-calendar-check"></i>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Manage your Routines</h4>
                          <p>
                            Simple and intuitive way to view your routines with
                            their exercises
                          </p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-icon">
                          <i className="far fa fa-chart-pie"></i>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Detailed Reports</h4>
                          <p>
                            Workouts / routines / exercises history - view all
                            your workouts history and see how your progress goes
                          </p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-icon">
                          <i className="far fa fa-tape"></i>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Body Measurements</h4>
                          <p>
                            Body measurements - add your body metrics over time
                            and see how you gain mass or lose weight
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="tab-3"
              role="tabpanel"
              aria-labelledby="tab-3"
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="icon-cards-area">
                      <div className="card">
                        <div className="card-icon">
                          <i className="far fa fa-rss"></i>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Feed Section</h4>
                          <p>
                            You can add friends and like and comment on your
                            friend's workouts
                          </p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-icon">
                          <i className="far fa fa-palette"></i>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Customization Options</h4>
                          <p>
                            The simplest interface of any fitness app with a lot
                            of customization options for users
                          </p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-icon">
                          <i className="fas fa fa-sync"></i>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Sync Your Data</h4>
                          <p>
                            On iOS you can also sync your apple health data so
                            you can see your heart rate and calories per workout
                          </p>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-icon">
                          <i className="far fa fa-chart-area"></i>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Advanced Reports</h4>
                          <p>
                            Advanced Statistics show your personal records and
                            progression and even calculate your one rep max and
                            total weight lifted!
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="text-area">
                      <h3>User Friendly</h3>
                      <p>
                        BROLIFT is your best friend for managing your exercises,
                        creating routines, planning your workouts and recording
                        your progress all the way.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <img
                      className="img-fluid"
                      src="https://storage.googleapis.com/brolifthomepage/images/features-iphone-3.png"
                      alt="alternative"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="preview" className="basic-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>PREVIEW</h2>
            <div className="p-heading p-large">
              BROLIFT is your best friend for managing your exercises, creating
              routines, planning your workouts and recording your progress all
              the way.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="image-container">
              <div className="video-wrapper">
                <a
                  className="popup-youtube"
                  href="https://www.youtube.com/watch?v=SVw3PIJX4cA"
                  data-effect="fadeIn"
                >
                  <img
                    className="img-fluid"
                    src="https://storage.googleapis.com/brolifthomepage/images/video-frame.jpg"
                    alt="alternative"
                  />
                  <span className="video-play-button">
                    <span></span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="details" className="basic-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img
              className="img-fluid"
              src="https://storage.googleapis.com/brolifthomepage/images/details-1-iphone.png"
              alt="alternative"
            />
          </div>
          <div className="col-lg-6">
            <div className="text-container">
              <h3>Track your Workouts</h3>
              <p>
                BROLIFT is your best friend for managing your exercises,
                creating routines, planning your workouts and recording your
                progress all the way.
              </p>
              <a className="btn-solid-reg page-scroll" href="#download-now">
                Download Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="basic-3">
      <div className="second">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container">
                <h3>Advanced Reports</h3>
                <p>
                  In profile section, you can keep an eye on your everday's
                  activites &amp; track your progress on daily basis with a more
                  in depth view with the help of charts.
                </p>
                <a className="btn-solid-reg page-scroll" href="#download-now">
                  Download Now
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src="https://storage.googleapis.com/brolifthomepage/images/details-2-iphone.png"
                alt="alternative"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="slider-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="slider-container">
              <div className="swiper-container image-slider">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-1.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-1.png"
                        alt="alternative"
                      />
                    </a>
                  </div>

                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-2.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-2.png"
                        alt="alternative"
                      />
                    </a>
                  </div>

                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-3.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-3.png"
                        alt="alternative"
                      />
                    </a>
                  </div>

                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-4.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-4.png"
                        alt="alternative"
                      />
                    </a>
                  </div>

                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-5.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-5.png"
                        alt="alternative"
                      />
                    </a>
                  </div>

                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-6.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-6.png"
                        alt="alternative"
                      />
                    </a>
                  </div>

                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-7.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-7.png"
                        alt="alternative"
                      />
                    </a>
                  </div>

                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-8.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-8.png"
                        alt="alternative"
                      />
                    </a>
                  </div>

                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-9.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-9.png"
                        alt="alternative"
                      />
                    </a>
                  </div>

                  <div className="swiper-slide">
                    <a
                      href="https://storage.googleapis.com/brolifthomepage/images/screenshot-10.png"
                      className="popup-link"
                      data-effect="fadeIn"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/brolifthomepage/images/screenshot-10.png"
                        alt="alternative"
                      />
                    </a>
                  </div>
                </div>

                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="basic-4" id="download-now">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-5">
            <div className="text-container">
              <h2>Download <span className="blue">BROLIFT</span></h2>
              <p className="p-large">
                BROLIFT is your best friend for managing your exercises,
                creating routines, planning your workouts and recording your
                progress all the way!
              </p>
              <a
                className="btn-solid-lg"
                href=" https://apps.apple.com/us/app/brolift/id1495919572"
              >
                <i className="fab fa-apple"></i>APP STORE
              </a>
              <a
                className="btn-solid-lg"
                href="https://play.google.com/store/apps/details?id=com.brolift"
              >
                <i className="fab fa-google-play"></i>PLAY STORE
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-xl-7">
            <div className="image-container">
              <img
                className="img-fluid"
                src="https://storage.googleapis.com/brolifthomepage/images/download.png"
                alt="alternative"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="counter">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div id="counter">
              <div className="cell">
                <div className="counter-value number-count" data-count="231">1</div>
                <p className="counter-info">Happy Users</p>
              </div>
              <div className="cell">
                <div className="counter-value number-count" data-count="59">1</div>
                <p className="counter-info">Good Reviews</p>
              </div>
              <div className="cell">
                <div className="counter-value number-count" data-count="85">1</div>
                <p className="counter-info">Total Active Users</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="slider-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="slider-container">
              <div className="swiper-container card-slider">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="card">
                      <img
                        className="card-image"
                        src="https://storage.googleapis.com/brolifthomepage/images/testimonial-2.jpg"
                        alt="alternative"
                      />
                      <div className="card-body">
                        <p className="testimonial-text">
                          It has a really well variety of exercises and it keeps
                          track of how much time you spent with info out along
                          with the number of workouts you've done total. With
                          each exercise they give you a warm up before and
                          stretches after. You can put in your weight each time
                          you check and it'll keep that logged.
                        </p>
                        <p className="testimonial-author">Roy Smith</p>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="card">
                      <img
                        className="card-image"
                        src="https://storage.googleapis.com/brolifthomepage/images/testimonial-3.jpg"
                        alt="alternative"
                      />
                      <div className="card-body">
                        <p className="testimonial-text">
                          5 stars!! Great selection of workouts ! I'm using the
                          lower body and legs exercises now. Once I'm in better
                          shape, I'll add the planks. I add alarms to remind me
                          to move at work and to work out at home. I just
                          started using the meal planning, shopping, monitoring
                          feature. I love that all 3 of these ...
                        </p>
                        <p className="testimonial-author">Marsha Singer</p>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="card">
                      <img
                        className="card-image"
                        src="https://storage.googleapis.com/brolifthomepage/images/testimonial-4.jpg"
                        alt="alternative"
                      />
                      <div className="card-body">
                        <p className="testimonial-text">
                          I don't have that much time to workout and was
                          searching for something simple to follow that gives
                          you a good workout whenever you can spear some time
                          for it. This one does it in good taste without wearing
                          you out at the beginning. It also motivated me to
                          continue since it is not judging me when i d...
                        </p>
                        <p className="testimonial-author">Tim Shaw</p>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="card">
                      <img
                        className="card-image"
                        src="https://storage.googleapis.com/brolifthomepage/images/testimonial-5.jpg"
                        alt="alternative"
                      />
                      <div className="card-body">
                        <p className="testimonial-text">
                          love this app and the work out options are great for
                          me as I have not been an active person and the thought
                          of going to the gym scared me, this app helped get
                          into working out, and I love the fact that it tells
                          you how many calories you burned during each workout.
                          I like that it syncs with Google fi...
                        </p>
                        <p className="testimonial-author">Lindsay Spice</p>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="card">
                      <img
                        className="card-image"
                        src="https://storage.googleapis.com/brolifthomepage/images/testimonial-6.jpg"
                        alt="alternative"
                      />
                      <div className="card-body">
                        <p className="testimonial-text">
                          loved this program. all set up you just have to
                          follow. Love that you can adjust the interval time as
                          well as the number of sets. really happy for the warm
                          up and cool down options too. Would be nice to have a
                          follow-on program with some different exercises for
                          variety after you finish the first one...
                        </p>
                        <p className="testimonial-author">Ann Black</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="contact" className="form">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>CONTACT</h2>
            <ul className="list-unstyled li-space-lg">
              <li className="address">
                Don't hesitate to give us a call or just use the contact form
                below
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <form id="contactForm" data-toggle="validator" data-focus="false">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control-input"
                  id="cname"
                  required
                />
                <label className="label-control" htmlFor="cname"> Name </label>
                <div className="help-block with-errors"></div>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control-input"
                  id="cemail"
                  required
                />
                <label className="label-control" htmlFor="cemail"> Email </label>
                <div className="help-block with-errors"></div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control-textarea"
                  id="cmessage"
                  required
                ></textarea>
                <label className="label-control" htmlFor="cmessage">
                  Your message
                </label>
                <div className="help-block with-errors"></div>
              </div>
              <div className="form-group checkbox">
                <input
                  type="checkbox"
                  id="cterms"
                  value="Agreed-to-Terms"
                  required
                />
                I have read and agree to BROLIFT's stated conditions in
                <a href="/privacy">Privacy Policy</a>
                <div className="help-block with-errors"></div>
              </div>
              <div className="form-group">
                <button type="submit" className="form-control-submit-button">
                  SUBMIT MESSAGE
                </button>
              </div>
              <div className="form-message">
                <div id="cmsgSubmit" className="h3 text-center hidden"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-col">
              <h4>About BROLIFT</h4>
              <p>
                BROLIFT is a workout tracker with a focus on simplicity and
                clean design.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-col middle">
              <h4>Important Links</h4>
              <ul className="list-unstyled li-space-lg">
                <li className="media">
                  <i className="fas fa-square"></i>
                  <div className="media-body">
                    Read our
                    <a className="turquoise" href="/privacy"> Privacy Policy </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-col last">
              <h4>Social Media</h4>
              <span className="fa-stack">
                <a href="#your-link">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-facebook-f fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="#your-link">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-twitter fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="#your-link">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-google-plus-g fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="#your-link">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-instagram fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="#your-link">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-linkedin-in fa-stack-1x"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p className="p-small">Copyright © BROLIFT</p>
          </div>
        </div>
      </div>
    </div></>
  );
}

export default Home;
