import { uuidv4 } from '../../../utils/uuid';
import { removeItemAll } from '../../../utils/arrayHelper';
import { uniq } from 'lodash';

import { httpsCallable } from "firebase/functions";
import { db, cloudFunctions } from '../../../services/firestoredb';
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";

// export function paginateComments(
//   allComments,
//   comments,
//   from_commentId,
//   direction,
//   parent_commentId
// ) {
//   const c = [...allComments];
//   if (!parent_commentId) {
//     const lastIndex = allComments.findIndex(
//       c => c.commentId == from_commentId
//     );
//     if (direction == "up") {
//       comments = comments.concat(c.splice(lastIndex + 1, 5));
//     } else {
//       const start = lastIndex - 6 > 1 ? lastIndex - 6 : 0;

//       const part = c.slice(start, lastIndex);
//       console.log(start, lastIndex);
//       comments = [...part, ...comments];
//     }
//   } else {
//     const parentLastIndexDB = allComments.findIndex(
//       c => c.commentId == parent_commentId
//     );
//     const children = c[parentLastIndexDB].children;
//     const target = children.findIndex(c => c.commentId == from_commentId);
//     const existingIndex = comments.findIndex(
//       c => c.commentId == parent_commentId
//     );

//     if (direction == "up") {
//       const append = children.slice(target + 1, 5);
//       comments[existingIndex].children.concat(append);
//     } else {
//       const start = target - 6 >= 0 ? target : 0;
//       const prepend = children.slice(start - 6, target);
//       comments[existingIndex].children = [
//         ...prepend,
//         ...comments[existingIndex].children
//       ];
//     }
//   }
//   return comments;
// }

export function like(comments, cmnt, lift_id, user, lift_user_id) {
  if (cmnt.parentId) {
    updateDoc(doc(db, "lift_comment", lift_id), {
      comments: comments.map(comment => {
        if (comment.id === cmnt.parentId) {
          const children = comment.children.map(child => {
            if (child.id === cmnt.id) {
              let likes = cmnt.likes || [];
              let sendNotifcation = false;
              if (likes.includes(user.uid)) {
                likes = removeItemAll(likes, user.uid);
              } else {
                likes.push(user.uid);
                sendNotifcation = true;
              }

              if (sendNotifcation) {
                const sendFriendsNotification = httpsCallable(cloudFunctions, 'friendRequestNotification');

                sendFriendsNotification({
                  friendUid: child.user?.id,
                  title: `${user.displayName} liked your comment`,
                  body: cmnt.comment,
                  tab: 'Feed',
                  screen: '/activity/',
                  initialParams: {
                    lift_id: lift_id,
                    uid: lift_user_id,
                  },
                })
              }
              return {
                ...child,
                likes: likes,
              };
            } else {
              return child;
            }
          });

          return {
            ...comment,
            children: children,
          };
        } else {
          return comment;
        }
      }),
    }, { merge: true });
  } else {
    updateDoc(doc(db, "lift_comment", lift_id), {
      comments: comments.map(comment => {
        if (comment.id === cmnt.id) {
          let likes = cmnt.likes || [];
          let sendNotifcation = false;
          if (likes.includes(user.uid)) {
            likes = removeItemAll(likes, user.uid);
          } else {
            likes.push(user.uid);
            sendNotifcation = true;
          }

          if (sendNotifcation) {
            const sendFriendsNotification = httpsCallable(cloudFunctions, 'friendRequestNotification');

            sendFriendsNotification({
              friendUid: comment.user?.uid,
              title: `${user.displayName} liked your comment`,
              body: cmnt.comment,
              tab: 'Feed',
              screen: '/activity/',
              initialParams: {
                lift_id: lift_id,
                uid: lift_user_id,
              },
            })
          }

          return {
            parentId: null,
            reported: [],
            created_at: cmnt.updated_at,
            ...cmnt,
            likes: likes,
          };
        } else {
          return comment;
        }
      }),
    }, { merge: true });
  }
}

export function edit(comments, cmnt, text, lift_id, user) {
  if (text === undefined || text === null || text === '') {
    // TODO Maybe delete instead
    return;
  }
  if (cmnt.user?.uid === user.uid) {
    if (cmnt.parentId) {
      updateDoc(doc(db, "lift_comment", lift_id), {
        comments: comments.map(comment => {
          if (comment.id === cmnt.parentId) {
            const children = comment.children.map(child => {
              if (child.id === cmnt.id) {
                return {
                  ...child,
                  comment: text,
                  updated_at: new Date(),
                };
              } else {
                return child;
              }
            });

            return {
              ...comment,
              children: children,
            };
          } else {
            return comment;
          }
        }),
      }, { merge: true });
    } else {
      updateDoc(doc(db, "lift_comment", lift_id), {
        comments: comments.map(comment => {
          if (comment.id === cmnt.id) {
            return {
              parentId: null,
              likes: [],
              reported: [],
              created_at: cmnt.updated_at,
              ...cmnt,
              comment: text,
              updated_at: new Date(),
            };
          } else {
            return comment;
          }
        }),
      }, { merge: true });
    }
  }
}

export function deleteComment(comments, cmnt, lift_id, user) {
  if (cmnt.user?.uid === user.uid) {
    if (cmnt.parentId) {
      updateDoc(doc(db, "lift_comment", lift_id), {
        comments: comments.map(comment => {
          if (comment.id === cmnt.parentId) {
            const children = comment.children.filter(
              child => child.id !== cmnt.id,
            );
            return {
              ...comment,
              children: children,
            };
          } else {
            return comment;
          }
        }),
      }, { merge: true });
    } else {
      updateDoc(doc(db, "lift_comment", lift_id), {
        comments: arrayRemove(cmnt),
      }, { merge: true });
    }
  }
}

export async function save(
  comments,
  commentText,
  parentCommentId,
  user,
  lift_id,
  lift_user_id,
) {
  if (commentText === undefined || commentText === null || commentText === '') {
    return;
  }
  const created_at = new Date();

  if (
    parentCommentId === undefined ||
    parentCommentId === null ||
    parentCommentId === false
  ) {
    await updateDoc(doc(db, "lift_comment", lift_id), {
      comments: arrayUnion({
        id: uuidv4(),
        parentId: parentCommentId,
        comment: commentText,
        created_at: created_at,
        updated_at: created_at,
        user: {
          uid: user.uid,
        },
        likes: [],
        reported: [],
        children: [],
      }),
    }, { merge: true });
    if (lift_user_id !== user.uid) {
      const sendFriendsNotification = httpsCallable(cloudFunctions, 'friendRequestNotification');

      sendFriendsNotification({
        friendUid: lift_user_id,
        title: `${user.displayName} commented your workout`,
        body: commentText,
        tab: 'Feed',
        screen: '/activity/',
        initialParams: {
          lift_id: lift_id,
          uid: lift_user_id,
        },
      })
    }
  } else {
    let parentComment;
    await updateDoc(doc(db, "lift_comment", lift_id), {
      comments: comments.map(c => {
        if (parentCommentId === c.id) {
          parentComment = c;
          const existingChildren = c.children || [];
          c.children = [
            ...existingChildren,
            {
              id: uuidv4(),
              parentId: parentCommentId,
              comment: commentText,
              created_at: created_at,
              updated_at: created_at,
              user: {
                uid: user.uid,
              },
              likes: [],
              reported: [],
            },
          ];
          return c;
        } else {
          return c;
        }
      }),
    }, { merge: true });

    const sendFriendsNotification = httpsCallable(cloudFunctions, 'friendRequestNotification');

    let peopleWhoGotNotification = [];

    //workout owner
    if (lift_user_id !== user.uid) {
      peopleWhoGotNotification.push(lift_user_id);
      sendFriendsNotification({
        friendUid: lift_user_id,
        title: `${user.displayName} replied to a comment on your workout`,
        body: commentText,
        tab: 'Feed',
        screen: '/activity/',
        initialParams: {
          lift_id: lift_id,
          uid: lift_user_id,
        },
      })
    }

    // comment owner
    if (parentComment?.user?.uid !== user.uid) {
      peopleWhoGotNotification.push(parentComment?.user?.uid);
      sendFriendsNotification({
        friendUid: parentComment?.user?.uid,
        title: `${user.displayName} replied to your comment on a workout`,
        body: commentText,
        tab: 'Feed',
        screen: '/activity/',
        initialParams: {
          lift_id: lift_id,
          uid: lift_user_id,
        },
      })
    }

    // people in thread
    uniq((parentComment?.children || []).map(child => child?.user?.uid).filter(x => x !== undefined && x !== null)).forEach((personInThread) => {
      if (personInThread !== user.uid && !peopleWhoGotNotification.includes(personInThread)) {
        peopleWhoGotNotification.push(personInThread);
        sendFriendsNotification({
          friendUid: personInThread,
          title: `${user.displayName} replied to a thread you are in`,
          body: commentText,
          tab: 'Feed',
          screen: '/activity/',
          initialParams: {
            lift_id: lift_id,
            uid: lift_user_id,
          },
        })
      }
    })
  }
}

export function report(comments, cmnt, lift_id, user) {
  if (cmnt.parentId) {
    updateDoc(doc(db, "lift_comment", lift_id), {
      comments: comments.map(comment => {
        if (comment.id === cmnt.parentId) {
          const children = comment.children.map(child => {
            if (child.id === cmnt.id) {
              let reported = cmnt.reported || [];
              if (reported.includes(user.uid)) {
                reported = removeItemAll(reported, user.uid);
              } else {
                reported.push(user.uid);
              }

              return {
                ...child,
                reported: reported,
              };
            } else {
              return child;
            }
          });

          return {
            ...comment,
            children: children,
          };
        } else {
          return comment;
        }
      }),
    }, { merge: true });
  } else {
    updateDoc(doc(db, "lift_comment", lift_id), {
      comments: comments.map(comment => {
        if (comment.id === cmnt.id) {
          let reported = cmnt.reported || [];
          if (reported.includes(user.uid)) {
            reported = removeItemAll(reported, user.uid);
          } else {
            reported.push(user.uid);
          }

          return {
            parentId: null,
            likes: [],
            created_at: cmnt.updated_at,
            ...cmnt,
            reported: reported,
          };
        } else {
          return comment;
        }
      }),
    }, { merge: true });
  }
}