import React, {useState, useEffect, useContext} from 'react';
import ExerciseSearch from './ExerciseSearch';

import {getPRsByExerciseID} from '../../localDatabase/serverLifts';
// import {allExercises} from '../../localDatabase/serverExercises';
import {userContext} from '../../contexts/currentUser';
import {exercisesContext} from '../../contexts/exercises';
import {bodyParts} from '../../utils/exerciseFilters';

const ExerciseSelector = ({
  muscleType,
  equipmentType,
  onExerciseClick,
  onMuscleTypeClick,
  onEquipmentTypeClick,
  exerciseError,
  exerciseId,
  lift_id,
  goToLift,
  goToNextExercise,
  searchValue,
  setSearchValue,
  autoFocus,
  noPr,
  // uid,
  lift_data,
  onBodyPartClick,
  bodyPart,
}) => {
  const currentUser = useContext(userContext);
  const localExercises = useContext(exercisesContext);
  const [exercises, setExercises] = useState([]);
  const [exercisesLoading, setexercisesLoading] = useState(true);
  const local = true;

  useEffect(() => {
    if (local) {
      setexercisesLoading(true);
      setExercises(localExercises);
      setexercisesLoading(false);
    }
    // else {
    //   setexercisesLoading(true);
    //   allExercises(uid || currentUser.uid, (serverExercises) => {
    //     setExercises(serverExercises);
    //     setexercisesLoading(false);
    //   });
    // }
  }, [currentUser, local, localExercises]);

  const PRsByExerciseID = getPRsByExerciseID(lift_data);
  const filteredExercises = exercises
    .map((e) => {
      return {
        ...e,
        has_pr_sort: noPr ? undefined : PRsByExerciseID[e.id] ? true : false,
      };
    })
    .filter((e) => {
      let shouldShow = true;
      if (muscleType && equipmentType) {
        shouldShow =
          e.muscle_type === muscleType && e.equipment_type === equipmentType;
      } else if (bodyPart && equipmentType) {
        shouldShow =
          bodyParts[bodyPart].includes(e.muscle_type) &&
          e.equipment_type === equipmentType;
      } else if (muscleType) {
        shouldShow = e.muscle_type === muscleType;
      } else if (equipmentType) {
        shouldShow = e.equipment_type === equipmentType;
      } else if (bodyPart) {
        shouldShow = bodyParts[bodyPart].includes(e.muscle_type);
      }
      return shouldShow;
    });

  return (
    <ExerciseSearch
      {...{
        filteredExercises,
        muscleType,
        equipmentType,
        exercises,
        onMuscleTypeClick,
        onEquipmentTypeClick,
        exerciseError,
        exerciseId,
        onExerciseClick,
        lift_id,
        goToLift,
        goToNextExercise,
        searchValue,
        setSearchValue,
        autoFocus,
        noPr,
        PRsByExerciseID,
        exercisesLoading,
        onBodyPartClick,
        bodyPart,
      }}
    />
  );
};

export default ExerciseSelector;