import {uuidv4} from '../utils/uuid';
import {flatten, uniq} from 'lodash';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from './firestoredb';

export const firebaseSignAttempt = async (user, cb) => {
    const docRef = doc(db, "Users", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await cb(docSnap.data());
    } else {
      const displayName = user.displayName || '';
      const email = user.email || '';
      const emailStripped = email.split('@')[0];
      const data = {
        id: uuidv4(),
        uid: user.uid,
        displayName: displayName,
        email: email,
        photoURL: user.photoURL,
        created_at: new Date(),
        updated_at: new Date(),
        keywords: uniq(
          flatten([
            displayName
              .split('')
              .map((_, i) => displayName.slice(0, i + 1).toLowerCase()),
            emailStripped
              .split('')
              .map((_, i) => emailStripped.slice(0, i + 1).toLowerCase()),
          ]),
        ),
      };
      await setDoc(doc(db, "Users", user.uid), data);
      await cb(data)
    }
}