import React, {useContext} from "react";

import MenuItem from '@mui/material/MenuItem';
import { useNavigate, createSearchParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { themeContext } from "../contexts/theme";

const NavbarLink = ({ url, params, text, handleCloseUserMenu }) => {
  const navigation = useNavigate();
  const theme = useContext(themeContext);
  return <MenuItem onClick={() => {
    navigation({
      pathname: url, search: `?${createSearchParams(params || {})}`
    });
    handleCloseUserMenu();
  }}>
    <Typography color={theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)'} textAlign="center">{text}</Typography>
  </MenuItem>
};

export default NavbarLink;