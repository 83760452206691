import React, {useContext} from 'react';
import { themeContext } from '../contexts/theme';

import PacmanLoader from "react-spinners/PacmanLoader";

const Spinner = ({loading}) => {

  const theme = useContext(themeContext);
  return loading ? <div style={{margin: '0 auto', display: 'block', padding: 50}}>
    <PacmanLoader color={`rgb(${theme.primary.main})`} loading={loading} css="margin: 0 auto;display: block;" size={20} />
  </div> : null
};

export default Spinner;