import React, { useContext } from 'react';

import { useNavigate, createSearchParams } from 'react-router-dom';

import { formatDate, formatDateTime } from '../utils/formatDate';

import { ChevronRight, Whatshot } from '@material-ui/icons';
import { themeContext } from '../contexts/theme';

import { groupBy } from 'lodash';

import TimerDisplay from './lifts/TimerDisplay';

const WorkoutDetailListCard = ({
  item,
  local,
  exerciseId,
  exercise,
  notClickable,
}) => {
  const navigation = useNavigate();
  const theme = useContext(themeContext);
  const groupedSRWs = groupBy(
    item.lift_trackers_attributes.filter(lt => lt.exercise_id === exerciseId),
    'lift_id',
  );
  const lift_ids = Object.keys(groupedSRWs);
  return lift_ids.map(lift_id => {
    const srws = groupedSRWs[lift_id];
    return (
      <div
        key={`${item.id}`}
        onClick={() => {
          if (!notClickable) {
            navigation({
              pathname: '/activity', search: `?${createSearchParams({
                lift_id: item.id,
                uid: item.uid || item.user_id,
              })}`
            });
          }
        }}
        style={{
          padding: 10,
          margin: 10,
          backgroundColor: theme.darkMode
            ? `rgba(255,255,255,0.1)`
            : 'rgba(255,255,255,0.4)',
          borderRadius: 5,
          borderWidth: 1,
          borderColor: 'rgba(0,0,0,0.1)',
          cursor: 'pointer'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <div style={{ flexGrow: 1, width: notClickable ? '100%' : '93%' }}>
            <div
              key={'name'}
              style={{
                backgroundColor: 'transparent',
                color: `rgb(${theme.primary.main})`,
              }}>
              {item.name}
            </div>
            <div
              key={'date'}
              style={{
                backgroundColor: 'transparent',
                color: theme.darkMode
                  ? 'rgba(255, 255, 255, 0.7)'
                  : 'rgba(0, 0, 0, 0.7)',
                marginBottom: 10,
              }}>
              {formatDate(item.lifted_at)} - {formatDateTime(item.lifted_at)}
            </div>
          </div>

          <div style={{ marginTop: 5, width: notClickable ? '0%' : '7%' }}>
            <ChevronRight
              style={{ fill: `${theme.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'})`, fontSize: 25 }}
            />
          </div>
        </div>
        {srws.map(srw => {
          return (
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                flexDirection: 'row',
                alignItems: 'center',
                display: 'flex',
              }}>
              <div style={{ width: '20%', flexDirection: 'row', display: 'flex', }}>
                <div
                  style={{
                    backgroundColor: theme.darkMode
                      ? 'rgba(255,255,255,0.1)'
                      : 'rgba(192,192,192,0.2)',
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 20,
                  }}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: 'bold',
                      color: theme.darkMode
                        ? 'rgba(255, 255, 255, 0.9)'
                        : 'rgba(0, 0, 0, 0.9)',
                    }}>
                    {`${srw.set_num}`}
                  </div>
                </div>
                {theme.mode === 'weightlifter' &&
                  srw.personal_record_attributes &&
                  srw.personal_record_attributes.id && (
                    <div style={{ marginLeft: 2, paddingTop: 3 }}>
                      <Whatshot
                        style={{ fill: `rgb(${theme.primary.main})`, fontSize: 20 }}
                      />
                    </div>
                  )}
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  width:
                    exercise.isBodyWeight || exercise.isTimeBased
                      ? '43%'
                      : theme.mode === 'weightlifter'
                        ? '28%'
                        : '40%',
                }}>
                <div
                  style={{
                    fontSize: theme.mode === 'weightlifter' ? 17 : 20,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.9)'
                      : 'rgba(0, 0, 0, 0.9)',
                  }}>
                  {exercise.isTimeBased
                    ? 'Time Based'
                    : exercise.isBodyWeight
                      ? 'Body Weight'
                      : (srw.weight || 0).toString()}
                </div>
                <div
                  style={{
                    paddingTop: 3,
                    fontSize: theme.mode === 'weightlifter' ? 14 : 17,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(0, 0, 0, 0.5)',
                  }}>
                  {exercise.isBodyWeight || exercise.isTimeBased ? '' : ' lbs'}
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  width:
                    exercise.isBodyWeight || exercise.isTimeBased
                      ? '37%'
                      : theme.mode === 'weightlifter'
                        ? '24%'
                        : '37%',
                }}>
                <div
                  style={{
                    fontSize: theme.mode === 'weightlifter' ? 17 : 20,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.9)'
                      : 'rgba(0, 0, 0, 0.9)',
                  }}>
                  {exercise.isTimeBased ? (
                    <TimerDisplay elaspedTime={srw.lift_elapsed_seconds} />
                  ) : (
                    srw.num_of_reps || 0
                  )}
                </div>
                <div
                  style={{
                    paddingTop: 3,
                    fontSize: theme.mode === 'weightlifter' ? 14 : 17,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(0, 0, 0, 0.5)',
                  }}>
                  {!exercise.isTimeBased && ' Reps'}
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  width:
                    exercise.isBodyWeight || exercise.isTimeBased
                      ? '0%'
                      : theme.mode === 'weightlifter'
                        ? '30%'
                        : '0%',
                }}>
                <div
                  style={{
                    fontSize: theme.mode === 'weightlifter' ? 17 : 20,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.9)'
                      : 'rgba(0, 0, 0, 0.9)',
                  }}>
                  {exercise.isTimeBased
                    ? ''
                    : exercise.isBodyWeight
                      ? ''
                      : '∑ '.concat((srw.weight * srw.num_of_reps).toString())}
                </div>
                <div
                  style={{
                    paddingTop: 3,
                    fontSize: theme.mode === 'weightlifter' ? 14 : 17,
                    color: theme.darkMode
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(0, 0, 0, 0.5)',
                  }}>
                  {exercise.isBodyWeight ? '' : ' lbs'}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  });
};

export default WorkoutDetailListCard;