import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { firebaseApp } from '../services/firestoredb';
import { mimeFromURL, isVideo } from '../utils/mimechecker';
const storageApp = getStorage(firebaseApp);

export const fetchAllPhotos = (uid, cb) => {
  const listRef = ref(storageApp, `/Users/${uid}/lift_tracker_groups`);
  listAll(listRef).then((res) => {
    const imagePromises = [];
    const thumbPromises = [];
    const subfolderPromises = [];
    let photos = [];
    let thumbs = [];
    // Loop over each folder
    res.prefixes.forEach((folderRef) => {
      subfolderPromises.push(
        new Promise((subFolderResolve) => {
          const getThatSub = async () => {
            listAll(folderRef).then((prefixResult) => {
              const thumbFolderPromises = [];
              // thumbnails
              prefixResult.prefixes.forEach((thumbFolderRef) => {
                thumbFolderPromises.push(
                  new Promise((thumbResolve) => {
                    const getThatThumb = async () => {
                      listAll(thumbFolderRef).then((thumbResult) => {
                        thumbResult.items.forEach(ref => {
                          thumbPromises.push(
                            new Promise((resolve) => {
                              const getThatS = async () => {
                                const url = await getDownloadURL(ref)
                                console.log(url);
                                const filename = url.split('/').pop().split('%2F').pop().split('?').shift();
                                const filenameNoThumb = filename.split('thumb_').pop();
                                const id = filenameNoThumb.split('.').shift();
                                const lift_id = url.split('/').pop().split('%2F')[3];
                                const user_id = url.split('/').pop().split('%2F')[1];
                                resolve({
                                  ref: ref,
                                  path: url,
                                  mime: mimeFromURL(url),
                                  filename: filename,
                                  id: id,
                                  lift_id: lift_id,
                                  user_id: user_id
                                });
                              }
                              getThatS();
                            })
                          );
                        });
                        thumbResolve();
                      })
                    }
                    getThatThumb();
                  })
                )
              })
              Promise.all(thumbFolderPromises).then(() => {
                subFolderResolve();
              })

              //full res images/videos
              prefixResult.items.forEach(ref => {
                imagePromises.push(
                  new Promise((resolve) => {
                    const getThatS = async () => {
                      const url = await getDownloadURL(ref)
                      const filename = url.split('/').pop().split('%2F').pop().split('?').shift();
                      const id = filename.split('.').shift();
                      const mime = mimeFromURL(url);
                      const lift_id = url.split('/').pop().split('%2F')[3];
                      const user_id = url.split('/').pop().split('%2F')[1];
                      resolve({
                        ref: ref,
                        path: url,
                        mime: mime,
                        filename: filename,
                        id: id,
                        isVideo: isVideo(mime),
                        lift_id: lift_id,
                        user_id: user_id
                      });
                    }
                    getThatS();
                  })
                );
              });
            })
          };
          getThatSub()
        })
      )
    });
    Promise.all(subfolderPromises).then(() => {
      Promise.all(thumbPromises).then((tempThumbs) => {
        thumbs = tempThumbs.flat();
        Promise.all(imagePromises).then((tempPhotos) => {
          photos = tempPhotos.flat()
          cb(photos, thumbs)
        });
      });
    });
  });
}

export const fetchLiftPhotos = (lift_user_id, lift_id, cb) => {
  // fetch photos
  const listRef = ref(storageApp, `/Users/${lift_user_id}/lift_tracker_groups/${lift_id}`);
  listAll(listRef).then((result) => {
    const imagePromises = [];
    const thumbFolderPromises = [];
    const thumbPromises = [];
    let photos = [];
    let thumbs = [];

    result.prefixes.forEach((thumbFolderRef) => {
      thumbFolderPromises.push(
        new Promise((thumbResolve) => {
          const getThatThumb = async () => {
            listAll(thumbFolderRef).then((thumbResult) => {
              thumbResult.items.forEach(ref => {
                thumbPromises.push(
                  new Promise((resolve) => {
                    const getThatS = async () => {
                      const url = await getDownloadURL(ref)
                      console.log(url);
                      const filename = url.split('/').pop().split('%2F').pop().split('?').shift();
                      const filenameNoThumb = filename.split('thumb_').pop();
                      const id = filenameNoThumb.split('.').shift();
                      const lift_id = url.split('/').pop().split('%2F')[3];
                      const user_id = url.split('/').pop().split('%2F')[1];
                      resolve({
                        ref: ref,
                        path: url,
                        mime: mimeFromURL(url),
                        filename: filename,
                        id: id,
                        lift_id: lift_id,
                        user_id: user_id
                      });
                    }
                    getThatS();
                  })
                );
              });
              thumbResolve();
            })
          }
          getThatThumb();
        })
      )
    })


    // Loop over each item
    result.items.forEach(ref => {
      imagePromises.push(
        new Promise((resolve) => {
          const getThatS = async () => {
            const url = await getDownloadURL(ref);
            const filename = url.split('/').pop().split('%2F').pop().split('?').shift();
            const id = filename.split('.').shift();
            const mime = mimeFromURL(url);
            const lift_id = url.split('/').pop().split('%2F')[3];
            const user_id = url.split('/').pop().split('%2F')[1];
            resolve({
              ref: ref,
              path: url,
              mime: mime,
              filename: filename,
              id: id,
              isVideo: isVideo(mime),
              lift_id: lift_id,
              user_id: user_id
            });
          }
          getThatS();
        })
      );
    });

    Promise.all(thumbFolderPromises).then(() => {
      Promise.all(thumbPromises).then((tempThumbs) => {
        thumbs = tempThumbs.flat();
        Promise.all(imagePromises).then((tempPhotos) => {
          photos = tempPhotos.flat()
          cb(photos, thumbs)
        });
      });
    });
  });
}

const serverPhotos = { fetchAllPhotos, fetchLiftPhotos };

export default serverPhotos;


