import React, { useContext, useState } from 'react';
import { themeContext } from '../contexts/theme';
import moment from 'moment';
import { ChevronRight } from '@material-ui/icons';
import PopupModal from '../components/PopupModal';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { bodyParts } from '../utils/exerciseFilters';
import {
  VictoryChart,
  VictoryBar,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel
} from 'victory';

const PeriodChart = ({
  muscleGroup,
  dataByPeriod,
  toggleIndex,
  currentPeriodLGs,
  theExercises,
}) => {
  const theme = useContext(themeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const formatLabel = value => {
    return toggleIndex === '0' ? moment(value).format('MM/DD') : `${value}`;
  };
  const [subMuscleGroupIndex, setSubMuscleGroupIndex] = useState('All');

  const mainGroups = bodyParts;

  const colors = [
    '#f34436',
    '#e82063',
    '#9b27b0',
    '#673bb6',

    '#3f51b4',
    '#2196f2',
    '#08a8f3',
    '#03bbd3',
    '#009687',

    '#4cae50',
    '#8cc24a',
    '#ccdc39',
    '#feeb3b',
    '#fec008',

    '#fe9700',
    '#fe5622',
    '#607d8b',
  ].reverse();

  const selectedMuscleGroup = mainGroups[muscleGroup];

  const dataBySubMuscle = Object.keys(currentPeriodLGs).reduce(
    (periodMap, period) => {
      currentPeriodLGs[period].forEach(ltg => {
        ltg.lift_trackers_attributes
          .filter(lt => {
            return Number.isInteger(lt.weight) && lt.weight > 0;
          })
          .forEach(lt => {
            const exercise =
              theExercises.find(e => e.id === lt.exercise_id) || {};
            periodMap[exercise.muscle_type] =
              periodMap[exercise.muscle_type] || 0;
            periodMap[exercise.muscle_type] =
              periodMap[exercise.muscle_type] + lt.weight * lt.num_of_reps;
          });
      });

      return periodMap;
    },
    {},
  );

  const dataByPeriodExercise = Object.keys(currentPeriodLGs).reduce(
    (periodMap, period) => {
      let periodSubData = {};
      currentPeriodLGs[period].forEach(ltg => {
        ltg.lift_trackers_attributes
          .filter(lt => {
            return Number.isInteger(lt.weight) && lt.weight > 0;
          })
          .forEach(lt => {
            const exercise =
              theExercises.find(e => e.id === lt.exercise_id) || {};

            if (
              selectedMuscleGroup
                .filter(sg => dataBySubMuscle[sg] > 0)
                .includes(exercise.muscle_type) &&
              (subMuscleGroupIndex === 'All' || subMuscleGroupIndex === exercise.muscle_type)
            ) {
              periodSubData[exercise.name] = periodSubData[exercise.name] || 0;
              periodSubData[exercise.name] =
                periodSubData[exercise.name] + lt.weight * lt.num_of_reps;
            }
          });
      });

      periodMap[period] = periodSubData;
      return periodMap;
    },
    {},
  );

  const firstBarData = Object.keys(dataByPeriod)
    .map(p => dataByPeriod[p][muscleGroup])
    .slice(0, 4)
    .reverse().map((datD, index) => {
      const barLabels = Object.keys(dataByPeriod)
        .map(p => `${p}`)
        .map(v => formatLabel(v))
        .slice(0, 4)
        .reverse();
      return {
        y: datD ? datD : 0,
        x: barLabels[index],
      }
    })

  return (
    <>
      <div
        onClick={() => {
          theme.mode === 'weightlifter' && setModalOpen(true);
        }}
        style={{
          borderRadius: 5,
          margin: 10,
          padding: 10,
          paddingBottom: 20,
          borderWidth: 1,
          borderColor: 'rgba(0,0,0,0.1)',
          backgroundColor: theme.darkMode
            ? 'rgba(255,255,255,0.07)'
            : 'rgba(255,255,255,0.4)',
        }}>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            <div
              style={{
                color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
                fontSize: 18,
                fontWeight: 'bold',
              }}>
              {muscleGroup}
            </div>
          </div>

          {theme.mode === 'weightlifter' && (
            <div style={{ marginTop: -2 }}>
              <ChevronRight
                style={{ fill: `${theme.darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'})`, fontSize: 25 }}
              />
            </div>
          )}
        </div>
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={{ x: [30, 60] }}
          width={350}
        >
          <VictoryAxis
            crossAxis
            style={{
              grid: {
                fill: 'none',
                stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'}, 0.1)`,
                strokeDasharray: '10, 5',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                pointerEvents: 'painted',
              },
              axis: {
                stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                strokeDasharray: '10, 5',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              },
              ticks: {
                stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                size: 0,
              },
              tickLabels: {
                fontSize: 15,
                padding: 5,
                fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                paddingLeft: 20
              },
            }}
            tickLabelComponent={<VictoryLabel dx={20} />}
          />
          <VictoryAxis
            dependentAxis
            theme={VictoryTheme.material}
            standalone={true}
            style={{
              grid: {
                fill: 'none',
                stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'}, 0.1)`,
                strokeDasharray: '10, 5',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                pointerEvents: 'painted',
              },
              axis: {
                stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                strokeDasharray: '10, 5',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              },
              ticks: {
                stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                size: 0,
              },
              tickLabels: {
                fontSize: 15,
                padding: 5,
                fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                paddingLeft: 20
              },
            }}
          />
          <VictoryBar
            alignment="start"
            barRatio={firstBarData.length > 1 ? 0.8 : 5}
            data={firstBarData}
            labels={({ datum }) => `${datum.y}`}
            labelComponent={
              <VictoryLabel dx={20} textAnchor="middle" />
            }
            style={{
              data: {
                strokeWidth: 1,
                fill: `rgba(${theme.primary.main}, 0.8)`,
                stroke: `rgb(${theme.primary.main})`,
              },
              labels: {
                fill: `rgb(${theme.primary.main})`,
                fontWeight: 'bold'
              },
            }}
          />
        </VictoryChart>
      </div>
      <PopupModal
        closeModal={() => setModalOpen(false)}
        closeText={'Back'}
        visible={modalOpen}
        title={muscleGroup}>
        <div>
          {selectedMuscleGroup.filter(sg => dataBySubMuscle[sg] > 0).length > 1 && (
            <div style={{  display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
              <ToggleButtonGroup
                color="primary"
                value={subMuscleGroupIndex}
                size={'small'}
                exclusive
                onChange={(_, newAlignment) => setSubMuscleGroupIndex(newAlignment)}
              >
                {['All',
                  ...selectedMuscleGroup.filter(sg => dataBySubMuscle[sg] > 0),
                ].map((k, index) => {
                  return <ToggleButton key={`dropdown-${index}`} value={k}>{k}</ToggleButton>
                })}
              </ToggleButtonGroup>
            </div>
          )}
          {Object.keys(dataByPeriodExercise).length === 0 && (
            <div
              style={{
                padding: 20,
                marginTop: 20,
                backgroundColor: theme.darkMode
                  ? `rgba(255,255,255,0.1)`
                  : `rgba(0,0,0,0.05)`,
                borderRadius: 5,
              }}>
              <div
                style={{
                  color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
                }}>
                No data available
              </div>
            </div>
          )}
          {Object.keys(dataByPeriodExercise)
            .slice(0, 4)
            .map((period, index) => {
              return (
                <div
                  key={`${index}`}
                  style={{
                    backgroundColor: theme.darkMode
                      ? 'rgba(255,255,255,0.07)'
                      : 'rgba(255,255,255,0.4)',
                    borderRadius: 5,
                    padding: 10,
                    paddingBottom: 20,
                    marginTop: 10,
                    marginBottom: 10,
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.1)',
                  }}>
                  <div style={{ flexDirection: 'row' }}>
                    <div style={{ flexGrow: 1 }}>
                      <div
                        style={{
                          color: theme.darkMode
                            ? 'rgb(255,255,255)'
                            : 'rgb(0,0,0)',
                          fontSize: 18,
                          fontWeight: 'bold',
                        }}>
                        {formatLabel(period)}
                      </div>
                    </div>
                  </div>
                  <VictoryChart
                    theme={VictoryTheme.material}
                    domainPadding={{ x: [40, 60] }}
                  >
                    <VictoryAxis
                      crossAxis
                      style={{
                        grid: {
                          fill: 'none',
                          stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'}, 0.1)`,
                          strokeDasharray: '10, 5',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                          pointerEvents: 'painted',
                        },
                        axis: {
                          stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                          strokeDasharray: '10, 5',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                        },
                        ticks: {
                          stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                          size: 0,
                        },
                        tickLabels: {
                          fontSize: 15,
                          padding: 5,
                          fill: `transparent`,
                          paddingLeft: 20
                        },
                      }}
                      tickLabelComponent={<VictoryLabel dx={20} />}
                    />
                    <VictoryAxis
                      dependentAxis
                      theme={VictoryTheme.material}
                      standalone={true}
                      style={{
                        grid: {
                          fill: 'none',
                          stroke: `rgba(${theme.darkMode ? '255,255,255' : '0,0,0'}, 0.1)`,
                          strokeDasharray: '10, 5',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                          pointerEvents: 'painted',
                        },
                        axis: {
                          stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                          strokeDasharray: '10, 5',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                        },
                        ticks: {
                          stroke: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                          size: 0,
                        },
                        tickLabels: {
                          fontSize: 15,
                          padding: 5,
                          fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                          paddingLeft: 20
                        },
                      }}
                    />
                    <VictoryBar
                      alignment="start"
                      barRatio={Object.keys(dataByPeriodExercise[period]).length > 1 ? 0.8 : 5}
                      data={Object.keys(dataByPeriodExercise[period]).map(
                        e => dataByPeriodExercise[period][e],
                      ).map((d, i) => { return { x: i, y: d } })}
                      labels={({ datum }) => `${datum.y}`}
                      labelComponent={
                        <VictoryLabel dx={20} textAnchor="middle" />
                      }
                      style={{
                        data: {
                          strokeWidth: 0,
                          fill: ({ index }) => colors[index],
                        },
                        labels: {
                          fill: `rgb(${theme.darkMode ? '255,255,255' : '0,0,0'})`,
                          fontWeight: 'bold'
                        },
                      }}
                    />
                  </VictoryChart>
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    {Object.keys(dataByPeriodExercise[period]).map((e, i) => {
                      return (
                        <div key={`${i}`} style={{ flexDirection: 'row', paddingLeft: 10, display: 'flex', flexWrap: 'wrap' }}>
                          <div
                            style={{
                              backgroundColor: colors[i],
                              height: 10,
                              width: 10,
                              borderRadius: 10,
                              justifyContent: 'center',
                              marginRight: 5,
                              marginTop: 2,
                            }}
                          />
                          <div
                            style={{
                              color: theme.darkMode
                                ? `rgba(255, 255, 255, 0.8)`
                                : `rgba(0, 0, 0, 0.8)`,
                            }}>
                            {e}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </PopupModal>
    </>
  );
};

export default PeriodChart;