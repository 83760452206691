import React, { useContext, useState, useRef, useEffect  } from 'react';

import ActivityCalendar from 'react-activity-calendar';
import ReactTooltip from 'react-tooltip';
import { groupBy } from 'lodash';

import moment from 'moment';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

import { themeContext } from '../../contexts/theme';

import { mapServerLiftsToSmallLifts } from '../../utils/lift-trackers';
import { getDateArray, convertToDate } from '../../utils/formatDate';

import SmallCard from '../../components/liftCards/SmallCard';
import RadarChart from '../../components/RadarChart';
import BarLineComboChart from '../../components/BarLineComboChart';
import PeriodChart from '../../components/PeriodChart';

import Exercises from './Exercises';
import PhotoLightGallery from './PhotoLightGallery';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const weekdays = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
];



const Profile = ({ currentUser, exercises, lift_data, photos, thumbs }) => {
  const [displayIndex, setdisplayIndex] = useState('0');
  const displayLookup = photos && photos.length > 0 ? { '0': 'Overall', '1': 'Exercises', '2': 'Photos'} : { '0': 'Overall', '1': 'Exercises' };
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(800)

  useEffect(() => {
    if( containerRef && containerRef.current && containerRef.current.offsetWidth !== containerWidth){
      setContainerWidth(containerRef.current.offsetWidth)
    }
  }, [containerRef, containerWidth]);

  const theme = useContext(themeContext);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [clickedDate, setClickedDate] = useState();
  const [hoveredDate, setHoveredDate] = useState();
  const grouped_lift_data = groupBy(
    mapServerLiftsToSmallLifts(lift_data, currentUser.uid),
    'lifted_at_date',
  );
  const lift_days = Object.keys(grouped_lift_data);
  const startDate = new Date(`${selectedYear}-01-01`);
  const endDate = new Date(`${selectedYear}-12-31`);
  const yearValues = [...new Set(lift_days.map(d => new Date(d).getFullYear()).concat(new Date().getFullYear()))].sort().reverse().map((year) => { return { value: year, label: year } });

  const clickedLiftData = clickedDate && grouped_lift_data[clickedDate];
  const hoveredLiftData = hoveredDate && grouped_lift_data[hoveredDate];

  const previousFourWeeks = moment().startOf('week').subtract(3, 'week');
  const previousFourWeeksStartOfWeek = previousFourWeeks;
  const previousFourWeeksEndOfWeek = new Date();

  const previousFourWeeksLGs = lift_data
    .filter(lg => {
      const justDate = convertToDate(lg.lifted_at).toLocaleDateString();
      const check = Date.parse(justDate);
      return (
        check <= previousFourWeeksEndOfWeek &&
        check >= previousFourWeeksStartOfWeek
      );
    })
    .sort((a, b) =>
      Date.parse(convertToDate(a.lifted_at).toLocaleDateString()) >
        Date.parse(convertToDate(b.lifted_at).toLocaleDateString())
        ? 1
        : -1,
    );

  const previousFourWeeksLGsGrouped = previousFourWeeksLGs.reduce((r, ltg) => {
    const weekNum = `        ${moment(convertToDate(ltg.lifted_at))
      .startOf('week')
      .format('MM/DD')}`;
    r[weekNum] = r[weekNum] || [];
    r[weekNum].push(ltg);
    return r;
  }, {});

  const currentPoundsByMuscleTypeByWeek = Object.keys(
    previousFourWeeksLGsGrouped,
  ).map(weekNum => {
    const weeksData = previousFourWeeksLGsGrouped[weekNum];
    let currentPoundsByMuscleType = {};
    weeksData.forEach(ltg => {
      ltg.lift_trackers_attributes
        .filter(lt => {
          return Number.isInteger(lt.weight) && lt.weight > 0;
        })
        .forEach(lt => {
          const exercise =
            (exercises && exercises.find(e => e.id === lt.exercise.id)) || {};
          currentPoundsByMuscleType[exercise.muscle_type] =
            currentPoundsByMuscleType[exercise.muscle_type] || 0;
          currentPoundsByMuscleType[exercise.muscle_type] =
            currentPoundsByMuscleType[exercise.muscle_type] +
            lt.weight * lt.num_of_reps;
        });
    });

    [
      'Abdominals',
      'Abductors',
      'Adductors',
      'Biceps',
      'Calves',
      'Chest',
      'Forearms',
      'Glutes',
      'Hamstrings',
      'Lats',
      'Lower Back',
      'Middle Back',
      'Neck',
      'Quadriceps',
      'Shoulders',
      'Traps',
      'Triceps',
    ].forEach(muscle_type => {
      currentPoundsByMuscleType[muscle_type] =
        currentPoundsByMuscleType[muscle_type] || 0;
    });
    const mainGroups = {
      Chest: currentPoundsByMuscleType['Chest'],
      Back:
        currentPoundsByMuscleType['Lats'] +
        currentPoundsByMuscleType['Lower Back'] +
        currentPoundsByMuscleType['Middle Back'] +
        currentPoundsByMuscleType['Neck'],
      Shoulders:
        currentPoundsByMuscleType['Shoulders'] +
        currentPoundsByMuscleType['Traps'],
      Arms:
        currentPoundsByMuscleType['Biceps'] +
        currentPoundsByMuscleType['Forearms'] +
        currentPoundsByMuscleType['Triceps'],
      Legs:
        currentPoundsByMuscleType['Abductors'] +
        currentPoundsByMuscleType['Adductors'] +
        currentPoundsByMuscleType['Calves'] +
        currentPoundsByMuscleType['Glutes'] +
        currentPoundsByMuscleType['Hamstrings'] +
        currentPoundsByMuscleType['Quadriceps'],
      Abs: currentPoundsByMuscleType['Abdominals'],
    };
    return mainGroups;
  });

  const allPrevWeekNums = [
    ...new Set(
      getDateArray(
        convertToDate(previousFourWeeksStartOfWeek),
        previousFourWeeksEndOfWeek,
      ).map(
        d =>
          `        ${moment(convertToDate(d)).startOf('week').format('MM/DD')}`,
      ),
    ),
  ];

  const previousFourWeeksBarChartData = allPrevWeekNums.map(weekNum => {
    const datData = previousFourWeeksLGsGrouped[weekNum];
    return {
      y: datData ? datData.length : 0,
      x: weekNum,
    };
  });
  const maxprevfourbarvalue = Math.max(
    ...previousFourWeeksBarChartData.map(d => d.y),
  );

  const previousFourWeeksLineData = allPrevWeekNums.map(weekNum => {
    const datData = previousFourWeeksLGsGrouped[weekNum];
    return {
      y: datData
        ? datData
          .map(ltg => {
            return ltg.lift_trackers_attributes.map(lt => {
              return lt.personal_record_attributes;
            });
          })
          .flat()
          .filter(x => {
            return x !== undefined;
          }).length
        : 0,
      x: weekNum,
    };
  });


  const previoustwelveMonths = moment().startOf('month').subtract(11, 'month');
  const previoustwelveMonthsStartOfMonth = previoustwelveMonths
    .startOf('month')
    .toDate();
  const previoustwelveMonthsEndOfMonth = new Date();

  const previoustwelveMonthsLGs = lift_data
    .filter(lg => {
      const justDate = convertToDate(lg.lifted_at).toLocaleDateString();
      const check = Date.parse(justDate);
      return (
        check <= previoustwelveMonthsEndOfMonth &&
        check >= previoustwelveMonthsStartOfMonth
      );
    })
    .sort((a, b) =>
      Date.parse(convertToDate(a.lifted_at).toLocaleDateString()) >
        Date.parse(convertToDate(b.lifted_at).toLocaleDateString())
        ? 1
        : -1,
    );

  const previoustwelveMonthsLGsGrouped = previoustwelveMonthsLGs.reduce(
    (r, ltg) => {
      const monthNum = `${moment(convertToDate(ltg.lifted_at))
        .startOf('month')
        .format('MMM')}`;
      r[monthNum] = r[monthNum] || [];
      r[monthNum].push(ltg);
      return r;
    },
    {},
  );

  const allPrevMonthNums = [
    ...new Set(
      getDateArray(
        convertToDate(previoustwelveMonthsStartOfMonth),
        previoustwelveMonthsEndOfMonth,
      ).map(
        d => `${moment(convertToDate(d)).startOf('month').format('MMM')}`,
      ),
    ),
  ];

  const previoustwelveMonthsbarChartData = allPrevMonthNums.map(monthNum => {
    const datData = previoustwelveMonthsLGsGrouped[monthNum];
    return {
      y: datData ? datData.length : 0,
      x: monthNum,
    };
  });
  const maxprevioustwelvebarvalue = Math.max(
    ...previoustwelveMonthsbarChartData.map(d => d.y),
  );

  const previoustwelveMonthsVarLineData = allPrevMonthNums.map(monthNum => {
    const datData = previoustwelveMonthsLGsGrouped[monthNum];
    return {
      y: datData
        ? datData
          .map(ltg => {
            return ltg.lift_trackers_attributes.map(lt => {
              return lt.personal_record_attributes;
            });
          })
          .flat()
          .filter(x => {
            return x !== undefined;
          }).length
        : 0,
      x: monthNum,
    };
  });

  const [toggleIndex, setToggleIndex] = useState('0');
  const toggleLookup = {
    '0': 'Last 4 Weeks',
    '1': 'Last 4 Months',
    '2': 'Last 4 Years'
  };

  const substractThing =
    toggleIndex === '0'
      ? 'weeks'
      : toggleIndex === '1'
        ? 'months'
        : toggleIndex === '2'
          ? 'years'
          : undefined;
  const groupbyThing =
    toggleIndex === '0'
      ? 'weekStart'
      : toggleIndex === '1'
        ? 'monthNum'
        : toggleIndex === '2'
          ? 'yearNum'
          : undefined;

  const liftData = lift_data.map((ltg) => ({
    id: ltg.id,
    name: ltg.name,
    uid: currentUser.uid,
    weekStart: moment(convertToDate(ltg.lifted_at).toUTCString())
      .startOf('week')
      .toDate(),
    monthNum: monthNames[convertToDate(ltg.lifted_at).getMonth()],
    yearNum: convertToDate(ltg.lifted_at).getFullYear(),
    lifted_at: convertToDate(ltg.lifted_at),
    lift_trackers_attributes: ltg.lift_trackers_attributes,
  }));

  const currentPeriodLGs = groupBy(
    liftData.filter((ltg) => {
      return (
        ltg.lifted_at.getTime() >=
        moment().subtract(5, substractThing).toDate().getTime()
      );
    }),
    groupbyThing,
  );

  const dataByPeriod = Object.keys(currentPeriodLGs).reduce(
    (periodMap, period) => {
      let barData = {};
      currentPeriodLGs[period].forEach((ltg) => {
        ltg.lift_trackers_attributes
          .filter((lt) => {
            return Number.isInteger(lt.weight) && lt.weight > 0;
          })
          .forEach((lt) => {
            const exercise =
              exercises.find((e) => e.id === lt.exercise_id) || {};
            barData[exercise.muscle_type] = barData[exercise.muscle_type] || 0;
            barData[exercise.muscle_type] =
              barData[exercise.muscle_type] + lt.weight * lt.num_of_reps;
          });
      });

      [
        'Abdominals',
        'Abductors',
        'Adductors',
        'Biceps',
        'Calves',
        'Chest',
        'Forearms',
        'Glutes',
        'Hamstrings',
        'Lats',
        'Lower Back',
        'Middle Back',
        'Neck',
        'Quadriceps',
        'Shoulders',
        'Traps',
        'Triceps',
      ].forEach((muscle_type) => {
        barData[muscle_type] = barData[muscle_type] || 0;
      });

      const mainGroups = {
        Chest: barData['Chest'],
        Back:
          barData['Lats'] +
          barData['Lower Back'] +
          barData['Middle Back'] +
          barData['Neck'],
        Shoulders: barData['Shoulders'] + barData['Traps'],
        Arms: barData['Biceps'] + barData['Forearms'] + barData['Triceps'],
        Legs:
          barData['Abductors'] +
          barData['Adductors'] +
          barData['Calves'] +
          barData['Glutes'] +
          barData['Hamstrings'] +
          barData['Quadriceps'],
        Abs: barData['Abdominals'],
      };
      periodMap[period] = mainGroups;
      return periodMap;
    },
    {},
  );

  return <div ref={containerRef}>
    <div style={{ paddingTop: 15, display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
      <ToggleButtonGroup
        color="primary"
        size={'small'}
        value={displayIndex}
        exclusive
        onChange={(_, newAlignment) => setdisplayIndex(newAlignment)}
      >
        {Object.keys(displayLookup).map((k, index) => {
          const label = displayLookup[k];
          return <ToggleButton key={`display-dropdown-${index}`} value={k}>{label}</ToggleButton>
        })}
      </ToggleButtonGroup>
    </div>
    {displayIndex === '0' &&
      <>
        <div style={{ width: 400, display: 'flex', flexDirection: 'row', paddingBottom: 10, marginTop: 15 }}>
          <div style={{
            paddingRight: 20,
            fontSize: 20,
            fontWeight: 'bold',
            marginTop: 6
          }}>
            Activity Chart:
          </div>
          <ToggleButtonGroup
            color="primary"
            size={'small'}
            value={selectedYear}
            exclusive
            onChange={(_, newAlignment) => setSelectedYear(newAlignment)}
          >
            {yearValues.reverse().map((yearValue, index) => {
              return <ToggleButton key={`display-dropdown-${index}`} value={yearValue.value}>{yearValue.label}</ToggleButton>
            })}
          </ToggleButtonGroup>
        </div>
        <ActivityCalendar
          color={`rgb(${theme.primary.main})`}
          blockRadius={20}
          blockSize={20}
          data={getDateArray(startDate, endDate).map(lt => {
            const hasLift = lift_days.includes(lt);
            return {
              count: hasLift ? 1 : 0,
              date: lt,
              level: hasLift ? 1 : 0
            }
          })}
          eventHandlers={{
            onClick: event => data => {
              // const inThePast = data.date === formatDate(new Date()) ? false : dateInPast(new Date(data.date), new Date());
              setClickedDate(data.date)
            },
            onMouseEnter: event => data => {
              setHoveredDate(data.date)
            }
          }}
          hideColorLegend={true}
          hideTotalCount={true}
          labels={{
            legend: {
              less: '',
              more: ''
            },
            months: monthNames,
            weekdays: weekdays
          }}
        >
          <ReactTooltip multiline>
            {`${hoveredDate} ${hoveredLiftData ? hoveredLiftData.map(ltg => ltg.name).join(', ') : ''}`}
          </ReactTooltip>
        </ActivityCalendar>
        {clickedLiftData && clickedLiftData.map((ltg, index) => <div key={`${index}`} style={{ paddingTop: 10 }}><SmallCard key={`smallcard-${index}`} item={ltg} /></div>)}
        <div
          style={{
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 15,
            marginTop: 15,
          }}>
          Last 4 Weeks
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', height: 350, marginBottom: 25 }}>
          <RadarChart
            cData={currentPoundsByMuscleTypeByWeek}
            labels={Object.keys(previousFourWeeksLGsGrouped)}
          />
          <BarLineComboChart
            height={350}
            width={450}
            minBarDisplayValue={5}
            maxBarValue={maxprevfourbarvalue}
            barData={previousFourWeeksBarChartData}
            lineData={previousFourWeeksLineData}
            barDomainPadding={[10, 60]}
            lineDomainPadding={[35, 40]}
            formatBarLabel={({ datum }) => `${datum.y}`}
          />
        </div>
        <div
          style={{
            color: theme.darkMode ? 'rgb(255,255,255)' : 'rgb(0,0,0)',
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 15,
            marginTop: 15,
          }}>
          Last 12 Months
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', height: 350, marginBottom: 25 }}>
          <BarLineComboChart
            height={350}
            width={containerWidth - 100}
            minBarDisplayValue={20}
            maxBarValue={maxprevioustwelvebarvalue}
            barData={previoustwelveMonthsbarChartData}
            lineData={previoustwelveMonthsVarLineData}
            barDomainPadding={[10, 50]}
            lineDomainPadding={[30, 30]}
            barLabelPadding={20}
            formatBarLabel={({ datum }) => `${datum.y}`}
          />
        </div>
        <div style={{ paddingTop: 15, display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
          <ToggleButtonGroup
            color="primary"
            value={toggleIndex}
            size={'small'}
            exclusive
            onChange={(_, newAlignment) => setToggleIndex(newAlignment)}
          >
            {Object.keys(toggleLookup).map((k, index) => {
              const label = toggleLookup[k];
              return <ToggleButton key={`dropdown-${index}`} value={k}>{label}</ToggleButton>
            })}
          </ToggleButtonGroup>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          <div style={{ width: 380 }}><PeriodChart
            key={'Chest'}
            muscleGroup={'Chest'}
            dataByPeriod={dataByPeriod}
            toggleIndex={toggleIndex}
            currentPeriodLGs={currentPeriodLGs}
            theExercises={exercises}
          /></div>
          <div style={{ width: 380 }}><PeriodChart
            key={'Back'}
            muscleGroup={'Back'}
            dataByPeriod={dataByPeriod}
            toggleIndex={toggleIndex}
            currentPeriodLGs={currentPeriodLGs}
            theExercises={exercises}
          /></div>
          <div style={{ width: 380 }}><PeriodChart
            key={'Shoulders'}
            muscleGroup={'Shoulders'}
            dataByPeriod={dataByPeriod}
            toggleIndex={toggleIndex}
            currentPeriodLGs={currentPeriodLGs}
            theExercises={exercises}
          /></div>
          <div style={{ width: 380 }}><PeriodChart
            key={'Arms'}
            muscleGroup={'Arms'}
            dataByPeriod={dataByPeriod}
            toggleIndex={toggleIndex}
            currentPeriodLGs={currentPeriodLGs}
            theExercises={exercises}
          /></div>
          <div style={{ width: 380 }}><PeriodChart
            key={'Legs'}
            muscleGroup={'Legs'}
            dataByPeriod={dataByPeriod}
            toggleIndex={toggleIndex}
            currentPeriodLGs={currentPeriodLGs}
            theExercises={exercises}
          /></div>
          <div style={{ width: 380 }}><PeriodChart
            key={'Abs'}
            muscleGroup={'Abs'}
            dataByPeriod={dataByPeriod}
            toggleIndex={toggleIndex}
            currentPeriodLGs={currentPeriodLGs}
            theExercises={exercises}
          /></div>
        </div>
      </>}
    {displayIndex === '1' && <Exercises uid={currentUser.uid} exercises={exercises} lift_data={lift_data} />}
    {displayIndex === '2' && <PhotoLightGallery photos={photos} thumbs={thumbs} lift_data={lift_data} />}
  </div>
}

export default Profile;