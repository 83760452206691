/**
 * Created by tino on 6/6/17.
 */
import React, { useState } from "react";

import { ThumbUp } from '@material-ui/icons';
import styles from "./styles";
import EditOptions from "./EditOptions";
import Avatar from '../Avatar';
import moment from "moment";

function timeDifference(current, previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return "Now";
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
    } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " days ago";
    } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " months ago";
    } else {
        return Math.round(elapsed / msPerYear) + " years ago";
    }
}

function convertToDate(value) {
    if (value === undefined || value === null || value === "") {
        return new Date();
    }
    if (typeof value === "string" || typeof value === "number") {
        return moment(value).toDate();
    }
    if (typeof value === "object" && typeof value.seconds === "number") {
        try {
            return value.toDate();
        } catch (err) {
            return new Date();
        }
    }
    if (typeof value === "object") {
        return value;
    }
    return new Date();
}

const Comment = (props) => {

    const [menuVisible, setMenuVisible] = useState(false);

    const getStyle = (name) => {
        return props.styles && props.styles[name]
            ? props.styles[name]
            : {};
    }

    const handleReport = () => {
        setMenuVisible(false)
        if (window.confirm("Are you sure you want to report?")) {
            props.reportAction(props.data);
        }
    }

    // const handleReply = () => {
    //     props.replyAction(props.data);
    // }

    const handleLike = () => {
        props.likeAction(props.data);
    }

    const handleEdit = () => {
        setMenuVisible(false)
        props.editComment(props.data);
    }

    const handleDelete = () => {
        setMenuVisible(false)
        if (window.confirm("Are you sure you want to delete?")) {
            props.deleteAction(props.data);
        }
    }

    const handleUsernameTap = () => {
        if (props.usernameTapAction) {
            props.usernameTapAction(props.user_id);
        }
    }

    const handleLikesTap = () => {
        props.likesTapAction(props.data);
    }

    return (
        <div  style={{...styles.commentContainer, ...getStyle("commentContainer")}}>
            <div style={{...styles.left, ...getStyle("left")}}>
                <div>
                    <div style={{ alignItems: "center" }} >
                        <div onClick={handleUsernameTap}>
                            <Avatar color={props.likedColor} image={<img
                                alt="commenter"
                                src={props.image} />}
                                size={30}
                                onClick={handleUsernameTap} />
                        </div>
                        {props.likesNr && props.likeAction ? (
                            <div
                                style={{...styles.actionButton,
                                    ...{ paddingTop: 5 },
                                    ...getStyle("actionButton"),
                                }}
                                onClick={handleLikesTap}
                            >
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                    <ThumbUp style={{ fill: props.likedColor || "grey", fontSize: 15 }} />
                                    <div style={{...styles.likeNr, ...getStyle("likeNr")}}>
                                        {" "}
                                        {props.likesNr}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {menuVisible && (
                <EditOptions
                    closeMenu={() => {
                        setMenuVisible(false)
                    }}
                    canEdit={props.canEdit}
                    reportAction={handleReport}
                    isOwnComment={props.isOwnComment}
                    reported={props.reported}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                />
            )}
            <div
                onClick={() => {setMenuVisible(false)}}
                onDoubleClick={() => {setMenuVisible(true)}}
                style={{...styles.right, ...getStyle("right")}}
            >
                <div style={{...styles.rightContent, ...getStyle("rightContent")}}>
                    <div
                        style={{...styles.rightContentTop, ...getStyle("rightContentTop")}}
                    >
                        <div onClick={handleUsernameTap}>
                            <div style={{...styles.name, ...getStyle("username")}}>
                                {props.username}
                            </div>
                        </div>
                    </div>
                    <div style={{...styles.body, ...getStyle("body")}}>
                        {props.body}
                    </div>
                </div>
                <div
                    style={{...styles.rightActionBar, ...getStyle("rightActionBar")}}
                >
                    <div style={{...styles.time, ...getStyle("timeAgoText")}}>
                        {timeDifference(new Date(), convertToDate(props.updatedAt))}
                    </div>
                    {props.likeAction ? (
                        <div
                            style={{...styles.actionButton, ...getStyle("actionButton")}}
                            onClick={handleLike}
                        >
                            <div style={{ flexDirection: "row" }}>
                                <div
                                    style={{...styles.actionText,
                                        ...getStyle("actionText"),
                                        ...getStyle("likeText"),
                                        ...{
                                            color: props.liked
                                                ? props.likedColor || "#4DB2DF"
                                                : props.unlikedColor || "#9B9B9B",
                                        },
                                    }}
                                >
                                    Like{" "}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {/* {props.replyAction ? (
                        <div
                            style={{...styles.actionButton, ...getStyle("actionButton")}}
                            onClick={handleReply}
                        >
                            <div
                                style={{...styles.actionText,
                                    ...getStyle("actionText"),
                                    ...getStyle("replyText"),
                                }}
                            >
                                Reply
                            </div>
                        </div>
                    ) : null} */}
                </div>
            </div>
        </div>
    );
}

export default Comment;